import { Button } from '@material-ui/core';
import React, { forwardRef, useEffect, useImperativeHandle } from 'react';
import { toast } from 'react-toastify';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';
import Web3 from 'web3';
import ABI from '../../ABI/ABI.json';
import DETH_ABI from '../../ABI/DETH_ABI.json';
import { acceptBId_Action, BidApply_ApproveAction, CancelBid_Action } from '../../actions/token';
import config from '../../lib/config';

toast.configure();
let toasterOption = config.toasterOption;
const IPFS_IMGurl = config.IPFS_IMG;

const exchangeAddress = config.multipleContract;

export const PlaceAndAcceptBidRef = forwardRef((props, ref) => {
  const [BidformSubmit, Set_BidformSubmit] = React.useState(false);
  const [NoOfToken_NeedToSend, Set_NoOfToken_NeedToSend] = React.useState(false);
  const [MetaMaskAmt, setMetaMaskAmt] = React.useState(false);
  const [AcceptCallStatus, setAccaptCallStatus] = React.useState('init');

  const convert = (n) => {
    var sign = +n < 0 ? '-' : '',
      toStr = n.toString();
    if (!/e/i.test(toStr)) {
      return n;
    }
    var [lead, decimal, pow] = n
      .toString()
      .replace(/^-/, '')
      .replace(/^([0-9]+)(e.*)/, '$1.$2')
      .split(/e|\./);
    return +pow < 0
      ? sign + '0.' + '0'.repeat(Math.max(Math.abs(pow) - 1 || 0, 0)) + lead + decimal
      : sign +
          lead +
          (+pow >= decimal.length
            ? decimal + '0'.repeat(Math.max(+pow - decimal.length || 0, 0))
            : decimal.slice(0, +pow) + '.' + decimal.slice(+pow));
  };

  const PriceCalculate_this = (data = {}) => {
    var price = typeof data.tokenBidAmt !== 'undefined' ? data.tokenBidAmt : tokenBidAmt;
    var quantity = typeof data.NoOfToken !== 'undefined' ? data.NoOfToken : NoOfToken;
    if (price === '') {
      price = 0;
    }
    if (quantity === '') {
      quantity = 0;
    }
    if (isNaN(price) !== true && isNaN(quantity) !== true) {
      let totalPrice;
      if (item.type === 721) {
        totalPrice = price;
      } else {
        totalPrice = price * quantity;
      }
      totalPrice = parseFloat(totalPrice);
      var per = (totalPrice * config.decimalvalues * config.fee) / 1e18;
      var sendMMAmt = totalPrice * config.decimalvalues + per;
      setMetaMaskAmt(sendMMAmt);
      // var finalPrice = totalPrice + per;
      // var totalPriceWithFee = parseFloat(finalPrice).toFixed(config.toFixed);
      // Set_YouWillPay(totalPriceWithFee);

      var finalPrice = sendMMAmt / config.decimalvalues;
      var totalPriceWithFee = parseFloat(finalPrice).toFixed(config.toFixed);
      Set_YouWillPay(totalPriceWithFee);
    } else {
      Set_YouWillPay(0);
    }
  };

  const Validation_PlaceABid = (chk) => {
    if (chk) {
      var ValidateError = {};

      if (NoOfToken === '') {
        ValidateError.NoOfToken = '"Quantity" is not allowed to be empty';
      } else if (isNaN(NoOfToken) === true) {
        ValidateError.NoOfToken = '"Quantity" must be a number';
      } else if (NoOfToken === 0) {
        ValidateError.NoOfToken = '"Quantity" is required';
      } else if (NoOfToken > AllowedQuantity) {
        ValidateError.NoOfToken = '"Quantity" must be less than or equal to ' + AllowedQuantity;
      }

      if (tokenBidAmt === '') {
        ValidateError.tokenBidAmt = '"Bid amount" is not allowed to be empty';
      } else if (isNaN(tokenBidAmt) === true) {
        ValidateError.tokenBidAmt = '"Bid amount" must be a number';
      } else if (tokenBidAmt === 0) {
        ValidateError.tokenBidAmt = '"Bid amount" must be greater than 0';
      } else if (item.minimumBid > tokenBidAmt) {
        ValidateError.tokenBidAmt = '"Bid amount" must be higher than or equal to ' + item.minimumBid;
      }

      Set_ValidateError(ValidateError);
      return ValidateError;
    }
  };
  const onKeyUp = (e) => {
    var charCode = e.keyCode;
    var ValidateError = {};
    if ((charCode > 47 && charCode < 58) || (charCode > 95 && charCode < 106)) {
      Set_ValidateError(ValidateError);
    } else {
      ValidateError.NoOfToken = '"Quantity" must be a number';
      Set_NoOfToken('');
      Set_ValidateError(ValidateError);
    }
  };
  const inputChange = (e) => {
    if (e && e.target && typeof e.target.value !== 'undefined' && e.target.name) {
      var value = e.target.value;

      switch (e.target.name) {
        case 'tokenBidAmt':
          if (value !== '' && isNaN(value) === false && value > 0) {
            Set_tokenBidAmt(value);
            PriceCalculate_this({ tokenBidAmt: value });
            ValidateError.tokenBidAmt = '';
            Set_ValidateError(ValidateError);
          } else if (value === 0) {
            ValidateError.tokenBidAmt = '"Bid amount" must be greater than 0';
            Set_tokenBidAmt('0');
            PriceCalculate_this({ tokenBidAmt: value });
            Set_ValidateError(ValidateError);
          } else if (value === '') {
            ValidateError.tokenBidAmt = '"Bid amount" is not allowed to be empty';
            // Set_tokenBidAmt("")
            // PriceCalculate_this({tokenBidAmt:""});
            Set_ValidateError(ValidateError);
          } else {
            ValidateError.tokenBidAmt = 'Enter Valid price';
            Set_ValidateError(ValidateError);
            Set_tokenBidAmt('0');
            PriceCalculate_this({ tokenBidAmt: value });
          }
          break;
        case 'NoOfToken':
          Set_NoOfToken(value);
          PriceCalculate_this({ NoOfToken: value });
          break;
        default:
          break;
      }
    }
  };

  const FormSubmit_PlaceABid = async (e) => {
    Set_BidformSubmit(true);
    if (parseFloat(YouWillPay) > TokenBalance) {
      // if (parseFloat(tokenBidAmt)*parseFloat(NoOfToken)>TokenBalance) {
      toast.error('Insufficient token balance', toasterOption);
      return false;
    }
    var errors = await Validation_PlaceABid(true);
    var errorsSize = Object.keys(errors).length;
    if (errorsSize !== 0) {
      toast.error('Form validation error. Fix all mistakes and submit again', toasterOption);
      return false;
    }
    window.$('#place_bid_modal').modal('hide');
    window.$('#proceed_bid_modal').modal('show');
  };

  const BidApply_ApproveCall = async () => {
    if (!window.ethereum) {
      toast.warning('OOPS!..connect Your Wallet', toasterOption);
      return false;
    }

    var web3 = new Web3(window.ethereum);
    var currAddr = window.web3.eth.defaultAccount;
    if (!currAddr) {
      toast.warning('OOPS!..connect Your Wallet', toasterOption);
    }

    Set_BidApply_ApproveCallStatus('processing');
    var CoursetroContract = new web3.eth.Contract(DETH_ABI, config.tokenAddr[config.tokenSymbol]);
    var getAllowance = await CoursetroContract.methods.allowance(currAddr, exchangeAddress).call();
    var sendVal = parseInt(MetaMaskAmt) + parseInt(getAllowance);
    sendVal = convert(sendVal);
    CoursetroContract.methods
      .approve(exchangeAddress, sendVal.toString())
      .send({ from: Accounts })
      .then(async (result) => {
        toast.success('Approve Successfully', toasterOption);
        var BidData = {
          tokenCounts: item.tokenCounts,
          tokenBidAddress: UserAccountAddr,
          tokenBidAmt: tokenBidAmt.toString(),
          NoOfToken: item.type === 721 ? 1 : NoOfToken,
        };
        var Resp = await BidApply_ApproveAction(BidData);
        if (Resp.data && Resp.data.type && Resp.data.type === 'success') {
          Set_BidApply_ApproveCallStatus('done');
        } else {
          toast.error('Approve failed', toasterOption);
          Set_BidApply_ApproveCallStatus('tryagain');
        }
      })
      .catch((error) => {
        toast.error('Approve failed', toasterOption);
        Set_BidApply_ApproveCallStatus('tryagain');
      });
  };
  const BidApply_SignCall = async () => {
    if (!window.ethereum) {
      toast.warning('OOPS!..connect Your Wallet', toasterOption);
      return;
    }
    var web3 = new Web3(window.ethereum);
    var currAddr = window.web3.eth.defaultAccount;
    if (!currAddr) {
      toast.warning('OOPS!..connect Your Wallet', toasterOption);
      return;
    }

    Set_BidApply_SignCallStatus('processing');

    web3.eth.personal
      .sign('Bidding a Art', currAddr, 'Bid Placed')
      .then(async (result) => {
        toast.success('Bid sign successfully', toasterOption);
        Set_BidApply_SignCallStatus('done');
        setTimeout(() => window.$('#proceed_bid_modal').modal('hide'), 600);
        setTimeout(() => window.location.reload(), 1200);
      })
      .catch(() => {
        toast.error('Sign failed', toasterOption);
        Set_BidApply_SignCallStatus('tryagain');
      });
  };

  const CancelBid_Proceed = async (curBid_val) => {
    var payload = {
      tokenCounts: curBid_val.tokenCounts,
      tokenBidAddress: curBid_val.tokenBidAddress,
    };
    var Resp = await CancelBid_Action(payload);
    if (Resp && Resp.data && Resp.data.toast && Resp.data.toast.type && Resp.data.toast.message) {
      if (Resp.data.toast.type === 'error') {
        toast.error(Resp.data.toast.message, toasterOption);
      } else if (Resp.data.toast.type === 'success') {
        toast.success(Resp.data.toast.message, toasterOption);
      }
    }
    setTimeout(() => window.$('.modal').modal('hide'), 600);
    window.location.reload();
  };

  const AcceptBid_Proceed = async () => {
    if (window.ethereum) {
      var web3 = new Web3(window.ethereum);

      let CoursetroContract = new web3.eth.Contract(DETH_ABI, config.tokenAddr[config.tokenSymbol]);
      var tokenBal = await CoursetroContract.methods.balanceOf(AccepBidSelect.tokenBidAddress).call();
      var tokenBalance = tokenBal / config.decimalvalues;

      var valueesss =
        parseFloat(YouWillPayFee) * parseFloat(AccepBidSelect.NoOfToken) +
        parseFloat(AccepBidSelect.tokenBidAmt) * parseFloat(AccepBidSelect.NoOfToken);
      if (valueesss > 0 && tokenBalance >= valueesss) {
        CoursetroContract = new web3.eth.Contract(ABI, config.multipleContract);

        var passAmt = parseFloat(YouWillPayFee) + parseFloat(YouWillGet);
        passAmt = passAmt.toFixed(config.toFixed);
        // passAmt = (passAmt * config.decimalvalues).toString();
        passAmt = convert(passAmt * config.decimalvalues);
        if (NoOfToken_NeedToSend) {
          setAccaptCallStatus('processing');
          CoursetroContract.methods
            .acceptBId(
              config.tokenSymbol,
              AccepBidSelect.tokenBidAddress,
              passAmt.toString(),
              item.tokenCounts,
              NoOfToken_NeedToSend,
            )
            .send({ from: Accounts })
            .then(async (result) => {
              setAccaptCallStatus('done');
              let postData = {
                UserAccountAddr_byaccepter: UserAccountAddr, // old owner
                tokenBidAddress: AccepBidSelect.tokenBidAddress, // new owner
                tokenCounts: item.tokenCounts,
                tokenType: item.type,
                NoOfToken: item.type === 721 ? 1 : NoOfToken,
                transactionHash: result.transactionHash,
              };
              let Resp = await acceptBId_Action(postData);
              if (Resp.data && Resp.data.toast && Resp.data.toast.type === 'success') {
                toast.success('Collectible bid accepted', toasterOption);
                window.$('.modal').modal('hide');
                setTimeout(() => {
                  window.location.reload(false);
                }, 2000);
              }
            })
            .catch((err) => {
              console.log('err', err);
              setAccaptCallStatus('tryagain');
              toast.error('Request Rejected ', toasterOption);
            });
        }
      } else {
        toast.error('Bidder doesnt have Enough Balance ', toasterOption);
      }
    }
  };
  const Placebidmodal = async () => {
    setTimeout(() => window.$('#place_bid_modal').modal('hide'), 600);
    setTimeout(() => window.location.reload(false), 900);
  };
  useImperativeHandle(ref, () => ({
    async PlaceABid_Click(itemNew) {
      Set_BidformSubmit(false);
      var connectwallet = localStorage.getItem('jakcojsnick');
      if (!connectwallet) {
        window.location.href = '/connect';
        return false;
      }
      if (Bids && Bids.myBid && Bids.myBid.tokenBidAmt) {
        Set_NoOfToken(Bids.myBid.NoOfToken);
      }
      window.$('#place_bid_modal').modal('show');
    },
    async PriceCalculate(data = {}) {
      PriceCalculate_this(data);
    },
    async AcceptBid_Select(curBid_val) {
      if (window.ethereum) {
        var connectwallet = localStorage.getItem('jakcojsnick');
        if (!connectwallet) {
          window.location.href = '/connect';
          return false;
        }

        if (curBid_val && curBid_val.tokenBidAmt) {
          window.$('#accept_modal').modal('show');
          Set_AccepBidSelect(curBid_val);

          let totalAmt;
          if (MyTokenBalance < curBid_val.pending) {
            Set_NoOfToken_NeedToSend(MyTokenBalance);
            totalAmt = MyTokenBalance * curBid_val.tokenBidAmt;
          } else {
            Set_NoOfToken_NeedToSend(curBid_val.pending);
            totalAmt = curBid_val.pending * curBid_val.tokenBidAmt;
          }
          var ServiceFee_val = (totalAmt * config.fee) / 1e20;
          var YouWillGet_Val = totalAmt - ServiceFee_val;
          Set_YouWillPayFee(ServiceFee_val.toFixed(config.toFixed));
          Set_YouWillGet(YouWillGet_Val.toFixed(config.toFixed));
        }
      } else {
        window.$('#connect_modal').modal('show');
      }
    },
    async CancelBid_Select(curBid_val) {
      if (
        curBid_val &&
        curBid_val.pending > 0 &&
        (curBid_val.status === 'pending' || curBid_val.status === 'partiallyCompleted')
      ) {
        Set_AccepBidSelect(curBid_val);
        window.$('#cancel_modal').modal('show');
      } else {
        window.$('.modal').modal('hide');
      }
    },
  }));

  useEffect(() => {
    Validation_PlaceABid(BidformSubmit);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  var {
    Set_AccepBidSelect,
    Set_tokenBidAmt,
    Set_NoOfToken,
    Set_ValidateError,
    Set_YouWillPay,
    Set_YouWillPayFee,
    Set_YouWillGet,
    Set_BidApply_ApproveCallStatus,
    Set_BidApply_SignCallStatus,
    BidApply_ApproveCallStatus,
    UserAccountAddr,
    UserAccountBal,
    Accounts,
    item,
    MyTokenBalance,
    Bids,
    AccepBidSelect,
    tokenBidAmt,
    NoOfToken,
    ValidateError,
    TokenBalance,
    YouWillPay,
    YouWillPayFee,
    YouWillGet,
    BidApply_SignCallStatus,
    AllowedQuantity,
  } = props;

  return (
    <div>
      <div id="Validation_PlaceABid" onClick={() => Validation_PlaceABid(BidformSubmit)}></div>
      {/* place_bid Modal */}
      <div
        className="modal fade primary_modal"
        id="place_bid_modal"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="place_bid_modalCenteredLabel"
        aria-hidden="true"
        data-backdrop="static"
      >
        <div className="modal-dialog modal-dialog-centered modal-md" role="document">
          <div className="modal-content">
            <div className="modal-header text-center">
              <h5 className="modal-title" id="place_bid_modalLabel">
                Place a bid
              </h5>
              <p className="text-center place_bit_desc">You are about to place a bid for</p>
              <p className="place_bit_desc_2">
                <span className="text_red mr-2 d-block">{item.tokenName} by</span>
                {/* <small className="text_red ml-2">{isEmpty(item.usersinfo) ? item.tokenOwner : item.usersinfo.name}</small> */}
                <small className="text_red ml-2">
                  {item && item.tokenowners_current ? item.tokenowners_current[0].tokenOwner : ' '}
                </small>
              </p>
              <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={Placebidmodal}>
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body px-0 pt-0">
              <form className="px-4 bid_form">
                <label htmlFor="bid">Your bid</label>
                <div className="input-group mb-3 input_grp_style_1">
                  <input
                    type="text"
                    name="tokenBidAmt"
                    id="tokenBidAmt"
                    className="form-control"
                    placeholder="Enter your bit amount"
                    aria-label="bid"
                    aria-describedby="basic-addon2"
                    onChange={inputChange}
                    autoComplete="off"
                    // value={tokenBidAmt}
                  />
                  <div className="input-group-append">
                    <span className="input-group-text" id="basic-addon2">
                      {config.tokenSymbol}
                    </span>
                  </div>
                </div>
                {ValidateError.tokenBidAmt && (
                  <span className="text-danger">
                    <br />
                    {ValidateError.tokenBidAmt}
                  </span>
                )}
                {item.type === config.multipleType && (
                  <label htmlFor="qty">
                    Enter quantity <span className="label_muted pl-2">({AllowedQuantity} available)</span>
                  </label>
                )}
                {item.type === config.multipleType && (
                  <div className="mb-3 input_grp_style_1">
                    <input
                      type="text"
                      name="NoOfToken"
                      id="NoOfToken"
                      className="form-control"
                      placeholder="Enter your bit quantity"
                      onChange={inputChange}
                      autoComplete="off"
                      value={NoOfToken}
                      onKeyUp={onKeyUp}
                    />
                  </div>
                )}
                {ValidateError.NoOfToken && (
                  <span className="text-danger">
                    <br />
                    {ValidateError.NoOfToken}
                  </span>
                )}
                <div className="row pb-3">
                  <div className="col-12 col-sm-6">
                    <p className="buy_desc_sm">Your balance</p>
                  </div>
                  <div className="col-12 col-sm-6 text-sm-right">
                    <p className="buy_desc_sm_bold">
                      {UserAccountBal} {config.currencySymbol}
                    </p>
                  </div>
                </div>
                <div className="row pb-3">
                  <div className="col-12 col-sm-6">
                    <p className="buy_desc_sm">Your bidding balance</p>
                  </div>
                  <div className="col-12 col-sm-6 text-sm-right">
                    <p className="buy_desc_sm_bold">
                      {TokenBalance} {config.tokenSymbol}
                    </p>
                  </div>
                </div>
                <div className="row pb-3">
                  <div className="col-12 col-sm-6">
                    <p className="buy_desc_sm">Service fee</p>
                  </div>
                  <div className="col-12 col-sm-6 text-sm-right">
                    <p className="buy_desc_sm_bold">
                      {config.fee * 100}% <span>{config.tokenSymbol}</span>
                    </p>
                  </div>
                </div>
                <div className="row pb-3">
                  <div className="col-12 col-sm-6">
                    <p className="buy_desc_sm">You will pay</p>
                  </div>
                  <div className="col-12 col-sm-6 text-sm-right">
                    <p className="buy_desc_sm_bold">
                      {YouWillPay} <span>{config.tokenSymbol}</span>
                    </p>
                  </div>
                </div>

                <div className="text-center">
                  {/* data-dismiss="modal" aria-label="Close" data-toggle="modal" data-target="#proceed_bid_modal" */}
                  <Button className="create_btn btn-block" onClick={() => FormSubmit_PlaceABid()}>
                    Place a bid
                  </Button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      {/* end place_bid modal */}

      {/* proceed_bid Modal */}
      <div
        className="modal fade primary_modal"
        id="proceed_bid_modal"
        data-backdrop="static"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="proceed_bid_modalCenteredLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered modal-md" role="document">
          <div className="modal-content">
            <div className="modal-header text-center">
              <h5 className="modal-title" id="proceed_bid_modalLabel">
                Follow Steps
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
                onClick={() => window.location.reload(false)}
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <form>
                <div className="media approve_media">
                  <i className="fas fa-check mr-3 pro_complete" aria-hidden="true"></i>
                  {/* <i className="fa fa-spinner mr-3 spinner_icon" aria-hidden="true"></i> */}
                  <div className="media-body">
                    <p className="mt-0 approve_text">Approve</p>
                    <p className="mt-0 approve_desc">Checking balance and approving</p>
                  </div>
                </div>
                <div className="text-center my-3">
                  <Button
                    className={
                      'btn-block ' +
                      (BidApply_ApproveCallStatus === 'processing' || BidApply_ApproveCallStatus === 'done'
                        ? 'btn_outline_red'
                        : 'create_btn')
                    }
                    disabled={BidApply_ApproveCallStatus === 'processing' || BidApply_ApproveCallStatus === 'done'}
                    onClick={BidApply_ApproveCall}
                  >
                    {BidApply_ApproveCallStatus === 'processing' && (
                      <i className="fa fa-spinner mr-3 spinner_icon" aria-hidden="true" id="circle1"></i>
                    )}
                    {BidApply_ApproveCallStatus === 'init' && 'Approve'}
                    {BidApply_ApproveCallStatus === 'processing' && 'In-progress...'}
                    {BidApply_ApproveCallStatus === 'done' && 'Done'}
                    {BidApply_ApproveCallStatus === 'tryagain' && 'Try Again'}
                  </Button>
                </div>
                <div className="media approve_media">
                  <i className="fas fa-check mr-3" aria-hidden="true"></i>
                  <div className="media-body">
                    <p className="mt-0 approve_text">Signature</p>
                    <p className="mt-0 approve_desc">Create a signatute to place a bid</p>
                  </div>
                </div>
                <div className="text-center mt-3">
                  <Button
                    className={
                      'btn-block ' +
                      (BidApply_ApproveCallStatus !== 'done' ||
                      BidApply_SignCallStatus === 'processing' ||
                      BidApply_SignCallStatus === 'done'
                        ? 'btn_outline_red'
                        : 'create_btn')
                    }
                    disabled={
                      BidApply_ApproveCallStatus !== 'done' ||
                      BidApply_SignCallStatus === 'processing' ||
                      BidApply_SignCallStatus === 'done'
                    }
                    onClick={BidApply_SignCall}
                  >
                    {BidApply_SignCallStatus === 'processing' && (
                      <i className="fa fa-spinner mr-3 spinner_icon" aria-hidden="true" id="circle1"></i>
                    )}
                    {BidApply_SignCallStatus === 'init' && 'Start'}
                    {BidApply_SignCallStatus === 'processing' && 'In-progress...'}
                    {BidApply_SignCallStatus === 'done' && 'Done'}
                    {BidApply_SignCallStatus === 'tryagain' && 'Try Again'}
                  </Button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      {/* end proceed_bid modal */}

      {/* accept bid Modal */}
      <div
        className="modal fade primary_modal"
        id="accept_modal"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="accept_modalCenteredLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered modal-md" role="document">
          <div className="modal-content">
            <div className="modal-header text-center">
              <h5 className="modal-title" id="accept_modalLabel">
                Accept bid
              </h5>
              <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body px-0">
              <div className="img_accept text-center">
                {item && item.image && item.image.split('.').pop() === 'mp4' ? (
                  <video
                    src={`${IPFS_IMGurl}/${item.ipfsimage}`}
                    type="video/mp4"
                    alt="Collections"
                    className="img-fluid"
                    autoPlay
                    controls
                    playsInline
                    loop
                  />
                ) : (
                  <img src={`${IPFS_IMGurl}/${item.ipfsimage}`} alt="Collections" className="img-fluid " />
                )}
              </div>
              <p className="text-center accept_desc">
                <span className="buy_desc_sm">You are about to accept bid for</span>
                <span className="buy_desc_sm_bold pl-2">{item.tokenName}</span>
                <span className="buy_desc_sm pl-2">from</span>
                <span className="buy_desc_sm_bold pl-2">{AccepBidSelect.tokenBidAddress}</span>
              </p>
              <p className="info_title text-center">
                {AccepBidSelect.tokenBidAmt} {config.tokenSymbol} for {AccepBidSelect.NoOfToken} edition(s)
              </p>
              <div className="row mx-0 pb-3">
                <div className="col-12 col-sm-6 px-4">
                  <p className="buy_desc_sm">Service fee in %</p>
                </div>
                <div className="col-12 col-sm-6 px-4 text-sm-right">
                  <p className="buy_desc_sm_bold">
                    {config.fee * 100}% {config.tokenSymbol}
                  </p>
                </div>
              </div>
              <div className="row mx-0 pb-3">
                <div className="col-12 col-sm-6 px-4">
                  <p className="buy_desc_sm">Service fee in</p>
                </div>
                <div className="col-12 col-sm-6 px-4 text-sm-right">
                  <p className="buy_desc_sm_bold">
                    {YouWillPayFee} {config.tokenSymbol}
                  </p>
                </div>
              </div>
              <div className="row mx-0 pb-3">
                <div className="col-12 col-sm-6 px-4">
                  <p className="buy_desc_sm">You will get</p>
                </div>
                <div className="col-12 col-sm-6 px-4 text-sm-right">
                  <p className="buy_desc_sm_bold">
                    {YouWillGet} {config.tokenSymbol}
                  </p>
                </div>
              </div>
              <form className="px-4">
                <div className="text-center">
                  <Button
                    className="create_btn btn-block"
                    onClick={() => AcceptBid_Proceed()}
                    disabled={AcceptCallStatus === 'processing'}
                  >
                    {AcceptCallStatus === 'processing' && (
                      <i className="fa fa-spinner mr-3 spinner_icon" aria-hidden="true" id="circle1"></i>
                    )}
                    Accept bid
                  </Button>
                  <Button className="btn_outline_red btn-block" data-dismiss="modal" aria-label="Close">
                    Cancel
                  </Button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      {/* end accept bid modal */}

      {/* accept bid Modal */}
      <div
        className="modal fade primary_modal"
        id="cancel_modal"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="accept_modalCenteredLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered modal-md" role="document">
          <div className="modal-content">
            <div className="modal-header text-center">
              <h5 className="modal-title" id="accept_modalLabel">
                Cancel bid
              </h5>
              <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body px-0">
              <div className="img_accept text-center">
                {item && item.image && item.image.split('.').pop() === 'mp4' ? (
                  <video
                    src={`${IPFS_IMGurl}/${item.ipfsimage}`}
                    type="video/mp4"
                    alt="Collections"
                    className="img-fluid"
                    autoPlay
                    controls
                    playsInline
                    loop
                  />
                ) : (
                  <img src={`${IPFS_IMGurl}/${item.ipfsimage}`} alt="Collections" className="img-fluid " />
                )}
              </div>
              <p className="text-center accept_desc">
                <span className="buy_desc_sm">You are about to cancel bid for</span>
                <span className="buy_desc_sm_bold pl-2">{item.tokenName}</span>
              </p>
              <p className="info_title text-center">
                {AccepBidSelect.tokenBidAmt} {config.tokenSymbol} for 1 edition(s)
              </p>
              <form className="px-4">
                <div className="text-center">
                  <Button className="create_btn btn-block" onClick={() => CancelBid_Proceed(AccepBidSelect)}>
                    Cancel bid
                  </Button>
                  <Button className="btn_outline_red btn-block" data-dismiss="modal" aria-label="Close">
                    Cancel
                  </Button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      {/* end accept bid modal */}
    </div>
  );
});
