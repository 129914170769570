import { Button } from '@material-ui/core';
// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';
import '@metamask/legacy-web3';
import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import { Scrollbars } from 'react-custom-scrollbars-2';
import ReactMarkdown from 'react-markdown';
import { useParams } from 'react-router-dom';
import { FacebookIcon, FacebookShareButton, TwitterIcon, TwitterShareButton } from 'react-share';
// Slick Carousel
import Slider from 'react-slick';
import { toast } from 'react-toastify';
import remarkGfm from 'remark-gfm';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';
import Web3 from 'web3';
import ABI from '../ABI/ABI.json';
import DETH_ABI from '../ABI/DETH_ABI.json';
import {
  Bidding_Detail_Action,
  BurnField,
  HistoryList,
  otherauthorlist,
  TokenCounts_Get_Detail_Action,
} from '../actions/token';
import { getCurAddr, halfAddrShow } from '../actions/user';
import styles from '../assets/jss/material-kit-react/views/landingPage.js';
import Footer from '../components/Footer/Footer';
// core components
import Header from '../components/Header/Header';
import HeaderLinks from '../components/Header/HeaderLinks';
import config from '../lib/config';
import { BurnRef } from './separate/BurnRef';
import { CancelOrderRef } from './separate/CancelOrderRef';
import ConnectWallet from './separate/ConnectWallet';
import { LikeRef } from './separate/LikeRef';
import { PlaceAndAcceptBidRef } from './separate/PlaceAndAcceptBidRef';
import { PurchaseNowRef } from './separate/PurchaseNowRef';
import { PutOnSaleRef } from './separate/PutOnSaleRef';
import { ReportNowRef } from './separate/ReportNowRef';
import { ShareNowRef } from './separate/ShareNowRef';
import TokenItem from './separate/TokenItem';
import { WalletRef } from './separate/WalletRef';

toast.configure();
let toasterOption = config.toasterOption;
const contractaddress = config.multipleContract;
const dashboardRoutes = [];
const IPFS_IMGurl = config.IPFS_IMG;

const multipleContract = config.multipleContract;

const useStyles = makeStyles(styles);

// Scroll to Top
function ScrollToTopOnMount() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return null;
}

export default function Info(props) {
  // Countdown Timer

  var LikeForwardRef = useRef();
  var PlaceABidForwardRef = useRef();
  var PutOnSaleForwardRef = useRef();
  var PurchaseNowForwardRef = useRef();
  var CancelOrderForwardRef = useRef();
  var WalletForwardRef = useRef();
  var BurnForwardRef = useRef();
  var ReportForwardRef = useRef();
  var ShareForwardRef = useRef();

  var { tokenidval } = useParams();

  // wallet related : common state
  const [WalletConnected, Set_WalletConnected] = React.useState('false');
  const [UserAccountAddr, Set_UserAccountAddr] = React.useState('');
  const [UserAccountBal, Set_UserAccountBal] = React.useState(0);
  const [AddressUserDetails, Set_AddressUserDetails] = useState({});
  const [Accounts, Set_Accounts] = React.useState('');
  const [MyItemAccountAddr, Set_MyItemAccountAddr] = React.useState('');
  const [tokenCounts, Set_tokenCounts] = useState(tokenidval);
  const [item, Set_item] = useState({});
  const [tokenCounts_Detail, Set_tokenCounts_Detail] = useState({});
  const [AllowedQuantity, Set_AllowedQuantity] = useState({});
  const [MyTokenBalance, Set_MyTokenBalance] = useState(0);
  const [MyTokenDetail, Set_MyTokenDetail] = useState({});
  const [Bids, Set_Bids] = useState([]);
  const [AccepBidSelect, Set_AccepBidSelect] = useState(0);
  const [tokenBidAmt, Set_tokenBidAmt] = useState(0);
  const [NoOfToken, Set_NoOfToken] = useState(1);
  const [ValidateError, Set_ValidateError] = useState({});
  const [TokenBalance, Set_TokenBalance] = useState(0);
  const [YouWillPay, Set_YouWillPay] = useState(0);
  const [YouWillPayFee, Set_YouWillPayFee] = useState(0);
  const [YouWillGet, Set_YouWillGet] = useState(0);
  const [LikedTokenList, setLikedTokenList] = React.useState([]);
  const [servicefee, setservicefee] = React.useState(0);
  // const [MultipleWei, Set_MultipleWei] = useState(0);
  const [BidApply_ApproveCallStatus, Set_BidApply_ApproveCallStatus] = React.useState('init');
  const [BidApply_SignCallStatus, Set_BidApply_SignCallStatus] = React.useState('init');
  const [HitItem, Set_HitItem] = useState({});
  const [BuyOwnerDetailFirst, Set_BuyOwnerDetailFirst] = useState({});
  const [totaluserbidAmt, setTotaluserbidAmt] = React.useState(0);
  const [tokenlist, settokenlist] = React.useState([]);

  const [buytoken, set_buytoken] = React.useState('ORE');

  const [burnLoading, setBurnLoading] = useState('empty');
  const [noofitems, setnoofitem] = useState();
  const [tokenHistory, set_tokenHistory] = useState([]);

  const AfterWalletConnected = async () => {
    var curAddr = await getCurAddr();
    var payload = {
      curAddr: curAddr,
      tokenCounts: tokenidval,
    };
    TokenCounts_Get_Detail_Call(payload);
    BiddingAmout();

    if (window.ethereum) {
      var web3 = new Web3(window.ethereum);
      var CoursetroContract = new web3.eth.Contract(DETH_ABI, config.tokenAddr[config.tokenSymbol]);
      var tokenBal = await CoursetroContract.methods.balanceOf(curAddr).call();
      var tokenBalance = tokenBal / config.decimalvalues;
      Set_TokenBalance(tokenBalance.toFixed(5));
    }

    try {
      LikeForwardRef.current.getLikesData();
    } catch (err) {}
    getBiddingHistory();
  };

  async function getBiddingHistory() {
    var payload = {
      tokenCounts: tokenidval,
    };
    var Resp = await HistoryList(payload);
    if (Resp && Resp.data && Resp.data.list) {
      set_tokenHistory(Resp.data.list);
    }
  }

  const TokenCounts_Get_Detail_Call = async (payload) => {
    var curAddr = await getCurAddr();
    var Resp = await TokenCounts_Get_Detail_Action(payload);
    if (Resp && Resp && Resp.data && Resp.data.Detail && Resp.data.Detail.Resp) {
      var TokenResp = Resp.data.Detail.Resp;

      if (TokenResp && TokenResp.Token && TokenResp.Token[0] && TokenResp.Token[0].tokenowners_current) {
        set_buytoken(TokenResp.Token[0].biddingtoken);

        for (let i = 0; i < TokenResp.Token[0].tokenowners_current.length; i++) {
          const element = TokenResp.Token[0].tokenowners_current[i];
          if (element.balance > 0 && element.tokenPrice > 0 && element.tokenOwner !== curAddr) {
            Set_BuyOwnerDetailFirst(element);
            break;
          }
        }
      }
      Set_tokenCounts_Detail(TokenResp);

      if (TokenResp.Bids) {
        Set_Bids(TokenResp.Bids);
      }

      var IndexVal = -1;

      if (TokenResp && TokenResp.Token[0] && TokenResp.Token[0].tokenowners_all && curAddr) {
        var tokenowners_all = TokenResp.Token[0].tokenowners_all;
        IndexVal = tokenowners_all.findIndex((val) => val.tokenOwner.toString() === curAddr.toString());
      }

      var newMyTokenBalance = 0;

      if (IndexVal > -1) {
        newMyTokenBalance = tokenowners_all[IndexVal].balance;

        Set_MyTokenBalance(newMyTokenBalance);
        Set_MyTokenDetail(tokenowners_all[IndexVal]);
      } else {
        newMyTokenBalance = 0;
        Set_MyTokenBalance(0);
        Set_MyTokenDetail({});
      }

      var burnQty = 0;
      if (TokenResp.BurnToken && TokenResp.BurnToken > 0) {
        burnQty = TokenResp.BurnToken;
      }

      if (TokenResp.TotalQuantity) {
        Set_AllowedQuantity(TokenResp.TotalQuantity - newMyTokenBalance - burnQty);
      } else {
        Set_AllowedQuantity(0);
      }
      if (TokenResp.Token && TokenResp.Token[0]) {
        Set_item(TokenResp.Token[0]);
        othertokenlist(
          TokenResp.Token[0] &&
            TokenResp.Token[0].tokenowners_current &&
            TokenResp.Token[0].tokenowners_current[0] &&
            TokenResp.Token[0].tokenowners_current[0].tokenOwner
            ? TokenResp.Token[0].tokenowners_current[0].tokenOwner
            : '',
        );
      }
    }
  };

  async function othertokenlist(addy) {
    var payload = {
      limit: config.limit,
      page: 1,
      currAddr: UserAccountAddr,
      CatName: '',
      from: 'Home',
      tokeyowner: addy,
    };
    var resp = await otherauthorlist(payload);
    if (resp && resp.data && resp.data.list) {
      settokenlist(resp.data.list);
    }
  }

  async function BiddingAmout() {
    try {
      var currAddr = window.web3.eth.defaultAccount;
      var payload = { currAddr };
      var Resp = await Bidding_Detail_Action(payload);
      if (Resp && Resp.data) {
        setTotaluserbidAmt(Resp.data.res1);
      }
    } catch (err) {}
  }

  async function GetUserBal() {
    await WalletForwardRef.current.GetUserBal();
  }

  const classes = useStyles();
  const { ...rest } = props;

  useEffect(() => {
    getservicefee();
  }, []);

  const Burntoken = async (data, MyTokenDetail) => {
    if (GetUserBal < 0) {
      toast.error('Invalid balance');
      return false;
    }
    if (window.ethereum) {
      var web3 = new Web3(window.ethereum);
      if (web3 && web3.eth) {
        setBurnLoading('processing');

        const newAidicontract = new web3.eth.Contract(ABI, multipleContract);

        newAidicontract.methods
          .burn(UserAccountAddr, item.tokenCounts, noofitems)
          .send({ from: Accounts })
          .then(async (result) => {
            setBurnLoading('done');
            var postData = {
              tokenOwner: UserAccountAddr,
              tokenCounts: data.tokenCounts,
              // balance: balance,
              // balance: MyTokenDetail.balance,
              blockHash: result.blockHash,
              transactionHash: result.transactionHash,
              contractAddress: data.contractAddress,
              type: data.type,
              balance: noofitems,
              currAddr: UserAccountAddr,
              quant: MyTokenDetail.balance,
            };

            var updateBurnField = await BurnField(postData);
            if (updateBurnField) {
              toast.success('Burned successfully', toasterOption);
              // window.$('#burn_token_modal').modal('hide');
              document.getElementById('closeburn').click();
              window.location.reload();
            }
          })
          .catch((error) => {
            setBurnLoading('error');
            toast.error('Order not placed', toasterOption);
          });
      }
    }
  };

  const handleChange = (e) => {
    if (e.target && e.target.value)
      if (MyTokenDetail.balance >= e.target.value) {
        setnoofitem(e.target.value);
        setBurnLoading('init');
      } else if (e.target.value === 0) {
        setBurnLoading('zero');
      } else if (e.target.value === '') {
        setBurnLoading('empty');
      } else if (e.target.value === undefined) {
        setBurnLoading('empty');
      } else {
        setBurnLoading('errors');
      }
  };

  async function getservicefee() {
    var web3 = new Web3(window.ethereum);
    var CoursetroContract = new web3.eth.Contract(ABI, contractaddress);
    var fee = await CoursetroContract.methods.getServiceFee().call();
    setservicefee(fee / config.decimalvalues);
  }

  var settingsLiveAuction = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1279,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 959,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 599,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  async function copyToClipboard(e) {
    var textField = document.createElement('textarea');
    textField.innerText = config.Front_URL + '/info/' + tokenidval;
    document.body.appendChild(textField);
    textField.select();
    document.execCommand('copy');
    textField.remove();
    toast.success('Copied Successfully', toasterOption);
  }
  return (
    <div className="inner_header">
      <ConnectWallet
        Set_UserAccountAddr={Set_UserAccountAddr}
        Set_UserAccountBal={Set_UserAccountBal}
        Set_WalletConnected={Set_WalletConnected}
        Set_AddressUserDetails={Set_AddressUserDetails}
        Set_Accounts={Set_Accounts}
        WalletConnected={WalletConnected}
        AfterWalletConnected={AfterWalletConnected}
      />
      <PutOnSaleRef
        ref={PutOnSaleForwardRef}
        Set_HitItem={Set_HitItem}
        item={HitItem}
        UserAccountAddr={UserAccountAddr}
        UserAccountBal={UserAccountBal}
        Accounts={Accounts}
        GetUserBal={GetUserBal}
      />
      <LikeRef ref={LikeForwardRef} setLikedTokenList={setLikedTokenList} MyItemAccountAddr={MyItemAccountAddr} />
      <PlaceAndAcceptBidRef
        ref={PlaceABidForwardRef}
        Set_WalletConnected={Set_WalletConnected}
        Set_UserAccountAddr={Set_UserAccountAddr}
        Set_UserAccountBal={Set_UserAccountBal}
        Set_AddressUserDetails={Set_AddressUserDetails}
        Set_Accounts={Set_Accounts}
        Set_MyItemAccountAddr={Set_MyItemAccountAddr}
        Set_tokenCounts={Set_tokenCounts}
        Set_item={Set_item}
        Set_tokenCounts_Detail={Set_tokenCounts_Detail}
        Set_MyTokenBalance={Set_MyTokenBalance}
        Set_Bids={Set_Bids}
        Set_AccepBidSelect={Set_AccepBidSelect}
        Set_tokenBidAmt={Set_tokenBidAmt}
        Set_NoOfToken={Set_NoOfToken}
        Set_ValidateError={Set_ValidateError}
        Set_TokenBalance={Set_TokenBalance}
        Set_YouWillPay={Set_YouWillPay}
        Set_YouWillPayFee={Set_YouWillPayFee}
        Set_YouWillGet={Set_YouWillGet}
        Set_BidApply_ApproveCallStatus={Set_BidApply_ApproveCallStatus}
        Set_BidApply_SignCallStatus={Set_BidApply_SignCallStatus}
        WalletConnected={WalletConnected}
        UserAccountAddr={UserAccountAddr}
        UserAccountBal={UserAccountBal}
        AddressUserDetails={AddressUserDetails}
        Accounts={Accounts}
        MyItemAccountAddr={MyItemAccountAddr}
        tokenCounts={tokenCounts}
        item={item}
        tokenCounts_Detail={tokenCounts_Detail}
        MyTokenBalance={MyTokenBalance}
        Bids={Bids}
        AccepBidSelect={AccepBidSelect}
        tokenBidAmt={tokenBidAmt}
        NoOfToken={NoOfToken}
        ValidateError={ValidateError}
        TokenBalance={TokenBalance}
        YouWillPay={YouWillPay}
        YouWillPayFee={YouWillPayFee}
        YouWillGet={YouWillGet}
        BidApply_ApproveCallStatus={BidApply_ApproveCallStatus}
        BidApply_SignCallStatus={BidApply_SignCallStatus}
        AllowedQuantity={AllowedQuantity}
        totaluserbidAmt={totaluserbidAmt}
        setTotaluserbidAmt={setTotaluserbidAmt}
      />
      <WalletRef
        ref={WalletForwardRef}
        Set_UserAccountAddr={Set_UserAccountAddr}
        Set_WalletConnected={Set_WalletConnected}
        Set_UserAccountBal={Set_UserAccountBal}
      />
      <PurchaseNowRef
        ref={PurchaseNowForwardRef}
        Set_HitItem={Set_HitItem}
        item={HitItem}
        setservicefee={setservicefee}
        servicefee={servicefee}
        UserAccountAddr={UserAccountAddr}
        UserAccountBal={UserAccountBal}
        TokenBalance={TokenBalance}
        Accounts={Accounts}
        GetUserBal={GetUserBal}
        dethbalance={TokenBalance}
        buytoken={buytoken}
      />
      <CancelOrderRef
        ref={CancelOrderForwardRef}
        Set_HitItem={Set_HitItem}
        item={HitItem}
        UserAccountAddr={UserAccountAddr}
        UserAccountBal={UserAccountBal}
        Accounts={Accounts}
        GetUserBal={GetUserBal}
      />
      <BurnRef
        ref={BurnForwardRef}
        GetUserBal={GetUserBal}
        UserAccountAddr={UserAccountAddr}
        UserAccountBal={UserAccountBal}
        TokenBalance={TokenBalance}
        Accounts={Accounts}
      />
      <ReportNowRef UserAccountAddr={UserAccountAddr} ref={ReportForwardRef} />
      <ShareNowRef ref={ShareForwardRef} />
      <Header
        fixed
        color="transparent"
        routes={dashboardRoutes}
        brand={<img src={require('../assets/images/logo.png')} alt="logo" className="img-fluid" />}
        rightLinks={<HeaderLinks />}
        changeColorOnScroll={{
          height: 50,
          color: 'dark',
        }}
        {...rest}
      />

      <ScrollToTopOnMount />
      <div className={classes.pageHeader + ' inner_pageheader info_header'}>
        <main className="main">
          <div className="container">
            <div className="row row--grid">
              <div className="col-12">
                <div className="main__title main__title--page">
                  <h1>{item.tokenName}</h1>
                  <div className="masonry_likes">
                    <div className="dropdown d-inline-block">
                      <i
                        className="fas fa-ellipsis-h"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                      ></i>
                      <div className="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenuButton">
                        <button type="button" className="dropdown-item" data-toggle="modal" data-target="#share_modal">
                          Share
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-12 col-xl-8">
                <div className="asset__item collum">
                  {item && item.image && item.image.split('.').pop() === 'mp4' ? (
                    <video
                      src={`${IPFS_IMGurl}/${item.ipfsimage}`}
                      type="video/mp4"
                      alt="Collections"
                      className="img-fluid img_info"
                      controls
                    />
                  ) : item && item.image && item.image.split('.').pop() === 'mp3' ? (
                    <audio
                      src={`${IPFS_IMGurl}/${item.ipfsimage}`}
                      type="audio/mp3"
                      controls
                      className="audio"
                      autostart="off"
                    ></audio>
                  ) : (
                    <img src={`${IPFS_IMGurl}/${item.ipfsimage}`} alt="Collections" />
                  )}
                  {item && item.image && item.image.split('.').pop() === 'mp3' && (
                    <img src={config.Back_URL + '/images/music.png'} alt="" />
                  )}
                  <div className="share share--asset">
                    {/* <a className="share__link share__link--fb"><svg width="9" height="17" viewBox="0 0 9 17" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M5.56341 16.8197V8.65888H7.81615L8.11468 5.84663H5.56341L5.56724 4.43907C5.56724 3.70559 5.63693 3.31257 6.69042 3.31257H8.09873V0.5H5.84568C3.1394 0.5 2.18686 1.86425 2.18686 4.15848V5.84695H0.499939V8.6592H2.18686V16.8197H5.56341Z" /></svg> <span onClick={() => facebookshare()}>share</span></a> */}
                    <FacebookShareButton url={config.shareurl + tokenCounts} quote={'ORE FORGE'} className="share">
                      <FacebookIcon size={32} round={true} />
                    </FacebookShareButton>
                    <TwitterShareButton
                      url={config.shareurl + tokenCounts}
                      title={'ORE FORGE'}
                      quote={'ORE FORGE'}
                      className="share"
                    >
                      <TwitterIcon size={32} round={true} />
                    </TwitterShareButton>
                    {/* <a className="share__link share__link--tw"><svg width="16" height="12" viewBox="0 0 16 12" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M7.55075 3.19219L7.58223 3.71122L7.05762 3.64767C5.14804 3.40404 3.47978 2.57782 2.06334 1.1902L1.37085 0.501686L1.19248 1.01013C0.814766 2.14353 1.05609 3.34048 1.843 4.14552C2.26269 4.5904 2.16826 4.65396 1.4443 4.38914C1.19248 4.3044 0.972149 4.24085 0.951164 4.27263C0.877719 4.34677 1.12953 5.31069 1.32888 5.69202C1.60168 6.22165 2.15777 6.74068 2.76631 7.04787L3.28043 7.2915L2.67188 7.30209C2.08432 7.30209 2.06334 7.31268 2.12629 7.53512C2.33613 8.22364 3.16502 8.95452 4.08833 9.2723L4.73884 9.49474L4.17227 9.8337C3.33289 10.321 2.34663 10.5964 1.36036 10.6175C0.888211 10.6281 0.5 10.6705 0.5 10.7023C0.5 10.8082 1.78005 11.4014 2.52499 11.6344C4.75983 12.3229 7.41435 12.0264 9.40787 10.8506C10.8243 10.0138 12.2408 8.35075 12.9018 6.74068C13.2585 5.88269 13.6152 4.315 13.6152 3.56293C13.6152 3.07567 13.6467 3.01212 14.2343 2.42953C14.5805 2.09056 14.9058 1.71983 14.9687 1.6139C15.0737 1.41264 15.0632 1.41264 14.5281 1.59272C13.6362 1.91049 13.5103 1.86812 13.951 1.39146C14.2762 1.0525 14.6645 0.438131 14.6645 0.258058C14.6645 0.22628 14.5071 0.279243 14.3287 0.374576C14.1398 0.480501 13.7202 0.639389 13.4054 0.734722L12.8388 0.914795L12.3247 0.565241C12.0414 0.374576 11.6427 0.162725 11.4329 0.0991699C10.8978 -0.0491255 10.0794 -0.0279404 9.59673 0.14154C8.2852 0.618204 7.45632 1.84694 7.55075 3.19219Z" /></svg> <span onClick={() => tweetershare()}>tweet</span></a> */}
                    <a className="share__link share__link--link mt-op">
                      <svg width="18" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                        <path d="M8,12a1,1,0,0,0,1,1h6a1,1,0,0,0,0-2H9A1,1,0,0,0,8,12Zm2,3H7A3,3,0,0,1,7,9h3a1,1,0,0,0,0-2H7A5,5,0,0,0,7,17h3a1,1,0,0,0,0-2Zm7-8H14a1,1,0,0,0,0,2h3a3,3,0,0,1,0,6H14a1,1,0,0,0,0,2h3A5,5,0,0,0,17,7Z" />
                      </svg>{' '}
                      <span onClick={copyToClipboard}>copy link</span>
                    </a>
                    <p className="label_po mt-op">{tokenCounts}</p>
                  </div>

                  {LikedTokenList.findIndex((tokenCounts) => tokenCounts.tokenCounts === item.tokenCounts) > -1 ? (
                    <button
                      className="asset__likes asset__likes--active"
                      onClick={() => LikeForwardRef.current.hitLike(item)}
                      style={{ cursor: 'pointer' }}
                      type="button"
                    >
                      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                        <path d="M20.16,5A6.29,6.29,0,0,0,12,4.36a6.27,6.27,0,0,0-8.16,9.48l6.21,6.22a2.78,2.78,0,0,0,3.9,0l6.21-6.22A6.27,6.27,0,0,0,20.16,5Zm-1.41,7.46-6.21,6.21a.76.76,0,0,1-1.08,0L5.25,12.43a4.29,4.29,0,0,1,0-6,4.27,4.27,0,0,1,6,0,1,1,0,0,0,1.42,0,4.27,4.27,0,0,1,6,0A4.29,4.29,0,0,1,18.75,12.43Z"></path>
                      </svg>
                      <span>{item.likecount}</span>
                    </button>
                  ) : (
                    <button
                      className="asset__likes"
                      onClick={() => LikeForwardRef.current.hitLike(item)}
                      style={{ cursor: 'pointer' }}
                      type="button"
                    >
                      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                        <path d="M20.16,5A6.29,6.29,0,0,0,12,4.36a6.27,6.27,0,0,0-8.16,9.48l6.21,6.22a2.78,2.78,0,0,0,3.9,0l6.21-6.22A6.27,6.27,0,0,0,20.16,5Zm-1.41,7.46-6.21,6.21a.76.76,0,0,1-1.08,0L5.25,12.43a4.29,4.29,0,0,1,0-6,4.27,4.27,0,0,1,6,0,1,1,0,0,0,1.42,0,4.27,4.27,0,0,1,6,0A4.29,4.29,0,0,1,18.75,12.43Z"></path>
                      </svg>
                      <span>{item.likecount}</span>
                    </button>
                  )}
                </div>
              </div>

              <div className="col-12 col-xl-4">
                <div className="asset__info">
                  {item && item.longDescription && (
                    <div className="asset__desc">
                      <h2>Description</h2>
                      <ReactMarkdown remarkPlugins={[remarkGfm]}>{item.longDescription}</ReactMarkdown>
                    </div>
                  )}

                  <ul className="asset__authors">
                    {item &&
                      item.tokenCreatorInfo &&
                      item.tokenCreatorInfo.curraddress &&
                      item.tokenCreatorInfo.curraddress[0] && (
                        <li>
                          <span>Creator</span>
                          <div
                            className={
                              item.tokenCreatorInfo &&
                              item.tokenCreatorInfo.Accountverified[0] !== undefined &&
                              item.tokenCreatorInfo.Accountverified[0] === 'Verified'
                                ? 'asset__author asset__author--verified'
                                : 'asset__author'
                            }
                          >
                            <img
                              src={
                                item.tokenCreatorInfo &&
                                item.tokenCreatorInfo.image[0] !== undefined &&
                                item.tokenCreatorInfo.image[0] !== ''
                                  ? item.tokenCreatorInfo.image[0]
                                  : require('../assets/images/avatars/avatar13.jpg')
                              }
                              alt=""
                            />
                            <button type="button">
                              {item &&
                              item.tokenCreatorInfo &&
                              item.tokenCreatorInfo.name &&
                              item.tokenCreatorInfo.name.length > 0 &&
                              item.tokenCreatorInfo.name[0] !== ''
                                ? item.tokenCreatorInfo.name[0]
                                : item.tokenCreatorInfo.curraddress[0]}
                            </button>
                          </div>
                        </li>
                      )}
                    {/*  <li>
                <span>Collection</span>
                <div className="asset__author ">
                  <img src={require("../assets/images/avatars/avatar13.jpg")} alt="" />
                  <a href="collection.html">The Meta Key</a>
                </div>
              </li> */}
                  </ul>

                  <ul className="nav nav-tabs asset__tabs" role="tablist">
                    <li className="nav-item">
                      <a
                        className="nav-link active"
                        data-toggle="tab"
                        href="#tab-1"
                        role="tab"
                        aria-controls="tab-1"
                        aria-selected="true"
                      >
                        Details
                      </a>
                    </li>

                    <li className="nav-item">
                      <a
                        className="nav-link"
                        data-toggle="tab"
                        href="#tab-2"
                        role="tab"
                        aria-controls="tab-2"
                        aria-selected="false"
                      >
                        Bids
                      </a>
                    </li>

                    <li className="nav-item">
                      <a
                        className="nav-link"
                        data-toggle="tab"
                        href="#tab-3"
                        role="tab"
                        aria-controls="tab-3"
                        aria-selected="false"
                      >
                        History
                      </a>
                    </li>
                  </ul>

                  <div className="tab-content">
                    <div className="tab-pane fade show active" id="tab-1" role="tabpanel">
                      <Scrollbars style={{ width: '100%', height: 250 }} className="customScroll">
                        <div className="asset__actions asset__actions--scroll" id="asset__actions--scroll">
                          {item.tokenowners_current &&
                            item.tokenowners_current.length > 0 &&
                            item.tokenowners_current.map((itemCur, i) => {
                              return (
                                <div className="asset__action" key={i}>
                                  <img
                                    src={
                                      item.tokenOwnerInfo &&
                                      item.tokenOwnerInfo.image[i] &&
                                      item.tokenOwnerInfo.image[i] !== undefined &&
                                      item.tokenOwnerInfo.image[i] !== ''
                                        ? item.tokenOwnerInfo.image[i]
                                        : require('../assets/images/avatars/avatar13.jpg')
                                    }
                                    alt=""
                                  />
                                  <div>
                                    <p>
                                      <button type="button">
                                        {item.tokenOwnerInfo &&
                                        item.tokenOwnerInfo.name[i] &&
                                        item.tokenOwnerInfo.name[i] !== undefined &&
                                        item.tokenOwnerInfo.name[i] !== ''
                                          ? item.tokenOwnerInfo.name[i]
                                          : halfAddrShow(itemCur.tokenOwner)}
                                      </button>
                                    </p>
                                    {itemCur.tokenPrice > 0 && (
                                      <p>
                                        {itemCur.balance} of {itemCur.quantity} on sale for {itemCur.tokenPrice}{' '}
                                        {item.biddingtoken} {itemCur.quantity > 0 && 'each'}
                                      </p>
                                    )}
                                    {itemCur.tokenPrice === 0 && (
                                      <p className="mt-0 media_text mb-0">
                                        {itemCur.balance}/{itemCur.quantity} Not for sale
                                      </p>
                                    )}
                                  </div>
                                  {itemCur.tokenPrice > 0 &&
                                    itemCur.balance > 0 &&
                                    itemCur.tokenOwner !== UserAccountAddr && (
                                      <Button
                                        className="btn_outline_red btnBuyNowSmall"
                                        onClick={() => PurchaseNowForwardRef.current.PurchaseNow_Click(item, itemCur)}
                                      >
                                        Buy Now
                                      </Button>
                                    )}
                                  {/* {
                            itemCur.tokenPrice > 0 && itemCur.balance > 0 && itemCur.tokenOwner !== UserAccountAddr &&
                            <Button className="btn_outline_red btnBuyNowSmall" onClick= { () =>PurchaseNowForwardRef.current.PurchaseNow_Click(item,itemCur)} >Buy Now</Button>
                            } */}
                                </div>
                              );
                            })}
                        </div>
                      </Scrollbars>
                    </div>

                    <div className="tab-pane fade" id="tab-2" role="tabpanel">
                      <div className="asset__actions">
                        {Bids &&
                          Bids.pending &&
                          Bids.pending.length > 0 &&
                          Bids.pending.map((curBid) => {
                            return (
                              <div
                                className={
                                  curBid && curBid.bidDetails && curBid.bidDetails.Accountverified === 'Verified'
                                    ? 'asset__action asset__action--verified'
                                    : 'asset__action'
                                }
                              >
                                {/* <div className="asset__action asset__action--verified"> */}
                                {/* <img src={require("../assets/images/avatars/avatar13.jpg")} alt="" /> */}
                                <img
                                  src={
                                    curBid &&
                                    curBid.bidDetails &&
                                    curBid.bidDetails.image !== undefined &&
                                    curBid.bidDetails.image !== ''
                                      ? curBid.bidDetails.image
                                      : require('../assets/images/avatars/avatar13.jpg')
                                  }
                                  alt=""
                                />
                                {/* <img src={require("../assets/images/avatars/avatar13.jpg")} alt="" /> */}
                                <p>
                                  Bid of{' '}
                                  <b>
                                    {curBid.tokenBidAmt} {config.tokenSymbol} for{' '}
                                    {tokenCounts_Detail.TotalQuantity > 0 && (
                                      <span>
                                        {' '}
                                        {curBid.pending}/{curBid.NoOfToken} edition
                                      </span>
                                    )}{' '}
                                  </b>{' '}
                                  at {moment(curBid.timestamp).format('MMMM Do YYYY, h:mm a')} <br />
                                  by {halfAddrShow(curBid.tokenBidAddress)}
                                </p>
                                {UserAccountAddr &&
                                  UserAccountAddr !== curBid.tokenBidAddress &&
                                  item &&
                                  item.tokenowners_current &&
                                  item.tokenowners_current.findIndex((e) => e.tokenOwner === UserAccountAddr) > -1 && (
                                    <div className="ml-2 ml-cus">
                                      <Button
                                        className="create_btn mb-2"
                                        onClick={() => PlaceABidForwardRef.current.AcceptBid_Select(curBid)}
                                      >
                                        Accept
                                      </Button>
                                    </div>
                                  )}
                                {UserAccountAddr && UserAccountAddr === curBid.tokenBidAddress && (
                                  <Button
                                    className="create_btn mb-2"
                                    onClick={() => PlaceABidForwardRef.current.CancelBid_Select(curBid)}
                                  >
                                    Cancel
                                  </Button>
                                )}
                              </div>
                            );
                          })}
                      </div>
                    </div>

                    <div className="tab-pane fade" id="tab-3" role="tabpanel">
                      {tokenHistory &&
                        tokenHistory.length > 0 &&
                        tokenHistory.map((list) => {
                          var txt = '';
                          if (list.actiontype === 'bidrequest') {
                            txt = 'Bid ';
                          } else if (list.actiontype === 'sales') {
                            txt = 'Minted  ';
                          } else if (list.actiontype === 'purchase') {
                            txt = 'Purchase ';
                          }

                          return (
                            <div className="asset__action">
                              {list && list.userData && list.userData.image && list.userData.image !== '' ? (
                                <img src={config.Back_URL + '/profile/' + list.userData.image} alt="" />
                              ) : (
                                <img src={require('../assets/images/avatars/avatar13.jpg')} alt="" />
                              )}
                              <p>
                                {txt}
                                {list.actiontype === 'bidrequest' && <b>{list.price} ORE </b>}
                                by {halfAddrShow(list.useraddress)}
                                <br />
                                at {moment(list.timestamp).fromNow()}
                                <br />{' '}
                              </p>
                            </div>
                          );
                        })}
                      {tokenHistory && tokenHistory.length === 0 && (
                        <div className="asset__action">
                          <p>No History</p>
                        </div>
                      )}
                    </div>
                  </div>

                  {Bids.highestBid && Bids.highestBid.tokenBidAmt > 0 && (
                    <div className="asset__wrap">
                      <div className="asset__img">
                        {/* <img src={require("../assets/images/avatars/avatar13.jpg")} alt="" /> */}
                        <img
                          src={
                            Bids.highestBid &&
                            Bids.highestBid.bidDetails &&
                            Bids.highestBid.bidDetails.image !== undefined &&
                            Bids.highestBid.bidDetails.image !== ''
                              ? Bids.highestBid.bidDetails.image
                              : require('../assets/images/avatars/avatar13.jpg')
                          }
                          alt=""
                        />
                      </div>
                      <div className="asset__price">
                        <span>
                          Highest bid by{' '}
                          <strong>
                            {Bids.highestBid.bidBy && Bids.highestBid.bidBy.name
                              ? Bids.highestBid.bidBy.name
                              : Bids.highestBid.tokenBidAddress.slice(0, 8).concat('....')}
                          </strong>
                        </span>
                        <span>
                          {Bids.highestBid.tokenBidAmt} -{config.tokenSymbol}
                        </span>
                      </div>
                    </div>
                  )}

                  {item &&
                  item.PutOnSaleType === 'TimedAuction' &&
                  ((item.clocktime !== null &&
                    item.endclocktime !== null &&
                    new Date(item.endclocktime) < Date.now()) ||
                    (Bids.completed && Bids.completed.length > 0)) ? (
                    <p className="mt-0 media_text_big_1 text-center">Auction Completed</p>
                  ) : tokenCounts_Detail.TotalQuantity > MyTokenBalance ? (
                    <div className="asset__btns">
                      {BuyOwnerDetailFirst && BuyOwnerDetailFirst.tokenOwner ? (
                        <button
                          className="asset__btn asset__btn--clr"
                          type="button"
                          onClick={() => PurchaseNowForwardRef.current.PurchaseNow_Click(item, BuyOwnerDetailFirst)}
                        >
                          {'Buy - '}
                          {item &&
                            item.tokenowners_current &&
                            item.tokenowners_current[0] &&
                            item.tokenowners_current[0].tokenPrice + ' ' + item.biddingtoken}
                        </button>
                      ) : (
                        ' '
                      )}

                      {MyTokenDetail && MyTokenDetail.balance > 0 && MyTokenDetail.tokenPrice > 0 ? (
                        <button
                          className="asset__btn asset__btn--clr"
                          type="button"
                          onClick={() => PutOnSaleForwardRef.current.PutOnSale_Click(item, MyTokenDetail)}
                        >
                          {'Change Price - '}
                          {item &&
                            item.tokenowners_current &&
                            item.tokenowners_current[0] &&
                            item.tokenowners_current[0].tokenPrice + ' ' + item.biddingtoken}
                        </button>
                      ) : (
                        ''
                      )}

                      {Bids && Bids.myBid && !Bids.myBid.status ? (
                        <button
                          className="asset__btn asset__btn--clr"
                          type="button"
                          onClick={() => PlaceABidForwardRef.current.PlaceABid_Click(item)}
                        >
                          Bid now
                        </button>
                      ) : Bids && Bids.myBid && Bids.myBid.status && Bids.myBid.status === 'pending' ? (
                        <button
                          className="asset__btn asset__btn--clr"
                          type="button"
                          onClick={() => PlaceABidForwardRef.current.PlaceABid_Click(item)}
                        >
                          Edit a bid
                        </button>
                      ) : (
                        Bids &&
                        Bids.myBid &&
                        Bids.myBid.status &&
                        Bids.myBid.status === 'partiallyCompleted' && (
                          <button
                            className="asset__btn asset__btn--clr"
                            type="button"
                            onClick={() => PlaceABidForwardRef.current.CancelBid_Select(Bids.myBid)}
                          >
                            Cancel Bid
                          </button>
                        )
                      )}
                    </div>
                  ) : (
                    ' '
                  )}
                </div>
              </div>
            </div>
            {item.contents && (item.contents.unlockcontent || item.contents.secondaryFileHash) && (
              <div className="asset_item_link">
                <div className="asset_item_link_title">Contents</div>
                {item.contents.unlockcontent && (
                  <a href={item.contents.unlockcontent} target="_blank" rel="noopener noreferrer">
                    {item.contents.unlockcontent}
                  </a>
                )}
                {item.contents.secondaryFileName && item.contents.secondaryFileHash && (
                  <div>
                    {item.tokenCategory === 'Picture' ? (
                      <img src={`${IPFS_IMGurl}/${item.contents.secondaryFileHash}`} alt="secondary content" />
                    ) : (
                      <div className="asset_item_collection">
                        {item.tokenCategory === 'Music' && <i className="fa fa-music" aria-hidden="true"></i>}
                        {item.tokenCategory === 'Movie' && <i className="fa fa-film" aria-hidden="true"></i>}
                        {item.tokenCategory === 'Ebook' && <i className="fa fa-file-text" aria-hidden="true"></i>}
                        <a
                          href={`${IPFS_IMGurl}/${item.contents.secondaryFileHash}`}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {item.contents.secondaryFileName}
                        </a>
                        {/* <i className="fa fa-external-link" aria-hidden="true">sadas</i> */}
                      </div>
                    )}
                  </div>
                )}
              </div>
            )}
            <section className="row row--grid mt-4">
              <div className="col-12">
                <div className="main__title main__title--border-top mt-0 pt-4">
                  <h2>
                    <a href="explore.html">Other author assets</a>
                  </h2>
                </div>
              </div>

              <div className="col-12">
                <div className="main__carousel-wrap">
                  <div className="main__carousel main__carousel--explore owl-carousel" id="explore">
                    <Slider {...settingsLiveAuction}>
                      {tokenlist &&
                        tokenlist.map((item) => {
                          return (
                            <TokenItem
                              item={item}
                              Set_item={Set_item}
                              LikedTokenList={LikedTokenList}
                              hitLike={LikeForwardRef.current.hitLike}
                              UserAccountAddr={UserAccountAddr}
                              UserAccountBal={UserAccountBal}
                              PutOnSale_Click={PutOnSaleForwardRef.current.PutOnSale_Click}
                              PurchaseNow_Click={PurchaseNowForwardRef.current.PurchaseNow_Click}
                              PlaceABid_Click={PlaceABidForwardRef.current.PlaceABid_Click}
                              Set_Bids={Set_Bids}
                              Bids={Bids}
                              Set_BuyOwnerDetailFirst={Set_BuyOwnerDetailFirst}
                              Set_tokenCounts_Detail={Set_tokenCounts_Detail}
                              Set_MyTokenBalance={Set_MyTokenBalance}
                              Set_MyTokenDetail={Set_MyTokenDetail}
                              Set_AllowedQuantity={Set_AllowedQuantity}
                              Set_YouWillPay={Set_YouWillPay}
                              Set_YouWillPayFee={Set_YouWillPayFee}
                              Set_YouWillGet={Set_YouWillGet}
                              // Bid_Click={PlaceABidForwardRef.current.Bid_Click}
                              Burn_Click={BurnForwardRef.current.Burn_Click}
                              CancelOrder_Click={CancelOrderForwardRef.current.CancelOrder_Click}
                              WalletConnected={WalletConnected}
                              SubmitReport_Click={ReportForwardRef.current.SubmitReport_Click}
                              ShareSocial_Click={ShareForwardRef.current.ShareSocial_Click}
                            />
                          );
                        })}
                    </Slider>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </main>
      </div>
      <Footer />
      {/* buy Modal */}
      <div
        className="modal fade primary_modal"
        id="buy_modal"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="buy_modalCenteredLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered modal-sm" role="document">
          <div className="modal-content">
            <div className="modal-header text-center">
              <h5 className="modal-title" id="buy_modalLabel">
                Checkout
              </h5>
              <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <div className="row mx-0 bor_bot_modal">
                <div className="col-12 col-md-12 p-0">
                  <p className="buy_title_sm">Seller</p>
                  <p className="buy_title_md">Uniaqua</p>
                </div>
                <div className="col-12 col-md-12 p-0">
                  <p className="buy_title_sm">Buyer</p>
                  <p className="buy_title_md short_address">0x75Cf28f29266A413eC878CE5C4a1c9682C819586</p>
                </div>
              </div>
              <div className="bor_bot_modal mb-3">
                <p className="buy_title_md px-4 py-3 text-center mb-0">1.01 ETH</p>
              </div>
              <div className="row mx-0 pb-3">
                <div className="col-12 col-sm-6 p-0">
                  <p className="buy_desc_sm">Your balance</p>
                </div>
                <div className="col-12 col-sm-6 p-0 text-sm-right">
                  <p className="buy_desc_sm_bold">10.25 ETH</p>
                </div>
              </div>
              <div className="row mx-0 pb-3">
                <div className="col-12 col-sm-6 p-0">
                  <p className="buy_desc_sm">Service fee</p>
                </div>
                <div className="col-12 col-sm-6 p-0 text-sm-right">
                  <p className="buy_desc_sm_bold">0.012 ETH</p>
                </div>
              </div>
              <div className="row mx-0 pb-3">
                <div className="col-12 col-sm-6 p-0">
                  <p className="buy_desc_sm">You will pay</p>
                </div>
                <div className="col-12 col-sm-6 p-0 text-sm-right">
                  <p className="buy_desc_sm_bold">1.112 ETH</p>
                </div>
              </div>
              <form className="p-0">
                <div className="text-center">
                  <Button
                    className="create_btn btn-block"
                    data-dismiss="modal"
                    aria-label="Close"
                    data-toggle="modal"
                    data-target="#proceed_modal"
                  >
                    Proceed to payment
                  </Button>
                  <Button className="btn_outline_red btn-block" data-dismiss="modal" aria-label="Close">
                    Cancel
                  </Button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      {/* end buy modal */}

      {/* proceed Modal */}
      <div
        className="modal fade primary_modal"
        id="proceed_modal"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="proceed_modalCenteredLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered modal-sm" role="document">
          <div className="modal-content">
            <div className="modal-header text-center">
              <h5 className="modal-title" id="proceed_modalLabel">
                Follow Steps
              </h5>
              <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <form>
                <p className="mt-0 purchase_text text-center">Purchase</p>
                <p className="mt-0 purchase_desc text-center">Send transaction with your wallet</p>

                <div className="text-center my-3">
                  <Button className="btn_outline_red btn-block">Inprogress</Button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      {/* end proceed modal */}

      {/* place_bid Modal */}
      <div
        className="modal fade primary_modal"
        id="place_bid_modal"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="place_bid_modalCenteredLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered modal-sm" role="document">
          <div className="modal-content">
            <div className="modal-header text-center">
              <h5 className="modal-title" id="place_bid_modalLabel">
                Place a bid
              </h5>
              <p className="text-center place_bit_desc">You are about to place a bid for</p>
              <p className="place_bit_desc_2">
                <span className="text_red mr-2">Uniaqua</span>by<span className="text_red ml-2">NFTbuyer</span>
              </p>

              <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <form className="bid_form">
                <label htmlFor="bid">Your bid</label>
                <div className="input-group mb-3 input_grp_style_1">
                  <input
                    type="text"
                    id="bid"
                    className="form-control"
                    placeholder="Enter your display name"
                    aria-label="bid"
                    aria-describedby="basic-addon2"
                  />
                  <div className="input-group-append">
                    <span className="input-group-text" id="basic-addon2">
                      ETH
                    </span>
                  </div>
                </div>

                {/* <label htmlFor="qty">Enter quantity <span className="label_muted pl-2">(30 available)</span></label>
            <div className="mb-3 input_grp_style_1">
          <input type="text" id="qty" className="form-control" placeholder="1"  />

        </div> */}
                <div className="row pb-3">
                  <div className="col-12 col-sm-6">
                    <p className="buy_desc_sm">Your balance</p>
                  </div>
                  <div className="col-12 col-sm-6 text-sm-right">
                    <p className="buy_desc_sm_bold">10.25 ETH</p>
                  </div>
                </div>
                <div className="row pb-3">
                  <div className="col-12 col-sm-6">
                    <p className="buy_desc_sm">Your bidding balance</p>
                  </div>
                  <div className="col-12 col-sm-6 text-sm-right">
                    <p className="buy_desc_sm_bold">0.012 ETH</p>
                  </div>
                </div>
                <div className="row pb-3">
                  <div className="col-12 col-sm-6">
                    <p className="buy_desc_sm">Service fee</p>
                  </div>
                  <div className="col-12 col-sm-6 text-sm-right">
                    <p className="buy_desc_sm_bold">1.112 ETH</p>
                  </div>
                </div>
                <div className="row pb-3">
                  <div className="col-12 col-sm-6">
                    <p className="buy_desc_sm">You will pay</p>
                  </div>
                  <div className="col-12 col-sm-6 text-sm-right">
                    <p className="buy_desc_sm_bold">1.112 ETH</p>
                  </div>
                </div>

                <div className="text-center">
                  <Button
                    className="create_btn btn-block"
                    data-dismiss="modal"
                    aria-label="Close"
                    data-toggle="modal"
                    data-target="#proceed_bid_modal"
                  >
                    Place a bid
                  </Button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      {/* end place_bid modal */}

      {/* place_bid multiple */}
      <div
        className="modal fade primary_modal"
        id="place_bid_multiple_modal"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="place_bid_multiple_modalCenteredLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered modal-sm" role="document">
          <div className="modal-content">
            <div className="modal-header text-center">
              <h5 className="modal-title" id="place_bid_multiple_modalLabel">
                Place a bid
              </h5>
              <p className="text-center place_bit_desc">You are about to place a bid for</p>
              <p className="place_bit_desc_2">
                <span className="text_red mr-2">Uniaqua</span>by<span className="text_red ml-2">NFTbuyer</span>
              </p>

              <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <form className="px-4 bid_form">
                <label htmlFor="bid">Your bid</label>
                <div className="input-group mb-3 input_grp_style_1">
                  <input
                    type="text"
                    id="bid"
                    className="form-control"
                    placeholder="Enter your display name"
                    aria-label="bid"
                    aria-describedby="basic-addon2"
                  />
                  <div className="input-group-append">
                    <span className="input-group-text" id="basic-addon2">
                      ETH
                    </span>
                  </div>
                </div>

                <label htmlFor="qty">
                  Enter quantity <span className="label_muted pl-2">(30 available)</span>
                </label>
                <div className="mb-3 input_grp_style_1">
                  <input type="text" id="qty" className="form-control" placeholder="1" />
                </div>
                <div className="row pb-3">
                  <div className="col-12 col-sm-6">
                    <p className="buy_desc_sm">Your balance</p>
                  </div>
                  <div className="col-12 col-sm-6 text-sm-right">
                    <p className="buy_desc_sm_bold">10.25 ETH</p>
                  </div>
                </div>
                <div className="row pb-3">
                  <div className="col-12 col-sm-6">
                    <p className="buy_desc_sm">Your bidding balance</p>
                  </div>
                  <div className="col-12 col-sm-6 text-sm-right">
                    <p className="buy_desc_sm_bold">0.012 ETH</p>
                  </div>
                </div>
                <div className="row pb-3">
                  <div className="col-12 col-sm-6">
                    <p className="buy_desc_sm">Service fee</p>
                  </div>
                  <div className="col-12 col-sm-6 text-sm-right">
                    <p className="buy_desc_sm_bold">1.112 ETH</p>
                  </div>
                </div>
                <div className="row pb-3">
                  <div className="col-12 col-sm-6">
                    <p className="buy_desc_sm">You will pay</p>
                  </div>
                  <div className="col-12 col-sm-6 text-sm-right">
                    <p className="buy_desc_sm_bold">1.112 ETH</p>
                  </div>
                </div>

                <div className="text-center">
                  <Button
                    className="create_btn btn-block"
                    data-dismiss="modal"
                    aria-label="Close"
                    data-toggle="modal"
                    data-target="#proceed_bid_modal"
                  >
                    Place a bid
                  </Button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      {/* end place_bid modal multiple */}

      {/* proceed_bid Modal */}
      <div
        className="modal fade primary_modal"
        id="proceed_bid_modal"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="proceed_bid_modalCenteredLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered modal-sm" role="document">
          <div className="modal-content">
            <div className="modal-header text-center">
              <h5 className="modal-title" id="proceed_bid_modalLabel">
                Follow Steps
              </h5>
              <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <form>
                <div className="media approve_media">
                  <i className="fas fa-check mr-3 pro_complete" aria-hidden="true"></i>
                  {/* <i className="fa fa-spinner mr-3 spinner_icon" aria-hidden="true"></i> */}
                  <div className="media-body">
                    <p className="mt-0 approve_text">Approve</p>
                    <p className="mt-0 approve_desc">Checking balance and approving</p>
                  </div>
                </div>
                <div className="text-center my-3">
                  <Button className="btn_outline_red btn-block">Done</Button>
                </div>
                <div className="media approve_media">
                  <i className="fas fa-check mr-3" aria-hidden="true"></i>
                  <div className="media-body">
                    <p className="mt-0 approve_text">Signature</p>
                    <p className="mt-0 approve_desc">Create a signatute to place a bid</p>
                  </div>
                </div>
                <div className="text-center mt-3">
                  <Button className="create_btn btn-block" disabled>
                    Start
                  </Button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      {/* end proceed_bid modal */}

      {/* burn_token Modal */}
      <div
        className="modal fade primary_modal"
        id="burn_token_modal"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="burn_token_modalCenteredLabel"
        aria-hidden="true"
        data-backdrop="static"
        data-keyboard="false"
      >
        <div className="modal-dialog modal-dialog-centered modal-sm" role="document">
          <div className="modal-content">
            <div className="modal-header text-center">
              <h5 className="modal-title" id="burn_token_modalLabel">
                Burn token
              </h5>
              <button type="button" id="closeburn" className="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body px-0">
              <form className="bid_form">
                <div className="px-4">
                  <p className="mt-0 approve_desc"> Tokens Available - {MyTokenDetail.balance}</p>
                </div>

                <div className="px-4 media-body">
                  <p className="mt-0 approve_desc">
                    Are you sure to burn this token? This action cannot be undone. Token will be transfered to zero
                    address
                  </p>
                </div>
                <div className="px-4 mt-4 pt-2">
                  <input
                    id="burn"
                    name="burn"
                    className="form-control"
                    onChange={(e) => {
                      handleChange(e);
                    }}
                  />
                  <div className="text-center mt-3">
                    <Button
                      className="primary_btn"
                      onClick={() => Burntoken(item, MyTokenDetail)}
                      disabled={burnLoading === 'done'}
                    >
                      {burnLoading === 'processing' && (
                        <i className="fa fa-spinner mr-3 spinner_icon" aria-hidden="true" id="circle1"></i>
                      )}
                      {burnLoading === 'init' && 'Continue'}
                      {burnLoading === 'processing' && 'In-progress...'}
                      {burnLoading === 'done' && 'Done'}
                      {burnLoading === 'error' && 'Try Again'}
                      {burnLoading === 'errors' && 'Check Balance'}
                      {burnLoading === 'zero' && "Qty can't be Zero"}
                      {burnLoading === 'empty' && 'Please Enter the Qty'}
                    </Button>
                    <Button className="create_btn" data-dismiss="modal" aria-label="Close">
                      Cancel
                    </Button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      {/* end burn_token modal */}

      {/* accept bid Modal */}
      <div
        className="modal fade primary_modal"
        id="accept_modal"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="accept_modalCenteredLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered modal-sm" role="document">
          <div className="modal-content">
            <div className="modal-header text-center">
              <h5 className="modal-title" id="accept_modalLabel">
                Accept bid
              </h5>
              <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <div className="img_accept text-center">
                <img src={require('../assets/images/img_info.png')} alt="Collections" className="img-fluid" />
              </div>
              <p className="text-center accept_desc">
                <span className="buy_desc_sm">You are about to accept bid for</span>
                <span className="buy_desc_sm_bold pl-2">Uniaqua</span>
                <span className="buy_desc_sm pl-2">from</span>
                <span className="buy_desc_sm_bold pl-2 short_address m-auto d-block">
                  0x75Cf28f29266A413eC878CE5C4a1c9682C819586
                </span>
              </p>
              <p className="info_title text-center">0 ETH for 1 edition(s)</p>

              <div className="row mx-0 pb-3">
                <div className="col-12 col-sm-6 px-4">
                  <p className="buy_desc_sm">Service fee</p>
                </div>
                <div className="col-12 col-sm-6 px-4 text-sm-right">
                  <p className="buy_desc_sm_bold">0.012 ETH</p>
                </div>
              </div>
              <div className="row mx-0 pb-3">
                <div className="col-12 col-sm-6 px-4">
                  <p className="buy_desc_sm">You will get</p>
                </div>
                <div className="col-12 col-sm-6 px-4 text-sm-right">
                  <p className="buy_desc_sm_bold">1.112 ETH</p>
                </div>
              </div>
              <form className="px-4">
                <div className="text-center">
                  <Button className="create_btn btn-block" data-dismiss="modal" aria-label="Close">
                    Accept bid
                  </Button>
                  <Button className="btn_outline_red btn-block" data-dismiss="modal" aria-label="Close">
                    Cancel
                  </Button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      {/* end accept bid modal */}
    </div>
  );
}
