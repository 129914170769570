import { Button } from '@material-ui/core';
import '@metamask/legacy-web3';
import React, { forwardRef, useEffect, useImperativeHandle } from 'react';
import { useHistory } from 'react-router-dom';
import Select from 'react-select';
import { toast } from 'react-toastify';
import Web3 from 'web3';
import ABI from '../../ABI/ABI.json';
import { TokenPriceChange_update_Action } from '../../actions/token';
import { halfAddrShow } from '../../actions/user';
import config from '../../lib/config';

toast.configure();
let toasterOption = config.toasterOption;
const IPFS_IMGurl = config.IPFS_IMG;

const exchangeAddress = config.multipleContract;

export const PutOnSaleRef = forwardRef((props, ref) => {
  const history = useHistory();

  const [priceoptions] = React.useState([
    {
      value: 'BNB',
      label: 'BNB',
    },
    {
      value: 'ORE',
      label: 'ORE',
    },
  ]);

  const [biddingtoken, set_biddingtoken] = React.useState('BNB');

  const [FormSubmitLoading, Set_FormSubmitLoading] = React.useState('');

  const [ValidateError, Set_ValidateError] = React.useState({});
  const [YouWillGet, Set_YouWillGet] = React.useState(0);

  const [TokenPrice, Set_TokenPrice] = React.useState(0);
  const [TokenPrice_Initial, Set_TokenPrice_Initial] = React.useState(0);

  const inputChange = (e) => {
    if (e && e.target && typeof e.target.value !== 'undefined' && e.target.name) {
      let value = e.target.value;
      switch (e.target.name) {
        case 'TokenPrice':
          Set_TokenPrice(value);
          if (value !== '' && isNaN(value) === false && value > 0) {
            let weii = value * config.decimalvalues;
            let per = weii * config.fee;
            Set_YouWillGet(parseFloat((weii - per) / config.decimalvalues).toFixed(config.toFixed));
          }
          ItemValidation({ TokenPrice: value });
          break;
        default:
        // code block
      }
    }
  };

  const SelectBidcurrency = (e) => {
    set_biddingtoken(e.value);
  };

  const ItemValidation = async (data = {}) => {
    let ValidateError = {};

    let Chk_TokenPrice = typeof data.TokenPrice !== 'undefined' ? data.TokenPrice : TokenPrice;

    if (Chk_TokenPrice === '') {
      ValidateError.TokenPrice = '"Token Price" is not allowed to be empty';
    } else if (Chk_TokenPrice === 0) {
      ValidateError.TokenPrice = '"Token Price" must be greater than 0';
    } else if (isNaN(Chk_TokenPrice) === true) {
      ValidateError.TokenPrice = '"Token Price" must be a number';
    } else if (TokenPrice_Initial > 0 && Chk_TokenPrice >= TokenPrice_Initial) {
      ValidateError.TokenPrice = '"Token Price" must be less than ' + TokenPrice_Initial;
    } else {
      delete ValidateError.TokenPrice;
    }
    // else if(Chk_TokenPrice > UserAccountBal) {
    //     ValidateError.TokenPrice = 'Insufficient balance, Check your wallet balance';
    // }
    // else if(Chk_TokenPrice > UserAccountBal) {
    //     ValidateError.TokenPrice = 'Insufficient balance, Check your wallet balance1';
    // }
    // else {
    //     await props.GetUserBal();
    //     if(Chk_TokenPrice > UserAccountBal) {
    //         ValidateError.TokenPrice = 'Insufficient balance, Check your wallet balance2';
    //     }
    //     else {
    //         delete ValidateError.TokenPrice;
    //     }
    // }
    Set_ValidateError(ValidateError);
    return ValidateError;
  };

  const convert = (n) => {
    let sign = +n < 0 ? '-' : '',
      toStr = n.toString();
    if (!/e/i.test(toStr)) {
      return n;
    }
    let [lead, decimal, pow] = n
      .toString()
      .replace(/^-/, '')
      .replace(/^([0-9]+)(e.*)/, '$1.$2')
      .split(/e|\./);
    return +pow < 0
      ? sign + '0.' + '0'.repeat(Math.max(Math.abs(pow) - 1 || 0, 0)) + lead + decimal
      : sign +
          lead +
          (+pow >= decimal.length
            ? decimal + '0'.repeat(Math.max(+pow - decimal.length || 0, 0))
            : decimal.slice(0, +pow) + '.' + decimal.slice(+pow));
  };
  const FormSubmit = async () => {
    if (TokenPrice !== '' && TokenPrice !== null && TokenPrice !== undefined && parseFloat(TokenPrice) > 0) {
      let errors = await ItemValidation();
      let errorsSize = Object.keys(errors).length;
      if (errorsSize !== 0) {
        toast.error('Form validation error. Fix mistakes and submit again', toasterOption);
        return false;
      }

      if (window.ethereum) {
        let web3 = new Web3(window.ethereum);
        if (web3 && web3.eth) {
          let CoursetroContract = new web3.eth.Contract(ABI, exchangeAddress);

          let newtoken = convert(TokenPrice * config.decimalvalues);

          Set_FormSubmitLoading('processing');
          CoursetroContract.methods
            .orderPlace(props.item.tokenCounts, newtoken.toString())
            .send({ from: props.Accounts })
            .then(async (result) => {
              Set_FormSubmitLoading('done');
              let postData = {
                tokenOwner: UserAccountAddr,
                tokenCounts: props.item.tokenCounts,
                tokenPrice: TokenPrice,
                blockHash: result.blockHash,
                transactionHash: result.transactionHash,
                biddingtoken: biddingtoken,
              };
              let Resp = await TokenPriceChange_update_Action(postData);
              if (Resp.data && Resp.data.RetType && Resp.data.RetType === 'success') {
                toast.success('Collectible price changed successfully', toasterOption);
                window.$('#PutOnSale_modal').modal('hide');
                setTimeout(() => {
                  history.push('/');
                }, 2000);
              }
            })
            .catch((error) => {
              Set_FormSubmitLoading('error');
              console.log('error : ', error);
              toast.error('Order not placed', toasterOption);
            });
        }
      }
    } else {
      toast.error('Enter Valid Price', toasterOption);
    }
  };

  let { item, UserAccountAddr } = props;
  useEffect(() => {
    Set_ValidateError({});
  }, []);

  useImperativeHandle(ref, () => ({
    async PutOnSale_Click(item, ownerdetail) {
      let connectwallet = localStorage.getItem('jakcojsnick');
      if (!connectwallet) {
        window.location.href = '/connect';
        return false;
      }
      props.Set_HitItem(item);
      Set_TokenPrice(ownerdetail.tokenPrice);
      Set_TokenPrice_Initial(ownerdetail.tokenPrice);
      Set_ValidateError({});
      window.$('#PutOnSale_modal').modal('show');
    },
  }));
  return (
    <div
      className="modal fade primary_modal"
      id="PutOnSale_modal"
      tabIndex="-1"
      role="dialog"
      data-backdrop="static"
      data-keyboard="false"
      aria-labelledby="accept_modalCenteredLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered modal-sm" role="document">
        <div className="modal-content">
          <div className="modal-header text-center">
            <h5 className="modal-title" id="accept_modalLabel">
              {TokenPrice_Initial === 0 || TokenPrice_Initial === null ? 'Put On Sale' : 'Change Price'}
            </h5>
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
              id="close9"
              onClick={() => window.location.reload(false)}
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body px-0">
            <div className="img_accept text-center">
              {item && item.image && item.image.split('.').pop() === 'mp4' ? (
                <video
                  src={`${IPFS_IMGurl}/${item.ipfsimage}`}
                  type="video/mp4"
                  alt="Collections"
                  className="img-fluid"
                  autoPlay
                  controls
                  playsInline
                  loop
                />
              ) : (
                <img src={`${IPFS_IMGurl}/${item.ipfsimage}`} alt="Collections" className="img-fluid " />
              )}
            </div>
            <p className="text-center accept_desc">
              <span className="buy_desc_sm" styel={{ fontSize: 12 }}>
                You are about to Place Order for
              </span>
              <span className="buy_desc_sm_bold pl-1 bold_red owner_break">{item.tokenName}</span>
              <br></br>
              <span className="buy_desc_sm" styel={{ fontSize: 12 }}>
                Ownership for
              </span>
              <span className="buy_desc_sm_bold pl-1 bold_red owner_break">{halfAddrShow(UserAccountAddr)}</span>
            </p>
            <form className="bid_form" action="#">
              <div className="bor_bot_modal mb-3 px-4 ">
                <div className="mx-0 pb-3"></div>
                <label htmlFor="qty">Enter Price</label>
                <div className="mb-3 input_grp_style_1 d-flex justify-content-center">
                  <input
                    type="text"
                    className="form-control primary_inp text-center"
                    name="TokenPrice"
                    id="TokenPrice"
                    onChange={inputChange}
                    placeholder="e.g. 10"
                    autoComplete="off"
                  />
                  <div className="input-group-append selectCrypto">
                    <Select
                      defaultValue={{ label: 'BNB', value: 'BNB' }}
                      className="form-control primary_inp custom-select"
                      id="TokenCategory"
                      name="TokenCategory"
                      onChange={SelectBidcurrency}
                      options={priceoptions}
                      label="Single select"
                      formControlProps={{
                        fullWidth: true,
                      }}
                    />
                  </div>
                </div>
                {ValidateError.TokenPrice && <span className="text-danger">{ValidateError.TokenPrice}</span>}
              </div>
            </form>
            <div className="row mx-0 pb-3">
              <div className="col-12 col-sm-6 px-4">
                <p className="buy_desc_sm">Service fee</p>
              </div>
              <div className="col-12 col-sm-6 px-4 text-sm-right">
                <p className="buy_desc_sm_bold">
                  {config.fee * 100}% <span>{biddingtoken}</span>
                </p>
              </div>
            </div>
            <div className="row mx-0 pb-3">
              <div className="col-12 col-sm-6 px-4">
                <p className="buy_desc_sm">You will get</p>
              </div>
              <div className="col-12 col-sm-6 px-4 text-sm-right">
                <p className="buy_desc_sm_bold">
                  {YouWillGet} <span>{biddingtoken}</span>
                </p>
              </div>
            </div>
            <form className="px-4">
              <div className="text-center">
                <Button
                  type="button"
                  className="create_btn btn-block"
                  onClick={() => FormSubmit()}
                  disabled={FormSubmitLoading === 'processing'}
                >
                  {FormSubmitLoading === 'processing' && (
                    <i className="fa fa-spinner mr-3 spinner_icon" aria-hidden="true" id="circle1"></i>
                  )}
                  Sign sell order
                </Button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
});
