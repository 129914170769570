/*eslint-disable*/
import { List, ListItem } from '@material-ui/core';
// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';
import React, { useEffect } from 'react';
// react components for routing our app without refresh
import { Link } from 'react-router-dom';
import { headerSearch } from '../../actions/items';
import styles from '../../assets/jss/material-kit-react/components/headerLinksStyle.js';
import config from '../../lib/config';

const IPFS_IMGurl = config.IPFS_IMG;

const useStyles = makeStyles(styles);

export default function HeaderSearch(props) {
  const classes = useStyles();
  const [itemList, setitemList] = React.useState([]);
  const [userList, setuserList] = React.useState([]);
  const [Keyword, Set_Keyword] = React.useState('');
  const [searchShow, setsearchShow] = React.useState(false);

  useEffect(() => {
    getSearchText();
  }, []);

  const searchChange = async (e) => {
    var value = e.target.value;
    if (value && value !== '') {
      Set_Keyword(value);
      var response = await headerSearch({ search: value });

      setitemList(response.result);
      setuserList(response.userList);
      setsearchShow(true);
    } else {
      setitemList([]);
      setuserList([]);
      Set_Keyword('');
    }
  };

  const keyPress = async (e) => {
    if (e.keyCode === 13) {
      window.location = '/search?query=' + e.target.value;
    }
  };

  async function searchPage() {
    window.location = '/search?query=' + Keyword;
  }

  function getParameterByName(name, url = window.location.href) {
    name = name.replace(/[\[\]]/g, '\\$&');
    var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
      results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, ' '));
  }

  function getSearchText() {
    var searchtxt = getParameterByName('query');
    if (searchtxt) {
      Set_Keyword(searchtxt);
    }
  }
  return (
    <List className={classes.list + ' main_navbar'}>
      <ListItem className={classes.listItem}>
        <div className="search_inp_group">
          <input
            type="text"
            onKeyDown={keyPress}
            value={Keyword}
            onChange={searchChange}
            className="search_inp"
            placeholder="Search collections / creators"
          />
          <div className="search_inp_group_append">
            <i className="fas fa-search"></i>
          </div>
        </div>
        {1 === 1 && (
          <div
            className="topSearchPopup noti_parent noti_parnt_user usemneu_dd"
            id="usemneu_dd"
            style={{ background: '#f8f9fa' }}
          >
            {itemList && itemList.length > 0 && (
              <div className="Item_list">
                <h3 className="mt-0 searchResultTitle">Items</h3>
                <ul className="noti_ul_dd">
                  {itemList.map((item) => {
                    return (
                      <li>
                        <Link to={'/info/' + item.tokenCounts}>
                          <div className="searchResultImg">
                            {item.image.split('.').pop() === 'mp4' ? (
                              <video
                                src={`${IPFS_IMGurl}/${item.ipfsimage}`}
                                type="video/mp4"
                                alt="Collections"
                                className="img-fluid"
                                autoPlay
                                controls
                                playsInline
                                loop
                              />
                            ) : (
                              <img
                                src={`${IPFS_IMGurl}/${item.ipfsimage}`}
                                alt="Collections"
                                className="img-fluid "
                                width="30px"
                                height="50px"
                              />
                            )}
                          </div>
                          <div className="search_filter_txt">
                            <h2>{item.tokenName}</h2>
                            <p>
                              {(item.PutOnSale === false ||
                                (item.PutOnSale === true &&
                                  item.PutOnSaleType === 'FixedPrice' &&
                                  item &&
                                  item.tokenowners_current &&
                                  item.tokenowners_current.tokenPrice === 0)) && <span>Not for sale </span>}
                              {item.PutOnSale === true &&
                                item.PutOnSaleType === 'FixedPrice' &&
                                item.tokenowners_current.tokenPrice > 0 && (
                                  <span>
                                    {item.tokenowners_current.tokenPrice} {config.currencySymbol}{' '}
                                  </span>
                                )}
                              {item.PutOnSale === true && item.PutOnSaleType === 'TimedAuction' && (
                                <span>Minimum Bid </span>
                              )}
                              {item.PutOnSale === true && item.PutOnSaleType === 'UnLimitedAuction' && (
                                <span>Open sor Bids </span>
                              )}
                              {item.tokenowners_current.balance} of {item.tokenowners_current.quantity}
                            </p>
                          </div>
                        </Link>
                      </li>
                    );
                  })}
                </ul>
              </div>
            )}

            {userList && userList.length > 0 && (
              <div className="Item_list">
                <h3 className="mt-0 searchResultTitle">Users</h3>
                <ul className="noti_ul_dd mb-3">
                  {userList.map((item) => {
                    var imageUrl =
                      item.image && item.image !== ''
                        ? config.Back_URL + '/images/noimage.png'
                        : config.Back_URL + '/images/noimage.png';

                    var followers = item.followers && item.followers > 0 ? item.followers : '0';

                    return (
                      <li>
                        <Link to={'/user/' + item.curraddress}>
                          <div className="searchResultImg">
                            <img src={imageUrl} alt="Collections" className="img-fluid " />
                          </div>
                          <div className="search_filter_txt">
                            <h2>{item.name}</h2>
                            <p>{item.numberOfFollower} Followers</p>
                          </div>
                        </Link>
                      </li>
                    );
                  })}
                </ul>
              </div>
            )}
            <div className="text-center">
              <button onClick={searchPage} className="btn_outline_red" tabIndex="0" type="button">
                <span>All Results </span>
              </button>
            </div>
          </div>
        )}
      </ListItem>
    </List>
  );
}
