import { List, ListItem } from '@material-ui/core';
import AppBar from '@material-ui/core/AppBar';
import Button from '@material-ui/core/Button';
import Drawer from '@material-ui/core/Drawer';
import Hidden from '@material-ui/core/Hidden';
import IconButton from '@material-ui/core/IconButton';
// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
// @material-ui/icons
import Menu from '@material-ui/icons/Menu';
import axios from 'axios';
// nodejs library that concatenates classes
import classNames from 'classnames';
// nodejs library to set properties for components
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link, NavLink } from 'react-router-dom';
import { toast } from 'react-toastify';
import { forgotPassword, login, register, setCurrentUser } from '../../actions/community';
// core components
import styles from '../../assets/jss/material-kit-react/components/headerStyle.js';
import isEmpty from '../../lib/isEmpty';
import HeaderSearch from '../../views/separate/HeaderSearch';
import CustomDropdown from '../CustomDropdown/CustomDropdown';

const useStyles = makeStyles(styles);
const initialFormValue = {
  email: '',
  username: '',
  password: '',
  repassword: '',
  loginemail: '',
  loginpassword: '',
};
toast.configure();
let toasterOption = {
  position: 'top-right',
  autoClose: 2000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
};

const Header = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [formValue, setFormValue] = useState(initialFormValue);
  const [validateError, setValidateError] = useState({});
  const { email, username, password, repassword } = formValue;

  const onChange = (e) => {
    e.preventDefault();
    const { id, value } = e.target;
    let formData = { ...formValue, ...{ [id]: value } };
    setFormValue(formData);
  };

  React.useEffect(() => {
    if (props.changeColorOnScroll) {
      window.addEventListener('scroll', headerColorChange);
    }
    return function cleanup() {
      if (props.changeColorOnScroll) {
        window.removeEventListener('scroll', headerColorChange);
      }
    };
  });
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };
  const headerColorChange = () => {
    const { color, changeColorOnScroll } = props;
    const windowsScrollTop = window.pageYOffset;
    if (windowsScrollTop > changeColorOnScroll.height) {
      document.body.getElementsByTagName('header')[0].classList.remove(classes[color]);
      document.body.getElementsByTagName('header')[0].classList.add(classes[changeColorOnScroll.color]);
    } else {
      document.body.getElementsByTagName('header')[0].classList.add(classes[color]);
      document.body.getElementsByTagName('header')[0].classList.remove(classes[changeColorOnScroll.color]);
    }
  };
  const { color, rightLinks, leftLinks, brand, fixed, absolute } = props;
  const appBarClasses = classNames({
    [classes.appBar]: true,
    [classes[color]]: color,
    [classes.absolute]: absolute,
    [classes.fixed]: fixed,
  });
  const brandComponent = (
    <Link className={classes.title} to="/home">
      {brand}
    </Link>
  );

  const loginFormSubmit = async (e) => {
    e.preventDefault();
    axios.get('https://pro.ip-api.com/json?key=VT6Pto4zNt4X0vF').then(async (response) => {
      var data = response.data;
      let reqData = { email, password, data };
      const { error, active, token } = await login(reqData);
      if (isEmpty(error)) {
        if (active !== 'Not Activated') {
          setFormValue(initialFormValue);
          toast.success('Logged in Successfully.', toasterOption);
          window.location.reload();
          dispatch(setCurrentUser(token));
        } else {
          toast.success('Your account has been not activated. Please contact admin.', toasterOption); // TODO Why success?
          setValidateError('');
        }
      } else {
        setValidateError(error);
      }
    });
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    let reqData = { username, repassword, password, email };
    const data = await register(reqData);
    if (data && data.error) {
      setValidateError(data.error);
    } else {
      setValidateError('');
      toast.success(
        'Thank you for signing up! Please check your email account and click on the activation link.',
        toasterOption,
      );
      window.location.reload();
    }
  };

  const handleforgotFormSubmit = async (e) => {
    e.preventDefault();
    let reqData = { email };
    let { error } = await forgotPassword(reqData);
    if (isEmpty(error)) {
      setFormValue(initialFormValue);
      toast.success('We have sent you a password reset email', toasterOption);
      window.location.reload();
    } else {
      setValidateError(error);
    }
  };

  return (
    <div>
      <AppBar className={appBarClasses + ' CustomNavbar'}>
        <Toolbar className="container">
          {leftLinks !== undefined ? brandComponent : null}
          <div className={classes.flex}>
            {leftLinks !== undefined ? (
              <Hidden mdDown implementation="css">
                {leftLinks}
              </Hidden>
            ) : (
              brandComponent
            )}
            <Hidden mdDown>
              <div className="leftMenuLinks">
                <List className={classes.list + ' main_navbar py-0'}>
                  <ListItem className={classes.listItem}>
                    <HeaderSearch />
                  </ListItem>
                  <ListItem className={classes.listItem}>
                    <NavLink className={classes.navLink} to="/home">
                      Explore
                    </NavLink>
                  </ListItem>

                  <ListItem className={classes.listItem}>
                    <NavLink className={classes.navLink} to="/my-items">
                      My items
                    </NavLink>
                  </ListItem>

                  <ListItem className={classes.listItem}>
                    <NavLink className={classes.navLink} to="/how-it-works">
                      How it Works
                    </NavLink>
                  </ListItem>

                  <ListItem className={classes.listItem + ' menu_dropdown'}>
                    <CustomDropdown
                      noLiPadding
                      buttonText="Community"
                      dropdownList={[
                        <NavLink to="/activity" className={classes.dropdownLink}>
                          Activity
                        </NavLink>,
                        <NavLink to="/following" className={classes.dropdownLink}>
                          Following
                        </NavLink>,
                        <NavLink to="/community" className={classes.dropdownLink}>
                          Community
                        </NavLink>,
                        <NavLink to="/discussion" className={classes.dropdownLink}>
                          Discussion
                        </NavLink>,
                        // <NavLink to="/voting" className={classes.dropdownLink}>
                        //   Voting
                        // </NavLink>
                      ]}
                    />
                  </ListItem>
                </List>
              </div>
            </Hidden>
          </div>
          <Hidden mdDown implementation="css">
            {rightLinks}
          </Hidden>
          <Hidden lgUp>
            <IconButton
              className="hamburger_icon"
              color="inherit"
              aria-label="open drawer"
              onClick={handleDrawerToggle}
            >
              <Menu />
            </IconButton>
          </Hidden>
        </Toolbar>
        <Hidden lgUp implementation="js">
          <Drawer
            variant="temporary"
            anchor={'right'}
            open={mobileOpen}
            classes={{
              paper: classes.drawerPaper,
            }}
            onClose={handleDrawerToggle}
          >
            <div className={classes.appResponsive}>
              {leftLinks}
              {rightLinks}
            </div>
          </Drawer>
        </Hidden>
      </AppBar>

      {/* Login Modal */}
      <div
        className="modal fade primary_modal"
        id="login_modal"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="login_modalCenteredLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered modal-md" role="document">
          <div className="modal-content">
            <div className="modal-header text-center">
              <h5 className="modal-title" id="login_modalLabel">
                Login
              </h5>
              <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <form className="login_form" onSubmit={loginFormSubmit}>
                <label htmlFor="email">Email Address</label>
                <div className="mb-3">
                  <input
                    type="email"
                    name="email"
                    id="email"
                    onChange={onChange}
                    className="form-control"
                    placeholder="Email Address"
                  />
                  {validateError.email && <span className="text-danger">{validateError.email}</span>}
                </div>
                <label htmlFor="password">Password</label>
                <div className="mb-3">
                  <input
                    type="password"
                    id="password"
                    onChange={onChange}
                    className="form-control"
                    placeholder="************"
                  />
                  {validateError.password && <span className="text-danger">{validateError.password}</span>}
                </div>
                <div className="text-center mt-3">
                  <Button className="create_btn btn-block" type="submit">
                    Login
                  </Button>
                </div>
                <div className="text-center mt-3">
                  <div className="row">
                    <div className="col-12 col-md-6">
                      <span
                        className="pwd_link"
                        data-dismiss="modal"
                        aria-label="Close"
                        data-toggle="modal"
                        data-target="#pwd_modal"
                      >
                        Forgot Password
                      </span>
                    </div>
                    <div className="col-12 col-md-6">
                      <span
                        className="create_link text-md-right"
                        data-dismiss="modal"
                        aria-label="Close"
                        data-toggle="modal"
                        data-target="#register_modal"
                      >
                        Create Account
                      </span>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>

      {/* Create Account Modal */}
      <div
        className="modal fade primary_modal"
        id="register_modal"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="register_modalCenteredLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered modal-md" role="document">
          <div className="modal-content">
            <div className="modal-header text-center">
              <h5 className="modal-title" id="register_modalLabel">
                Create Account
              </h5>
              <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <form className="login_form" onSubmit={handleFormSubmit}>
                <label htmlFor="email_reg">Email Address</label>
                <div className="mb-3">
                  <input
                    type="email"
                    id="email"
                    name="email"
                    onChange={onChange}
                    className="form-control"
                    placeholder="Enter Email Address"
                  />
                  {validateError.email && <span className="text-danger">{validateError.email}</span>}
                </div>
                <label htmlFor="user_reg">Username</label>
                <div className="mb-3">
                  <input
                    type="text"
                    id="username"
                    name="username"
                    onChange={onChange}
                    className="form-control"
                    placeholder="Enter Username"
                  />
                  {validateError.username && <span className="text-danger">{validateError.username}</span>}
                </div>
                <label htmlFor="username_reg">Password</label>
                <div className="mb-3">
                  <input
                    type="password"
                    id="password"
                    name="password"
                    onChange={onChange}
                    className="form-control"
                    placeholder="Enter Password"
                  />
                  {validateError.password && <span className="text-danger">{validateError.password}</span>}
                </div>
                <label htmlFor="password_reg">Re-Password</label>
                <div className="mb-3">
                  <input
                    type="password"
                    id="repassword"
                    name="repassword"
                    onChange={onChange}
                    className="form-control"
                    placeholder="Re-Enter Password"
                  />
                  {validateError.repassword && <span className="text-danger">{validateError.repassword}</span>}
                </div>
                <div className="text-center mt-3">
                  <Button className="create_btn btn-block" type="Submit">
                    Create New Account
                  </Button>
                </div>
                <div className="text-center mt-3">
                  <span
                    className="privacy_link"
                    data-dismiss="modal"
                    aria-label="Close"
                    data-toggle="modal"
                    data-target="#login_modal"
                  >
                    Login
                  </span>
                </div>
                <div className="text-center">
                  <p className="reg_desc mb-0">
                    By registering, you agree to the
                    <Link to="/privacy" target="_blank" className="privacy_link px-1">
                      Privacy Policy
                    </Link>
                    and
                    <Link to="/terms" target="_blank" className="privacy_link pl-1">
                      Terms of service
                    </Link>
                  </p>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>

      {/* Forgot Password Modal */}
      <div
        className="modal fade primary_modal"
        id="pwd_modal"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="pwd_modalCenteredLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered modal-md" role="document">
          <div className="modal-content">
            <div className="modal-header text-center">
              <h5 className="modal-title" id="pwd_modalLabel">
                Forgot Password
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
                onClick={() => setTimeout(() => window.location.reload(), 500)}
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <p className="forgot_desc">
                Enter your username / email address, and we'll send you a password reset email.
              </p>
              <form className="login_form" onSubmit={handleforgotFormSubmit}>
                <label htmlFor="email_username">Username / Email Address</label>
                <div className="mb-3">
                  <input
                    type="text"
                    id="email"
                    name="email"
                    onChange={onChange}
                    className="form-control"
                    placeholder="Enter username / email"
                  />
                  {validateError.email && <span className="text-danger">{validateError.email}</span>}
                </div>
                <div className="text-center mt-3">
                  <Button className="create_btn btn-block" type="submit">
                    Submit
                  </Button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Header;
Header.defaultProp = {
  color: 'white',
};

Header.propTypes = {
  color: PropTypes.oneOf(['primary', 'info', 'success', 'warning', 'danger', 'transparent', 'white', 'rose', 'dark']),
  rightLinks: PropTypes.node,
  leftLinks: PropTypes.node,
  brand: PropTypes.element,
  fixed: PropTypes.bool,
  absolute: PropTypes.bool,
  // this will cause the sidebar to change the color from
  // props.color (see above) to changeColorOnScroll.color
  // when the window.pageYOffset is heigher or equal to
  // changeColorOnScroll.height and then when it is smaller than
  // changeColorOnScroll.height change it back to
  // props.color (see above)
  changeColorOnScroll: PropTypes.shape({
    height: PropTypes.number.isRequired,
    color: PropTypes.oneOf(['primary', 'info', 'success', 'warning', 'danger', 'transparent', 'white', 'rose', 'dark'])
      .isRequired,
  }),
};
