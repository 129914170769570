import React from 'react';
import { Link } from 'react-router-dom';

export default function CollectionDisplay({ collection: { _id, name, image, userId } }) {
  return (
    <div className="px-3">
      <div className="card mb-0">
        <div className="card__cover">
          <Link to={`collections/${_id}`}>
            <img src={image} alt={name} className="img-fluid " />
          </Link>
        </div>
        <div className="titleCard">
          <Link to={`collections/${_id}`}>
            <h3 className="card__title">{name}</h3>
          </Link>
        </div>
        <div
          className={
            userId && userId.Accountverified === 'Verified' ? 'card__author card__author--verified' : 'card__author'
          }
        >
          {userId && userId.curraddress && userId.image ? (
            <a
              href={
                userId.customurl && userId.customurl !== '' ? '/user/' + userId.customurl : '/userprofile/' + userId.id
              }
            >
              <img src={userId.image} alt={userId.name} />
            </a>
          ) : (
            <a
              href={
                userId && userId.customurl && userId.customurl !== ''
                  ? '/user/' + userId.customurl
                  : '/userprofile/' + userId.id
              }
            >
              <img src={require('../../assets/images/avatars/avatar5.jpg')} alt="" />
            </a>
          )}
        </div>
      </div>
    </div>
  );
}
