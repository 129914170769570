import { Button } from '@material-ui/core';
// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';
import $ from 'jquery';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import Web3 from 'web3';
import { getCurAddr, UserProfile_Update_Action, VerifyProfileRequest } from '../actions/user';
import styles from '../assets/jss/material-kit-react/views/landingPage.js';
import Footer from '../components/Footer/Footer';
import GridContainer from '../components/Grid/GridContainer';
import GridItem from '../components/Grid/GridItem';
// core components
import Header from '../components/Header/Header';
import HeaderLinks from '../components/Header/HeaderLinks';
import config from '../lib/config';
import ConnectWallet from './separate/ConnectWallet';

toast.configure();
let toasterOption = config.toasterOption;

const dashboardRoutes = [];

const useStyles = makeStyles(styles);

// Scroll to Top
function ScrollToTopOnMount() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return null;
}

export default function EditProfile(props) {
  const classes = useStyles();
  const { ...rest } = props;
  const history = useHistory();

  const [customurl] = useState('');
  // wallet related : common state
  const [WalletConnected, Set_WalletConnected] = useState('false');
  const [AddressUserDetails, Set_AddressUserDetails] = useState({});

  const [TokenFile, setTokenFile] = React.useState('');
  const [TokenFilePreReader, setTokenFilePreReader] = React.useState('');

  const inputChange = (e) => {
    if (e && e.target && typeof e.target.value !== 'undefined' && e.target.name) {
      const value = e.target.value;
      const name = e.target.name;

      Set_AddressUserDetails((AddressUserDetails) => ({
        ...AddressUserDetails,
        [name]: value,
      }));
      CreateItemValidation();
    }
  };

  const checkboxChange = (e) => {
    if (e && e.target && typeof e.target.value !== 'undefined' && e.target.name) {
      const value = e.target.checked;
      const name = e.target.name;

      Set_AddressUserDetails((AddressUserDetails) => ({
        ...AddressUserDetails,
        [name]: value,
      }));
      CreateItemValidation();
    }
  };

  const selectFileChange = (e) => {
    var validExtensions = ['png', 'gif', 'webp', 'mp4', 'mp3']; //array of valid extensions
    if (e.target && e.target.files) {
      var reader = new FileReader();
      var file = e.target.files[0];
      var fileName = file.name;
      var fileNameExt = fileName.substr(fileName.lastIndexOf('.') + 1);
      if ($.inArray(fileNameExt, validExtensions) === -1) {
        toast.error('Only these file types are accepted : ' + validExtensions.join(', '), toasterOption);
        $("input[name='image']").val('');
        return false;
      } else {
        const fileSize = file.size / 1024 / 1024;
        if (fileSize > 30) {
          toast.error('File size exceeds 30 MB', toasterOption);
          $("input[name='image']").val('');
          return false;
        } else {
          setTokenFile(file);
          reader.onloadend = function (e) {
            if (reader.result) {
              setTokenFilePreReader(reader.result);
            }
          };
        }
      }
    }
  };

  const GETVERIFIED = async () => {
    var currAddr = await getCurAddr();

    if (currAddr) {
      var formData = new FormData();
      formData.append('addr', currAddr);
      formData.append('Verificationstatus', 'Pending');
      var Resp = await VerifyProfileRequest(formData);
      if (Resp && Resp.data && Resp.data.toast && Resp.data.toast.type && Resp.data.toast.type === 'success') {
        setTimeout(() => {
          history.push('/edit-profile');
        }, 2000);
      }
    }
  };

  const FormSubmit = async () => {
    var currAddr = await getCurAddr();
    if (currAddr) {
      var web3 = new Web3(window.ethereum);
      web3.eth.personal
        .sign(
          `Your Created profile as ${AddressUserDetails.name} and Your url is ${config.Front_URL}/${customurl}`,
          currAddr,
          `Your Created profile as ${AddressUserDetails.name} and Your url is ${config.Front_URL}/${customurl}`,
        )
        .then(async () => {
          var formData = new FormData();
          if (TokenFile) {
            formData.append('Image', TokenFile);
          }
          formData.append('addr', currAddr);
          formData.append('name', AddressUserDetails.name);
          formData.append('personalsite', AddressUserDetails.personalsite);
          formData.append('customurl', AddressUserDetails.customurl);
          formData.append('email', AddressUserDetails.email);
          formData.append('bio', AddressUserDetails.bio);
          formData.append('twitter', AddressUserDetails.twitter);
          formData.append('youtube', AddressUserDetails.youtube);
          formData.append('facebook', AddressUserDetails.facebook);
          formData.append('instagram', AddressUserDetails.instagram);
          await UserProfile_Update_Action(formData);
        });
    }
  };

  const CreateItemValidation = () => {};

  async function AfterWalletConnected() {
    await getCurAddr();
  }

  return (
    <div className="inner_header">
      <ConnectWallet
        Set_WalletConnected={Set_WalletConnected}
        Set_AddressUserDetails={Set_AddressUserDetails}
        WalletConnected={WalletConnected}
        AfterWalletConnected={AfterWalletConnected}
      />
      <Header
        fixed
        color="transparent"
        routes={dashboardRoutes}
        brand={<img src={require('../assets/images/logo.png')} alt="logo" className="img-fluid" />}
        rightLinks={<HeaderLinks />}
        changeColorOnScroll={{
          height: 50,
          color: 'dark',
        }}
        {...rest}
      />
      <ScrollToTopOnMount />
      <div className={classes.pageHeader + ' inner_pageheader'}>
        <div className={classes.container}>
          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              <div className="d-flex align-items-center">
                <h2 className="inner_title">Edit Profile</h2>
              </div>
            </GridItem>
          </GridContainer>
        </div>
        <div className="container">
          <GridContainer>
            <GridItem xs={12} sm={3} md={3}>
              <div className="holder">
                {AddressUserDetails && AddressUserDetails.image && AddressUserDetails.image !== '' ? (
                  <img src={AddressUserDetails.image} alt="logo" id="imgPreview" className="img-fluid" />
                ) : TokenFilePreReader && TokenFilePreReader !== '' ? (
                  <img src={TokenFilePreReader} alt="logo" id="imgPreview" className="img-fluid" />
                ) : (
                  <img
                    src={require('../assets/images/profile_img.png')}
                    alt="logo"
                    id="imgPreview"
                    className="img-fluid"
                  />
                )}
                <div className="profile_edit_panel">
                  <div className="profile_edit_icon">
                    <i className="fas fa-pencil-alt"></i>
                  </div>
                </div>
                <input type="file" id="photo" name="image" onChange={selectFileChange} />
              </div>
            </GridItem>
            <GridItem xs={12} sm={9} md={9}>
              <form>
                <div className="form-row">
                  <div className="form-group col-md-6">
                    <label className="primary_label" htmlFor="name">
                      Display Name
                    </label>
                    <input
                      type="text"
                      className="form-control primary_inp"
                      id="name"
                      name="name"
                      placeholder="Enter your display name"
                      onChange={inputChange}
                      defaultValue={AddressUserDetails.name}
                    />
                  </div>
                  <div className="form-group col-md-6">
                    <label className="primary_label" htmlFor="desccription">
                      Personal site or portfolio
                    </label>
                    <input
                      type="text"
                      className="form-control primary_inp"
                      id="personalsite"
                      name="personalsite"
                      onChange={inputChange}
                      defaultValue={AddressUserDetails.personalsite}
                    />
                  </div>
                </div>

                <div className="form-row">
                  <div className="form-group col-md-6">
                    <label className="primary_label" htmlFor="name">
                      Custom URL ({config.Front_URL}/{customurl})
                    </label>
                    <input
                      type="text"
                      className="form-control primary_inp"
                      id="customurl"
                      name="customurl"
                      onChange={inputChange}
                      defaultValue={AddressUserDetails.customurl}
                    />
                  </div>
                  <div className="form-group col-md-6">
                    <label className="primary_label" htmlFor="desccription">
                      Email
                    </label>
                    <input
                      type="text"
                      className="form-control primary_inp"
                      id="email"
                      name="email"
                      placeholder="Your email for marketplace notifications"
                      onChange={inputChange}
                      defaultValue={AddressUserDetails.email}
                    />
                  </div>
                </div>

                <div className="form-row">
                  <div className="form-group col-md-6">
                    <label className="primary_label" htmlFor="name">
                      Bio
                    </label>
                    <input
                      type="text"
                      className="form-control primary_inp"
                      id="bio"
                      name="bio"
                      placeholder="Tell about yourself in a few words"
                      onChange={inputChange}
                      defaultValue={AddressUserDetails.bio}
                    />
                  </div>
                  <div className="form-group col-md-6">
                    <label className="primary_label" htmlFor="desccription">
                      Verification
                    </label>

                    {AddressUserDetails.Accountverified === 'Not Verified' ? (
                      <div className="">
                        <Button className="primary_btn w-100" onClick={() => GETVERIFIED()}>
                          Get verified
                        </Button>
                        <p className="text-muted mb-0 ml-3">
                          Procceed with verification proccess to get more visibility and gain trust on ORE FORGE
                          Marketplace. Please allow up to several weeks for the process.
                        </p>
                      </div>
                    ) : AddressUserDetails.Accountverified === 'Pending' ? (
                      <div className="">
                        <Button className="primary_btn w-100" disabled={true}>
                          Pending
                        </Button>
                        <p className="text-muted mb-0 ml-3">Your Verification is Pending .</p>
                      </div>
                    ) : AddressUserDetails.Accountverified === 'Verified' ? (
                      <div className="">
                        <Button className="primary_btn w-100" disabled={true}>
                          Verified
                        </Button>
                        <p className="text-muted mb-0 ml-3">Your Account has been Verified .</p>
                      </div>
                    ) : (
                      <div className="">
                        <Button className="primary_btn w-100" onClick={() => GETVERIFIED()}>
                          Get verified
                        </Button>
                        <p className="text-muted mb-0 ml-3">Your Verification got Rejected. Please Try Again .</p>
                      </div>
                    )}
                  </div>
                </div>
                <div className="form-row">
                  <div className="form-group col-md-6">
                    <label className="primary_label" htmlFor="name">
                      Social Media Links
                    </label>
                  </div>
                </div>
                <div className="form-row">
                  <div className="form-group col-md-6">
                    <input
                      type="text"
                      className="form-control primary_inp"
                      id="twitter"
                      name="twitter"
                      placeholder="Twitter account address"
                      onChange={inputChange}
                      defaultValue={AddressUserDetails.twitter}
                    />
                  </div>
                  <div className="form-group col-md-6">
                    <input
                      type="text"
                      className="form-control primary_inp"
                      id="youtube"
                      name="youtube"
                      placeholder="Youtube channel address"
                      onChange={inputChange}
                      defaultValue={AddressUserDetails.youtube}
                    />
                  </div>
                </div>
                <div className="form-row">
                  <div className="form-group col-md-6">
                    <input
                      type="text"
                      className="form-control primary_inp"
                      id="facebook"
                      name="facebook"
                      placeholder="Facebook account address"
                      onChange={inputChange}
                      defaultValue={AddressUserDetails.facebook}
                    />
                  </div>
                  <div className="form-group col-md-6">
                    <input
                      type="text"
                      className="form-control primary_inp"
                      id="instagram"
                      name="instagram"
                      placeholder="Instagram address"
                      onChange={inputChange}
                      defaultValue={AddressUserDetails.instagram}
                    />
                  </div>
                </div>

                <div className="mt-5 d-flex justify-content-end">
                  <Button className="create_btn" onClick={FormSubmit}>
                    Update Profile
                  </Button>
                </div>
              </form>
            </GridItem>
          </GridContainer>
        </div>
      </div>
      <Footer />
    </div>
  );
}
