import axios from 'axios';
import config from '../lib/config';

export const headerSearch = async (data) => {
  try {
    let respData = await axios({
      method: 'get',
      url: `${config.vUrl}/token/header-search?search=` + data.search,
    });
    return {
      loading: false,
      result: respData.data.result,
      userList: respData.data.users,
    };
  } catch (err) {
    return {
      loading: false,
      error: returnErr(err),
    };
  }
};

export const GetItemList = async (data) => {
  try {
    let respData = await axios({
      method: 'get',
      url: `${config.vUrl}/token/collectibles/list/search?search=` + data.search + '&page=' + data.page,
    });
    return {
      loading: false,
      result: respData.data.list,
    };
  } catch (err) {
    return {
      loading: false,
      error: returnErr(err),
    };
  }
};

export const GetUserList = async (data) => {
  try {
    let respData = await axios({
      method: 'get',
      url: `${config.vUrl}/token/list/user-search?search=` + data.search + '&page=' + data.page,
    });
    return {
      loading: false,
      result: respData.data.list,
    };
  } catch (err) {
    return {
      loading: false,
      error: returnErr(err),
    };
  }
};

export const ReportRequest = async (data) => {
  try {
    let respData = await axios({
      method: 'post',
      url: `${config.vUrl}/user/submit-report`,
      data: data,
    });
    return {
      loading: false,
      status: respData.data.status,
    };
  } catch (err) {
    return {
      loading: false,
      error: returnErr(err),
    };
  }
};

function returnErr(err) {
  if (err.response && err.response.data && err.response.data.errors) {
    return err.response.data.errors;
  } else {
    return '';
  }
}

export const getLegalAgreementOptions = async () => {
  try {
    let response = await axios({
      method: 'get',
      url: `${config.v1Url}/getLegal`,
    });
    return {
      loading: false,
      data: response.data,
    };
  } catch (error) {
    return {
      loading: false,
      error: returnErr(error),
    };
  }
};
