// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';
import React, { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { GetItemList } from '../actions/items';
import { CollectiblesList_Follow, CollectiblesList_Home } from '../actions/token';
import { getCurAddr } from '../actions/user';
import styles from '../assets/jss/material-kit-react/views/landingPage.js';
import Footer from '../components/Footer/Footer';
import GridContainer from '../components/Grid/GridContainer';
import GridItem from '../components/Grid/GridItem';
// core components
import Header from '../components/Header/Header';
import HeaderLinks from '../components/Header/HeaderLinks';
import config from '../lib/config';
import { BurnRef } from './separate/BurnRef';
import { CancelOrderRef } from './separate/CancelOrderRef';
import ConnectWallet from './separate/ConnectWallet';
import Explore from './separate/Explore';
import { LikeRef } from './separate/LikeRef';
import { PlaceAndAcceptBidRef } from './separate/PlaceAndAcceptBidRef';
import { PurchaseNowRef } from './separate/PurchaseNowRef';
import { PutOnSaleRef } from './separate/PutOnSaleRef';
import { ReportNowRef } from './separate/ReportNowRef';
import { ShareNowRef } from './separate/ShareNowRef';
import { WalletRef } from './separate/WalletRef';

toast.configure();
const dashboardRoutes = [];

const useStyles = makeStyles(styles);

// Scroll to Top
function ScrollToTopOnMount() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return null;
}

export default function Following(props) {
  var LikeForwardRef = useRef();
  var PutOnSaleForwardRef = useRef();
  var PurchaseNowForwardRef = useRef();
  var BurnForwardRef = useRef();
  var CancelOrderForwardRef = useRef();
  var WalletForwardRef = useRef();
  var ReportForwardRef = useRef();
  var ShareForwardRef = useRef();
  var PlaceABidForwardRef = useRef();

  const classes = useStyles();
  const { ...rest } = props;

  const [WalletConnected, Set_WalletConnected] = React.useState('false');
  const [UserAccountAddr, Set_UserAccountAddr] = React.useState('');
  const [UserAccountBal, Set_UserAccountBal] = React.useState(0);
  const [AddressUserDetails, Set_AddressUserDetails] = useState({});
  const [Accounts, Set_Accounts] = React.useState('');
  const [itemPage] = React.useState(0);

  const [MyItemAccountAddr, Set_MyItemAccountAddr] = React.useState('');

  const [HitItem, Set_HitItem] = useState({});

  const [LikedTokenList, setLikedTokenList] = React.useState([]);
  const [item, Set_item] = useState({});

  const [pricesort, setpricesort] = React.useState('');
  const [datesort, setdatesort] = React.useState('');
  const [likesort, setlikesort] = React.useState('');
  const [ownersort, setownersort] = React.useState('');

  const [CatName] = React.useState('All');
  const [CatBasedTokenList, setCatBasedTokenList] = React.useState({
    loader: false,
    All: { page: 1, list: [], onmore: true },
  });
  const [tokenCounts, Set_tokenCounts] = useState(0);
  const [tokenCounts_Detail, Set_tokenCounts_Detail] = useState({});
  const [MyTokenBalance, Set_MyTokenBalance] = useState(0);
  const [Bids, Set_Bids] = useState([]);
  const [AccepBidSelect, Set_AccepBidSelect] = useState(0);
  const [tokenBidAmt, Set_tokenBidAmt] = useState(0);
  const [NoOfToken, Set_NoOfToken] = useState(1);
  const [ValidateError, Set_ValidateError] = useState({});
  const [TokenBalance, Set_TokenBalance] = useState(0);
  const [YouWillPay, Set_YouWillPay] = useState(0);
  const [YouWillPayFee, Set_YouWillPayFee] = useState(0);
  const [YouWillGet, Set_YouWillGet] = useState(0);
  const [BidApply_ApproveCallStatus, Set_BidApply_ApproveCallStatus] = React.useState('init');
  const [BidApply_SignCallStatus, Set_BidApply_SignCallStatus] = React.useState('init');
  const [AllowedQuantity, Set_AllowedQuantity] = useState(0);
  const [fullLoader, Set_fullLoader] = useState(false);
  const [totaluserbidAmt, setTotaluserbidAmt] = React.useState(0);

  function getParameterByName(name, url = window.location.href) {
    name = name.replace(/[[\]]/g, '\\$&');
    var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
      results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, ' '));
  }

  useEffect(() => {
    SearchList();
    TokenListCall();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  async function SearchList() {
    var searchtxt = getParameterByName('query');
    await GetItemList({ search: searchtxt, page: itemPage });
  }
  async function AfterWalletConnected() {
    try {
      LikeForwardRef.current.getLikesData();
    } catch (err) {}

    FollowList_Get_Call();
  }

  async function FollowList_Get_Call() {
    // var addr = UserAccountAddr;
    var addr = await getCurAddr();
    await CollectiblesList_Follow({
      target: 'following',
      addr: addr,
      follower: addr,
    });
  }

  async function GetUserBal() {
    await WalletForwardRef.current.GetUserBal();
  }

  const pricechange = async (e) => {
    setlikesort('');
    setdatesort('');
    setownersort('');
    if (e.target.value === '1') {
      setpricesort(1);
    } else {
      setpricesort(-1);
    }
    TokenListCall({
      CatName: CatName,
      page: 1,
      pricesort: e.target.value === '1' ? 1 : -1,
      likesort: likesort,
      datesort: datesort,
      ownersort: ownersort,
    });
  };

  const likechange = async (e) => {
    setdatesort('');
    setpricesort('');
    setownersort('');
    if (e.target.value === '1') {
      setlikesort(1);
    } else {
      setlikesort(-1);
    }
    TokenListCall({
      CatName: CatName,
      page: 1,
      pricesort: pricesort,
      likesort: e.target.value === '1' ? 1 : -1,
      datesort: datesort,
      ownersort: ownersort,
    });
  };

  const authorchange = async (e) => {
    if (e.target.value === '-1') {
      setlikesort('');
      setdatesort('');
      setpricesort('');
      setownersort(-1);
    } else {
      setownersort(e.target.value);
    }
    TokenListCall({
      CatName: CatName,
      page: 1,
      pricesort: '',
      likesort: '',
      datesort: '',
      ownersort: e.target.value === '-1' ? -1 : e.target.value,
    });
  };

  const sortchange = async (e) => {
    if (e.target.value === '1') {
      setdatesort(1);
      setlikesort('');
      setpricesort('');
      setownersort('');
      TokenListCall({
        CatName: CatName,
        page: 1,
        pricesort: pricesort,
        likesort: likesort,
        datesort: e.target.value === '1' ? 1 : -1,
        ownersort: ownersort,
      });
    } else if (e.target.value === '-1') {
      setdatesort(-1);
      setlikesort('');
      setpricesort('');
      setownersort('');
      TokenListCall({
        CatName: CatName,
        page: 1,
        pricesort: pricesort,
        likesort: likesort,
        datesort: e.target.value === '1' ? 1 : -1,
        ownersort: ownersort,
      });
    } else if (e.target.value === 'lowprice') {
      setpricesort(1);
      setlikesort('');
      setdatesort('');
      setownersort('');
      TokenListCall({
        CatName: CatName,
        page: 1,
        pricesort: 1,
        likesort: likesort,
        datesort: datesort,
        ownersort: ownersort,
      });
    } else {
      setpricesort(-1);
      setlikesort('');
      setdatesort('');
      setownersort('');
      TokenListCall({
        CatName: CatName,
        page: 1,
        pricesort: -1,
        likesort: likesort,
        datesort: datesort,
        ownersort: ownersort,
      });
    }
  };

  async function TokenListCall(data = {}) {
    var currAddr = await getCurAddr();
    var name = CatName;
    if (data.CatName) {
      name = data.CatName;
    }
    var payload = {
      limit: config.limit,
      page: CatBasedTokenList[name] && CatBasedTokenList[name].page ? CatBasedTokenList[name].page : 1,
      currAddr: currAddr,
      CatName: name,
      price: data.pricesort,
      like: data.likesort,
      date: data.datesort,
      owner: data.ownersort,
      from: 'Home',
    };
    CatBasedTokenList.loader = true;
    setCatBasedTokenList(CatBasedTokenList);

    var resp = await CollectiblesList_Home(payload);
    CatBasedTokenList.loader = false;
    setCatBasedTokenList(CatBasedTokenList);
    if (
      resp &&
      resp.data &&
      resp.data.from === 'token-collectibles-list-home' &&
      resp.data.list &&
      resp.data.list.length > 0
    ) {
      if (typeof CatBasedTokenList[name] === 'undefined') {
        CatBasedTokenList[name] = { page: 1, list: [] };
      }
      //CatBasedTokenList[name].list = CatBasedTokenList[name].list.concat(resp.data.list);
      CatBasedTokenList[name].list = resp.data.list;
      setCatBasedTokenList([]);
      setCatBasedTokenList(CatBasedTokenList);
    } else {
      CatBasedTokenList[name].onmore = false;
      setCatBasedTokenList([]);
      setCatBasedTokenList(CatBasedTokenList);
    }
  }

  return (
    <div className="inner_header">
      <ConnectWallet
        Set_UserAccountAddr={Set_UserAccountAddr}
        Set_UserAccountBal={Set_UserAccountBal}
        Set_WalletConnected={Set_WalletConnected}
        Set_AddressUserDetails={Set_AddressUserDetails}
        Set_Accounts={Set_Accounts}
        WalletConnected={WalletConnected}
        AfterWalletConnected={AfterWalletConnected}
        Set_fullLoader={Set_fullLoader}
        fullLoader={fullLoader}
      />
      {/*<BidPopup
        item={HitItem}
        UserAccountAddr={UserAccountAddr}
        UserAccountBal={UserAccountBal}
      />*/}
      <PutOnSaleRef
        ref={PutOnSaleForwardRef}
        Set_HitItem={Set_HitItem}
        item={HitItem}
        UserAccountAddr={UserAccountAddr}
        UserAccountBal={UserAccountBal}
        Accounts={Accounts}
        GetUserBal={GetUserBal}
      />
      <PlaceAndAcceptBidRef
        ref={PlaceABidForwardRef}
        Set_HitItem={Set_HitItem}
        Set_WalletConnected={Set_WalletConnected}
        Set_UserAccountAddr={Set_UserAccountAddr}
        Set_UserAccountBal={Set_UserAccountBal}
        Set_AddressUserDetails={Set_AddressUserDetails}
        Set_Accounts={Set_Accounts}
        Set_MyItemAccountAddr={Set_MyItemAccountAddr}
        Set_tokenCounts={Set_tokenCounts}
        Set_item={Set_item}
        Set_tokenCounts_Detail={Set_tokenCounts_Detail}
        Set_MyTokenBalance={Set_MyTokenBalance}
        Set_Bids={Set_Bids}
        Set_AccepBidSelect={Set_AccepBidSelect}
        Set_tokenBidAmt={Set_tokenBidAmt}
        Set_NoOfToken={Set_NoOfToken}
        Set_ValidateError={Set_ValidateError}
        Set_TokenBalance={Set_TokenBalance}
        Set_YouWillPay={Set_YouWillPay}
        Set_YouWillPayFee={Set_YouWillPayFee}
        Set_YouWillGet={Set_YouWillGet}
        Set_BidApply_ApproveCallStatus={Set_BidApply_ApproveCallStatus}
        Set_BidApply_SignCallStatus={Set_BidApply_SignCallStatus}
        WalletConnected={WalletConnected}
        UserAccountAddr={UserAccountAddr}
        UserAccountBal={UserAccountBal}
        AddressUserDetails={AddressUserDetails}
        Accounts={Accounts}
        MyItemAccountAddr={MyItemAccountAddr}
        tokenCounts={tokenCounts}
        item={item}
        tokenCounts_Detail={tokenCounts_Detail}
        MyTokenBalance={MyTokenBalance}
        Bids={Bids}
        AccepBidSelect={AccepBidSelect}
        tokenBidAmt={tokenBidAmt}
        NoOfToken={NoOfToken}
        ValidateError={ValidateError}
        TokenBalance={TokenBalance}
        YouWillPay={YouWillPay}
        YouWillPayFee={YouWillPayFee}
        YouWillGet={YouWillGet}
        BidApply_ApproveCallStatus={BidApply_ApproveCallStatus}
        BidApply_SignCallStatus={BidApply_SignCallStatus}
        AllowedQuantity={AllowedQuantity}
        totaluserbidAmt={totaluserbidAmt}
        setTotaluserbidAmt={setTotaluserbidAmt}
      />
      <PurchaseNowRef
        ref={PurchaseNowForwardRef}
        Set_HitItem={Set_HitItem}
        item={HitItem}
        UserAccountAddr={UserAccountAddr}
        UserAccountBal={UserAccountBal}
        TokenBalance={TokenBalance}
        Accounts={Accounts}
        GetUserBal={GetUserBal}
      />
      <WalletRef
        ref={WalletForwardRef}
        Set_UserAccountAddr={Set_UserAccountAddr}
        Set_WalletConnected={Set_WalletConnected}
        Set_UserAccountBal={Set_UserAccountBal}
      />
      <LikeRef ref={LikeForwardRef} setLikedTokenList={setLikedTokenList} MyItemAccountAddr={MyItemAccountAddr} />
      <BurnRef
        ref={BurnForwardRef}
        GetUserBal={GetUserBal}
        UserAccountAddr={UserAccountAddr}
        UserAccountBal={UserAccountBal}
        TokenBalance={TokenBalance}
        Accounts={Accounts}
      />
      {/*  <CancelOrderRef
        ref={CancelOrderForwardRef}
        Set_HitItem={Set_HitItem}
        item={HitItem}
        UserAccountAddr={UserAccountAddr}
        UserAccountBal={UserAccountBal}
        Accounts={Accounts}
        GetUserBal={GetUserBal}
      />*/}
      <CancelOrderRef
        ref={CancelOrderForwardRef}
        Set_HitItem={Set_HitItem}
        item={HitItem}
        UserAccountAddr={UserAccountAddr}
        UserAccountBal={UserAccountBal}
        TokenBalance={TokenBalance}
        Accounts={Accounts}
        GetUserBal={GetUserBal}
        // againCall={TokenCounts_Get_Detail_Call}
        // showingloadertrue={showingloader_true}
        // showingloaderfalse={showingloader_false}
      />
      <ReportNowRef UserAccountAddr={UserAccountAddr} ref={ReportForwardRef} />
      <ShareNowRef ref={ShareForwardRef} />
      <Header
        fixed
        color="transparent"
        routes={dashboardRoutes}
        brand={
          <Link to="/home">
            <img src={require('../assets/images/logo.png')} alt="logo" className="img-fluid" />
          </Link>
        }
        rightLinks={<HeaderLinks />}
        changeColorOnScroll={{
          height: 50,
          color: 'dark',
        }}
        {...rest}
      />
      <ScrollToTopOnMount />
      <div className={classes.pageHeader + ' inner_pageheader'}>
        <div className="bg_red_1">
          <div className={classes.container}>
            <GridContainer>
              <GridItem xs={12} sm={12} md={12}>
                <div className="d-flex align-items-center justify-content-between">
                  <h2 className="inner_title">Search</h2>
                  <div className="custom_filter">
                    <a
                      href="#discoverFilter"
                      className="menu_btn landingFilterButton"
                      data-toggle="collapse"
                      role="button"
                      aria-expanded="false"
                      aria-controls="discoverFilter"
                    >
                      Filter & Sort <img src={require('../assets/images/filtersorticon.png')} alt="" />
                    </a>
                  </div>
                </div>
                <div className="collapse" id="discoverFilter">
                  <div className="card card-body my-2">
                    <GridContainer className="w-100">
                      <GridItem xs={12} sm={6} md={6} lg={3}>
                        <div className="form-group">
                          <label htmlFor="inputEmail4" className="auth_label">
                            Price
                          </label>
                          <select className="custom-select auth_inp" onChange={pricechange}>
                            <option selected Value="-1">
                              Highest Price
                            </option>
                            <option value="1">Lowest Price</option>
                          </select>
                        </div>
                      </GridItem>
                      <GridItem xs={12} sm={6} md={6} lg={3}>
                        <div className="form-group">
                          <label htmlFor="inputEmail4" className="auth_label">
                            Likes
                          </label>
                          <select className="custom-select auth_inp" onChange={likechange}>
                            <option selected value="-1">
                              Most Liked
                            </option>
                            <option value="1">less Liked</option>
                          </select>
                        </div>
                      </GridItem>
                      <GridItem xs={12} sm={6} md={6} lg={3}>
                        <div className="form-group">
                          <label htmlFor="inputEmail4" className="auth_label">
                            Creator
                          </label>
                          <select className="custom-select auth_inp" onChange={authorchange}>
                            <option selected value="verified">
                              Verified Only
                            </option>
                            <option value="popular">Popular Creators</option>
                            <option value="-1">New Creators</option>
                          </select>
                        </div>
                      </GridItem>
                      <GridItem xs={12} sm={6} md={6} lg={3}>
                        <div className="form-group">
                          <label htmlFor="inputEmail4" className="auth_label">
                            Sort By
                          </label>
                          <select className="custom-select auth_inp" onChange={sortchange}>
                            <option selected value="-1">
                              Newest
                            </option>
                            <option value="1">Oldest</option>
                            {/* <option value="lowprice">Price Low</option>
                          <option value="highprice">Price High</option> */}
                          </select>
                        </div>
                      </GridItem>
                    </GridContainer>
                  </div>
                </div>
              </GridItem>
            </GridContainer>
          </div>
        </div>
        <div className="container mt-3">
          <div className="row">
            {CatBasedTokenList &&
            CatName &&
            CatBasedTokenList[CatName] &&
            CatBasedTokenList[CatName].list &&
            CatBasedTokenList[CatName].list.length > 0
              ? CatBasedTokenList[CatName].list.map((item) => {
                  return (
                    <Explore
                      item={item}
                      Set_item={Set_item}
                      LikedTokenList={LikedTokenList}
                      hitLike={LikeForwardRef.current.hitLike}
                      UserAccountAddr={UserAccountAddr}
                      UserAccountBal={UserAccountBal}
                      PutOnSale_Click={PutOnSaleForwardRef.current.PutOnSale_Click}
                      PurchaseNow_Click={PurchaseNowForwardRef.current.PurchaseNow_Click}
                      PlaceABid_Click={PlaceABidForwardRef.current.PlaceABid_Click}
                      Set_Bids={Set_Bids}
                      Bids={Bids}
                      Set_tokenCounts_Detail={Set_tokenCounts_Detail}
                      Set_MyTokenBalance={Set_MyTokenBalance}
                      Set_AllowedQuantity={Set_AllowedQuantity}
                      Set_YouWillPay={Set_YouWillPay}
                      Set_YouWillPayFee={Set_YouWillPayFee}
                      Set_YouWillGet={Set_YouWillGet}
                      // Bid_Click={PlaceABidForwardRef.current.Bid_Click}
                      Burn_Click={BurnForwardRef.current.Burn_Click}
                      CancelOrder_Click={CancelOrderForwardRef.current.CancelOrder_Click}
                      WalletConnected={WalletConnected}
                      SubmitReport_Click={ReportForwardRef.current.SubmitReport_Click}
                      ShareSocial_Click={ShareForwardRef.current.ShareSocial_Click}
                    />
                  );
                })
              : 'No record Found'}
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
