import { Button } from '@material-ui/core';
// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';
import $ from 'jquery';
import React, { useEffect, useRef, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
// Slick Carousel
import Slider from 'react-slick';
import { toast } from 'react-toastify';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';
import Web3 from 'web3';
import DETH_ABI from '../ABI/DETH_ABI.json';
import { Bidding_Detail_Action, CollectiblesList_MyItems } from '../actions/token';
import {
  Activity_List_Action,
  changeReceiptStatus_Action,
  FollowChange_Action,
  getCurAddr,
  ParamAccountAddr_Detail_Get,
  UserProfile_Update_Action,
  User_Followers_List_Action,
  User_Following_List_Action,
  User_Follow_Get_Action,
} from '../actions/user';
import styles from '../assets/jss/material-kit-react/views/landingPage.js';
import Footer from '../components/Footer/Footer';
import GridContainer from '../components/Grid/GridContainer';
import GridItem from '../components/Grid/GridItem';
// core components
import Header from '../components/Header/Header';
import HeaderLinks from '../components/Header/HeaderLinks';
import config from '../lib/config';
import ActivityTab from './separate/ActivityTab';
import { BurnRef } from './separate/BurnRef';
import { CancelOrderRef } from './separate/CancelOrderRef';
import ConnectWallet from './separate/ConnectWallet';
import { LikeRef } from './separate/LikeRef';
import { PlaceAndAcceptBidRef } from './separate/PlaceAndAcceptBidRef';
import { PurchaseNowRef } from './separate/PurchaseNowRef';
import { PutOnSaleRef } from './separate/PutOnSaleRef';
import { ReportNowRef } from './separate/ReportNowRef';
import { ShareNowRef } from './separate/ShareNowRef';
import TokenItem from './separate/TokenItem';
import { WalletRef } from './separate/WalletRef';

toast.configure();
let toasterOption = config.toasterOption;

const dashboardRoutes = [];

const useStyles = makeStyles(styles);

// Scroll to Top
function ScrollToTopOnMount() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return null;
}

export default function UserProfile(props) {
  const classes = useStyles();
  const { ...rest } = props;

  var LikeForwardRef = useRef();
  var PutOnSaleForwardRef = useRef();
  var PurchaseNowForwardRef = useRef();
  var WalletForwardRef = useRef();
  var ReportForwardRef = useRef();
  var ShareForwardRef = useRef();
  var PlaceABidForwardRef = useRef();
  var CancelOrderForwardRef = useRef();
  var BurnForwardRef = useRef();

  var { paramUsername, paramAddress } = useParams();
  if (typeof paramUsername === 'undefined') {
    paramUsername = '';
  }
  if (typeof paramAddress === 'undefined') {
    paramAddress = '';
  }

  const [ParamAccountCustomUrl] = React.useState(paramUsername);
  const [ParamAccountAddr] = React.useState(paramAddress);

  const [UserNotFound, Set_UserNotFound] = React.useState(false);
  const [FollowingUserList, Set_FollowingUserList] = React.useState([]);

  // wallet related : common state
  const [WalletConnected, Set_WalletConnected] = React.useState('false');
  const [UserAccountAddr, Set_UserAccountAddr] = React.useState('');
  const [UserAccountBal, Set_UserAccountBal] = React.useState(0);
  const [AddressUserDetails, Set_AddressUserDetails] = useState({});
  const [Accounts, Set_Accounts] = React.useState('');

  const [MyItemAccountAddr, Set_MyItemAccountAddr] = React.useState('');
  const [MyItemAccountAddr_Details, Set_MyItemAccountAddr_Details] = React.useState('');

  const [HitItem, Set_HitItem] = useState({});

  const [LikedTokenList, setLikedTokenList] = React.useState([]);

  const [OnSale_Count, Set_OnSale_Count] = useState(0);
  const [OnSale_List, Set_OnSale_List] = useState([]);

  const [Collectibles_Count, Set_Collectibles_Count] = useState(0);
  const [Collectibles_List, Set_Collectibles_List] = useState([]);

  const [Created_Count, Set_Created_Count] = useState(0);
  const [Created_List, Set_Created_List] = useState([]);

  const [Liked_Count, Set_Liked_Count] = useState(0);
  const [Liked_List, Set_Liked_List] = useState([]);

  //by muthu
  const [Bids, Set_Bids] = useState([]);
  const [tokenCounts_Detail, Set_tokenCounts_Detail] = useState({});
  const [MyTokenBalance, Set_MyTokenBalance] = useState(0);
  const [AllowedQuantity, Set_AllowedQuantity] = useState({});
  const [YouWillPay, Set_YouWillPay] = useState(0);
  const [YouWillPayFee, Set_YouWillPayFee] = useState(0);
  const [YouWillGet, Set_YouWillGet] = useState(0);
  const [item, Set_item] = useState({});
  const [tokenCounts, Set_tokenCounts] = useState(0);
  const [AccepBidSelect, Set_AccepBidSelect] = useState(0);
  const [tokenBidAmt, Set_tokenBidAmt] = useState(0);
  const [NoOfToken, Set_NoOfToken] = useState(1);
  const [ValidateError, Set_ValidateError] = useState({});
  const [TokenBalance, Set_TokenBalance] = useState(0);
  const [BidApply_ApproveCallStatus, Set_BidApply_ApproveCallStatus] = React.useState('init');
  const [BidApply_SignCallStatus, Set_BidApply_SignCallStatus] = React.useState('init');

  const [followingCount, Set_followingCount] = useState(0);
  const [followersCount, Set_followersCount] = useState(0);
  const [followingStatus, Set_followingStatus] = useState(true);
  const [loadMoreBtn, Set_loadMoreBtn] = useState(true);
  const [followersPage] = useState(1);
  const [followingPage] = useState(1);
  const [followersStatus, Set_followersStatus] = useState(true);
  const [FollowerUserList, Set_FollowerUserList] = React.useState([]);
  const [loadMoreBtn1, Set_loadMoreBtn1] = useState(true);
  const [is_follow, Set_is_follow] = useState('no');
  const [ActivityList, Set_ActivityList] = React.useState([]);
  const [Activitycount, Set_Activitycount] = React.useState(0);
  const [CurTabName, Set_CurTabName] = React.useState('OnSale');

  const [MyItemcoverimage, Set_MyItemcoverimage] = React.useState('');
  const [totaluserbidAmt, setTotaluserbidAmt] = React.useState(0);
  const [filterType, setfilterType] = React.useState('');
  const [activityType] = React.useState('all');
  // const [HitItem, Set_HitItem] = useState({});
  async function CorrectDataGet(Resp, Target) {
    var RetData = { count: 0, list: [] };
    if (Resp && Resp.data && Resp.data.Target && Resp.data.list && Resp.data.list[0]) {
      if (Resp.data.Target === 'Count' && Resp.data.list[0].count) {
        RetData.count = Resp.data.list[0].count;
      } else if (Resp.data.Target === 'List' && Resp.data.list[0]) {
        RetData.list = Resp.data.list;
      }
    }
    if (Resp && Resp.data && Resp.data.Target && Resp.data.changeStatusList) {
      changeReceiptStatus_Call(Resp.data.changeStatusList);
    }
    return RetData;
  }

  const selectFileChange = async (e) => {
    var validExtensions = ['png', 'gif', 'webp', 'mp4', 'mp3']; //array of valid extensions
    if (e.target && e.target.files) {
      var file = e.target.files[0];
      var fileName = file.name;
      var fileNameExt = fileName.substr(fileName.lastIndexOf('.') + 1);
      if ($.inArray(fileNameExt, validExtensions) === -1) {
        toast.error('Only these file types are accepted : ' + validExtensions.join(', '), toasterOption);
        $("input[name='image']").val('');
        return false;
      } else {
        const fileSize = file.size / 1024 / 1024;
        if (fileSize > 30) {
          toast.error('File size exceeds 30 MB', toasterOption);
          $("input[name='image']").val('');
          return false;
        } else {
          var currAddr = await getCurAddr();

          var formData = new FormData();
          if (file) {
            formData.append('CoverImage', file);
          }
          formData.append('addr', currAddr);
          var Resp = await UserProfile_Update_Action(formData);
          if (Resp && Resp.data && Resp.data.toast && Resp.data.toast.type && Resp.data.toast.type === 'success') {
            setTimeout(() => {
              window.location.reload(false);
            }, 2000);
          }
        }
      }
    }
  };

  async function changeFilter(type) {
    try {
      var resp = await Activity_List_Action({
        addr: window.web3.eth.defaultAccount,
        actiontype: activityType,
        filter: type,
      });
      setfilterType(type);
      if (resp && resp.data && resp.data.list) {
        var List = resp.data.list;
        Set_ActivityList(List);
      } else {
        Set_ActivityList([]);
      }
    } catch (err) {}
  }

  async function resetFilter() {
    var address = '';
    if (activityType === 'my') {
      var currAddr = await getCurAddr();
      address = currAddr;
    }
    setfilterType('');
    var resp = await Activity_List_Action({
      addr: address,
      actiontype: activityType,
      filter: '',
    });

    if (resp && resp.data && resp.data.list && resp.data.list.length > 0) {
      var List = resp.data.list;
      var totalList = ActivityList.concat(List);
      Set_ActivityList(totalList);
    } else {
      Set_loadMoreBtn(false);
    }
  }

  async function changeReceiptStatus_Call(list) {
    var web3 = new Web3(window.ethereum);
    list.map(async (item) => {
      if (item && typeof item.checkAdd !== 'undefined' && item.checkAdd.hashValue) {
        try {
          var data = await web3.eth.getTransactionReceipt(item.checkAdd.hashValue);
          var hashValue = item.checkAdd.hashValue;
          if (data === null) {
          } else {
            if (data.status === '0x0') {
            } else {
              var payload = {
                status: 'true',
                hashValue: hashValue,
              };
              await changeReceiptStatus_Action(payload);
            }
          }
        } catch (err) {}
      }
    });
  }

  async function Tab_Click(TabName) {
    Set_CurTabName(TabName);
    await Tab_Data_Call('List', TabName);
    await Tab_Data_Call('Count', TabName);
  }
  async function Tab_Data_Call(Target, TabName, init = false) {
    if (MyItemAccountAddr) {
      var ReqData = {
        Addr: MyItemAccountAddr,
        MyItemAccountAddr: MyItemAccountAddr,
        ParamAccountAddr: ParamAccountAddr,
        UserAccountAddr: MyItemAccountAddr,
        Target: Target,
        TabName: TabName,
        init: init,
        from: 'My-Items',
      };

      var Resp = {};
      Resp = await CollectiblesList_MyItems(ReqData);
      var RespNew = await CorrectDataGet(Resp);
      if ((Target === 'Count' && typeof RespNew.count !== 'undefined') || (Target === 'List' && RespNew.list)) {
        if (TabName === 'collectibles') {
          if (Target === 'Count') {
            Set_Collectibles_Count(RespNew.count);
          }
          if (Target === 'List') {
            Set_Collectibles_List(RespNew.list);
          }
        } else if (TabName === 'onsale') {
          if (Target === 'Count') {
            Set_OnSale_Count(RespNew.count);
          }
          if (Target === 'List') {
            Set_OnSale_List(RespNew.list);
          }
        } else if (TabName === 'created') {
          if (Target === 'Count') {
            Set_Created_Count(RespNew.count);
          }
          if (Target === 'List') {
            Set_Created_List(RespNew.list);
          }
        } else if (TabName === 'liked') {
          if (Target === 'Count') {
            Set_Liked_Count(RespNew.count);
          }
          if (Target === 'List') {
            Set_Liked_List(RespNew.list);
          }
        }
      }
    }
    return true;
  }

  async function FollowingTab(address, pos, isFollow) {
    var currAddr = await getCurAddr();
    var web3 = new Web3(window.ethereum);
    var Payload = {};
    Payload.currAddr = currAddr;
    Payload.ParamAccountAddr = address;

    var msg = 'I would like to follow user: ' + address;
    if (isFollow === 'yes') {
      msg = 'I would like to stop following user: ' + address;
    }

    await web3.eth.personal.sign(msg, currAddr);
    var Resp = await FollowChange_Action(Payload);
    var foll = Resp && Resp.data && Resp.data.ChangeType ? Resp.data.ChangeType : 'no';

    if (
      (ParamAccountAddr !== '' && currAddr !== '' && currAddr === ParamAccountAddr) ||
      ParamAccountAddr === '' ||
      !ParamAccountAddr
    ) {
      //same user
      FollowingUserList.splice(pos, 1);
      Set_followingStatus(false);
      Set_FollowingUserList(FollowingUserList);
      Set_followingStatus(true);

      var followingcnt = Resp && Resp.data && Resp.data.following ? Resp.data.following : '0';
      var followerscnt = Resp && Resp.data && Resp.data.followers ? Resp.data.followers : '0';
      Set_followingCount(followingcnt);
      Set_followersCount(followerscnt);
    } else {
      //another user
      FollowingUserList[pos].isFollow = foll;
      Set_followingStatus(false);
      Set_FollowingUserList(FollowingUserList);
      Set_followingStatus(true);
    }
    if (foll === 'yes') {
      toast.success('Follow successfully', toasterOption);
    } else {
      toast.success('Un-Follow successfully', toasterOption);
    }
  }
  async function FollowChange_Call() {
    var web3 = new Web3(window.ethereum);
    var currAddr = await getCurAddr();
    var Payload = {};
    Payload.currAddr = currAddr;
    Payload.ParamAccountAddr = ParamAccountAddr;

    var msg = 'I would like to follow user: ' + ParamAccountAddr;
    if (is_follow === 'yes') {
      msg = 'I would like to stop following user: ' + ParamAccountAddr;
    }

    await web3.eth.personal.sign(msg, currAddr);
    var Resp = await FollowChange_Action(Payload);

    var foll = Resp && Resp.data && Resp.data.ChangeType ? Resp.data.ChangeType : 'no';
    Set_is_follow(foll);

    if (foll === 'yes') {
      toast.success('Follow successfully', toasterOption);
    } else {
      toast.success('Un-Follow successfully', toasterOption);
    }
    Check_follow();
    if (CurTabName === 'followers') {
      Get_Followers_List();
    } else if (CurTabName === 'following') {
      Get_Following_List();
    }

    //User_FollowList_Get_Call();
  }

  const Get_MyItemAccountAddr_Details = async (payload) => {
    var Resp = await ParamAccountAddr_Detail_Get(payload);
    if (Resp.data && Resp.data.User && Resp.data.User.coverimage) {
      Set_MyItemcoverimage(Resp.data.User.coverimage);
    }

    if (Resp && Resp.data && Resp.data.User && Resp.data.User.curraddress !== '') {
      Set_UserNotFound(false);
      Set_MyItemAccountAddr(Resp.data.User.curraddress);

      if (Resp.data.User) {
        Set_MyItemAccountAddr_Details(Resp.data.User);
      }
    } else {
      toast.warning('User not found', toasterOption);
      Set_UserNotFound(true);
    }
  };
  async function BiddingAmout() {
    try {
      var currAddr = window.web3.eth.defaultAccount;
      var payload = { currAddr };
      var Resp = await Bidding_Detail_Action(payload);
      if (Resp && Resp.data) {
        setTotaluserbidAmt(Resp.data.res1);
      }
    } catch (err) {}
  }
  // async function AfterWalletConnected() {}
  const AfterWalletConnected = async () => {
    var currAddr = await getCurAddr();
    BiddingAmout();
    //by muthu
    if (window.ethereum) {
      var web3 = new Web3(window.ethereum);
      var CoursetroContract = new web3.eth.Contract(DETH_ABI, config.tokenAddr[config.tokenSymbol]);
      var tokenBal = await CoursetroContract.methods.balanceOf(currAddr).call();
      var tokenBalance = tokenBal / config.decimalvalues;
      Set_TokenBalance(tokenBalance.toFixed(6));
    }

    //
    if (typeof currAddr !== 'undefined') {
      if (ParamAccountAddr || ParamAccountCustomUrl) {
        if (ParamAccountAddr && ParamAccountAddr.toString() === currAddr.toString()) {
          Set_MyItemAccountAddr(ParamAccountAddr);
          Get_MyItemAccountAddr_Details({ addr: currAddr });
        } else {
          var payload = {};
          if (ParamAccountAddr) {
            payload.addr = ParamAccountAddr;
          } else if (ParamAccountCustomUrl) {
            payload.customurl = ParamAccountCustomUrl;
          }
          await Get_MyItemAccountAddr_Details(payload);
        }
      } else {
        Set_MyItemAccountAddr(currAddr);
        Get_MyItemAccountAddr_Details({ addr: currAddr });
      }

      window.$('#AfterWalletConnected_two').click();
      // After set state value not getting so above click function i used.
      // After this onclick state value getting purfectly (By Raj)
    }
  };

  async function copyToClipboard(e) {
    var textField = document.createElement('textarea');
    textField.innerText = ParamAccountAddr ? ParamAccountAddr : UserAccountAddr;
    document.body.appendChild(textField);
    textField.select();
    document.execCommand('copy');
    textField.remove();
    toast.success('Copied Successfully', toasterOption);
  }

  async function AfterWalletConnected_two() {
    // User_FollowList_Get_Call();
    Check_follow();
    Get_Activity_list();
    try {
      LikeForwardRef.current.getLikesData();
    } catch (err) {}

    await Tab_Data_Call('Count', 'onsale', true);
    await Tab_Data_Call('List', 'onsale');
    await Tab_Data_Call('Count', 'collectibles');
    await Tab_Data_Call('Count', 'created');
    await Tab_Data_Call('Count', 'owned');
    await Tab_Data_Call('Count', 'liked');
  }

  async function GetUserBal() {
    await WalletForwardRef.current.GetUserBal();
  }

  const loadFollowing = async (e) => {
    var currAddr = await getCurAddr();
    var resp = await User_Following_List_Action({
      addr: MyItemAccountAddr,
      loginUser: currAddr,
      page: followingPage,
    });

    if (resp && resp.data && resp.data.list) {
      var List = resp.data.list;
      if (List && List.length > 0) {
        Set_followingStatus(false);
        var totalList = FollowingUserList.concat(List);
        Set_FollowingUserList(totalList);
        Set_followingStatus(true);
      } else {
        Set_loadMoreBtn(false);
      }
    }
  };
  async function Check_follow() {
    var data = {
      useraddress: UserAccountAddr,
      followaddress: MyItemAccountAddr,
    };
    var resp = await User_Follow_Get_Action(data);
    var foll = resp && resp.data && resp.data.isFollow ? resp.data.isFollow : 'no';
    var followingcnt = resp && resp.data && resp.data.following ? resp.data.following : '0';
    var followerscnt = resp && resp.data && resp.data.followers ? resp.data.followers : '0';
    Set_is_follow(foll);
    Set_followingCount(followingcnt);
    Set_followersCount(followerscnt);
  }

  async function FollowerTab(address, pos, isFollow) {
    var web3 = new Web3(window.ethereum);
    var currAddr = await getCurAddr();
    var currentUser = 'no';
    if (
      (ParamAccountAddr !== '' && currAddr !== '' && currAddr === ParamAccountAddr) ||
      ParamAccountAddr === '' ||
      !ParamAccountAddr
    ) {
      currentUser = 'yes';
    }
    var msg = 'I would like to follow user: ' + address;
    if (isFollow === 'yes') {
      msg = 'I would like to stop following user: ' + address;
    }

    var Payload = {};
    Payload.currAddr = currAddr;
    Payload.ParamAccountAddr = address;
    Payload.currentUser = currentUser;
    await web3.eth.personal.sign(msg, currAddr);
    var Resp = await FollowChange_Action(Payload);

    var foll = Resp && Resp.data && Resp.data.ChangeType ? Resp.data.ChangeType : 'no';
    FollowerUserList[pos].isFollow = foll;
    Set_followersStatus(false);
    Set_FollowerUserList(FollowerUserList);
    Set_followersStatus(true);

    if (currentUser === 'yes') {
      var followingcnt = Resp && Resp.data && Resp.data.following ? Resp.data.following : '0';
      var followerscnt = Resp && Resp.data && Resp.data.followers ? Resp.data.followers : '0';
      Set_followingCount(followingcnt);
      Set_followersCount(followerscnt);
    }
    if (foll === 'yes') {
      toast.success('Follow successfully', toasterOption);
    } else {
      toast.success('Un-Follow successfully', toasterOption);
    }
    Check_follow();
  }

  const loadFollowers = async (e) => {
    var currAddr = await getCurAddr();
    var resp = await User_Followers_List_Action({
      addr: MyItemAccountAddr,
      loginUser: currAddr,
      page: followersPage,
    });

    if (resp && resp.data && resp.data.list) {
      var MyFollowerList = resp.data.list;
      if (MyFollowerList && MyFollowerList.length > 0) {
        Set_followersStatus(false);
        var totalList = FollowerUserList.concat(MyFollowerList);
        Set_FollowerUserList(totalList);
        Set_followersStatus(true);
      } else {
        Set_loadMoreBtn1(false);
      }
    }
  };

  async function Get_Followers_List() {
    Set_CurTabName('followers');
    var currAddr = await getCurAddr();
    var resp = await User_Followers_List_Action({
      addr: MyItemAccountAddr,
      loginUser: currAddr,
    });

    if (resp && resp.data && resp.data.list) {
      var MyFollowingList = resp.data.list;
      Set_FollowerUserList(MyFollowingList);
    } else {
      Set_FollowerUserList([]);
    }
  }

  async function Get_Following_List() {
    Set_CurTabName('following');
    var currAddr = await getCurAddr();
    var resp = await User_Following_List_Action({
      addr: MyItemAccountAddr,
      loginUser: currAddr,
    });

    if (resp && resp.data && resp.data.list) {
      var MyFollowingList = resp.data.list;
      Set_FollowingUserList(MyFollowingList);
    } else {
      Set_FollowingUserList([]);
    }
  }
  async function Get_Activity_list() {
    Set_CurTabName('activity');
    var resp = await Activity_List_Action({
      addr: MyItemAccountAddr,
    });

    if (resp && resp.data && resp.data.list) {
      var List = resp.data.list;

      if (List.length > 0) {
        Set_Activitycount(List.length);
      }
      Set_ActivityList(List);
    } else {
      Set_ActivityList([]);
    }
  }

  var settingsLiveAuction = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1279,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 959,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 599,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  // Countdown Timer

  return (
    <div className="inner_header">
      <div id="AfterWalletConnected_two" onClick={() => AfterWalletConnected_two()}>
        &nbsp;
      </div>
      <ConnectWallet
        Set_UserAccountAddr={Set_UserAccountAddr}
        Set_UserAccountBal={Set_UserAccountBal}
        Set_WalletConnected={Set_WalletConnected}
        Set_AddressUserDetails={Set_AddressUserDetails}
        Set_Accounts={Set_Accounts}
        WalletConnected={WalletConnected}
        AfterWalletConnected={AfterWalletConnected}
      />
      <PutOnSaleRef
        ref={PutOnSaleForwardRef}
        Set_HitItem={Set_HitItem}
        item={HitItem}
        UserAccountAddr={UserAccountAddr}
        UserAccountBal={UserAccountBal}
        Accounts={Accounts}
        GetUserBal={GetUserBal}
      />
      <PlaceAndAcceptBidRef
        ref={PlaceABidForwardRef}
        Set_WalletConnected={Set_WalletConnected}
        Set_UserAccountAddr={Set_UserAccountAddr}
        Set_UserAccountBal={Set_UserAccountBal}
        Set_AddressUserDetails={Set_AddressUserDetails}
        Set_Accounts={Set_Accounts}
        Set_MyItemAccountAddr={Set_MyItemAccountAddr}
        Set_tokenCounts={Set_tokenCounts}
        Set_item={Set_item}
        Set_tokenCounts_Detail={Set_tokenCounts_Detail}
        Set_MyTokenBalance={Set_MyTokenBalance}
        Set_Bids={Set_Bids}
        Set_AccepBidSelect={Set_AccepBidSelect}
        Set_tokenBidAmt={Set_tokenBidAmt}
        Set_NoOfToken={Set_NoOfToken}
        Set_ValidateError={Set_ValidateError}
        Set_TokenBalance={Set_TokenBalance}
        Set_YouWillPay={Set_YouWillPay}
        Set_YouWillPayFee={Set_YouWillPayFee}
        Set_YouWillGet={Set_YouWillGet}
        Set_BidApply_ApproveCallStatus={Set_BidApply_ApproveCallStatus}
        Set_BidApply_SignCallStatus={Set_BidApply_SignCallStatus}
        WalletConnected={WalletConnected}
        UserAccountAddr={UserAccountAddr}
        UserAccountBal={UserAccountBal}
        AddressUserDetails={AddressUserDetails}
        Accounts={Accounts}
        MyItemAccountAddr={MyItemAccountAddr}
        tokenCounts={tokenCounts}
        item={item}
        tokenCounts_Detail={tokenCounts_Detail}
        MyTokenBalance={MyTokenBalance}
        Bids={Bids}
        AccepBidSelect={AccepBidSelect}
        tokenBidAmt={tokenBidAmt}
        NoOfToken={NoOfToken}
        ValidateError={ValidateError}
        TokenBalance={TokenBalance}
        YouWillPay={YouWillPay}
        YouWillPayFee={YouWillPayFee}
        YouWillGet={YouWillGet}
        BidApply_ApproveCallStatus={BidApply_ApproveCallStatus}
        BidApply_SignCallStatus={BidApply_SignCallStatus}
        AllowedQuantity={AllowedQuantity}
        totaluserbidAmt={totaluserbidAmt}
        setTotaluserbidAmt={setTotaluserbidAmt}
      />
      <PurchaseNowRef
        ref={PurchaseNowForwardRef}
        Set_HitItem={Set_HitItem}
        item={HitItem}
        UserAccountAddr={UserAccountAddr}
        UserAccountBal={UserAccountBal}
        TokenBalance={TokenBalance}
        Accounts={Accounts}
        GetUserBal={GetUserBal}
      />
      <WalletRef
        ref={WalletForwardRef}
        Set_UserAccountAddr={Set_UserAccountAddr}
        Set_WalletConnected={Set_WalletConnected}
        Set_UserAccountBal={Set_UserAccountBal}
      />
      <LikeRef ref={LikeForwardRef} setLikedTokenList={setLikedTokenList} MyItemAccountAddr={MyItemAccountAddr} />
      <ReportNowRef UserAccountAddr={UserAccountAddr} ref={ReportForwardRef} />
      <BurnRef
        ref={BurnForwardRef}
        GetUserBal={GetUserBal}
        UserAccountAddr={UserAccountAddr}
        UserAccountBal={UserAccountBal}
        TokenBalance={TokenBalance}
        Accounts={Accounts}
      />
      <ShareNowRef ref={ShareForwardRef} />
      <CancelOrderRef
        ref={CancelOrderForwardRef}
        Set_HitItem={Set_HitItem}
        item={HitItem}
        UserAccountAddr={UserAccountAddr}
        UserAccountBal={UserAccountBal}
        TokenBalance={TokenBalance}
        Accounts={Accounts}
        GetUserBal={GetUserBal}
      />
      <Header
        fixed
        color="transparent"
        routes={dashboardRoutes}
        brand={<img src={require('../assets/images/logo.png')} alt="logo" className="img-fluid" />}
        rightLinks={<HeaderLinks />}
        changeColorOnScroll={{
          height: 50,
          color: 'dark',
        }}
        {...rest}
      />
      <ScrollToTopOnMount />
      {UserNotFound === true ? (
        <div className={classes.pageHeader + ' inner_pageheader items_header'}>
          <div className="container">
            <div className="text-center py-5">
              <p className="not_found_text">Something went wrong</p>
              <p className="not_found_text_sub">Can't fetch profile info. Try again later</p>
              <div className="mt-3">
                <Link to="/">
                  <Button type="button" className="green_btn">
                    Browse Marketplace
                  </Button>
                </Link>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className={classes.pageHeader + ' inner_pageheader items_header'}>
          <div>
            <GridContainer className="mx-0">
              <GridItem xs={12} sm={12} md={12} className="px-0">
                <div className="items_bg">
                  {MyItemcoverimage && MyItemcoverimage !== '' ? (
                    <img src={MyItemcoverimage} alt="profile" className="img-fluid" />
                  ) : (
                    <img
                      src={require('../assets/images/myitems_bg.png')}
                      alt="profile"
                      id="imgPreview"
                      className="img-fluid"
                    />
                  )}

                  {MyItemAccountAddr !== '' && MyItemAccountAddr !== UserAccountAddr ? (
                    ''
                  ) : (
                    <div className="container edit_cover_container">
                      <p className="edit_cover_text" data-toggle="modal" data-target="#edit_cover_modal">
                        Edit cover
                      </p>
                    </div>
                  )}

                  {/* <img src={require("../assets/images/myitems_bg.png")} alt="profile" className="img-fluid" />
                <div className="container edit_cover_container">
                  <p className="edit_cover_text" data-toggle="modal" data-target="#edit_cover_modal">Edit cover</p>
                </div> */}
                </div>
              </GridItem>
            </GridContainer>
          </div>
          <div>
            <GridContainer className="mx-0">
              <GridItem xs={12} sm={12} md={12} className="px-0">
                <div className="items_bg_pink">
                  <div className="item_prof">
                    {MyItemAccountAddr_Details &&
                    MyItemAccountAddr_Details.image &&
                    MyItemAccountAddr_Details.image !== '' ? (
                      <img
                        src={MyItemAccountAddr_Details.image}
                        alt="logo"
                        id="imgPreview"
                        className="img-fluid items_profile"
                      />
                    ) : (
                      <img
                        src={require('../assets/images/avatars/avatar.jpg')}
                        alt="profile"
                        id="imgPreview"
                        className="img-fluid items_profile"
                      />
                    )}
                    {MyItemAccountAddr !== '' && MyItemAccountAddr !== UserAccountAddr ? (
                      ''
                    ) : (
                      <Link to="/edit-profile">
                        <div className="profile_edit_panel item_prof_panel">
                          <div className="profile_edit_icon">
                            <i className="fas fa-pencil-alt"></i>
                          </div>
                          {/* <input type="file" name="photograph" id="photo" required="true" /> */}
                        </div>
                      </Link>
                    )}
                  </div>
                  {MyItemAccountAddr_Details && MyItemAccountAddr_Details.name && (
                    <p>
                      <span className="address_text">{MyItemAccountAddr_Details.name}</span>
                    </p>
                  )}
                  {MyItemAccountAddr_Details && MyItemAccountAddr_Details.customurl && (
                    <p>
                      <span className="address_text">@{MyItemAccountAddr_Details.customurl}</span>
                    </p>
                  )}
                  <div className="crd_link_">
                    {MyItemAccountAddr_Details && MyItemAccountAddr_Details.instagram && (
                      <p>
                        <span className="address_text">
                          <a href={MyItemAccountAddr_Details.instagram}>
                            <i className="fab fa-instagram notes_fa mr-2 "></i>
                          </a>
                        </span>
                      </p>
                    )}
                    {MyItemAccountAddr_Details && MyItemAccountAddr_Details.twitter && (
                      <p>
                        <span className="address_text">
                          <a href={MyItemAccountAddr_Details.twitter}>
                            <i className="fab fa-twitter notes_fa mr-2"></i>
                          </a>
                        </span>
                      </p>
                    )}
                    {MyItemAccountAddr_Details && MyItemAccountAddr_Details.facebook && (
                      <p>
                        <span className="address_text">
                          <a href={MyItemAccountAddr_Details.facebook}>
                            <i className="fab fa-facebook-f notes_fa mr-2"></i>
                          </a>
                        </span>
                      </p>
                    )}
                    {MyItemAccountAddr_Details && MyItemAccountAddr_Details.youtube && (
                      <p>
                        <span className="address_text">
                          <a href={MyItemAccountAddr_Details.youtube}>
                            <i className="fab fa-youtube  notes_fa mr-2"></i>
                          </a>
                        </span>
                      </p>
                    )}
                  </div>
                  <p>
                    <span className="address_text">{MyItemAccountAddr}</span>
                    <span onClick={copyToClipboard}>
                      <i className="fas fa-sticky-note notes_fa"></i>
                    </span>
                  </p>
                  <div className="mt-3 d-flex align-items-center">
                    {MyItemAccountAddr !== '' && MyItemAccountAddr !== UserAccountAddr ? (
                      <Button className="btn_outline_red" onClick={FollowChange_Call}>
                        {is_follow === 'yes' ? 'Unfollow' : 'Follow'}
                      </Button>
                    ) : (
                      MyItemAccountAddr !== '' && (
                        <Link className="btn_outline_red" to="/edit-profile">
                          Edit Profile
                        </Link>
                      )
                    )}

                    <div className="masonry_likes ml-3 px-2">
                      <div className="dropdown d-inline-block">
                        <i
                          className="fas fa-share-alt"
                          data-toggle="dropdown"
                          aria-haspopup="true"
                          aria-expanded="false"
                        ></i>
                        <div className="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenuButton">
                          <a className="dropdown-item" data-toggle="modal" data-target="#share_modal">
                            Share
                          </a>

                          {item.tokenowners_current &&
                          item.tokenowners_current.length > 0 &&
                          item.tokenowners_current.findIndex((e) => e.tokenOwner === UserAccountAddr) > -1 ? (
                            <a className="dropdown-item" href="#" data-toggle="modal" data-target="#burn_token_modal">
                              Burn
                            </a>
                          ) : (
                            ''
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="masonry_likes ml-2 px-2">
                      <div className="dropdown d-inline-block">
                        <i
                          className="fas fa-ellipsis-h"
                          data-toggle="dropdown"
                          aria-haspopup="true"
                          aria-expanded="false"
                        ></i>
                        <div className="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenuButton">
                          <a className="dropdown-item" href="#" data-toggle="modal" data-target="#share_modal">
                            Share
                          </a>
                          {/*<a className="dropdown-item" href="#">View Fullscreen</a> */}

                          {item.tokenowners_current &&
                          item.tokenowners_current.length > 0 &&
                          item.tokenowners_current.findIndex((e) => e.tokenOwner === UserAccountAddr) > -1 ? (
                            <a className="dropdown-item" href="#" data-toggle="modal" data-target="#burn_token_modal">
                              Burn
                            </a>
                          ) : (
                            ''
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </GridItem>
            </GridContainer>
          </div>
          <div className="container">
            <GridContainer>
              <GridItem xs={12} sm={12} md={12}>
                <nav className="masonry_tab_nav mt-4 items_tab_outer">
                  <div className="nav nav-tabs masonry_tab primary_tab items_tab" id="nav-tab" role="tablist">
                    <a
                      className="nav-link active"
                      id="onsale-tab"
                      data-toggle="tab"
                      href="#onsale"
                      role="tab"
                      aria-controls="onsale"
                      aria-selected="true"
                      onClick={() => Tab_Click('onsale')}
                    >
                      <div className="tab_head">OnSale</div>
                      <span className="tab_count">{OnSale_Count}</span>
                    </a>
                    <a
                      className="nav-link"
                      id="collectibles-tab"
                      data-toggle="tab"
                      href="#collectibles"
                      role="tab"
                      aria-controls="collectibles"
                      aria-selected="false"
                      onClick={() => Tab_Click('collectibles')}
                    >
                      <div className="tab_head">NFTs</div>
                      <span className="tab_count">{Collectibles_Count}</span>
                    </a>
                    <a
                      className="nav-link"
                      id="created-tab"
                      data-toggle="tab"
                      href="#created"
                      role="tab"
                      aria-controls="created"
                      aria-selected="false"
                      onClick={() => Tab_Click('created')}
                    >
                      <div className="tab_head">Created</div>
                      <span className="tab_count">{Created_Count}</span>
                    </a>
                    <a
                      className="nav-link"
                      id="liked-tab"
                      data-toggle="tab"
                      href="#liked"
                      role="tab"
                      aria-controls="liked"
                      aria-selected="false"
                      onClick={() => Tab_Click('liked')}
                    >
                      <div className="tab_head">Liked</div>
                      <span className="tab_count">{Liked_Count}</span>
                    </a>
                    <a
                      className="nav-link"
                      id="activity-tab"
                      data-toggle="tab"
                      href="#activity"
                      role="tab"
                      aria-controls="activity"
                      aria-selected="false"
                      onClick={() => Get_Activity_list()}
                    >
                      <div className="tab_head">Activity</div>
                      <span className="tab_count">{Activitycount}</span>
                    </a>
                    <a
                      className="nav-link"
                      id="following-tab"
                      data-toggle="tab"
                      href="#following"
                      role="tab"
                      aria-controls="following"
                      aria-selected="false"
                      onClick={() => Get_Following_List()}
                    >
                      <div className="tab_head">Following</div>
                      <span className="tab_count">{followingCount}</span>
                    </a>
                    <a
                      className="nav-link"
                      id="followers-tab"
                      data-toggle="tab"
                      href="#followers"
                      role="tab"
                      aria-controls="followers"
                      aria-selected="false"
                      onClick={() => Get_Followers_List()}
                    >
                      <div className="tab_head">Followers</div>
                      <span className="tab_count">{followersCount}</span>
                    </a>
                  </div>
                </nav>
                <div className="tab-content explore_tab_content mt-2" id="nav-tabContent">
                  <div className="tab-pane fade show active" id="onsale" role="tabpanel" aria-labelledby="onsale-tab">
                    <div className="main__carousel-wrap">
                      <div className="main__carousel main__carousel--live owl-carousel" id="live">
                        {OnSale_Count > 0 || OnSale_List.length > 0 ? (
                          <Slider {...settingsLiveAuction}>
                            {OnSale_List.map((item) => {
                              return (
                                <TokenItem
                                  item={item}
                                  Set_item={Set_item}
                                  LikedTokenList={LikedTokenList}
                                  hitLike={LikeForwardRef.current.hitLike}
                                  UserAccountAddr={UserAccountAddr}
                                  UserAccountBal={UserAccountBal}
                                  PutOnSale_Click={PutOnSaleForwardRef.current.PutOnSale_Click}
                                  PurchaseNow_Click={PurchaseNowForwardRef.current.PurchaseNow_Click}
                                  PlaceABid_Click={PlaceABidForwardRef.current.PlaceABid_Click}
                                  Set_Bids={Set_Bids}
                                  Bids={Bids}
                                  Set_tokenCounts_Detail={Set_tokenCounts_Detail}
                                  Set_MyTokenBalance={Set_MyTokenBalance}
                                  Set_AllowedQuantity={Set_AllowedQuantity}
                                  Set_YouWillPay={Set_YouWillPay}
                                  Set_YouWillPayFee={Set_YouWillPayFee}
                                  Set_YouWillGet={Set_YouWillGet}
                                  // Bid_Click={PlaceABidForwardRef.current.Bid_Click}
                                  Burn_Click={BurnForwardRef.current.Burn_Click}
                                  CancelOrder_Click={CancelOrderForwardRef.current.CancelOrder_Click}
                                  WalletConnected={WalletConnected}
                                  SubmitReport_Click={ReportForwardRef.current.SubmitReport_Click}
                                  ShareSocial_Click={ShareForwardRef.current.ShareSocial_Click}
                                />
                              );
                            })}
                          </Slider>
                        ) : (
                          <div className="text-center py-5">
                            <p className="not_found_text">No items found</p>
                            <p className="not_found_text_sub">
                              Come back soon! Or try to browse something for you on our marketplace
                            </p>
                            <div className="mt-3">
                              <Button className="create_btn">
                                <Link to="/home">Browse Marketplace</Link>
                              </Button>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="tab-pane fade" id="collectibles" role="tabpanel" aria-labelledby="collectibles-tab">
                    <div className="proposal_panel_overall">
                      {Collectibles_Count > 0 || Collectibles_List.length > 0 ? (
                        <Slider {...settingsLiveAuction}>
                          {Collectibles_List.map((item) => {
                            return (
                              <TokenItem
                                item={item}
                                Set_item={Set_item}
                                LikedTokenList={LikedTokenList}
                                hitLike={LikeForwardRef.current.hitLike}
                                UserAccountAddr={UserAccountAddr}
                                UserAccountBal={UserAccountBal}
                                PutOnSale_Click={PutOnSaleForwardRef.current.PutOnSale_Click}
                                PurchaseNow_Click={PurchaseNowForwardRef.current.PurchaseNow_Click}
                                PlaceABid_Click={PlaceABidForwardRef.current.PlaceABid_Click}
                                WalletConnected={WalletConnected}
                                SubmitReport_Click={ReportForwardRef.current.SubmitReport_Click}
                                ShareSocial_Click={ShareForwardRef.current.ShareSocial_Click}
                                CancelOrder_Click={CancelOrderForwardRef.current.CancelOrder_Click}
                                Set_Bids={Set_Bids}
                                Bids={Bids}
                                Burn_Click={BurnForwardRef.current.Burn_Click}
                                Set_tokenCounts_Detail={Set_tokenCounts_Detail}
                                Set_MyTokenBalance={Set_MyTokenBalance}
                                Set_AllowedQuantity={Set_AllowedQuantity}
                                Set_YouWillPay={Set_YouWillPay}
                                Set_YouWillPayFee={Set_YouWillPayFee}
                                Set_YouWillGet={Set_YouWillGet}
                              />
                            );
                          })}
                        </Slider>
                      ) : (
                        <div className="text-center py-5">
                          <p className="not_found_text">No items found</p>
                          <p className="not_found_text_sub">
                            Come back soon! Or try to browse something for you on our marketplace
                          </p>
                          <div className="mt-3">
                            <Button className="create_btn">
                              <Link to="/home">Browse Marketplace</Link>
                            </Button>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="tab-pane fade" id="created" role="tabpanel" aria-labelledby="created-tab">
                    <div className="proposal_panel_overall">
                      <Slider {...settingsLiveAuction}>
                        {Created_List.length > 0 &&
                          Created_List.map((item) => {
                            return (
                              <TokenItem
                                item={item}
                                Set_item={Set_item}
                                LikedTokenList={LikedTokenList}
                                hitLike={LikeForwardRef.current.hitLike}
                                UserAccountAddr={UserAccountAddr}
                                UserAccountBal={UserAccountBal}
                                PutOnSale_Click={PutOnSaleForwardRef.current.PutOnSale_Click}
                                PurchaseNow_Click={PurchaseNowForwardRef.current.PurchaseNow_Click}
                                PlaceABid_Click={PlaceABidForwardRef.current.PlaceABid_Click}
                                WalletConnected={WalletConnected}
                                SubmitReport_Click={ReportForwardRef.current.SubmitReport_Click}
                                ShareSocial_Click={ShareForwardRef.current.ShareSocial_Click}
                                CancelOrder_Click={CancelOrderForwardRef.current.CancelOrder_Click}
                                Set_Bids={Set_Bids}
                                Bids={Bids}
                                Burn_Click={BurnForwardRef.current.Burn_Click}
                                Set_tokenCounts_Detail={Set_tokenCounts_Detail}
                                Set_MyTokenBalance={Set_MyTokenBalance}
                                Set_AllowedQuantity={Set_AllowedQuantity}
                                Set_YouWillPay={Set_YouWillPay}
                                Set_YouWillPayFee={Set_YouWillPayFee}
                                Set_YouWillGet={Set_YouWillGet}
                              />
                            );
                          })}
                      </Slider>
                    </div>
                  </div>

                  <div className="tab-pane fade" id="liked" role="tabpanel" aria-labelledby="liked-tab">
                    <div className="proposal_panel_overall">
                      {Liked_Count > 0 || Liked_List.length > 0 ? (
                        <Slider {...settingsLiveAuction}>
                          {Liked_List.map((item) => {
                            return (
                              <TokenItem
                                item={item}
                                Set_item={Set_item}
                                LikedTokenList={LikedTokenList}
                                hitLike={LikeForwardRef.current.hitLike}
                                UserAccountAddr={UserAccountAddr}
                                UserAccountBal={UserAccountBal}
                                PutOnSale_Click={PutOnSaleForwardRef.current.PutOnSale_Click}
                                PurchaseNow_Click={PurchaseNowForwardRef.current.PurchaseNow_Click}
                                PlaceABid_Click={PlaceABidForwardRef.current.PlaceABid_Click}
                                WalletConnected={WalletConnected}
                                SubmitReport_Click={ReportForwardRef.current.SubmitReport_Click}
                                ShareSocial_Click={ShareForwardRef.current.ShareSocial_Click}
                                CancelOrder_Click={CancelOrderForwardRef.current.CancelOrder_Click}
                                Set_Bids={Set_Bids}
                                Bids={Bids}
                                Burn_Click={BurnForwardRef.current.Burn_Click}
                                Set_tokenCounts_Detail={Set_tokenCounts_Detail}
                                Set_MyTokenBalance={Set_MyTokenBalance}
                                Set_AllowedQuantity={Set_AllowedQuantity}
                                Set_YouWillPay={Set_YouWillPay}
                                Set_YouWillPayFee={Set_YouWillPayFee}
                                Set_YouWillGet={Set_YouWillGet}
                              />
                            );
                          })}
                        </Slider>
                      ) : (
                        <div className="text-center py-5">
                          <p className="not_found_text">No items found</p>
                          <p className="not_found_text_sub">
                            Come back soon! Or try to browse something for you on our marketplace
                          </p>
                          <div className="mt-3">
                            <Button className="create_btn">
                              <Link to="/home">Browse Marketplace</Link>
                            </Button>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="tab-pane fade" id="activity" role="tabpanel" aria-labelledby="activity-tab">
                    <div className="proposal_panel_overall">
                      <GridContainer className="align-items-start">
                        <GridItem xs={12} sm={8} md={8}>
                          {ActivityList &&
                            ActivityList.length > 0 &&
                            ActivityList.map((activitydata, i) => {
                              return activitydata.actiontype !== '' ? (
                                <ActivityTab key={i} item={activitydata} UserAccountAddr={UserAccountAddr} />
                              ) : (
                                ''
                              );
                            })}
                        </GridItem>
                        <GridItem xs={12} sm={4} md={4}>
                          <div className="filter_panel">
                            <h2>Filter</h2>
                            {filterType !== '' && (
                              <p>
                                <a href="#" onClick={() => resetFilter('listings')}>
                                  Reset filter
                                </a>
                              </p>
                            )}
                            <div className="filter_panel_grp">
                              <Button
                                className={filterType === 'listings' ? 'filter_btn selected' : 'filter_btn'}
                                onClick={() => changeFilter('listings')}
                              >
                                Listings
                              </Button>
                              <Button
                                className={filterType === 'purchase' ? 'filter_btn selected' : 'filter_btn'}
                                onClick={() => changeFilter('purchase')}
                              >
                                Purchases
                              </Button>
                              <Button
                                className={filterType === 'sales' ? 'filter_btn selected' : 'filter_btn'}
                                onClick={() => changeFilter('sales')}
                              >
                                Sales
                              </Button>
                              {/* <Button className={(filterType=="listings")?"filter_btn selected":"filter_btn"}>Transfers</Button> */}
                              <Button
                                className={filterType === 'burns' ? 'filter_btn selected' : 'filter_btn'}
                                onClick={() => changeFilter('burns')}
                              >
                                Burns
                              </Button>
                              <Button
                                className={filterType === 'bidrequest' ? 'filter_btn selected' : 'filter_btn'}
                                onClick={() => changeFilter('bidrequest')}
                              >
                                Bids
                              </Button>
                              <Button
                                className={filterType === 'like' ? 'filter_btn selected' : 'filter_btn'}
                                onClick={() => changeFilter('like')}
                              >
                                Likes
                              </Button>
                              <Button
                                className={filterType === 'following' ? 'filter_btn selected' : 'filter_btn'}
                                onClick={() => changeFilter('following')}
                              >
                                Followings
                              </Button>
                            </div>
                          </div>
                        </GridItem>
                      </GridContainer>
                    </div>
                  </div>
                  <div className="tab-pane fade" id="following" role="tabpanel" aria-labelledby="following-tab">
                    <div className="proposal_panel_overall">
                      <div className="followers_overall py-5">
                        {followingStatus && FollowingUserList && FollowingUserList.length > 0 && (
                          <div className="row">
                            {FollowingUserList.map((item, i) => {
                              var name =
                                item && item.userdetail && item.userdetail.name && item.userdetail.name !== ''
                                  ? item.userdetail.name
                                  : item.userdetail && item.userdetail.curraddress
                                  ? item.userdetail.curraddress
                                  : '';

                              if (name.length > 8) {
                                name = name.substring(0, 8) + '...';
                              }

                              var followers = item && item.numberOfFollower ? item.numberOfFollower : 0;

                              var imageUrl =
                                item.userdetail && item.userdetail.image && item.userdetail.image !== ''
                                  ? item.userdetail.image
                                  : config.Back_URL + '/images/noimage.png';

                              return (
                                <div className="col-12 col-md-6 col-xl-4 mb-4">
                                  <div className="card my-0 p-0">
                                    <div className="card-body p-3 w-100">
                                      <div className="media follow_media">
                                        <a href={'/user/' + item.followaddress}>
                                          <img src={imageUrl} alt="User" className="img-fluid mr-3" />
                                        </a>
                                        <div className="media-body flex_body">
                                          <div>
                                            <p className="mt-0 media_num mb-0">{followers} followers</p>
                                            <p className="mt-0 media_text">{name}</p>
                                          </div>
                                          {item.followaddress !== UserAccountAddr && (
                                            <div className="ml-2">
                                              <Button
                                                onClick={() => FollowingTab(item.followaddress, i, item.isFollow)}
                                                className="create_btn"
                                              >
                                                {item.isFollow === 'yes' ? 'Un-follow' : 'Follow'}
                                              </Button>
                                            </div>
                                          )}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              );
                            })}
                          </div>
                        )}
                        {loadMoreBtn && FollowingUserList && FollowingUserList.length > 0 && (
                          <div className="text-center" id="spinBut">
                            <Button
                              className="create_btn"
                              onClick={() => {
                                loadFollowing();
                              }}
                              id="onmore"
                              style={{ display: 'flex' }}
                            >
                              Load More
                            </Button>
                          </div>
                        )}

                        {FollowingUserList && FollowingUserList.length === 0 && (
                          <div className="text-center py-5">
                            <p className="not_found_text">No Result Found</p>
                            <p className="not_found_text_sub">
                              Explore the marketplace for your first NFT or subscribe for free drop notifications
                            </p>
                            <div className="mt-3">
                              <Button className="green_btn">
                                <Link to="/search?query">Browse Marketplace</Link>
                              </Button>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="tab-pane fade" id="followers" role="tabpanel" aria-labelledby="followers-tab">
                    <div className="proposal_panel_overall">
                      <div className="followers_overall py-5">
                        {followersStatus && FollowerUserList && FollowerUserList.length > 0 && (
                          <div className="row">
                            {followersStatus &&
                              FollowerUserList.map((item, i) => {
                                var name =
                                  item && item.userdetail && item.userdetail.name && item.userdetail.name !== ''
                                    ? item.userdetail.name
                                    : item.userdetail && item.userdetail.curraddress
                                    ? item.userdetail.curraddress
                                    : '';

                                if (name.length > 8) {
                                  name = name.substring(0, 8) + '...';
                                }

                                var followers = item && item.numberOfFollower ? item.numberOfFollower : 0;

                                var imageUrl =
                                  item.userdetail && item.userdetail.image && item.userdetail.image !== ''
                                    ? config.Back_URL + '/profile/' + item.userdetail.image
                                    : config.Back_URL + '/images/noimage.png';

                                return (
                                  <div className="col-12 col-md-6 col-xl-4 mb-4">
                                    <div className="card my-0">
                                      <div className="card-body p-3 w-100">
                                        <div className="media follow_media">
                                          <a href={'/user/' + item.useraddress}>
                                            <img
                                              src={imageUrl}
                                              alt="User"
                                              height="50px"
                                              width="50px"
                                              className="img-fluid mr-3"
                                            />
                                          </a>
                                          <div className="media-body flex_body">
                                            <div>
                                              <p className="mt-0 media_num mb-0">{followers} followers</p>
                                              <p className="mt-0 media_text">{name}</p>
                                            </div>

                                            {item.useraddress !== UserAccountAddr && (
                                              <div className="ml-2">
                                                <Button
                                                  onClick={() => FollowerTab(item.useraddress, i, item.isFollow)}
                                                  className="create_btn"
                                                >
                                                  {item.isFollow === 'yes' ? 'Un-follow' : 'Follow'}
                                                </Button>
                                              </div>
                                            )}
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                );
                              })}
                          </div>
                        )}
                        {loadMoreBtn1 && FollowerUserList && FollowerUserList.length > 0 && (
                          <div className="text-center" id="spinBut">
                            <Button
                              className="create_btn"
                              onClick={() => {
                                loadFollowers();
                              }}
                              id="onmore"
                              style={{ display: 'flex' }}
                            >
                              Load More
                            </Button>
                          </div>
                        )}

                        {FollowerUserList && FollowerUserList.length === 0 && (
                          <div className="text-center py-5">
                            <p className="not_found_text">No Result Found</p>
                            <p className="not_found_text_sub">
                              Explore the marketplace for your first NFT or subscribe for free drop notifications
                            </p>
                            <div className="mt-3">
                              <Button className="green_btn">
                                <Link to="/search?query">Browse Marketplace</Link>
                              </Button>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </GridItem>
            </GridContainer>
          </div>
        </div>
      )}
      <Footer />

      {/* edit_cover Modal */}
      <div
        className="modal fade primary_modal"
        id="edit_cover_modal"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="edit_cover_modalCenteredLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered modal-sm" role="document">
          <div className="modal-content">
            <div className="modal-header text-center">
              <h5 className="modal-title" id="edit_cover_modalLabel_1">
                Update cover
              </h5>
              <h5 className="modal-title d-none" id="edit_cover_modalLabel_2">
                Follow Steps
              </h5>

              <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <div className="update_cover_div_1" id="update_cover_div_1">
                <p className="mt-0 approve_desc text-center mb-4">
                  Upload new cover for your profile page. We recommended to upload images in 1140×260 resolution
                </p>
                <form className="text-center">
                  <div className="file_btn btn primary_btn">
                    Choose image
                    <input className="inp_file" type="file" name="image" onChange={selectFileChange} />
                  </div>
                </form>
              </div>
              <div className="update_cover_div_2 d-none" id="update_cover_div_2">
                <div className="media approve_media">
                  {/* <i className="fas fa-check mr-3 pro_complete" aria-hidden="true"></i> */}
                  <i className="fa fa-spinner mr-3 spinner_icon" aria-hidden="true"></i>
                  <div className="media-body">
                    <p className="mt-0 approve_text">Preferences</p>
                    <p className="mt-0 approve_desc">Updating cover</p>
                  </div>
                </div>
                <div className="text-center my-3">
                  <Button className="btn_outline_red btn-block">Inprogress</Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* end edit_cover modal */}

      {/* Put Sale Modal */}
      <div
        className="modal fade primary_modal"
        id="put_sale_modal"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="put_sale_modalCenteredLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered modal-sm" role="document">
          <div className="modal-content" id="hide">
            <div className="modal-header text-center">
              <h5 className="modal-title" id="put_sale_modalLabel_1">
                Put on Sale
              </h5>
              <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <div className="update_cover_div_2" id="update_cover_div_2">
                <form>
                  <div className="form-row">
                    <div className="form-group col-md-12">
                      <div className="input_group">
                        <input
                          type="text"
                          className="primary_inp form-control"
                          id="price"
                          name="price"
                          placeholder="Enter price for one piece"
                        />
                        <select defaultValue="BNB" className="custom-select select_menu choose_price form-control">
                          <option value="BNB">BNB</option>
                          <option value="ETH">ETH</option>
                        </select>
                      </div>
                      <p className="form_note mb-0">
                        Service fee <span className="text-dark">2.5%</span>
                      </p>
                      <p className="form_note mb-0">
                        You will receive <span className="text-dark">0 ETH</span> $0.00
                      </p>
                    </div>
                  </div>
                </form>
                <div className="text-center my-3">
                  <Button
                    className="btn_outline_red btn-block"
                    data-dismiss="modal"
                    data-toggle="modal"
                    data-target="#follow_steps_modal"
                  >
                    Next Step
                  </Button>
                  <Button className="btn_outline_red btn-block" data-dismiss="modal">
                    Cancel
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* End Put Sale modal */}

      {/* Following Steps Modal */}
      <div
        className="modal fade primary_modal"
        id="follow_steps_modal"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="follow_steps_modalCenteredLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered modal-sm" role="document">
          <div className="modal-content" id="hide">
            <div className="modal-header text-center">
              <h5 className="modal-title" id="follow_steps_modalLabel_1">
                Follow Steps
              </h5>
              <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <div className="update_cover_div_2" id="update_cover_div_2">
                <div className="media approve_media">
                  {/* <i className="fas fa-check mr-3 pro_complete" aria-hidden="true"></i> */}
                  <i className="fa fa-spinner mr-3 spinner_icon" aria-hidden="true"></i>
                  <div className="media-body">
                    <p className="mt-0 approve_text">Sign Sell Order</p>
                    <p className="mt-0 approve_desc">Sign sell order using your wallet</p>
                  </div>
                </div>
                <div className="text-center my-3">
                  <Button className="btn_outline_red btn-block">Follow Wallet Instructions</Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Following Steps modal */}
    </div>
  );
}
