/*eslint-disable*/
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';
import React from 'react';
import styles from '../../assets/jss/material-kit-react/components/headerLinksStyle.js';
import Button from '../CustomButtons/Button';

const useStyles = makeStyles(styles);

export default function HeaderLinksCommunity(props) {
  const classes = useStyles();
  async function logout() {
    if (localStorage.getItem('community_token')) {
      localStorage.removeItem('community_token');
      localStorage.removeItem('address');
    }
    window.location.href = '/community';
  }

  return (
    <List className={classes.list + ' main_navbar commun_navbar'}>
      {localStorage.community_token ? (
        ''
      ) : (
        <ListItem className={classes.listItem}>
          <Button
            className={classes.navLink + ' create_btn btn_header mr-3'}
            data-toggle="modal"
            data-target="#login_modal"
          >
            Login
          </Button>
        </ListItem>
      )}
      {localStorage.community_token ? (
        ''
      ) : (
        <ListItem className={classes.listItem}>
          <Button
            className={classes.navLink + ' create_btn btn_header'}
            data-toggle="modal"
            data-target="#register_modal"
          >
            Signup
          </Button>
        </ListItem>
      )}
      {localStorage.community_token ? (
        <ListItem className={classes.listItem} onClick={() => logout()}>
          <Button className={classes.navLink + ' create_btn btn_header mr-3'}>Logout</Button>
        </ListItem>
      ) : (
        ''
      )}
    </List>
  );
}
