import Onboard from 'bnc-onboard';
import React, { useEffect, useRef } from 'react';
import Modal from 'react-modal';
import { useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import Web3 from 'web3';
// https://data-seed-prebsc-1-s1.binance.org:8545/
import { AddressUserDetails_GetOrSave_Action } from '../../actions/user';
import config from '../../lib/config';

toast.configure();
let toasterOption = config.toasterOption;

export default function ConnectWallet(props) {
  const { WalletConnected } = props;

  const timerRef = useRef(null);

  useEffect(() => {
    getInit();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const getInit = async () => {
    connect_Wallet('metamask');
  };

  // head to blocknative.com to create a key
  const BLOCKNATIVE_KEY = 'blocknative-api-key';
  // the network id that your dapp runs on
  const NETWORK_ID = 1;

  const connect_Wallet = async (type = 'metamask') => {
    window.$('.modal').modal('hide');
    if (type === 'Onboard') {
      try {
        Onboard({
          dappId: BLOCKNATIVE_KEY,
          networkId: NETWORK_ID,
          subscriptions: {
            wallet: (wallet) => {
              // instantiate web3 when the user has selected a wallet
              web3 = new Web3(wallet.provider);
            },
          },
        });
      } catch (err) {
        console.log('err', err);
      }
    } else {
      if (type === 'walletconnect') {
        // let provider = new WalletConnectProvider({
        //   infuraId: "27e484dcd9e3efcfd25a83a78777cdf1",
        // });
      } else if (type === 'metamask') {
        var provider = window.ethereum;
        var web3 = new Web3(provider);
        if (typeof web3 !== 'undefined') {
          //
        } else {
          props.Set_WalletConnected(false);
          toast.warning('Connect to Binance Network', toasterOption);
          return false;
        }
      }

      if (provider) {
        try {
          provider
            .enable()
            .then(async function () {
              const web3 = new Web3(window.web3.currentProvider);
              if (window.web3.currentProvider.networkVersion === config.networkVersion) {
                if (window.web3.currentProvider.isMetaMask === true) {
                  if (window.web3 && window.web3.eth && window.web3.eth.defaultAccount) {
                    let currAddr = window.web3.eth.defaultAccount;
                    if (props.Set_UserAccountAddr) {
                      props.Set_UserAccountAddr(currAddr);
                    }

                    if (props.Set_WalletConnected) {
                      props.Set_WalletConnected(true);
                    }
                    let result = await web3.eth.getAccounts();
                    let setacc = result[0];

                    if (props.Set_Accounts) {
                      props.Set_Accounts(setacc);
                    }

                    web3.eth.getBalance(setacc).then((val) => {
                      let balance = val / 1000000000000000000;
                      if (props.Set_UserAccountBal) {
                        props.Set_UserAccountBal(balance);
                      }

                      AfterWalletConnected();
                    });
                  }
                }
              } else {
                props.Set_WalletConnected(false);
                toast.warning('Please Connect to Binance Network', toasterOption);
              }
            })
            .catch((e) => {
              props.Set_WalletConnected(false);
              toast.warning('Please Connect to Binance Network', toasterOption);
            });
        } catch (err) {
          props.Set_WalletConnected(false);
        }
      } else {
        props.Set_WalletConnected(false);
        toast.warning('Please Add Metamask External', toasterOption);
      }
    }
  };
  const AfterWalletConnected = () => {
    AddressUserDetails_GetOrSave_Call();
    props.AfterWalletConnected();
  };
  const AddressUserDetails_GetOrSave_Call = async () => {
    let addr = window.web3.eth.defaultAccount;
    let ReqData = { addr: addr };
    let Resp = await AddressUserDetails_GetOrSave_Action(ReqData);
    if (Resp && Resp.data && Resp.data.data) {
      props.Set_AddressUserDetails(Resp.data.data.User);
    } else {
      props.Set_AddressUserDetails({});
    }
    return true;
  };

  window.addEventListener('load', (event) => {
    event.preventDefault();
    if (window.ethereum) {
      window.ethereum.on('accountsChanged', function (accounts) {
        if (timerRef.current) {
          clearTimeout(timerRef.current);
        }
        timerRef.current = setTimeout(() => {
          connect_Wallet();
        }, 1000);
      });

      window.ethereum.on('chainChanged', function (networkId) {
        if (networkId === config.networkVersion) {
          if (timerRef.current) {
            clearTimeout(timerRef.current);
          }
          timerRef.current = setTimeout(() => {
            connect_Wallet();
          }, 1000);
          props.Set_WalletConnected(true);
        } else {
          props.Set_WalletConnected(false);
        }
      });
    }
  });

  const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
    },
  };

  let subtitle;

  function afterOpenModal() {
    // references are now sync'd and can be accessed.
    subtitle.style.color = '#f00';
  }

  const connect_Wallet_call = (type) => {
    // if(WalletConnected!=true) {
    //   connect_Wallet(type);
    // }
  };

  let pathVal = '';

  const location = useLocation();
  if (location.pathname) {
    if (location.pathname.split('/').length >= 2) {
      pathVal = location.pathname.split('/')[1];
    }
  }

  return (
    <div>
      {props.fullLoader && <div className="loading"></div>}
      {(WalletConnected === false || WalletConnected === 'false') &&
        (pathVal === 'my-items' || pathVal === 'following' || pathVal === 'activity' || pathVal === 'info') && (
          <Modal isOpen={true} onAfterOpen={afterOpenModal} style={customStyles} contentLabel="Example Modal">
            <div className="modaltest" style={{ opacity: 2, position: 'relative' }}>
              <h2 ref={(_subtitle) => (subtitle = _subtitle)}>Network</h2>
              <div>Connect to Binance Network.</div>
            </div>
          </Modal>
        )}
      <div id="connect_Wallet_call" onClick={() => connect_Wallet_call('metamask')}></div>
    </div>
  );
}
