import { Button } from '@material-ui/core';
// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';
import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Activity_List_Action, getCurAddr } from '../actions/user';
import styles from '../assets/jss/material-kit-react/views/landingPage.js';
import Footer from '../components/Footer/Footer.jsx';
import GridContainer from '../components/Grid/GridContainer.jsx';
import GridItem from '../components/Grid/GridItem.jsx';
// core components
import Header from '../components/Header/Header.jsx';
import HeaderLinks from '../components/Header/HeaderLinks.jsx';
import ActivityTab from './separate/ActivityTab';
import ConnectWallet from './separate/ConnectWallet';

const dashboardRoutes = [];

const useStyles = makeStyles(styles);

// Scroll to Top
function ScrollToTopOnMount() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return null;
}

export default function Activity(props) {
  const classes = useStyles();
  const { ...rest } = props;

  const [WalletConnected, Set_WalletConnected] = React.useState('false');
  const [UserAccountAddr, Set_UserAccountAddr] = React.useState('');
  const [filterType, setfilterType] = React.useState('');
  const [activityType, setactivityType] = React.useState('all');
  const [ActivityList, Set_ActivityList] = React.useState([]);
  const [page, setpage] = React.useState(1);
  const [loadMoreBtn, setloadMoreBtn] = React.useState(true);
  const [AddressUserDetails, Set_AddressUserDetails] = React.useState({});

  useEffect(() => {
    Get_Activity_list();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const AfterWalletConnected = async () => {
    var currAddr = await getCurAddr();
    if (typeof currAddr !== 'undefined') {
      window.$('#AfterWalletConnected_two').click();
    }
  };

  async function changeTab(type) {
    var address = '';
    if (type === 'my') {
      var currAddr = await getCurAddr();
      address = currAddr;
    }
    setactivityType(type);

    var resp = await Activity_List_Action({
      addr: address,
      actiontype: type,
      filter: filterType,
    });

    if (resp && resp.data && resp.data.list) {
      var List = resp.data.list;
      setloadMoreBtn(true);
      Set_ActivityList(List);
    } else {
      Set_ActivityList([]);
    }
  }

  async function Get_Activity_list() {
    var address = '';
    if (activityType === 'my') {
      var currAddr = await getCurAddr();
      address = currAddr;
    }

    var resp = await Activity_List_Action({
      addr: address,
      actiontype: activityType,
      filter: filterType,
    });

    if (resp && resp.data && resp.data.list) {
      var List = resp.data.list;
      Set_ActivityList(List);
    }
  }

  async function changeFilter(type) {
    var address = '';
    if (activityType === 'my') {
      var currAddr = await getCurAddr();
      address = currAddr;
    }

    var resp = await Activity_List_Action({
      addr: address,
      actiontype: activityType,
      filter: type,
    });
    setfilterType(type);

    if (resp && resp.data && resp.data.list) {
      var List = resp.data.list;
      Set_ActivityList(List);
    } else {
      Set_ActivityList([]);
    }
  }

  const loadMoreActivity = async (e) => {
    var address = '';
    if (activityType === 'my') {
      var currAddr = await getCurAddr();
      address = currAddr;
    }

    var resp = await Activity_List_Action({
      addr: address,
      actiontype: activityType,
      filter: filterType,
      page: page,
    });

    if (resp && resp.data && resp.data.list && resp.data.list.length > 0) {
      var nxtPage = resp.data && resp.data.page ? resp.data.page : 0;
      var List = resp.data.list;
      var totalList = ActivityList.concat(List);
      Set_ActivityList(totalList);
      setpage(nxtPage);
    } else {
      setloadMoreBtn(false);
    }
  };

  async function resetFilter() {
    var address = '';
    if (activityType === 'my') {
      var currAddr = await getCurAddr();
      address = currAddr;
    }
    setfilterType('');
    var resp = await Activity_List_Action({
      addr: address,
      actiontype: activityType,
      filter: '',
    });

    if (resp && resp.data && resp.data.list && resp.data.list.length > 0) {
      var nxtPage = resp.data && resp.data.page ? resp.data.page : 0;
      var List = resp.data.list;
      var totalList = ActivityList.concat(List);
      Set_ActivityList(totalList);
      setpage(nxtPage);
    } else {
      setloadMoreBtn(false);
    }
  }

  return (
    <div className="inner_header">
      <ConnectWallet
        Set_UserAccountAddr={Set_UserAccountAddr}
        Set_WalletConnected={Set_WalletConnected}
        WalletConnected={WalletConnected}
        AfterWalletConnected={AfterWalletConnected}
        Set_AddressUserDetails={Set_AddressUserDetails}
      />
      <Header
        fixed
        color="transparent"
        routes={dashboardRoutes}
        brand={
          <Link to="/home">
            <img src={require('../assets/images/logo.png')} alt="logo" className="img-fluid" />
          </Link>
        }
        rightLinks={<HeaderLinks />}
        changeColorOnScroll={{
          height: 50,
          color: 'dark',
        }}
        {...rest}
      />
      <ScrollToTopOnMount />
      <div className={classes.pageHeader + ' inner_pageheader'}>
        <div className="container mt-3">
          <GridContainer className="align-items-start">
            <GridItem xs={12} sm={8} md={8}>
              <div className="d-flex justify-content-between">
                <h2 className="inner_title">Activity</h2>
              </div>
              <nav className="masonry_tab_nav mt-4 items_tab_outer">
                <div className="nav nav-tabs masonry_tab primary_tab" id="nav-tab" role="tablist">
                  <a
                    onClick={() => changeTab('all')}
                    className="nav-link active"
                    id="all-tab"
                    data-toggle="tab"
                    href="#all"
                    role="tab"
                    aria-controls="all"
                    aria-selected="true"
                  >
                    All
                  </a>

                  <a
                    onClick={() => changeTab('following')}
                    className="nav-link"
                    id="following-tab"
                    data-toggle="tab"
                    href="#following"
                    role="tab"
                    aria-controls="following"
                    aria-selected="false"
                  >
                    Following
                  </a>
                  <a
                    onClick={() => changeTab('my')}
                    className="nav-link"
                    id="activity-tab"
                    data-toggle="tab"
                    href="#activity"
                    role="tab"
                    aria-controls="activity"
                    aria-selected="false"
                  >
                    My Activity
                  </a>
                </div>
              </nav>
              <div className="tab-content explore_tab_content mt-2" id="nav-tabContent">
                <div className="tab-pane fade show active" id="all" role="tabpanel" aria-labelledby="all-tab">
                  <div className="proposal_panel_overall">
                    <div className="followers_overall py-3">
                      <div className="row">
                        {ActivityList &&
                          ActivityList.length > 0 &&
                          ActivityList.map((activitydata) => {
                            return activitydata.actiontype !== '' ? (
                              <ActivityTab item={activitydata} UserAccountAddr={UserAccountAddr} />
                            ) : (
                              ''
                            );
                          })}
                      </div>

                      {loadMoreBtn && ActivityList && ActivityList.length > 0 && (
                        <div className="text-center" id="spinBut">
                          <Button
                            className="create_btn"
                            onClick={() => {
                              loadMoreActivity();
                            }}
                            id="onmore"
                            style={{ display: 'flex' }}
                          >
                            Load More
                          </Button>
                        </div>
                      )}

                      {ActivityList && ActivityList.length === 0 && (
                        <div className="text-center py-5">
                          <p className="not_found_text">No Result Found</p>
                        </div>
                      )}
                    </div>
                  </div>
                </div>

                <div className="tab-pane fade" id="following" role="tabpanel" aria-labelledby="following-tab">
                  <div className="proposal_panel_overall">
                    <div className="followers_overall py-3">
                      <div className="row">
                        {ActivityList.map((activitydata) => {
                          return <ActivityTab item={activitydata} UserAccountAddr={UserAccountAddr} />;
                        })}
                      </div>
                      {loadMoreBtn && ActivityList && ActivityList.length > 0 && (
                        <div className="text-center" id="spinBut">
                          <Button
                            className="create_btn"
                            onClick={() => {
                              loadMoreActivity();
                            }}
                            id="onmore"
                            style={{ display: 'flex' }}
                          >
                            Load More
                          </Button>
                        </div>
                      )}
                      {ActivityList && ActivityList.length === 0 && (
                        <div className="text-center py-5">
                          <p className="not_found_text">No Result Found</p>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <div className="tab-pane fade" id="activity" role="tabpanel" aria-labelledby="activity-tab">
                  <div className="proposal_panel_overall">
                    <div className="followers_overall py-3">
                      <div className="row">
                        {ActivityList.map((activitydata) => {
                          return <ActivityTab item={activitydata} UserAccountAddr={UserAccountAddr} />;
                        })}
                      </div>
                      {loadMoreBtn && ActivityList && ActivityList.length > 0 && (
                        <div className="text-center" id="spinBut">
                          <Button
                            className="create_btn"
                            onClick={() => {
                              loadMoreActivity();
                            }}
                            id="onmore"
                            style={{ display: 'flex' }}
                          >
                            Load More
                          </Button>
                        </div>
                      )}
                      {ActivityList && ActivityList.length === 0 && (
                        <div className="text-center py-5">
                          <p className="not_found_text">No Result Found</p>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </GridItem>
            <GridItem xs={12} sm={4} md={4}>
              <div className="filter_panel">
                <h2>Filter</h2>
                {filterType !== '' && (
                  <p>
                    <button type="button" onClick={() => resetFilter('listings')}>
                      Reset filter
                    </button>
                  </p>
                )}
                <div className="filter_panel_grp">
                  <Button
                    className={filterType === 'listings' ? 'filter_btn selected' : 'filter_btn'}
                    onClick={() => changeFilter('listings')}
                  >
                    Listings
                  </Button>
                  <Button
                    className={filterType === 'purchase' ? 'filter_btn selected' : 'filter_btn'}
                    onClick={() => changeFilter('purchase')}
                  >
                    Purchases
                  </Button>
                  <Button
                    className={filterType === 'sales' ? 'filter_btn selected' : 'filter_btn'}
                    onClick={() => changeFilter('sales')}
                  >
                    Sales
                  </Button>
                  {/* <Button className={(filterType=="listings")?"filter_btn selected":"filter_btn"}>Transfers</Button> */}
                  <Button
                    className={filterType === 'burns' ? 'filter_btn selected' : 'filter_btn'}
                    onClick={() => changeFilter('burns')}
                  >
                    Burns
                  </Button>
                  <Button
                    className={filterType === 'bidrequest' ? 'filter_btn selected' : 'filter_btn'}
                    onClick={() => changeFilter('bidrequest')}
                  >
                    Bids
                  </Button>
                  <Button
                    className={filterType === 'like' ? 'filter_btn selected' : 'filter_btn'}
                    onClick={() => changeFilter('like')}
                  >
                    Likes
                  </Button>
                  <Button
                    className={filterType === 'following' ? 'filter_btn selected' : 'filter_btn'}
                    onClick={() => changeFilter('following')}
                  >
                    Followings
                  </Button>
                </div>
              </div>
            </GridItem>
          </GridContainer>
        </div>
      </div>
      <Footer />
    </div>
  );
}
