import React from 'react';
import { TokenCounts_Get_Detail_Action } from '../../actions/token';
import { getCurAddr } from '../../actions/user';
import GridItem from '../../components/Grid/GridItem';
import config from '../../lib/config';
import isEmpty from '../../lib/isEmpty';

const IPFS_IMGurl = config.IPFS_IMG;

export default function TokenItem(props) {
  async function showAllwithPro(data) {
    var curAddr = await getCurAddr();
    var payload = {
      curAddr: curAddr,
      tokenCounts: data.tokenCounts,
    };
    TokenCounts_Get_Detail_Call(payload);
  }
  const TokenCounts_Get_Detail_Call = async (payload) => {
    var curAddr = await getCurAddr();
    var Resp = await TokenCounts_Get_Detail_Action(payload);
    if (Resp && Resp && Resp.data && Resp.data.Detail && Resp.data.Detail.Resp) {
      var TokenResp = Resp.data.Detail.Resp;
      if (TokenResp && TokenResp.Token && TokenResp.Token[0] && TokenResp.Token[0].tokenowners_current) {
        for (let i = 0; i < TokenResp.Token[0].tokenowners_current.length; i++) {
          const element = TokenResp.Token[0].tokenowners_current[i];
          if (element.balance > 0 && element.tokenPrice > 0 && element.tokenOwner !== curAddr) {
            if (Set_BuyOwnerDetailFirst) {
              Set_BuyOwnerDetailFirst(element);
            }
            break;
          }
        }
      }
      Set_tokenCounts_Detail(TokenResp);
      if (TokenResp.Bids) {
        Set_Bids(TokenResp.Bids);
      }
      var IndexVal = -1;
      if (TokenResp.Token[0].tokenowners_all && curAddr) {
        var tokenowners_all = TokenResp.Token[0].tokenowners_all;
        IndexVal = tokenowners_all.findIndex((val) => val.tokenOwner.toString() === curAddr.toString());
      }
      var newMyTokenBalance = 0;
      if (IndexVal > -1) {
        newMyTokenBalance = tokenowners_all[IndexVal].balance;

        Set_MyTokenBalance(newMyTokenBalance);
        if (Set_MyTokenDetail) {
          Set_MyTokenDetail(tokenowners_all[IndexVal]);
        }
      } else {
        newMyTokenBalance = 0;
        Set_MyTokenBalance(0);
        if (Set_MyTokenDetail) {
          Set_MyTokenDetail({});
        }
      }

      var burnQty = 0;
      if (TokenResp.BurnToken) {
        burnQty = TokenResp.BurnToken;
      }

      if (TokenResp.TotalQuantity) {
        Set_AllowedQuantity(TokenResp.TotalQuantity - newMyTokenBalance - burnQty);
      } else {
        Set_AllowedQuantity(0);
      }
      if (TokenResp.Token && TokenResp.Token[0]) {
        Set_item(TokenResp.Token[0]);
      }
    }
  };

  var {
    item,
    LikedTokenList,
    hitLike,
    UserAccountAddr,
    PutOnSale_Click,
    PlaceABid_Click,
    PurchaseNow_Click,
    Burn_Click,
    CancelOrder_Click,
    WalletConnected,
    ShareSocial_Click,
    SubmitReport_Click,
    //by muthu
    Set_item,
    Set_Bids,
    Set_BuyOwnerDetailFirst,
    Set_tokenCounts_Detail,
    Set_MyTokenBalance,
    Set_MyTokenDetail,
    Set_AllowedQuantity,
    Bids,
  } = props;

  return item.tokenowners_current ? (
    <GridItem xs={12} sm={6} md={6} lg={3}>
      <div className="card">
        <a href={'/info/' + item.tokenCounts} className="card__cover">
          {item.image.split('.').pop() === 'mp4' ? (
            <video
              src={`${IPFS_IMGurl}/${item.ipfsimage}`}
              type="video/mp4"
              alt="Collections"
              className="img-fluid"
              controls
            />
          ) : item.image.split('.').pop() === 'mp3' ? (
            <audio
              src={`${IPFS_IMGurl}/${item.ipfsimage}`}
              type="audio/mp3"
              controls
              className="audio"
              autostart="off"
            ></audio>
          ) : (
            <img src={`${IPFS_IMGurl}/${item.ipfsimage}`} alt="Collections" className="img-fluid " />
          )}
          {item.image.split('.').pop() === 'mp3' && (
            <img src={config.Back_URL + '/images/music.png'} alt="Collections" className="img-fluid " />
          )}
          {item.PutOnSaleType === 'UnLimitedAuction' && (
            <span className="card__time card__time--clock">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                <path d="M8.46777,8.39453l-.00225.00183-.00214.00208ZM18.42188,8.208a1.237,1.237,0,0,0-.23-.17481.99959.99959,0,0,0-1.39941.41114,5.78155,5.78155,0,0,1-1.398,1.77734,8.6636,8.6636,0,0,0,.1333-1.50977,8.71407,8.71407,0,0,0-4.40039-7.582,1.00009,1.00009,0,0,0-1.49121.80567A7.017,7.017,0,0,1,7.165,6.87793l-.23047.1875a8.51269,8.51269,0,0,0-1.9873,1.8623A8.98348,8.98348,0,0,0,8.60254,22.83594.99942.99942,0,0,0,9.98,21.91016a1.04987,1.04987,0,0,0-.0498-.3125,6.977,6.977,0,0,1-.18995-2.58106,9.004,9.004,0,0,0,4.3125,4.0166.997.997,0,0,0,.71534.03809A8.99474,8.99474,0,0,0,18.42188,8.208ZM14.51709,21.03906a6.964,6.964,0,0,1-3.57666-4.40234,8.90781,8.90781,0,0,1-.17969-.96387,1.00025,1.00025,0,0,0-.79931-.84473A.982.982,0,0,0,9.77,14.80957a.99955.99955,0,0,0-.8667.501,8.9586,8.9586,0,0,0-1.20557,4.71777,6.98547,6.98547,0,0,1-1.17529-9.86816,6.55463,6.55463,0,0,1,1.562-1.458.74507.74507,0,0,0,.07422-.05469s.29669-.24548.30683-.2511a8.96766,8.96766,0,0,0,2.89874-4.63269,6.73625,6.73625,0,0,1,1.38623,8.08789,1.00024,1.00024,0,0,0,1.18359,1.418,7.85568,7.85568,0,0,0,3.86231-2.6875,7.00072,7.00072,0,0,1-3.2793,10.457Z" />
              </svg>
              Auction
            </span>
          )}
        </a>
        <div className="titleCard">
          <h3 className="card__title">
            <a href={'/info/' + item.tokenCounts}>{item.tokenName}</a>
          </h3>
          <div className="masonry_likes">
            <div className="dropdown d-inline-block">
              <button
                className="btn btn-secondary dropdown-toggle filter_btn dotsDrop"
                type="button"
                id={'dropdownMenuButton' + item.tokenCounts}
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
                onClick={() => showAllwithPro(item)}
              >
                <i className="fas fa-ellipsis-h"></i>
              </button>
              <div className="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenuButton">
                {WalletConnected === true &&
                  item.tokenowners_current &&
                  item.tokenowners_current.tokenOwner &&
                  item.tokenowners_current.tokenOwner === UserAccountAddr && (
                    <button
                      type="button"
                      className="dropdown-item"
                      onClick={() => PutOnSale_Click(item, item.tokenowners_current)}
                    >
                      {item.tokenowners_current.tokenPrice > parseFloat(0) ? 'Change price' : 'Put on sale'}
                    </button>
                  )}

                {WalletConnected === true &&
                  item.tokenowners_current &&
                  item.tokenowners_current.tokenOwner &&
                  item.tokenowners_current.tokenOwner === UserAccountAddr &&
                  item.tokenowners_current.tokenPrice > 0 && (
                    <button
                      type="button"
                      className="dropdown-item"
                      onClick={() => CancelOrder_Click(item, item.tokenowners_current)}
                    >
                      Cancel Order
                    </button>
                  )}
                {WalletConnected === true &&
                  item.tokenowners_current &&
                  (item.PutOnSaleType === 'FixedPrice' || item.PutOnSaleType === 'UnLimitedAuction') &&
                  item.tokenowners_current.tokenOwner &&
                  item.tokenowners_current.tokenOwner === UserAccountAddr && (
                    <button
                      type="button"
                      className="dropdown-item"
                      onClick={() => Burn_Click(item, item.tokenowners_current)}
                    >
                      Burn{' '}
                    </button>
                  )}

                {WalletConnected === true &&
                  item.tokenowners_current &&
                  item.PutOnSaleType === 'FixedPrice' &&
                  item.tokenowners_current.tokenPrice > parseFloat(0) &&
                  item.tokenowners_current.tokenOwner &&
                  item.tokenowners_current.tokenOwner !== UserAccountAddr && (
                    <button type="button" className="dropdown-item" onClick={() => PurchaseNow_Click(item)}>
                      Purchase Now{' '}
                    </button>
                  )}

                {WalletConnected === true &&
                item.tokenowners_current &&
                (item.PutOnSaleType === 'FixedPrice' || item.PutOnSaleType === 'UnLimitedAuction') &&
                item.tokenowners_current.tokenOwner &&
                item.tokenowners_current.tokenOwner !== UserAccountAddr ? (
                  //  &&
                  //   Bids
                  //   && Bids.myBid
                  //   && !Bids.myBid.status
                  // <div className="dropdown-item">
                  //   <span onClick={() => PlaceABid_Click(item)}>New Bid</span>

                  // </div>
                  <button type="button" className="dropdown-item" onClick={() => PlaceABid_Click(item)}>
                    New Bid{' '}
                  </button>
                ) : Bids && Bids.myBid && Bids.myBid.status && Bids.myBid.status === 'pending' ? (
                  // <div className="dropdown-item">
                  //   <span onClick={() => PlaceABid_Click(item)}>Edit bid </span>
                  // </div>
                  <button type="button" className="dropdown-item" onClick={() => PlaceABid_Click(item)}>
                    Edit Bid{' '}
                  </button>
                ) : (
                  <div></div>
                )}
                {WalletConnected === true &&
                  item.tokenowners_current &&
                  item.tokenowners_current.tokenOwner &&
                  item.tokenowners_current.tokenOwner !== UserAccountAddr && (
                    <button type="button" className="dropdown-item" onClick={() => SubmitReport_Click(item)}>
                      Report{' '}
                    </button>
                  )}

                {WalletConnected === true &&
                  item.tokenowners_current &&
                  item.tokenowners_current.tokenOwner &&
                  item.tokenowners_current.tokenOwner !== UserAccountAddr && (
                    <button type="button" className="dropdown-item" onClick={() => ShareSocial_Click(item)}>
                      Share{' '}
                    </button>
                  )}
              </div>
            </div>
          </div>
        </div>
        <div
          className={
            item &&
            item.tokenOwnerInfo &&
            item.tokenOwnerInfo.Accountverified &&
            item.tokenOwnerInfo.Accountverified !== undefined &&
            item.tokenOwnerInfo.Accountverified !== null &&
            item.tokenOwnerInfo.Accountverified === 'Verified'
              ? 'card__author card__author--verified'
              : 'card__author'
          }
        >
          {item.tokenOwnerInfo && item.tokenOwnerInfo.curraddress && item.tokenOwnerInfo.image ? (
            <a
              href={
                item.tokenOwnerInfo &&
                item.tokenOwnerInfo.customurl !== undefined &&
                item.tokenOwnerInfo.customurl !== '' &&
                item.tokenOwnerInfo.customurl !== null
                  ? '/user/' + item.tokenOwnerInfo.customurl
                  : '/userprofile/' + item.tokenOwner
              }
            >
              <img src={item.tokenOwnerInfo.image} alt="" />
            </a>
          ) : (
            <a
              href={
                item.tokenOwnerInfo &&
                item.tokenOwnerInfo.customurl !== undefined &&
                item.tokenOwnerInfo.customurl !== '' &&
                item.tokenOwnerInfo.customurl !== null
                  ? '/user/' + item.tokenOwnerInfo.customurl
                  : '/userprofile/' + item.tokenOwner
              }
            >
              <img src={require('../../assets/images/avatars/avatar5.jpg')} alt="" />
            </a>
          )}
          {!isEmpty(item.tokenOwnerInfo) && item.tokenOwnerInfo.name ? (
            <a href={'/userprofile/' + item.tokenOwnerInfo.curraddress}>{item.tokenOwnerInfo.name}</a>
          ) : (
            <a href={'/userprofile/' + item.tokenOwnerInfo.curraddress}>{item.tokenOwnerInfo.curraddress}</a>
          )}
        </div>
        <div className="card__info">
          {item.tokenowners_current.PutOnSale === false ? (
            <div className="card__price">
              <span>
                Not for sale {item.tokenowners_current.balance} of {item.tokenowners_current.quantity}
              </span>
              <span>Place a bid</span>
            </div>
          ) : (
            <div className="card__price">
              {item.tokenowners_current.PutOnSaleType === 'UnLimitedAuction' ? (
                <div className="card__price">
                  <span>
                    Open for Bids {item.tokenowners_current.balance} of {item.tokenowners_current.quantity}
                  </span>
                  <span>Place a bid</span>
                </div>
              ) : (
                <div className="card__price">
                  <span>
                    Open for sale {item.tokenowners_current.balance} of {item.tokenowners_current.quantity}
                  </span>
                  {/* <span>{item.tokenowners_current.tokenPrice} {item.biddingtoken}</span> */}
                  {item.PutOnSale === true &&
                    item.PutOnSaleType === 'FixedPrice' &&
                    item.tokenowners_current.tokenPrice > 0 && (
                      <span>
                        {item.tokenowners_current.tokenPrice} {item.biddingtoken}{' '}
                      </span>
                    )}
                </div>
              )}
            </div>
          )}
          <button
            className={
              LikedTokenList.findIndex((tokenCounts) => tokenCounts.tokenCounts === item.tokenCounts) > -1
                ? 'card__likes card__likes--active'
                : 'card__likes'
            }
            type="button"
            onClick={() => hitLike(item)}
            style={{ cursor: 'pointer' }}
          >
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
              <path d="M20.16,5A6.29,6.29,0,0,0,12,4.36a6.27,6.27,0,0,0-8.16,9.48l6.21,6.22a2.78,2.78,0,0,0,3.9,0l6.21-6.22A6.27,6.27,0,0,0,20.16,5Zm-1.41,7.46-6.21,6.21a.76.76,0,0,1-1.08,0L5.25,12.43a4.29,4.29,0,0,1,0-6,4.27,4.27,0,0,1,6,0,1,1,0,0,0,1.42,0,4.27,4.27,0,0,1,6,0A4.29,4.29,0,0,1,18.75,12.43Z" />
            </svg>
            <span className={item.tokenCounts + '-likecount'}>{item.likecount}</span>
          </button>
        </div>
      </div>
    </GridItem>
  ) : (
    ''
  );
}
