// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';
import { WalletConnectConnector } from '@web3-react/walletconnect-connector';
import React, { useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Web3 from 'web3';
import styles from '../assets/jss/material-kit-react/views/landingPage.js';
import Footer from '../components/Footer/Footer';
import GridContainer from '../components/Grid/GridContainer';
import GridItem from '../components/Grid/GridItem';
// core components
import Header from '../components/Header/Header';
import HeaderLinks from '../components/Header/HeaderLinks';

const dashboardRoutes = [];
toast.configure();
let toasterOption = {
  position: 'top-right',
  autoClose: 1000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: false,
  draggable: true,
  progress: undefined,
};

const useStyles = makeStyles(styles);

// Scroll to Top
function ScrollToTopOnMount() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return null;
}

export default function ConnectWallet(props) {
  const classes = useStyles();
  const history = useHistory();
  const { ...rest } = props;

  useEffect(() => {
    WalletConnect();
  });

  const walletconnectProvider = new WalletConnectConnector({
    infuraId: 'cf307663239548609f007a31e1e1ce9e',
    rpc: {
      97: 'https://data-seed-prebsc-1-s1.binance.org:8545/',
      56: 'https://bsc-dataseed.binance.org/',
    },
    qrcode: true,
    pollingInterval: 15000,
  });

  async function WalletConnect() {
    if (localStorage.getItem('jakcojsnick')) {
      history.push('/home');
    } else {
      // window.location.href="/connect"
      // return false;
    }
  }

  const connectWC = async () => {
    //  Enable session (triggers QR Code modal)
    await walletconnectProvider.activate().catch((error) => {
      toast.error(error.message.toString(), toasterOption);
    });
  };

  async function connectMetamask() {
    if (window.ethereum) {
      var web3 = new Web3(window.ethereum);
      try {
        if (typeof web3 !== 'undefined') {
          window.ethereum.enable().then(async function () {
            const web3 = new Web3(window.web3.currentProvider);
            if (window.web3.currentProvider.isMetaMask === true) {
              await web3.eth.getAccounts(async function (error, result) {
                localStorage.setItem('jakcojsnick', 'yes');
                localStorage.setItem('address', result[0]);
                window.location.href = '/home';
                //  history.push("/home")
              });
            }
          });
        } else {
          toast.error('Please Add Metamask External', toasterOption);
        }
      } catch (err) {}
    } else {
      toast.error('Please Add Metamask External', toasterOption);
    }
  }

  return (
    <div className="inner_header">
      <Header
        fixed
        color="transparent"
        routes={dashboardRoutes}
        brand={
          <Link to="/home">
            <img src={require('../assets/images/logo.png')} alt="logo" className="img-fluid" />
          </Link>
        }
        rightLinks={<HeaderLinks />}
        changeColorOnScroll={{
          height: 50,
          color: 'dark',
        }}
        {...rest}
      />
      <ScrollToTopOnMount />
      <div className={classes.pageHeader + ' inner_pageheader'}>
        <div className={classes.container}>
          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              <div className="d-flex align-items-center">
                <Link to="/home">
                  <img src={require('../assets/images/arrow_icon.png')} alt="logo" className="arrow_icon" />
                </Link>
                <h2 className="inner_title">Connect your wallet</h2>
              </div>
            </GridItem>
          </GridContainer>
        </div>
        <div className="container mt-4">
          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              <p className="create_para">Connect with one of available wallet providers or create a new wallet.</p>
              <div className="create_box_panel row connect_row">
                <div className="col-12 col-sm-6 col-lg-4 mb-3">
                  <Link className="create_box connect_wallet_box" onClick={connectMetamask}>
                    <h2>Metamask</h2>
                    <img src={require('../assets/images/metamask.svg')} alt="logo" className="img-fluid mt-3" />
                    <p className="mb-0 text-center mt-3">One of the most secure wallets with great flexibility</p>
                  </Link>
                </div>
                <div className="col-12 col-sm-6 col-lg-4 mb-3">
                  <Link className="create_box connect_wallet_box" onClick={connectWC}>
                    <h2>WalletConnect</h2>
                    <img src={require('../assets/images/metamask.svg')} alt="logo" className="img-fluid mt-3" />
                    <p className="mb-0 text-center mt-3">One of the most secure wallets with great flexibility</p>
                  </Link>
                </div>
              </div>
              <p className="create_para mt-3">
                We do not own your private keys and cannot access your funds without your confirmation.
              </p>
            </GridItem>
          </GridContainer>
        </div>
      </div>
      <Footer />
    </div>
  );
}
