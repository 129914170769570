import { Button } from '@material-ui/core';
import '@metamask/legacy-web3';
import BigNumber from 'bignumber.js';
import React, { forwardRef, useEffect, useImperativeHandle } from 'react';
import { toast } from 'react-toastify';
import Web3 from 'web3';
import ABI from '../../ABI/ABI.json';
import DETHABI from '../../ABI/DETH_ABI.json';
import { PurchaseNow_Complete_Action } from '../../actions/token';
import config from '../../lib/config';

toast.configure();
let toasterOption = config.toasterOption;

const contractaddress = config.multipleContract;

export const PurchaseNowRef = forwardRef((props, ref) => {
  let {
    UserAccountAddr,
    UserAccountBal,
    TokenBalance,
    MyItemAccountAddr,
    //buytoken,
  } = props;

  const [ApproveCallStatus, setApproveCallStatus] = React.useState('init');
  const [PurchaseCallStatus, setPurchaseCallStatus] = React.useState('init');
  const [PurchaseCurrency, Set_PurchaseCurrency] = React.useState('ORE');
  const [buytoken, Set_buytoken] = React.useState('ORE');

  let biddingtokenaddress = config.DETHaddress;
  if (buytoken === 'ORE') {
    biddingtokenaddress = 'ORE';
  } else {
    biddingtokenaddress = 'BNB';
  }

  const [NoOfToken, Set_NoOfToken] = React.useState(1);
  const [FormSubmitLoading, Set_FormSubmitLoading] = React.useState('');
  const [ValidateError, Set_ValidateError] = React.useState({});
  const [TokenPrice, Set_TokenPrice] = React.useState(0);

  const [subtotalPrice, setSubtotalPrice] = React.useState(0);
  const [totalPrice, setTotalPrice] = React.useState(0);

  async function buymodal() {
    setTimeout(() => window.$('#PurchaseNow_modal').modal('hide'), 600);
    setTimeout(() => window.location.reload(false), 900);
  }
  const PriceCalculate = async (data = {}) => {
    const price = typeof data.price !== 'undefined' ? data.price : TokenPrice;
    const quantity = typeof data.quantity !== 'undefined' ? data.quantity : NoOfToken;
    const weiPrice = price * 1e18;
    const subtotal = item.type === 721 ? weiPrice : quantity * weiPrice;
    const total = subtotal + (subtotal * servicefee) / 1e20;
    setSubtotalPrice(subtotal);
    setTotalPrice(total);
    // Set_Price(newPrice);
    // //("PriceCalculate : ",data, price,quantity,newPrice,weii,per,mulWei,(mulWei / config.decimalvalues).toFixed(config.toFixed))
  };

  const onKeyUp = (e) => {
    let charCode = e.keyCode;
    if ((charCode > 47 && charCode < 58) || (charCode > 95 && charCode < 106)) {
      let ValidateError = {};
      Set_ValidateError(ValidateError);
    } else {
      let ValidateError = {};
      ValidateError.NoOfToken = '"Quantity" must be a number';
      Set_NoOfToken('');
      Set_ValidateError(ValidateError);
    }
  };

  const inputChange = (e) => {
    if (e && e.target && typeof e.target.value !== 'undefined' && e.target.name) {
      let value = e.target.value;
      switch (e.target.name) {
        case 'NoOfToken':
          Set_NoOfToken(value);
          PriceCalculate({ quantity: value });
          break;
        case 'TokenPrice':
          Set_TokenPrice(value);
          if (value !== '' && isNaN(value) === false && value > 0) {
            PriceCalculate({ price: value });
          }
          break;
        default:
        // code block
      }
      // ItemValidation({TokenPrice:value});
    }
  };
  const ItemValidation = async (data = {}) => {
    let ValidateError = {};

    if (item && item.tokenowners_current) {
      let Chk_TokenPrice = typeof data.TokenPrice !== 'undefined' ? data.TokenPrice : TokenPrice;
      let quantity = typeof data.quantity !== 'undefined' ? data.quantity : NoOfToken;

      let Collectible_balance = 0;
      if (item && item.tokenowners_current && item.tokenowners_current.balance) {
        Collectible_balance = item.tokenowners_current.balance;
      }

      if (quantity === '') {
        ValidateError.NoOfToken = '"Quantity" is not allowed to be empty';
      } else if (quantity === 0) {
        ValidateError.NoOfToken = '"Quantity" must be greater than 0';
      } else if (isNaN(quantity) === true) {
        ValidateError.NoOfToken = '"Quantity" must be a number';
      }
      if (quantity > Collectible_balance) {
        ValidateError.NoOfToken = '"Quantity" may not exceed total collectible balance of ' + Collectible_balance;
      }

      if (Chk_TokenPrice === '') {
        ValidateError.TokenPrice = '"Token Price" is not allowed to be empty';
      } else if (Chk_TokenPrice === 0) {
        ValidateError.TokenPrice = '"Token Price" must be greater than 0';
      } else if (isNaN(Chk_TokenPrice) === true) {
        ValidateError.TokenPrice = '"Token Price" must be a number';
      } else if (PurchaseCurrency === 'ORE' && Chk_TokenPrice > TokenBalance) {
        ValidateError.TokenPrice = 'Insufficient balance, Check your  balance';
      } else if (PurchaseCurrency === 'BNB' && Chk_TokenPrice > UserAccountBal) {
        ValidateError.TokenPrice = 'Insufficient balance, Check your  balance';
      } else {
        await props.GetUserBal();
        if (Chk_TokenPrice > (PurchaseCurrency && PurchaseCurrency === 'ORE' ? TokenBalance : UserAccountBal)) {
          ValidateError.TokenPrice = 'Insufficient balance, Check your  balance';
        } else {
          delete ValidateError.TokenPrice;
        }
      }
      Set_ValidateError(ValidateError);
    }

    return ValidateError;
  };

  const FormSubmit = async () => {
    let errors = await ItemValidation();

    if (UserAccountBal === 0) {
      toast.error('Insufficient balance', toasterOption);
      return false;
    }
    if (PurchaseCurrency === 'BNB') {
      if (TokenPrice > UserAccountBal) {
        toast.error('Insufficient balance', toasterOption);
        return false;
      }
    }

    if (NoOfToken === '' || NoOfToken === 0 || NoOfToken === undefined || NoOfToken === null) {
      toast.error('Invalid Quantity', toasterOption);
      return false;
    }

    if (item.balance < NoOfToken) {
      toast.error('Insufficient Quantity', toasterOption);
      return false;
    }

    let errorsSize = Object.keys(errors).length;
    if (errorsSize !== 0) {
      toast.error('Form validation error. Fix mistakes and submit again', toasterOption);
      return false;
    }

    if (window.ethereum) {
      let web3 = new Web3(window.ethereum);
      if (web3 && web3.eth) {
        // if(config.PurchaseTransferType === 'token') {
        if (PurchaseCurrency !== 'BNB') {
          if (PurchaseCurrency === 'ORE') {
            let CoursetroContract = new web3.eth.Contract(DETHABI, config.tokenAddr[config.tokenSymbol]);
            let tokenBal = await CoursetroContract.methods.balanceOf(props.Accounts).call();

            if (tokenBal >= totalPrice) {
              window.$('.modal').modal('hide');
              window.$('#PurchaseStep_modal').modal('show');
            } else {
              toast.error('Insufficient balance', toasterOption);
            }
          }
        } else {
          const web3 = new Web3(window.ethereum);
          const CoursetroContract = new web3.eth.Contract(ABI, contractaddress);
          Set_FormSubmitLoading('processing');

          CoursetroContract.methods
            .saleToken(item.tokenowners_current.tokenOwner, item.tokenCounts, subtotalPrice.toString(), NoOfToken)
            .send({
              from: props.Accounts,
              value: totalPrice.toString(),
            })
            .then(async (result) => {
              Set_FormSubmitLoading('done');
              let postData = {
                tokenOwner: item.tokenowners_current.tokenOwner, // old owner
                UserAccountAddr: UserAccountAddr, // new owner
                tokenCounts: item.tokenCounts,
                tokenType: item.type,
                NoOfToken: item.type === 721 ? 1 : NoOfToken,
                transactionHash: result.transactionHash,
              };
              let Resp = await PurchaseNow_Complete_Action(postData);
              if (Resp.data && Resp.data.toast && Resp.data.toast.type === 'success') {
                toast.success('Collectible purchased successfully', toasterOption);
                window.$('.PurchaseNow_modal').modal('hide');
                setTimeout(() => {
                  window.location.reload(false);
                }, 2000);
              }
            })
            .catch((error) => {
              Set_FormSubmitLoading('error');
              toast.error('Order not placed', toasterOption);
            });
        }
      }
    }
  };

  const FormSubmit_StepOne = async () => {
    if (window.ethereum) {
      let web3 = new Web3(window.ethereum);

      if (web3 && web3.eth) {
        let web3 = new Web3(window.ethereum);

        const DETHSMARTCONTRACT = new web3.eth.Contract(DETHABI, config.DETHaddress);

        let getAllowance = await DETHSMARTCONTRACT.methods.allowance(props.Accounts, contractaddress).call();

        let CoursetroContract = PurchaseCurrency === 'ORE' ? DETHSMARTCONTRACT : ' ';

        let newallowsend = new BigNumber(parseFloat(getAllowance) + totalPrice).toFixed();
        setApproveCallStatus('processing');

        await CoursetroContract.methods
          .approve(
            contractaddress,
            // sendAmount
            // MultipleWei
            newallowsend,
          )
          .send({
            from: props.Accounts,
          })
          .then(async (result) => {
            setApproveCallStatus('done');
          })
          .catch((error) => {
            setApproveCallStatus('tryagain');
            console.log('error : ', error);
            toast.error('Order not approved', toasterOption);
          });
      }
    }
  };

  const FormSubmit_StepTwo = async () => {
    if (window.ethereum) {
      let web3 = new Web3(window.ethereum);
      if (web3 && web3.eth) {
        let web3 = new Web3(window.ethereum);
        let CoursetroContract = new web3.eth.Contract(ABI, contractaddress);

        let totalsum = Web3.utils.toWei((TokenPrice * NoOfToken).toString());
        setPurchaseCallStatus('processing');

        CoursetroContract.methods
          .saleWithToken(
            item.tokenowners_current.tokenOwner,
            item.tokenCounts,
            totalsum < 100 ? 100 : totalsum,
            // price,
            NoOfToken,
            biddingtokenaddress,
          )
          .send({
            from: props.Accounts,
            // value:MultipleWei
          })
          .then(async (result) => {
            setPurchaseCallStatus('done');
            let postData = {
              tokenOwner: item.tokenowners_current.tokenOwner, // old owner
              UserAccountAddr: UserAccountAddr, // new owner
              tokenCounts: item.tokenCounts,
              tokenType: item.type,
              NoOfToken: item.type === 721 ? 1 : NoOfToken,
              transactionHash: result.transactionHash,
            };
            let Resp = await PurchaseNow_Complete_Action(postData);
            if (Resp.data && Resp.data.toast && Resp.data.toast.type === 'success') {
              toast.success('Collectible purchase successfully', toasterOption);
              window.$('.PurchaseNow_modal').modal('hide');
              setTimeout(() => {
                window.location.reload(false);
              }, 2000);
            }
          })
          .catch((error) => {
            setPurchaseCallStatus('tryagain');
            toast.error('Order not placed', toasterOption);
          });
      }
    }
  };

  const [item, Set_item] = React.useState(props.item);
  const [servicefee, setservicefee] = React.useState(0);
  useEffect(() => {
    Set_ValidateError({});
    getservicefee();
    ItemValidation({ TokenPrice: TokenPrice });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getservicefee = async () => {
    if (window && window.ethereum !== undefined) {
      let web3 = new Web3(window.ethereum);
      let CoursetroContract = new web3.eth.Contract(ABI, contractaddress);
      let fee = await CoursetroContract.methods.getServiceFee().call();
      setservicefee(fee);
    }
  };

  useImperativeHandle(ref, () => ({
    async PurchaseNow_Click(item, BuyOwnerDetail = {}) {
      let connectwallet = localStorage.getItem('jakcojsnick');
      if (!connectwallet) {
        window.location.href = '/connect';
        return false;
      }

      if (BuyOwnerDetail && typeof BuyOwnerDetail.tokenOwner !== 'undefined') {
        item.tokenowners_current = {};
        item.tokenowners_current = BuyOwnerDetail;
      }

      if (item && item.tokenowners_current && item.tokenowners_current.tokenPrice) {
        Set_item(item);
        Set_TokenPrice(item.tokenowners_current.tokenPrice);
        Set_NoOfToken(1);
        PriceCalculate({
          quantity: 1,
          price: item.tokenowners_current.tokenPrice,
        });
        window.$('#PurchaseNow_modal').modal('show');
      }

      if (item && item.biddingtoken && item.biddingtoken !== '') {
        Set_PurchaseCurrency(item.biddingtoken);
        Set_buytoken(item.biddingtoken);
      }
    },
  }));

  return (
    <div>
      <div
        className="modal fade primary_modal PurchaseNow_modal"
        id="PurchaseNow_modal"
        tabIndex="-1"
        role="dialog"
        data-backdrop="static"
        data-keyboard="false"
        aria-labelledby="accept_modalCenteredLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered modal-md" role="document">
          <div className="modal-content">
            <div className="modal-header text-center">
              <h5 className="modal-title" id="buy_modalLabel">
                Checkout
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
                id="close9"
                onClick={buymodal}
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body px-0">
              <div className="row mx-0">
                <div className="col-12 col-sm-3 px-4">
                  <p className="buy_title_sm">Owner</p>
                </div>
                <div className="col-12 col-md-6 px-4">
                  <p className="buy_title_ownerName">
                    {item.userprofile && item.userprofile.name
                      ? item.userprofile && item.userprofile.name
                      : item && item.tokenowners_current && item.tokenowners_current.tokenOwner}
                  </p>
                </div>
              </div>
              <div className="row mx-0">
                <div className="col-12 col-md-3 px-4">
                  <p className="buy_title_sm">Buyer</p>
                </div>
                <div className="col-12 col-md-6 px-4">
                  <p className="buy_title_ownerName">{MyItemAccountAddr ? MyItemAccountAddr : UserAccountAddr}</p>
                </div>
              </div>
              <div className="bor_bot_modal mb-3 px-4 "></div>
              <form className="bid_form" action="#">
                {item.type === 721 ? (
                  ''
                ) : (
                  <div className="bor_bot_modal mb-3 px-4 ">
                    <label htmlFor="qty">Quantity</label>
                    <div className="mb-3 input_grp_style_1">
                      <input
                        type="text"
                        className="form-control primary_inp text-center"
                        name="NoOfToken"
                        id="NoOfToken"
                        onChange={inputChange}
                        placeholder="e.g. 2"
                        autoComplete="off"
                        value={NoOfToken}
                        onKeyUp={onKeyUp}
                      />
                      {ValidateError.NoOfToken && <span className="text-danger">{ValidateError.NoOfToken}</span>}
                      {!ValidateError.NoOfToken && ValidateError.TokenPrice && (
                        <span className="text-danger">{ValidateError.TokenPrice}</span>
                      )}
                    </div>
                  </div>
                )}
              </form>
              <div className="row mx-0 pb-3">
                <div className="col-12 col-sm-6 px-4">
                  <p className="buy_desc_sm">Your balance</p>
                </div>
                <div className="col-12 col-sm-6 px-4 text-sm-right">
                  <p className="buy_desc_sm_bold">
                    {PurchaseCurrency && PurchaseCurrency === 'ORE' ? TokenBalance : UserAccountBal} {PurchaseCurrency}
                  </p>
                </div>
              </div>
              <div className="row mx-0 pb-3">
                <div className="col-12 col-sm-6 px-4">
                  <p className="buy_desc_sm">Price</p>
                </div>
                <div className="col-12 col-sm-6 px-4 text-sm-right">
                  <p className="buy_desc_sm_bold">
                    {TokenPrice} {PurchaseCurrency}
                  </p>
                </div>
              </div>
              <div className="row mx-0 pb-3">
                <div className="col-12 col-sm-6 px-4">
                  <p className="buy_desc_sm">Service fee</p>
                </div>
                <div className="col-12 col-sm-6 px-4 text-sm-right">
                  <p className="buy_desc_sm_bold">
                    {config.fee * 100}% <span>{PurchaseCurrency}</span>
                  </p>
                </div>
              </div>
              <div className="row mx-0 pb-3">
                <div className="col-12 col-sm-6 px-4">
                  <p className="buy_desc_sm">You will pay</p>
                </div>
                <div className="col-12 col-sm-6 px-4 text-sm-right">
                  <p className="buy_desc_sm_bold">
                    {(totalPrice / 1e18).toFixed(config.toFixed)} <span>{PurchaseCurrency}</span>
                  </p>
                </div>
              </div>
              <form className="px-4">
                <div className="text-center">
                  <Button
                    type="button"
                    className="primary_btn btn-block"
                    onClick={() => FormSubmit()}
                    disabled={FormSubmitLoading === 'processing'}
                  >
                    {FormSubmitLoading === 'processing' && (
                      <i className="fa fa-spinner mr-3 spinner_icon" aria-hidden="true" id="circle1"></i>
                    )}
                    Proceed to payment
                  </Button>
                  <Button className="create_btn  btn-block" data-dismiss="modal" aria-label="Close">
                    Cancel
                  </Button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <div
        className="modal fade primary_modal PurchaseStep_modal"
        id="PurchaseStep_modal"
        tabIndex="-1"
        role="dialog"
        data-backdrop="static"
        data-keyboard="false"
        aria-labelledby="PurchaseStepCenteredLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered modal-md" role="document">
          <div className="modal-content">
            <div className="modal-header text-center">
              <h5 className="modal-title" id="PurchaseStepLabel">
                Follow Steps
              </h5>
              <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <form>
                <div className="text-center">
                  <p className="mt-3 purchase_desc text-center">Approve the transaction</p>
                  <Button
                    type="button"
                    onClick={() => FormSubmit_StepOne()}
                    className={
                      'primary_btn ' +
                      (ApproveCallStatus === 'processing' || ApproveCallStatus === 'done' ? 'create_btn' : 'btn-block')
                    }
                    disabled={ApproveCallStatus === 'processing' || ApproveCallStatus === 'done'}
                  >
                    {ApproveCallStatus === 'processing' && (
                      <i className="fa fa-spinner mr-3 spinner_icon" aria-hidden="true" id="circle1"></i>
                    )}
                    {ApproveCallStatus === 'init' && 'Approve'}
                    {ApproveCallStatus === 'processing' && 'In-progress...'}
                    {ApproveCallStatus === 'done' && 'Done'}
                    {ApproveCallStatus === 'tryagain' && 'Try Again'}
                  </Button>
                </div>
                <div className="text-center my-3">
                  <p className="mt-3 purchase_desc text-center">Send transaction with your wallet</p>
                  <Button
                    type="button"
                    onClick={() => FormSubmit_StepTwo()}
                    className={
                      'primary_btn ' +
                      (ApproveCallStatus !== 'done' ||
                      PurchaseCallStatus === 'processing' ||
                      PurchaseCallStatus === 'done'
                        ? 'create_btn'
                        : 'btn-block')
                    }
                    disabled={
                      ApproveCallStatus !== 'done' ||
                      PurchaseCallStatus === 'processing' ||
                      PurchaseCallStatus === 'done'
                    }
                  >
                    {PurchaseCallStatus === 'processing' && (
                      <i className="fa fa-spinner mr-3 spinner_icon" aria-hidden="true" id="circle1"></i>
                    )}
                    {PurchaseCallStatus === 'init' && 'Purchase'}
                    {PurchaseCallStatus === 'processing' && 'In-progress...'}
                    {PurchaseCallStatus === 'done' && 'Done'}
                    {PurchaseCallStatus === 'tryagain' && 'Try Again'}
                  </Button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
});
