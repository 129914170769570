import { Accordion, AccordionDetails, AccordionSummary, Button } from '@material-ui/core';
// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';
import CKEditor from 'ckeditor4-react';
import $ from 'jquery';
import React, { useEffect, useState } from 'react';
import ReactHtmlParser from 'react-html-parser';
import { Link, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import styled from 'styled-components';
import {
  getcategory,
  likeCommunity,
  mostLikes,
  paginatediscussion,
  replytopic,
  topicdetail,
  updateViewCount,
} from '../actions/community';
import styles from '../assets/jss/material-kit-react/views/landingPage.js';
import Footer from '../components/Footer/Footer';
import GridContainer from '../components/Grid/GridContainer';
import GridItem from '../components/Grid/GridItem';
// core components
import Header from '../components/Header/Header';
// import HeaderLinks from "components/Header/HeaderLinks.js";
import HeaderLinksCommunity from '../components/Header/HeaderLinksCommunity';

toast.configure();
let toasterOption = {
  position: 'top-right',
  autoClose: 2000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: false,
  draggable: true,
  progress: undefined,
};

const Icon = styled((props) => (
  <div {...props}>
    <div className="minus">
      <i className="fas fa-angle-up"></i>
    </div>
    <div className="plus">
      <i className="fas fa-angle-down"></i>
    </div>
  </div>
))`
  & > .plus {
    display: block;
    color: #4dc0e9;
    font-size: 20px;
  }
  & > .minus {
    display: none;
    color: #4dc0e9;
    font-size: 20px;
  }
  .Mui-expanded & > .minus {
    display: flex;
  }
  .Mui-expanded & > .plus {
    display: none;
  }
`;

const dashboardRoutes = [];

const useStyles = makeStyles(styles);

// Scroll to Top
function ScrollToTopOnMount() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return null;
}

export default function DiscussionDetail(props) {
  const classes = useStyles();
  const { ...rest } = props;
  const [expanded, setExpanded] = React.useState('panel1');
  const { id } = useParams();
  const [topicdetails, settopicdetails] = useState('');
  const [reply, setreply] = useState('');
  const [replyList, setreplyList] = useState([]);
  const [replycnt, setreplycnt] = useState(0);
  const [page, setpage] = useState(1);
  const [days, setdays] = useState('-');
  const [isLike, setisLike] = useState('unlike');
  const [likecnt, setlikecnt] = useState(0);
  const [catList, setcatList] = useState([]);
  const [likeList, setlikeList] = useState([]);

  useEffect(() => {
    gettopicdetails();
    getCategory();
    updateView();

    setTimeout(function () {
      getLikes();
    }, 1000);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const updateView = async (e) => {
    await updateViewCount({ discussionid: id });
  };

  const getLikes = async (e) => {
    let { list } = await mostLikes();
    setlikeList(list);
  };

  const getCategory = async (e) => {
    let result = await getcategory();
    if (result && result.result) {
      setcatList(result.result);
    }
  };

  const gettopicdetails = async (e) => {
    let { result, details, count, like } = await topicdetail({ id: id });

    if (result) {
      const oneDay = 24 * 60 * 60 * 1000;
      const firstDate = new Date(result.created_date);
      const secondDate = new Date();
      const diffDays = Math.round(Math.abs((firstDate - secondDate) / oneDay));

      if (diffDays > 0) {
        setdays(diffDays + ' days');
      } else {
        setdays('Recent');
      }
      setisLike(like);
      settopicdetails(result);
      setreplyList(details);
      setreplycnt(count);
      setlikecnt(result.likes_count);
    } else {
    }
  };

  const onEditorChange = (evt) => {
    var description_text = evt.editor.getData();
    setreply(description_text);
  };

  const replyTopic = async (e) => {
    e.preventDefault();
    if (reply && reply !== '') {
      let reqData = {
        reply,
        discussionid: id,
      };

      let { result, count } = await replytopic(reqData);

      if (result.reply && result.reply !== '') {
        replyList.push(result);
        setreplyList(replyList);
        setreplycnt(count);
        var objDiv = document.getElementById('scrolltop');
        objDiv.scrollTop = objDiv.scrollHeight;
        setreply('');
        window.location.reload();
      }
    } else {
      toast.error('Please enter message', toasterOption);
    }
  };

  const linkUnlike = async (e) => {
    let reqData = {
      discussionid: id,
    };

    let { status } = await likeCommunity(reqData);
    var cnt = 0;
    if (status === 'like') {
      cnt = parseFloat(likecnt) + parseFloat(1);
    } else {
      cnt = parseFloat(likecnt) - parseFloat(1);
    }

    setisLike(status);
    setlikecnt(cnt);
  };

  const handleScroll = async (e) => {
    var pos = $('#scrolltop').scrollTop();

    if (pos === 0) {
      let { details, skip } = await paginatediscussion({ id: id, page: page });

      if (details && details.length > 0) {
        var totalList = details.concat(replyList);
        setpage(skip);
        setreplyList(totalList);
        var elem = document.getElementById('scrolltop');
        elem.scrollTop = elem.scrollHeight;
      }
    }
  };

  const handleChangeFaq = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  return (
    <div className="inner_header">
      <Header
        fixed
        color="transparent"
        routes={dashboardRoutes}
        brand={
          <Link to="/home">
            <img src={require('../assets/images/logo.png')} alt="logo" className="img-fluid" />
          </Link>
        }
        rightLinks={<HeaderLinksCommunity />}
        changeColorOnScroll={{
          height: 50,
          color: 'dark',
        }}
        {...rest}
      />
      <ScrollToTopOnMount />
      <div className={classes.pageHeader + ' inner_pageheader pb-0'}>
        <div>
          <div className={classes.container}>
            <GridContainer>
              <GridItem xs={12} sm={12} md={12} lg={12} className="m-auto">
                <div className="d-flex align-items-center">
                  <Link to="/community" className="goback_link">
                    <i className="bi bi-chevron-left"></i>
                    <span>Go back</span>
                  </Link>
                </div>
              </GridItem>
            </GridContainer>

            <div className="row">
              <div className="col-12 col-md-8 pb-3 pb-md-5">
                <h2 className="inner_title">{topicdetails.topic}</h2>
                <Link to="/category">
                  <p className="cat_link cat_link_blue mb-0">{topicdetails.category_name}</p>
                </Link>
                <div className="mt-3">
                  <div className="faq_panel">
                    <Accordion
                      expanded={expanded === 'panel1'}
                      onChange={handleChangeFaq('panel1')}
                      className="mt-5 dis_accordion"
                    >
                      <AccordionSummary
                        expandIcon={<Icon />}
                        aria-controls="panel1bh-content"
                        id="panel1bh-header"
                        className="m-0 panel_header_dis px-2"
                      >
                        <div className="accordian_head w-100 dis_head">
                          <h2 className="inner_title d-flex justify-content-between">
                            <div className="media p-0">
                              <img
                                src={require('../assets/images/avatars/avatar8.jpg')}
                                alt="User"
                                className="img-fluid mr-3 align-self-center"
                              />
                              <div className="media-body">
                                <p className="mb-0 user_lh">{topicdetails.username}</p>
                                <p className="mb-0 text_gry d-flex align-items-center">
                                  {days} |{' '}
                                  <span className="masonry_likes">
                                    {localStorage.community_token && (
                                      <i
                                        className={isLike === 'like' ? 'fa-heart mr-2 far' : 'far fa-heart mr-2'}
                                        onClick={linkUnlike}
                                      ></i>
                                    )}
                                    {!localStorage.community_token && (
                                      <i className={isLike === 'like' ? 'fa-heart mr-2 far' : 'far fa-heart mr-2'}></i>
                                    )}
                                    <span className="mr-2 text_gry">{likecnt}</span>
                                  </span>{' '}
                                  | <i className="fas fa-link ml-2 icon_red"></i>
                                </p>
                              </div>
                            </div>
                            <div>
                              <span className="text_gry reply_count">{replycnt} replies</span>
                            </div>
                          </h2>
                        </div>
                      </AccordionSummary>

                      <AccordionDetails>
                        <div className="accordian_para">
                          <div className="desc_discussion pl-3 pl-lg-cus">
                            {topicdetails.description && <div>{ReactHtmlParser(topicdetails.description)}</div>}
                          </div>
                          <div
                            className="scrollable-content"
                            id="scrolltop"
                            style={{ overflow: 'auto' }}
                            onScroll={handleScroll}
                          >
                            {replyList &&
                              replyList.map((row, index) => {
                                return (
                                  <div className="desc_discussion pl-3 pl-lg-cus">
                                    <div className="media p-0">
                                      <img
                                        src={require('../assets/images/avatars/avatar8.jpg')}
                                        alt="User"
                                        className="img-fluid mr-3 align-self-center"
                                      />
                                      <div className="media-body">
                                        <p className="mb-0 reply_uer_name">{row.username}</p>
                                        {topicdetails.reply && <p className="mb-0">{ReactHtmlParser(row.reply)}</p>}
                                      </div>
                                    </div>
                                  </div>
                                );
                              })}
                          </div>

                          <div className="accordion_form p-3 px-lg-5 py-lg-4">
                            <p className="reply_txt">Reply</p>
                            <form className="login_form mt-3">
                              {/* <label htmlFor="reply_email">Usename / Email Address</label>
                              <div className="mb-3">
                                <input type="text" id="reply_email" className="form-control" placeholder="Usename / Email Address" />

                              </div> */}
                              <label htmlFor="password">Message</label>
                              <div className="my-3">
                                <CKEditor data="" onChange={onEditorChange} />
                              </div>
                              <div className="mt-3">
                                {localStorage.community_token && (
                                  <Button className="create_btn" onClick={replyTopic}>
                                    Reply
                                  </Button>
                                )}
                              </div>
                            </form>
                          </div>
                        </div>
                      </AccordionDetails>
                    </Accordion>
                  </div>
                </div>
              </div>
              <div className="col-12 col-md-4 border_left_md pb-3 pb-md-5">
                <p className="reply_txt">Category</p>
                <div className="pt-2 pb-5">
                  {catList &&
                    catList.map((row, index) => {
                      return (
                        <Link to={'/category/' + row._id}>
                          <p className="cat_link cat_link_blue mb-0">{row.name}</p>
                        </Link>
                      );
                    })}
                </div>

                {/*<p className="reply_txt">Recent Post</p>
                 <div className="pt-2 pb-5">
                  <Link to={"/topic-list#today"}>
                    <p className="post_link mb-0">Within 24 Hrs</p>
                  </Link>
                  <Link to={"/topic-list#week"}>
                    <p className="post_link active mb-0">This Week</p>
                  </Link>
                  <Link to={"/topic-list#month"}>
                    <p className="post_link mb-0">This month</p>
                  </Link>
                  <Link to={"/topic-list#3month"}>
                    <p className="post_link mb-0">Within 3 months</p>
                  </Link>
                </div>*/}

                <p className="reply_txt">Most Likes</p>
                <div className="pt-2 pb-5">
                  {likeList &&
                    likeList.map((row, index) => {
                      return (
                        <a href={'/discussion-detail/' + row._id}>
                          <p className="post_link mb-0">
                            {row.topic} - {row.likes_count}
                          </p>
                        </a>
                      );
                    })}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
