import { Box, Button } from '@material-ui/core';
// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';
import '@metamask/legacy-web3';
import $ from 'jquery';
import React, { useEffect, useState } from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import Datetime from 'react-datetime';
import 'react-datetime/css/react-datetime.css';
import { Link, useLocation } from 'react-router-dom';
import Select from 'react-select';
import { toast } from 'react-toastify';
import Web3 from 'web3';
import ABI from '../ABI/ABI.json';
import { getLegalAgreementOptions } from '../actions/items';
import {
  CreateTokenValidationAction,
  GetCategoryAction,
  ipfsImageHashGet,
  ipfsmetadatafuncitonNew,
  ListCollection,
  TokenAddItemAction,
  TokenAddOwnerAction,
  TokenCount_Get_Action,
} from '../actions/token';
import { getCurAddr } from '../actions/user';
import styles from '../assets/jss/material-kit-react/views/landingPage.js';
import Footer from '../components/Footer/Footer';
import GridContainer from '../components/Grid/GridContainer';
import GridItem from '../components/Grid/GridItem';
// core components
import Header from '../components/Header/Header';
import HeaderLinks from '../components/Header/HeaderLinks';
import config from '../lib/config';
import ConnectWallet from './separate/ConnectWallet';

toast.configure();
let toasterOption = config.toasterOption;

const multipleContract = config.multipleContract;
const SingleType = config.singleType;
const MultipleType = config.multipleType;

const dashboardRoutes = [];

const useStyles = makeStyles(styles);

// Scroll to Top
function ScrollToTopOnMount() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return null;
}

export default function CreateSingle(props) {
  const classes = useStyles();
  const { ...rest } = props;

  const [WalletConnected, Set_WalletConnected] = React.useState('false');
  const [UserAccountAddr, Set_UserAccountAddr] = React.useState('');
  const [AddressUserDetails, Set_AddressUserDetails] = useState({});
  const [Accounts, Set_Accounts] = React.useState('');
  const [ipfshash, setIpfsHash] = useState('');
  const [ipfshashurl, setipfshashurl] = useState('');
  const [ipfsmetatag, set_ipfsmetatag] = useState('');
  const [secondaryIpfs, setSecondaryIpfs] = useState('');

  const [biddingtoken, set_biddingtoken] = React.useState('');
  const [PutOnSaleType, setPutOnSaleType] = React.useState('FixedPrice');
  const [isPrivate, setIsPrivate] = React.useState(false);
  const [isOwnershipRights, setIsOwnershipRights] = React.useState(false);
  const [usageOption, setUsageOption] = useState({ option: '', text: '' });
  const [priceoptions] = React.useState([
    {
      value: 'BNB',
      label: 'BNB',
    },
    {
      value: 'ORE',
      label: 'ORE',
    },
  ]);
  const [legalAgreementsList, setLegalAgreementsList] = useState([]);
  var pathVal = '';
  const location = useLocation();
  if (location.pathname) {
    if (location.pathname.split('/').length >= 2) {
      pathVal = location.pathname.split('/')[1];
    }
  }
  const [location_pathname] = useState(pathVal);

  var CollectibleType_val = location_pathname === 'create-single' ? SingleType : MultipleType;
  var ContractAddressUser_val = location_pathname === 'create-single' ? config.singleContract : config.multipleContract;

  const [CollectibleType] = useState(CollectibleType_val);
  const [ContractAddressUser] = React.useState(ContractAddressUser_val);

  var myDate = new Date();
  var newdat = myDate.setDate(myDate.getDate() + parseInt(1));
  var initialEnd = new Date(newdat);

  const [StartDate, Set_StartDate] = React.useState('Right after listing');
  const [EndDate, Set_EndDate] = React.useState('1 Day');

  const [TokenQuantity, Set_TokenQuantity] = React.useState(1);

  const [TokenBid] = React.useState(true);

  const [MinimumBid, Set_MinimumBid] = React.useState(0);
  const [Clocktime, set_Clocktime] = React.useState('');
  const [EndClocktime, set_EndClocktime] = React.useState(initialEnd);

  const [UnLockcontent, Set_UnLockcontent] = React.useState('');
  const [Unlockoncepurchased, Set_Unlockoncepurchased] = React.useState(false);

  const [TokenCount, Set_TokenCount] = React.useState(0);

  const [ApproveCallStatus, setApproveCallStatus] = React.useState('init');
  const [MintCallStatus, setMintCallStatus] = React.useState('init');
  const [SignCallStatus, setSignCallStatus] = React.useState('init');
  const [SignLockCallStatus, setSignLockCallStatus] = React.useState('init');

  const [ValidateError, setValidateError] = React.useState({ TokenPrice: '' });

  const [PutOnSale, setPutOnSale] = React.useState(false);

  const [TokenCategory, setTokenCategory] = React.useState({ label: '' });
  const [CollectionCategory, setCollectionCategory] = React.useState({ label: '' });
  const [CategoryOption, setCategoryOption] = React.useState(0);
  const [TokenPrice, setTokenPrice] = React.useState(0);
  const [TokenName, setTokenName] = React.useState('');
  const [longDescription, setLongDescription] = React.useState('');
  const [shortDescription, setShortDescription] = React.useState('');
  const [TokenRoyalities, setTokenRoyalities] = React.useState('');
  const [TokenProperties, setTokenProperties] = React.useState('');

  const [TokenFile, setTokenFile] = React.useState('');
  const [TokenFilePreReader, setTokenFilePreReader] = React.useState('');
  const [TokenFilePreUrl, setTokenFilePreUrl] = React.useState('');

  const [secondaryFile, setSecondaryFile] = React.useState('');
  const [secondaryTokenFilePreReader, setSecondaryTokenFilePreReader] = React.useState('');
  const [secondaryTokenFilePreUrl, setSecondaryTokenFilePreUrl] = React.useState('');

  const [collectionList, setCollectionList] = React.useState([]);

  async function GetCategoryCall() {
    var resp = await GetCategoryAction();
    if (resp && resp.data && resp.data.list) {
      var CategoryOption = [];
      // eslint-disable-next-line array-callback-return
      resp.data.list.map((item) => {
        CategoryOption.push({
          name: 'TokenCategory',
          value: item._id,
          label: item.name,
        });
      });
      setCategoryOption(CategoryOption);
    }
  }

  const getCollecitonList = async (currAddress) => {
    const { data } = await ListCollection(currAddress);
    if (data.success) {
      let collectionList = [];
      // eslint-disable-next-line array-callback-return
      data.data.map((item) => {
        collectionList.push({
          name: 'CollectionCategory',
          value: item._id,
          label: item.name,
        });
      });
      setCollectionList(collectionList);
    } else {
      toast.error('Failed to get collection list', toasterOption);
      return null;
    }
  };

  const changePutOnSaleType = (type) => {
    setPutOnSaleType(type);
  };

  const CheckedChange = (e) => {
    if (e && e.target && e.target.name) {
      if (e.target.name === 'putonsale') {
        if (PutOnSale === false) {
          setPutOnSale(true);
          if (PutOnSaleType === '') {
            setPutOnSaleType('FixedPrice');
          }
        } else {
          setPutOnSale(false);
        }
      } else if (e.target.name === 'unlockoncepurchased') {
        if (Unlockoncepurchased === false) {
          Set_Unlockoncepurchased(true);
        } else {
          Set_Unlockoncepurchased(false);
        }
      } else if (e.target.name === 'isPrivate') {
        setIsPrivate(!isPrivate);
      } else if (e.target.name === 'rightsOwnership') {
        setIsOwnershipRights(!isOwnershipRights);
      }
    }
  };

  const selectFileChange = (e) => {
    var validExtensions = ['png', 'gif', 'webp', 'mp4', 'mp3', '.wmv', '.obj', '.fbx']; //array of valid extensions
    if (e.target && e.target.files) {
      var reader = new FileReader();
      var file = e.target.files[0];
      var fileName = file.name;
      var fileNameExt = fileName.substr(fileName.lastIndexOf('.') + 1);
      if ($.inArray(fileNameExt, validExtensions) === -1) {
        toast.error('Only these file types are accepted : ' + validExtensions.join(', '), toasterOption);
        $("input[name='image']").val('');
        return false;
      } else {
        const fileSize = file.size / 1024 / 1024;
        if (fileSize > 100) {
          toast.error('File size exceeds 100 MB', toasterOption);
          $("input[name='image']").val('');
          return false;
        } else {
          setTokenFile(file);
          reader.readAsDataURL(file);
          reader.onloadend = function (e) {
            if (reader.result) {
              setTokenFilePreReader(reader.result);
            }
          };
          setTokenFilePreUrl(e.target.value);
        }
      }
    }
  };

  const selectSecondaryFileChange = (e) => {
    var validExtensions = ['png', 'gif', 'webp', 'mp4', 'mp3', 'wmv', 'obj', 'fbx']; //array of valid extensions
    if (e.target && e.target.files) {
      var reader = new FileReader();
      var file = e.target.files[0];
      var fileName = file.name;
      var fileNameExt = fileName.substr(fileName.lastIndexOf('.') + 1);
      if ($.inArray(fileNameExt, validExtensions) === -1) {
        toast.error('Only these file types are accepted : ' + validExtensions.join(', '), toasterOption);
        $("input[name='image']").val('');
        return false;
      } else {
        const fileSize = file.size / 1024 / 1024;
        if (fileSize > 30) {
          toast.error('File size exceeds 30 MB', toasterOption);
          $("input[name='image']").val('');
          return false;
        } else {
          setSecondaryFile(file);
          reader.readAsDataURL(file);
          reader.onloadend = function (e) {
            if (reader.result) {
              setSecondaryTokenFilePreReader(reader.result);
            }
          };
          setSecondaryTokenFilePreUrl(e.target.value);
        }
      }
    }
  };

  const selectChange = (e) => {
    if (e && e.name && e.label && e.value) {
      switch (e.name) {
        case 'TokenCategory':
          setTokenCategory(e);
          break;
        case 'CollectionCategory':
          setCollectionCategory(e);
          break;
        default:
        // code block
      }
    }
  };

  const SelectBidcurrency = (e) => {
    set_biddingtoken(e.value);
  };

  const onKeyUp = (e) => {
    // var charCode = e.keyCode;
    // if ((charCode > 47 && charCode < 58) || (charCode > 95 && charCode < 106)) {
    //   var ValidateError = {};
    //   setValidateError(ValidateError);
    // } else {
    //   ValidateError.TokenQuantity = '"Quantity" must be a number';
    //   Set_TokenQuantity('');
    //   setValidateError(ValidateError);
    // }
  };
  const inputChange = (e) => {
    if (e && e.target && typeof e.target.value !== 'undefined' && e.target.name) {
      var value = e.target.value;
      switch (e.target.name) {
        case 'TokenPrice':
          if (value !== '' && isNaN(value) === false && value > 0) {
            setTokenPrice(value);
            ValidateError.TokenPrice = '';
            setValidateError(ValidateError);
          } else {
            ValidateError.TokenPrice = 'Enter Valid price';
            setValidateError(ValidateError);
            setTokenPrice('0');
          }
          break;
        case 'TokenName':
          setTokenName(value);
          break;
        case 'longDescription':
          setLongDescription(value);
          break;
        case 'shortDescription':
          setShortDescription(value);
          break;
        case 'TokenRoyalities':
          setTokenRoyalities(value);
          break;
        case 'TokenProperties':
          setTokenProperties(value);
          break;
        case 'UnLockcontent':
          Set_UnLockcontent(value);
          break;
        case 'MinimumBid':
          Set_MinimumBid(value);
          break;
        case 'TokenQuantity':
          Set_TokenQuantity(value);
          break;
        default:
        // code block
      }
      // CreateItemValidation();
    }
  };

  async function ApproveCall() {
    if (!window.ethereum) {
      toast.warning('OOPS!..connect Your Wallet', toasterOption);
      return false;
    }
    var web3 = new Web3(window.ethereum);
    var currAddr = window.web3.eth.defaultAccount;
    if (!currAddr) {
      toast.warning('OOPS!..connect Your Wallet', toasterOption);
      return false;
    }

    const CoursetroContract = new web3.eth.Contract(ABI, multipleContract);

    setApproveCallStatus('processing');

    // alert(TokenPrice);

    await CoursetroContract.methods
      .setApproval(ContractAddressUser, true)
      .send({
        from: Accounts,
      })
      .then(async (result) => {
        var tokenid =
          result &&
          result.events &&
          result.events.Approve &&
          result.events.Approve.returnValues &&
          result.events.Approve.returnValues[1]
            ? parseFloat(result.events.Approve.returnValues[1])
            : 0;
        if (tokenid > 0) {
          Set_TokenCount(tokenid);
          var senddata = {
            Image: TokenFile,
            tokenid: tokenid,
            TokenName: TokenName,
            secondaryContent: secondaryFile ? secondaryFile : null,
            category: secondaryFile ? TokenCategory : '',
          };
          var ipfsimghashget = await ipfsImageHashGet(senddata);
          if (ipfsimghashget.data !== undefined) {
            if (ipfsimghashget.data.ipfsval !== '') {
              setIpfsHash(ipfsimghashget.data.ipfsval);
              setipfshashurl(`${config.IPFS_IMG}/${ipfsimghashget.data.ipfsval}`);
              if (ipfsimghashget.data.secondaryContentHash) {
                setSecondaryIpfs(ipfsimghashget.data.secondaryContentHash);
              }
            }
          }

          var newobj = {
            TokenName: TokenName,
            tokenId: tokenid,
            image: config.IPFS_IMG + '/' + ipfsimghashget.data.ipfsval,
          };

          var ipfsmetatag = await ipfsmetadatafuncitonNew(newobj);

          if (ipfsmetatag.data !== undefined) {
            if (ipfsmetatag.data.ipfsval !== '') {
              set_ipfsmetatag(`${config.IPFS_IMG}/${ipfsmetatag.data.ipfsval}`);
            }
          }

          // set_ipfsmetatag(`${config.IPFS_IMG}/${ipfsimghashget.data.ipfsval}`)

          toast.success('Approve Successfully', toasterOption);
          setApproveCallStatus('done');
        } else {
          toast.error('Approve failed', toasterOption);
          setApproveCallStatus('tryagain');
        }
      })
      .catch((error) => {
        toast.error('Approve failed', toasterOption);
        setApproveCallStatus('tryagain');
      });
  }

  async function MintCall() {
    if (!window.ethereum) {
      toast.warning('OOPS!..connect Your Wallet', toasterOption);
      return false;
    }
    var web3 = new Web3(window.ethereum);
    var currAddr = window.web3.eth.defaultAccount;
    var CurrAddr = window.web3.eth.defaultAccount;
    if (!currAddr) {
      toast.warning('OOPS!..connect Your Wallet', toasterOption);
      return false;
    }

    const CoursetroContract = new web3.eth.Contract(ABI, multipleContract);
    const TokenPriceInStr = window.web3.toWei(TokenPrice).toString();
    setMintCallStatus('processing');
    await CoursetroContract.methods
      .mint(TokenCount, TokenPriceInStr, TokenRoyalities, TokenQuantity, TokenName, ipfshashurl, ipfsmetatag)
      .send({ from: Accounts })
      .then(async (result) => {
        var transactionHash = '';
        if (result && result.transactionHash) {
          transactionHash = result.transactionHash;
          // setTimeout(() => {
          toast.success('Your Token Added Successfully', toasterOption);
          var Status = 'true';
          var TokenAddItemPayload = {
            biddingtoken: biddingtoken,
            Image: TokenFile,
            ipfsimage: ipfshash,
            Name: TokenName,
            Count: TokenCount,
            longDescription,
            shortDescription,
            Price: TokenPrice,
            Royalities: TokenRoyalities,
            Category_label: TokenCategory.label,
            Bid: TokenBid,
            Properties: TokenProperties,
            Owner: CurrAddr,
            Creator: CurrAddr,
            CategoryId: TokenCategory.value,
            CollectionLabel: CollectionCategory.label,
            CollectionId: CollectionCategory.value,
            Quantity: TokenQuantity,
            Balance: TokenQuantity,
            ContractAddress: multipleContract,
            Status: Status,
            HashValue: transactionHash,
            Type: CollectibleType,
            MinimumBid: 0,
            Clocktime: '',
            EndClocktime: '',
            UnLockcontent: '',
            PutOnSale: PutOnSale,
            PutOnSaleType: PutOnSaleType,
            isPrivate,
            isMinting: isOwnershipRights,
            legalAgreement: usageOption,
            secondaryFileHash: secondaryIpfs ? secondaryIpfs : '',
            secondaryFile: secondaryFile ? secondaryFile : '',
          };
          if (Unlockoncepurchased === true) {
            TokenAddItemPayload.UnLockcontent = UnLockcontent;
          }

          if (PutOnSale === true) {
            if (PutOnSaleType === 'FixedPrice') {
              TokenAddItemPayload.Price = TokenPrice;
            } else if (PutOnSaleType === 'TimedAuction') {
              TokenAddItemPayload.MinimumBid = MinimumBid;
              TokenAddItemPayload.Clocktime = Clocktime;
              TokenAddItemPayload.EndClocktime = EndClocktime;
              TokenAddItemPayload.Price = 0;
            }
          }
          await TokenAddItemAction(TokenAddItemPayload);
          // if(CollectionStatus==1){
          //   ContractAddressUser=digitalContract
          // }else{
          //   ContractAddressUser=ContractAddressUser
          //   }
          var TokenAddOwnerPayload = {
            Count: TokenCount,
            Price: TokenPrice,
            Owner: CurrAddr,
            Balance: TokenQuantity,
            Quantity: TokenQuantity,
            ContractAddress: ContractAddressUser,
            Type: CollectibleType,
            HashValue: transactionHash,
            Status: Status,
            PutOnSaleType: PutOnSaleType,
          };
          if (PutOnSaleType === 'FixedPrice') {
            TokenAddOwnerPayload.Price = TokenPrice;
          }
          await TokenAddOwnerAction(TokenAddOwnerPayload);
          setMintCallStatus('done');
          // })
          // }, 1500);
        } else {
          toast.error('Mint not Successfully', toasterOption);
          setMintCallStatus('tryagain');
        }
      })
      .catch((error) => {
        toast.error('Mint not Successfully', toasterOption);
        setMintCallStatus('tryagain');
      });
  }

  async function SignCall() {
    if (!window.ethereum) {
      toast.warning('OOPS!..connect Your Wallet', toasterOption);
    }
    var web3 = new Web3(window.ethereum);
    var currAddr = window.web3.eth.defaultAccount;
    if (!currAddr) {
      toast.warning('OOPS!..connect Your Wallet', toasterOption);
    }

    setSignCallStatus('processing');
    web3.eth.personal
      .sign('Sign Sell Order', currAddr)
      .then(async (result) => {
        toast.success('Sign Successfully', toasterOption);
        setSignCallStatus('done');
      })
      .catch(() => {
        toast.error('Sign Failed', toasterOption);
        setSignCallStatus('tryagain');
      });
  }

  async function SignLockCall() {
    if (!window.ethereum) {
      toast.warning('OOPS!..connect Your Wallet', toasterOption);
    }
    var web3 = new Web3(window.ethereum);
    var currAddr = window.web3.eth.defaultAccount;
    if (!currAddr) {
      toast.warning('OOPS!..connect Your Wallet', toasterOption);
    }

    setSignLockCallStatus('processing');
    web3.eth.personal
      .sign('Sign Lock Order', currAddr)
      .then(async (result) => {
        toast.success('Sign Lock Order Successfully', toasterOption);
        setSignLockCallStatus('done');
        setTimeout(() => window.$('#create_item_modal').modal('hide'), 600);
        setTimeout(() => (window.location.href = config.Front_URL + '/my-items'), 1200);
      })
      .catch(() => {
        toast.error('Sign Failed', toasterOption);
        setSignLockCallStatus('tryagain');
      });
  }

  async function CreateItemValidation(chk) {
    if (chk) {
      var ValidateError = {};

      if (TokenName === '') {
        ValidateError.TokenName = '"Name" is not allowed to be empty';
      }

      if (TokenRoyalities === '') {
        ValidateError.TokenRoyalities = '"Royalties" is not allowed to be empty';
      } else if (isNaN(TokenRoyalities) === true) {
        ValidateError.TokenRoyalities = '"Royalties" must be a number';
      } else if (TokenRoyalities > 50) {
        ValidateError.TokenRoyalities = '"Royalties" must be less than or equal to 50';
      }

      // TokenFilePreReader
      // TokenFilePreUrl
      if (
        TokenFilePreUrl === '' ||
        TokenFilePreUrl === null ||
        TokenFilePreUrl === undefined ||
        TokenFilePreUrl === ''
      ) {
        ValidateError.TokenFilePreUrl = '"File" is required';
      }

      if (typeof TokenCategory.label === 'undefined' || TokenCategory.label === '') {
        ValidateError.TokenCategory = '"Category" is required';
      }

      // if (typeof CollectionCategory.label === 'undefined' || CollectionCategory.label === '') {
      //   ValidateError.CollectionCategory = '"Collection Category" is required';
      // }

      if (Unlockoncepurchased && UnLockcontent === '') {
        ValidateError.UnLockcontent = '"Locked content" is required';
      }
      if (PutOnSale === true && PutOnSaleType === 'FixedPrice') {
        if (biddingtoken === '') {
          ValidateError.biddingtoken = '"Currency" Should be Selected';
        }
        if (TokenPrice === '' || (isNaN(TokenPrice) === true && TokenPrice <= 0)) {
          ValidateError.TokenPrice = '"Price" must be a number';
        } else if (TokenPrice === 0) {
          ValidateError.TokenPrice = '"Price" must be greater than zero';
        }
      }

      if (PutOnSale === true && PutOnSaleType === 'TimedAuction') {
        if (MinimumBid === '') {
          ValidateError.MinimumBid = '"Bid Price" must be a number';
        }
      }

      if (TokenQuantity === '' || isNaN(TokenQuantity) === true) {
        ValidateError.TokenQuantity = '"Number of copies" must be a number & greater than zero';
      } else if (TokenQuantity === 0) {
        ValidateError.TokenQuantity = '"Number of copies" must be greater than zero';
      }

      if (longDescription && longDescription.length > 1000) {
        ValidateError.longDescription = '"Description" must be less than 1000 characters';
      }
      if (shortDescription && shortDescription.length > 75) {
        ValidateError.shortDescription = '"Short Description" must be less than 75 characters';
      }
      if (!isOwnershipRights) {
        ValidateError.ownershipRights = '"Ownership rights should be checked"';
      }
      setValidateError(ValidateError);
      return ValidateError;
    } else {
      return {};
    }
  }

  async function DateChange(from, val) {
    if (from === 'StartDateDropDown') {
      if (val === 'PickStartDate') {
        ModalAction('calendar_modal', 'show');
      } else {
        Set_StartDate('Right after listing');
      }
    } else if (from === 'EndDateDropDown') {
      if (val === 'PickEndDate') {
        ModalAction('calendar_modal_expire', 'show');
      } else {
        Set_EndDate(val);
        var myDate = new Date();
        var newdat = myDate.setDate(myDate.getDate() + parseInt(val));
        var newdate = new Date(newdat);
        set_EndClocktime(newdate);
      }
    }
  }

  async function ModalAction(id, type) {
    window.$('#' + id).modal(type);
    let dt, dt1;
    if (id === 'calendar_modal') {
      if (Clocktime) {
        dt = new Date(Clocktime);
        dt.setSeconds(0);
        dt1 = dt.toLocaleString();
        Set_StartDate(dt1);
      }
    } else if (id === 'calendar_modal_expire') {
      if (EndClocktime) {
        dt = new Date(EndClocktime);
        dt.setSeconds(0);
        dt1 = dt.toLocaleString();
        Set_EndDate(dt1);
      }
    }
  }

  async function CreateItem() {
    var connectwallet = localStorage.getItem('jakcojsnick');
    if (!connectwallet) {
      window.location.href = '/connect';
      return false;
    }
    var errors = await CreateItemValidation(true);
    var errorsSize = Object.keys(errors).length;

    if (errorsSize !== 0) {
      toast.error('Form validation error. Fix all mistakes and submit again', toasterOption);
      return false;
    } else if (window.ethereum) {
      if (typeof window.web3 === 'undefined' || typeof window.web3.eth === 'undefined') {
        toast.error('Please Connect to Binance Network', toasterOption);
        Set_WalletConnected(false);
      } else {
        var currAddr = window.web3.eth.defaultAccount;
        window.web3.eth.getBalance(currAddr, async (errors, balance) => {
          var usercurbal = 0;
          if (typeof balance === 'undefined' || balance === null) {
          } else {
            usercurbal = balance / config.decimalvalues;
          }
          if (usercurbal === 0) {
            toast.error('Insufficient balance', toasterOption);
            return false;
          }
          // else if(usercurbal < TokenPrice) {
          //   toast.error("Insufficient balance", toasterOption);
          //   return false;
          // }

          var TokenCategory_label = TokenCategory.label;

          let payload = {
            TokenName,
            TokenRoyalities,
            image: TokenFile,
            TokenCategory_label,
            PutOnSaleType,
            collectionCategory: CollectionCategory.value,
            TokenPrice,
            TokenQuantity,
            biddingtoken,
          };
          const resp = await CreateTokenValidationAction(payload);
          if (resp && resp.data) {
            if (resp.data.errors) {
              errors = resp.data.errors;
              var errorsSize = Object.keys(errors).length;
              if (errorsSize !== 0) {
                setValidateError(errors);
                toast.error('Form validation error. Fix all mistakes and submit again', toasterOption);
              } else {
                setValidateError({});
                window.$('#create_item_modal').modal('show');
              }
            }
          }
        });
      }
    } else {
      toast.error('Please Connect to Binance Network', toasterOption);
      Set_WalletConnected(false);
    }
  }

  const AfterWalletConnected = async () => {
    var currAddr = await getCurAddr();
    if (currAddr) {
      GetCategoryCall();
      TokenCount_Get_Call();
      getCollecitonList(currAddr);
    }
  };

  async function TokenCount_Get_Call() {
    var Resp = await TokenCount_Get_Action();
    if (Resp && Resp.data && Resp.data.tokenId) {
      // Set_TokenCount(Resp.data.tokenId);
    }
  }

  const getLegalsList = async () => {
    const response = await getLegalAgreementOptions();
    if (response?.data && response?.data?.legals) {
      setLegalAgreementsList(response.data.legals);
    } else {
      setLegalAgreementsList([]);
    }
  };
  useEffect(() => {
    getLegalsList();
  }, []);
  return (
    <div className="inner_header">
      <ConnectWallet
        Set_UserAccountAddr={Set_UserAccountAddr}
        Set_WalletConnected={Set_WalletConnected}
        Set_AddressUserDetails={Set_AddressUserDetails}
        Set_Accounts={Set_Accounts}
        WalletConnected={WalletConnected}
        AfterWalletConnected={AfterWalletConnected}
      />
      <Header
        fixed
        color="transparent"
        routes={dashboardRoutes}
        brand={<img src={require('../assets/images/logo.png')} alt="logo" className="img-fluid" />}
        rightLinks={<HeaderLinks />}
        changeColorOnScroll={{
          height: 50,
          color: 'dark',
        }}
        {...rest}
      />
      <ScrollToTopOnMount />
      <div className={classes.pageHeader + ' inner_pageheader'}>
        <div className={classes.container}>
          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              <div className="d-flex align-items-center">
                <Link to="/create">
                  <img src={require('../assets/images/arrow_icon.png')} alt="logo" className="arrow_icon" />
                </Link>
                {CollectibleType === 721 ? (
                  <h2 className="inner_title">Create Single Collectible</h2>
                ) : (
                  <h2 className="inner_title">Create Multiple Collectible</h2>
                )}
              </div>
            </GridItem>
          </GridContainer>
        </div>
        <div className="container">
          <GridContainer>
            <GridItem xs={12} sm={4} md={3}>
              <label className="primary_label">Preview</label>
              <div className="single_collectible">
                <div className="card mb-0">
                  <a className="card__cover">
                    {TokenFilePreUrl.split('.').pop() === 'mp4' ? (
                      <video
                        id="imgPreview"
                        src={
                          TokenFilePreReader !== '' ? TokenFilePreReader : require('../assets/images/cover/cover3.jpg')
                        }
                        type="video/mp4"
                        alt="Collection"
                        className="img-fluid"
                        autoPlay
                        playsInLine
                        loop
                        muted
                      ></video>
                    ) : TokenFilePreUrl.split('.').pop() === 'mp3' ? (
                      <audio
                        src={
                          TokenFilePreReader !== '' ? TokenFilePreReader : require('../assets/images/cover/cover3.jpg')
                        }
                        type="audio/mp3"
                        controls
                        className="audio"
                      ></audio>
                    ) : (
                      <img
                        src={
                          TokenFilePreReader !== '' ? TokenFilePreReader : require('../assets/images/cover/cover3.jpg')
                        }
                        id="imgPreview"
                        alt="Collections"
                        className="img-fluid"
                      />
                    )}
                    {TokenFilePreUrl.split('.').pop() === 'mp3' && (
                      <img
                        src={config.Back_URL + '/images/music.png'}
                        id="imgPreview"
                        alt="Collections"
                        className="img-fluid"
                      />
                    )}
                  </a>
                  <div className="card__author">
                    {AddressUserDetails &&
                    AddressUserDetails.image !== undefined &&
                    AddressUserDetails.image !== '' &&
                    AddressUserDetails.image !== null ? (
                      <img src={AddressUserDetails.image} alt="" />
                    ) : (
                      <img src={require('../assets/images/avatars/avatar13.jpg')} alt="" />
                    )}
                    <a href="#">
                      {AddressUserDetails && AddressUserDetails.name !== undefined
                        ? AddressUserDetails.name
                        : UserAccountAddr}
                    </a>
                  </div>
                  <div className="card__info">
                    <div className="card__price">
                      <span>{TokenName}</span>
                      <span>
                        {(PutOnSale === false ||
                          (PutOnSale === true && PutOnSaleType === 'FixedPrice' && TokenPrice === 0)) && (
                          <span>Not for sale </span>
                        )}
                        {PutOnSale === true && PutOnSaleType === 'FixedPrice' && TokenPrice > 0 && (
                          <span>
                            {TokenPrice} {biddingtoken + '  '}
                          </span>
                        )}
                        {PutOnSale === true && PutOnSaleType === 'TimedAuction' && <span>Minimum Bid </span>}
                        {PutOnSale === true && PutOnSaleType === 'UnLimitedAuction' && <span>Open for Bids </span>}
                        {TokenQuantity} of {TokenQuantity}
                      </span>
                      <span>
                        {PutOnSale === true && PutOnSaleType === 'TimedAuction' && (
                          <span>
                            {MinimumBid === '' ? 0 : MinimumBid} {biddingtoken}{' '}
                          </span>
                        )}
                        {PutOnSaleType !== 'TimedAuction' && <span>Place a bid</span>}
                      </span>
                    </div>

                    <button className="card__likes card__likes--active" type="button">
                      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                        <path d="M20.16,5A6.29,6.29,0,0,0,12,4.36a6.27,6.27,0,0,0-8.16,9.48l6.21,6.22a2.78,2.78,0,0,0,3.9,0l6.21-6.22A6.27,6.27,0,0,0,20.16,5Zm-1.41,7.46-6.21,6.21a.76.76,0,0,1-1.08,0L5.25,12.43a4.29,4.29,0,0,1,0-6,4.27,4.27,0,0,1,6,0,1,1,0,0,0,1.42,0,4.27,4.27,0,0,1,6,0A4.29,4.29,0,0,1,18.75,12.43Z" />
                      </svg>
                      <span>0</span>
                    </button>
                  </div>
                </div>
              </div>
              {secondaryFile && (
                <div className="single_collectible">
                  <div className="card mb-0">
                    <p>Unlocked Once Purchased</p>

                    <a className="card__cover">
                      {TokenFilePreUrl.split('.').pop() === 'mp4' ? (
                        <video
                          id="imgPreview"
                          src={
                            secondaryTokenFilePreReader !== ''
                              ? secondaryTokenFilePreReader
                              : require('../assets/images/cover/cover3.jpg')
                          }
                          type="video/mp4"
                          alt="Collection"
                          className="img-fluid"
                          autoPlay
                          playsInLine
                          loop
                          muted
                        ></video>
                      ) : TokenFilePreUrl.split('.').pop() === 'mp3' ? (
                        <audio
                          src={
                            secondaryTokenFilePreReader !== ''
                              ? secondaryTokenFilePreReader
                              : require('../assets/images/cover/cover3.jpg')
                          }
                          type="audio/mp3"
                          controls
                          className="audio"
                        ></audio>
                      ) : (
                        <img
                          src={
                            secondaryTokenFilePreReader !== ''
                              ? secondaryTokenFilePreReader
                              : require('../assets/images/cover/cover3.jpg')
                          }
                          id="imgPreview"
                          alt="Collections"
                          className="img-fluid"
                        />
                      )}
                      {secondaryTokenFilePreUrl.split('.').pop() === 'mp3' && (
                        <img
                          src={config.Back_URL + '/images/music.png'}
                          id="imgPreview"
                          alt="Collections"
                          className="img-fluid"
                        />
                      )}
                    </a>
                    <div className="card__author">
                      {AddressUserDetails &&
                      AddressUserDetails.image !== undefined &&
                      AddressUserDetails.image !== '' &&
                      AddressUserDetails.image !== null ? (
                        <img src={AddressUserDetails.image} alt="" />
                      ) : (
                        <img src={require('../assets/images/avatars/avatar13.jpg')} alt="" />
                      )}
                      <a href="#">
                        {AddressUserDetails && AddressUserDetails.name !== undefined
                          ? AddressUserDetails.name
                          : UserAccountAddr}
                      </a>
                    </div>
                  </div>
                </div>
              )}
            </GridItem>
            <GridItem xs={12} sm={8} md={9}>
              <form>
                <div className="form-row">
                  <div className="form-group col-md-6">
                    <div className="d-flex justify-content-between align-items-start">
                      <div>
                        <label className="primary_label" htmlFor="inputEmail4">
                          Upload file
                        </label>
                        <p className="form_note">PNG, GIF, WEBP, MP4 or MP3.</p>
                      </div>
                      <div className="file_btn primary_btn">
                        Upload
                        <input className="inp_file" type="file" name="image" onChange={selectFileChange} />
                      </div>
                    </div>
                    {ValidateError.TokenFilePreUrl && (
                      <span className="text-danger">{ValidateError.TokenFilePreUrl}</span>
                    )}
                  </div>
                </div>
                <div className="form-row">
                  <div className="form-group col-md-6">
                    <div className="d-flex justify-content-between align-items-start">
                      <div>
                        <label className="primary_label" htmlFor="inputEmail4">
                          Put on Sale
                        </label>
                        <p className="form_note">You’ll receive bids on this item</p>
                      </div>
                      <label className="switch toggle_custom">
                        <input
                          type="checkbox"
                          id="putonsale"
                          name="putonsale"
                          onChange={CheckedChange}
                          checked={PutOnSale}
                        />
                        <span className="slider"></span>
                      </label>
                    </div>
                  </div>
                  <div className="form-group col-md-6">
                    <div className="d-flex justify-content-between align-items-start">
                      <div>
                        <label className="primary_label" htmlFor="inputEmail4">
                          Unlock Once Purchased
                        </label>
                        <p className="form_note">
                          Content will be unlocked after
                          <br /> successful transaction
                        </p>
                      </div>
                      <label className="switch toggle_custom">
                        <input
                          type="checkbox"
                          id="unlockoncepurchased"
                          name="unlockoncepurchased"
                          onChange={CheckedChange}
                        />
                        <span className="slider"></span>
                      </label>
                    </div>
                  </div>
                </div>

                <div className="form-row mb-5">
                  {PutOnSale && (
                    <div className={CollectibleType === 721 ? 'form-group col-md-12' : 'form-group col-md-12'}>
                      <div className="d-flex align-items-start">
                        <Button
                          className={
                            PutOnSaleType === 'FixedPrice' ? 'active nft_create_btn mr-4' : 'nft_create_btn mr-4'
                          }
                          onClick={() => {
                            changePutOnSaleType('FixedPrice');
                          }}
                        >
                          Fixed Price
                        </Button>
                        {CollectibleType === 721 && (
                          <Button
                            className={
                              PutOnSaleType === 'TimedAuction' ? 'active nft_create_btn mr-4' : 'nft_create_btn mr-4'
                            }
                            onClick={() => {
                              changePutOnSaleType('TimedAuction');
                            }}
                          >
                            Timed Auction
                          </Button>
                        )}
                        <Button
                          className={PutOnSaleType === 'UnLimitedAuction' ? 'active nft_create_btn' : 'nft_create_btn'}
                          onClick={() => {
                            changePutOnSaleType('UnLimitedAuction');
                          }}
                        >
                          Ongoing Auction
                        </Button>
                      </div>
                    </div>
                  )}
                  {Unlockoncepurchased && (
                    <>
                      <div className="form-group col-md-6">
                        <input
                          type="text"
                          className="form-control primary_inp"
                          id="UnLockcontent"
                          onChange={inputChange}
                          name="UnLockcontent"
                          placeholder="Digital key,code to redeem or link to a file..."
                        />
                        <p className="form_note">Tip: Markdown syntax is supported</p>
                      </div>

                      <div className="form-group col-md-6">
                        <div className="d-flex justify-content-between align-items-start">
                          <div>
                            <label className="primary_label" htmlFor="inputEmail4">
                              Upload secondary file
                            </label>
                            <p className="form_note">PNG, GIF, WEBP, MP4, MP3, WMV, OBJ, or FBX.</p>
                          </div>
                          <Box sx={{ width: 100 }}>
                            <div className="file_btn primary_btn">
                              Upload
                              <input
                                className="inp_file"
                                type="file"
                                name="secondaryimage"
                                onChange={selectSecondaryFileChange}
                              />
                            </div>
                          </Box>
                        </div>
                        {ValidateError.TokenFilePreUrl && (
                          <span className="text-danger">{ValidateError.TokenFilePreUrl}</span>
                        )}
                      </div>
                    </>
                  )}
                </div>

                {PutOnSale === true && PutOnSaleType === 'FixedPrice' && (
                  <div className="form-row">
                    <div className="form-group col-md-6">
                      <label className="primary_label" htmlFor="name">
                        Instant Price
                      </label>
                      <div className="input-group">
                        <input
                          type="text"
                          className="form-control primary_inp"
                          id="TokenPrice"
                          name="TokenPrice"
                          onChange={inputChange}
                          placeholder="Enter the price of one piece"
                        />
                        <div className="input-group-append selectCrypto">
                          <Select
                            className="form-control primary_inp custom-select"
                            id="TokenCategory"
                            name="TokenCategory"
                            onChange={SelectBidcurrency}
                            options={priceoptions}
                            label="Single select"
                            formControlProps={{
                              fullWidth: true,
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                )}

                {ValidateError.TokenPrice && (
                  <span className="text-danger">
                    <br />
                    {ValidateError.TokenPrice}
                  </span>
                )}
                {ValidateError.biddingtoken && (
                  <span className="text-danger">
                    <br />
                    {ValidateError.biddingtoken}
                  </span>
                )}

                {PutOnSale === true && PutOnSaleType === 'TimedAuction' && (
                  <div className="form-row">
                    <div className="form-group col-md-6">
                      <label className="primary_label" htmlFor="minimumbid">
                        Minimum bid
                      </label>
                      <input
                        type="text"
                        className="form-control primary_inp"
                        id="MinimumBid"
                        name="MinimumBid"
                        onChange={inputChange}
                        placeholder="Enter minimum bid"
                      />
                    </div>
                    <div className="form-group col-md-6">
                      <div className="form-row">
                        <div className="form-group col-md-6">
                          <label className="primary_label" htmlFor="minimumbid">
                            Starting Date
                          </label>
                          <div className="dropdown">
                            <button
                              className="btn btn-secondary dropdown-toggle filter_btn"
                              type="button"
                              id="dropdownMenuButton"
                              data-toggle="dropdown"
                              aria-haspopup="true"
                              aria-expanded="false"
                            >
                              <span id="menuBut">{StartDate}</span>
                              <i className="fas fa-chevron-down"></i>
                            </button>
                            <div className="dropdown-menu filter_menu" aria-labelledby="dropdownMenuButton">
                              <div
                                id="RightAfterListing"
                                onClick={() => {
                                  DateChange('StartDateDropDown', 'RightAfterListing');
                                }}
                              >
                                Right after listing
                              </div>
                              <div
                                id="PickStart"
                                onClick={() => {
                                  DateChange('StartDateDropDown', 'PickStartDate');
                                }}
                              >
                                Pick specific date
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="form-group col-md-6">
                          <label className="primary_label" htmlFor="minimumbid">
                            Expiration Date
                          </label>
                          <div className="dropdown">
                            <button
                              className="btn btn-secondary dropdown-toggle filter_btn"
                              type="button"
                              id="dropdownMenuButton"
                              data-toggle="dropdown"
                              aria-haspopup="true"
                              aria-expanded="false"
                            >
                              <span id="menuBut">{EndDate}</span>
                              <i className="fas fa-chevron-down"></i>
                            </button>
                            <div className="dropdown-menu filter_menu" aria-labelledby="dropdownMenuButton">
                              <div
                                onClick={() => {
                                  DateChange('EndDateDropDown', '1 Day');
                                }}
                              >
                                1 Day
                              </div>
                              <div
                                onClick={() => {
                                  DateChange('EndDateDropDown', '3 Day');
                                }}
                              >
                                3 Day
                              </div>
                              <div
                                onClick={() => {
                                  DateChange('EndDateDropDown', '5 Day');
                                }}
                              >
                                5 Day
                              </div>
                              <div
                                onClick={() => {
                                  DateChange('EndDateDropDown', '7 Day');
                                }}
                              >
                                7 Day
                              </div>
                              <div
                                onClick={() => {
                                  DateChange('EndDateDropDown', 'PickEndDate');
                                }}
                              >
                                Pick specific date
                              </div>
                            </div>
                            {ValidateError.EndDate && <span className="text-danger">{ValidateError.EndDate}</span>}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}

                <div
                  className="modal fade primary_modal"
                  id="calendar_modal"
                  tabIndex="-1"
                  role="dialog"
                  aria-labelledby="calendar_modalCenteredLabel"
                  aria-hidden="true"
                >
                  <div className="modal-dialog modal-dialog-centered modal-sm" role="document">
                    <div className="modal-content">
                      <div className="modal-module">
                        <div className="modal-header text-center">
                          <h5 className="modal-title" id="calendar_modalLabel">
                            Choose date
                          </h5>
                          <button
                            type="button"
                            className="close"
                            data-dismiss="modal"
                            aria-label="Close"
                            id="cancelcalender"
                          >
                            <span aria-hidden="true">&times;</span>
                          </button>
                        </div>
                        <div className="modal-body">
                          <div className="pb-3">
                            <Datetime
                              minDate={new Date().getTime()}
                              input={false}
                              value={Clocktime}
                              onChange={(value) => set_Clocktime(value)}
                            />
                          </div>
                        </div>
                        <div className="text-center">
                          <Button
                            className="primary_btn"
                            id="doneStartDate"
                            onClick={() => ModalAction('calendar_modal', 'hide')}
                          >
                            Done
                          </Button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div
                  className="modal fade primary_modal"
                  id="calendar_modal_expire"
                  tabIndex="-1"
                  role="dialog"
                  aria-labelledby="calendar_modalCenteredLabel"
                  aria-hidden="true"
                >
                  <div className="modal-dialog modal-dialog-centered modal-sm" role="document">
                    <div className="modal-content">
                      <div className="modal-module">
                        <div className="modal-header text-center">
                          <h5 className="modal-title" id="calendar_modalLabel">
                            Choose date
                          </h5>
                          <button
                            type="button"
                            className="close"
                            data-dismiss="modal"
                            aria-label="Close"
                            id="cancelcalender"
                          >
                            <span aria-hidden="true">&times;</span>
                          </button>
                        </div>
                        <div className="modal-body">
                          <div className="pb-3">
                            <Datetime
                              input={false}
                              value={EndClocktime}
                              minDate={new Date().getTime()}
                              onChange={(value) => set_EndClocktime(value)}
                            />
                          </div>
                        </div>
                        <div className="text-center">
                          <Button
                            className="primary_btn"
                            id="doneEndDate"
                            onClick={() => ModalAction('calendar_modal_expire', 'hide')}
                          >
                            Done
                          </Button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="form-row">
                  <div className="form-group col-md-6">
                    <label className="primary_label" htmlFor="TokenName">
                      Name
                    </label>
                    <input
                      type="text"
                      className="form-control primary_inp"
                      id="TokenName"
                      name="TokenName"
                      onChange={inputChange}
                      placeholder="e.g. Redeemable"
                    />
                    {ValidateError.TokenName && (
                      <span className="text-danger">
                        <br />
                        {ValidateError.TokenName}
                      </span>
                    )}
                  </div>

                  {location_pathname === 'create-multiple' && (
                    <div className="form-group col-md-6">
                      <label className="primary_label" htmlFor="desccription">
                        Number of copies
                      </label>
                      <input
                        className="form-control primary_inp"
                        id="TokenQuantity"
                        name="TokenQuantity"
                        onKeyUp={onKeyUp}
                        onChange={(e) => {
                          inputChange(e);
                        }}
                        placeholder="e.g. 1"
                        value={TokenQuantity}
                        autoComplete="off"
                        type="number"
                        min="1"
                        onKeyDown={(evt) => ['e', 'E', '+', '-', '.'].includes(evt.key) && evt.preventDefault()}
                      />
                      {ValidateError.TokenQuantity && (
                        <span className="text-danger">
                          <br />
                          {ValidateError.TokenQuantity}
                        </span>
                      )}
                    </div>
                  )}
                </div>

                <div className="form-row">
                  <div className="form-group col-md-6">
                    <label className="primary_label" htmlFor="inputEmail4">
                      Category
                    </label>
                    <Select
                      className="form-control primary_inp custom-select"
                      id="TokenCategory"
                      name="TokenCategory"
                      onChange={selectChange}
                      options={CategoryOption}
                      label="Single select"
                      formControlProps={{
                        fullWidth: true,
                      }}
                    />
                    {ValidateError.TokenCategory && (
                      <span className="text-danger">
                        <br />
                        {ValidateError.TokenCategory}
                      </span>
                    )}
                  </div>
                  <div className="form-group col-md-6">
                    <label className="primary_label" htmlFor="longDescription">
                      Description <span className="text-muted">(Optional)</span>
                    </label>
                    <textarea
                      className="form-control primary_inp"
                      name="longDescription"
                      id="longDescription"
                      onChange={inputChange}
                      placeholder="Full description of the item"
                    ></textarea>

                    {ValidateError.longDescription && (
                      <span className="text-danger">
                        <br />
                        {ValidateError.longDescription}
                      </span>
                    )}
                  </div>
                </div>

                <div className="form-row">
                  <div className="form-group col-md-6">
                    <label className="primary_label" htmlFor="inputEmail4">
                      Collection
                    </label>
                    <Select
                      className="form-control primary_inp custom-select"
                      id="CollectionCategory"
                      name="CollectionCategory"
                      onChange={selectChange}
                      options={collectionList}
                      label="Single select"
                      formControlProps={{
                        fullWidth: true,
                      }}
                    />
                    {ValidateError.CollectionCategory && (
                      <span className="text-danger">
                        <br />
                        {ValidateError.CollectionCategory}
                      </span>
                    )}
                  </div>

                  <div className="form-group col-md-6">
                    <label className="primary_label" htmlFor="shortDescription">
                      Short Description <span className="text-muted">(Optional)</span>
                    </label>
                    <input
                      type="text"
                      className="form-control primary_inp"
                      name="shortDescription"
                      id="shortDescription"
                      onChange={inputChange}
                      placeholder="Description shown on listing pages"
                    />

                    {ValidateError.shortDescription && (
                      <span className="text-danger">
                        <br />
                        {ValidateError.shortDescription}
                      </span>
                    )}
                  </div>
                </div>

                <div className="form-row">
                  <div className="form-group col-md-6">
                    <label className="primary_label" htmlFor="name">
                      Royalties
                    </label>
                    <div className="inp_grp">
                      <input
                        type="number"
                        min="1"
                        onKeyDown={(evt) => ['e', 'E', '+', '-', '.'].includes(evt.key) && evt.preventDefault()}
                        className="form-control primary_inp"
                        onChange={inputChange}
                        id="TokenRoyalities"
                        name="TokenRoyalities"
                        placeholder="eg.1"
                      />
                      <p className="inp_append">%</p>
                    </div>
                    {ValidateError.TokenRoyalities && (
                      <span className="text-danger">
                        <br />
                        {ValidateError.TokenRoyalities}
                      </span>
                    )}
                  </div>
                  <div className="form-group col-md-6">
                    <label className="primary_label" htmlFor="desccription">
                      Properties <span className="text-muted">(Optional)</span>
                    </label>
                    <input
                      type="text"
                      className="form-control primary_inp"
                      id="TokenProperties"
                      name="TokenProperties"
                      onChange={inputChange}
                      placeholder="e.g. size"
                    />
                  </div>
                </div>

                <div className="form-row mt-4">
                  <div className="form-group col-xs-12 col-md-6">
                    <div className="d-flex justify-content-between align-items-start">
                      <div className="w-75">
                        <label className="primary_label" htmlFor="isPrivate">
                          Private
                        </label>
                        <p className="form_note">Is this item private (visible to only you)?</p>
                      </div>
                      <label className="switch toggle_custom">
                        <input type="checkbox" id="isPrivate" name="isPrivate" onChange={CheckedChange} />
                        <span className="slider"></span>
                      </label>
                    </div>
                  </div>
                  <div className="form-group col-xs-12 col-md-6">
                    <div className="d-flex justify-content-between align-items-start">
                      <div className="w-75">
                        <label className="primary_label" htmlFor="rightsOwnership">
                          By minting this item
                        </label>
                        <p className="form_note">
                          By minting this item I acknowledge that I have full rights and ownership to do so. I do not
                          hold ORE System liable for any of the content of this NFT item.
                        </p>
                        {ValidateError?.ownershipRights && (
                          <span className="text-danger">{ValidateError?.ownershipRights}</span>
                        )}
                      </div>
                      <label className="switch toggle_custom">
                        <input type="checkbox" id="rightsOwnership" name="rightsOwnership" onChange={CheckedChange} />
                        <span className="slider"></span>
                      </label>
                    </div>
                  </div>
                </div>

                <div className="form-row mt-4">
                  <div className="form-group col-xs-12 col-md-12">
                    <div className="d-flex justify-conten-start align-items-start">
                      {legalAgreementsList &&
                        legalAgreementsList.length > 0 &&
                        legalAgreementsList.map((item, index) => {
                          return (
                            <>
                              <div className="form-check w-50">
                                <input
                                  onChange={() => setUsageOption({ option: item.legal, text: item.verbiage, index })}
                                  class="legal-agreements__checkbox mr-3"
                                  type="radio"
                                  name="legalAgreementOption"
                                  id={`flexRadioDefault${index}`}
                                  value={item?.verbiage}
                                />
                                <label className="primary_label" htmlFor={`flexRadioDefault${index}`}>
                                  {item?.legal}
                                </label>
                              </div>
                            </>
                          );
                        })}
                    </div>
                    <div className="w-100">
                      <p className="form_note">
                        {Object?.keys(usageOption)?.length > 0 && legalAgreementsList[usageOption?.index]?.verbiage}
                      </p>
                    </div>
                  </div>
                </div>

                <div className="mt-5 d-flex justify-content-end">
                  <Button className="create_btn" onClick={CreateItem}>
                    Create item
                  </Button>
                </div>
              </form>
            </GridItem>
          </GridContainer>
        </div>
      </div>
      <Footer />

      {/* Create Item Modal */}
      <div
        className="modal fade primary_modal"
        id="create_item_modal"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="create_item_modalCenteredLabel"
        aria-hidden="true"
        data-backdrop="static"
      >
        <div className="modal-dialog modal-dialog-centered modal-sm" role="document">
          <div className="modal-content">
            <div className="modal-header text-center">
              <h5 className="modal-title" id="create_item_modalLabel">
                Follow Steps
              </h5>
              <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <form>
                <div className="media approve_media">
                  <i className="fas fa-check mr-3 pro_complete" aria-hidden="true"></i>
                  {/* <i className="fa fa-spinner mr-3 spinner_icon" aria-hidden="true"></i> */}
                  <div className="media-body">
                    <p className="mt-0 approve_text">Approve</p>
                    <p className="mt-0 approve_desc">Approve performing transactions with your wallet</p>
                  </div>
                </div>
                <div className="text-center my-3">
                  <Button
                    className={
                      'btn-block ' +
                      (ApproveCallStatus === 'processing' || ApproveCallStatus === 'done'
                        ? 'btn_outline_red'
                        : 'create_btn')
                    }
                    disabled={ApproveCallStatus === 'processing' || ApproveCallStatus === 'done'}
                    onClick={ApproveCall}
                  >
                    {ApproveCallStatus === 'processing' && (
                      <i className="fa fa-spinner mr-3 spinner_icon" aria-hidden="true" id="circle1"></i>
                    )}
                    {ApproveCallStatus === 'init' && 'Approve'}
                    {ApproveCallStatus === 'processing' && 'In-progress...'}
                    {ApproveCallStatus === 'done' && 'Done'}
                    {ApproveCallStatus === 'tryagain' && 'Try Again'}
                  </Button>
                </div>
                <div className="media approve_media">
                  <i className="fas fa-check mr-3 pro_complete" aria-hidden="true"></i>
                  <div className="media-body">
                    <p className="mt-0 approve_text">Upload files & Mint token</p>
                    <p className="mt-0 approve_desc">Call contract method</p>
                  </div>
                </div>
                <div className="text-center my-3">
                  {/*<Button className="create_btn btn-block">Start</Button>   */}
                  <Button
                    className={
                      'btn-block ' +
                      (ApproveCallStatus !== 'done' || MintCallStatus === 'processing' || MintCallStatus === 'done'
                        ? 'btn_outline_red'
                        : 'create_btn')
                    }
                    disabled={
                      ApproveCallStatus !== 'done' || MintCallStatus === 'processing' || MintCallStatus === 'done'
                    }
                    onClick={MintCall}
                  >
                    {MintCallStatus === 'processing' && (
                      <i className="fa fa-spinner mr-3 spinner_icon" aria-hidden="true" id="circle1"></i>
                    )}
                    {MintCallStatus === 'init' && 'Start'}
                    {MintCallStatus === 'processing' && 'In-progress...'}
                    {MintCallStatus === 'done' && 'Done'}
                    {MintCallStatus === 'tryagain' && 'Try Again'}
                  </Button>
                </div>

                <div className="media approve_media">
                  <i className="fas fa-check mr-3 pro_complete" aria-hidden="true"></i>
                  <div className="media-body">
                    <p className="mt-0 approve_text">Sign sell order</p>
                    <p className="mt-0 approve_desc">Sign sell order using your wallet</p>
                  </div>
                </div>
                <div className="text-center my-3">
                  {/*<Button className="create_btn btn-block" disabled>Start</Button>  */}
                  <Button
                    className={
                      'btn-block ' +
                      (MintCallStatus !== 'done' || SignCallStatus === 'processing' || SignCallStatus === 'done'
                        ? 'btn_outline_red'
                        : 'create_btn')
                    }
                    disabled={MintCallStatus !== 'done' || SignCallStatus === 'processing' || SignCallStatus === 'done'}
                    onClick={SignCall}
                  >
                    {SignCallStatus === 'processing' && (
                      <i className="fa fa-spinner mr-3 spinner_icon" aria-hidden="true" id="circle1"></i>
                    )}
                    {SignCallStatus === 'init' && 'Start'}
                    {SignCallStatus === 'processing' && 'In-progress...'}
                    {SignCallStatus === 'done' && 'Done'}
                    {SignCallStatus === 'tryagain' && 'Try Again'}
                  </Button>
                </div>

                <div className="media approve_media">
                  <i className="fas fa-check mr-3 pro_complete" aria-hidden="true"></i>
                  <div className="media-body">
                    <p className="mt-0 approve_text">Sign lock order</p>
                    <p className="mt-0 approve_desc">Sign lock order using your wallet</p>
                  </div>
                </div>
                <div className="text-center mt-3">
                  {/* <Button className="create_btn btn-block" disabled>Start</Button>   */}
                  <Button
                    className={
                      'btn-block ' +
                      (SignCallStatus !== 'done' || SignLockCallStatus === 'processing' || SignLockCallStatus === 'done'
                        ? 'btn_outline_red'
                        : 'create_btn')
                    }
                    disabled={
                      SignCallStatus !== 'done' || SignLockCallStatus === 'processing' || SignLockCallStatus === 'done'
                    }
                    onClick={SignLockCall}
                  >
                    {SignLockCallStatus === 'processing' && (
                      <i className="fa fa-spinner mr-3 spinner_icon" aria-hidden="true" id="circle1"></i>
                    )}
                    {SignLockCallStatus === 'init' && 'Start'}
                    {SignLockCallStatus === 'processing' && 'In-progress...'}
                    {SignLockCallStatus === 'done' && 'Done'}
                    {SignLockCallStatus === 'tryagain' && 'Try Again'}
                  </Button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
