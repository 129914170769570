import React from 'react';
import { Link } from 'react-router-dom';
import { SecondsToDay } from '../../helper/dateFormat';
import config from '../../lib/config';

export default function ActivityTab(props) {
  var { item } = props;

  var imageUrl =
    item.userdetail && item.userdetail.image && item.userdetail.image !== ''
      ? item.userdetail.image
      : config.Back_URL + '/images/noimage.png';
  var text = '';
  var name = '';
  if (item.actiontype === 'following') {
    text = 'started following ' + item.touseraddress;
    name = item.userdetail && item.userdetail.name !== '' ? item.userdetail.name : item.userdetail.curraddress;
  } else if (item.actiontype === 'listings') {
    text = 'listed by ' + item.useraddress + ' for ' + item.price + ' BNB';
    name = item.name ? item.name : '';
  } else if (item.actiontype === 'bidrequest') {
    text = item.useraddress + ' offered ' + item.price + ' ORE for 1 edition editions each';
    name = item.name ? item.name : '';
  } else if (item.actiontype === 'purchase') {
    text = 'purchased by ' + item.useraddress + ' for ' + item.price + ' BNB from ' + item.touseraddress;
    name = item.name ? item.name : '';
  } else if (item.actiontype === 'like') {
    text = 'liked by ' + item.useraddress;
    name = item.name ? item.name : '';
  } else if (item.actiontype === 'sales') {
    text = 'sales to ' + item.touseraddress + 'BNB from' + item.price;
    name = item.name ? item.name : '';
  } else if (item.actiontype === 'sales') {
    text = 'sales to ' + item.touseraddress + 'BNB from' + item.price;
    name = item.name ? item.name : '';
  } else if (item.actiontype === 'burns') {
    text = 'burns by ' + item.useraddress + 'no of tokens' + item.balance;
    name = item.name ? item.name : '';
  }

  var linkUrl = '/info/' + item.tokenCounts;
  if (item.itemtype === 'users') {
    linkUrl = '/user/' + item.useraddress;
  }

  if (name.length > 15) {
    name = name.substring(0, 18) + '...';
  }

  return (
    <div className="col-12 col-md-12 mb-4">
      <div className="card my-0">
        <div className="card-body px-3 py-0">
          <div className="media follow_media">
            <Link to={linkUrl}>
              <img src={imageUrl} height="50px" width="50px" alt="User" className="img-fluid mr-3" />
            </Link>
            <div className="media-body flex_body">
              <div>
                <p className="my-0 media_text">{name}</p>
                <p className="mt-0 media_num mt-0">{text}</p>
              </div>
            </div>
          </div>
          <div className="ml-2">
            <p className="mt-0 media_num mb-0 dur_text">{SecondsToDay(item.timestamp)}</p>
          </div>
        </div>
      </div>
    </div>
  );
}
