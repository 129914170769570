import { Accordion, AccordionDetails, AccordionSummary } from '@material-ui/core';
// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';
import axios from 'axios';
import React, { useEffect } from 'react';
import ReactHtmlParser from 'react-html-parser';
import styled from 'styled-components';
import styles from '../assets/jss/material-kit-react/views/landingPage.js';
import Footer from '../components/Footer/Footer';
import GridContainer from '../components/Grid/GridContainer';
import GridItem from '../components/Grid/GridItem';
// core components
import Header from '../components/Header/Header';
import HeaderLinks from '../components/Header/HeaderLinks';
import config from '../lib/config';

const Icon = styled((props) => (
  <div {...props}>
    <div className="minus">-</div>
    <div className="plus">+</div>
  </div>
))`
  & > .plus {
    display: block;
    color: #4cc0e9;
    font-size: 24px;
  }
  & > .minus {
    display: none;
    color: #4cc0e9;
    font-size: 24px;
  }
  .Mui-expanded & > .minus {
    display: flex;
  }
  .Mui-expanded & > .plus {
    display: none;
  }
`;

const dashboardRoutes = [];

const useStyles = makeStyles(styles);

// Scroll to Top
function ScrollToTopOnMount() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return null;
}

export default function HowItWorks(props) {
  const classes = useStyles();
  const { ...rest } = props;

  const [expanded, setExpanded] = React.useState('panel1');
  const [dataHow, setHowData] = React.useState([]);

  const handleChangeFaq = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  useEffect(() => {
    getHowData();
  }, []);

  const getHowData = () => {
    axios.get(`${config.Back_URL}/api/getFaqList`).then((res) => {
      if (res && res.data && res.data.data) {
        setHowData(res.data.data);
      }
    });
  };
  return (
    <div className="inner_header">
      <Header
        fixed
        color="transparent"
        routes={dashboardRoutes}
        brand={<img src={require('../assets/images/logo.png')} alt="logo" className="img-fluid" />}
        rightLinks={<HeaderLinks />}
        changeColorOnScroll={{
          height: 50,
          color: 'dark',
        }}
        {...rest}
      />
      <ScrollToTopOnMount />
      <div className={classes.pageHeader + ' inner_pageheader'}>
        <div className={classes.container}>
          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              <div className="d-flex align-items-center">
                <h2 className="inner_title">How it Works</h2>
              </div>
            </GridItem>
          </GridContainer>
        </div>
        {dataHow.map((item, i) => {
          return (
            <div className="container">
              <GridContainer>
                <GridItem xs={12} sm={12} md={12} className="m-auto">
                  <div className="faq_panel">
                    <Accordion
                      expanded={expanded === 'panel' + (i + 1)}
                      onChange={handleChangeFaq('panel' + (i + 1))}
                      className="mt-5"
                    >
                      <AccordionSummary expandIcon={<Icon />} aria-controls="panel1bh-content" id="panel1bh-header">
                        <div className="accordian_head">
                          <h2>
                            {i + 1} {item.question}
                          </h2>
                        </div>
                      </AccordionSummary>
                      <AccordionDetails>
                        {/*<div className="accordian_para">*/}
                        <div className="accordian_para">{ReactHtmlParser(item.answer)}</div>
                        {/*</div>*/}
                      </AccordionDetails>
                    </Accordion>
                  </div>
                </GridItem>
              </GridContainer>
            </div>
          );
        })}
      </div>
      <Footer />
    </div>
  );
}
