// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';
import React, { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { CollectiblesList_Follow } from '../actions/token';
import { getCurAddr } from '../actions/user';
import styles from '../assets/jss/material-kit-react/views/landingPage.js';
import Footer from '../components/Footer/Footer';
import GridContainer from '../components/Grid/GridContainer';
import GridItem from '../components/Grid/GridItem';
// core components
import Header from '../components/Header/Header';
import HeaderLinks from '../components/Header/HeaderLinks';
import BidPopup from './separate/BidPopup';
import ConnectWallet from './separate/ConnectWallet';
import Explore from './separate/Explore';
import { LikeRef } from './separate/LikeRef';
import { PurchaseNowRef } from './separate/PurchaseNowRef';
import { PutOnSaleRef } from './separate/PutOnSaleRef';
import { WalletRef } from './separate/WalletRef';

toast.configure();
const dashboardRoutes = [];

const useStyles = makeStyles(styles);

// Scroll to Top
function ScrollToTopOnMount() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return null;
}

export default function Following(props) {
  var LikeForwardRef = useRef();
  var PutOnSaleForwardRef = useRef();
  var PurchaseNowForwardRef = useRef();
  var WalletForwardRef = useRef();

  const classes = useStyles();
  const { ...rest } = props;

  const [WalletConnected, Set_WalletConnected] = React.useState('false');
  const [UserAccountAddr, Set_UserAccountAddr] = React.useState('');
  const [UserAccountBal, Set_UserAccountBal] = React.useState(0);
  const [AddressUserDetails, Set_AddressUserDetails] = React.useState({});
  const [Accounts, Set_Accounts] = React.useState('');

  const [MyItemAccountAddr] = React.useState('');

  const [HitItem, Set_HitItem] = useState({});

  const [LikedTokenList, setLikedTokenList] = React.useState([]);
  const [FollowingList, Set_FollowingList] = useState([]);

  async function AfterWalletConnected() {
    try {
      LikeForwardRef.current.getLikesData();
    } catch (err) {}
    FollowList_Get_Call();
  }

  async function FollowList_Get_Call() {
    // var addr = UserAccountAddr;
    var addr = await getCurAddr();
    var resp = await CollectiblesList_Follow({
      target: 'following',
      addr: addr,
      follower: addr,
    });
    if (resp && resp.data && resp.data.list) {
      Set_FollowingList(resp.data.list);
    } else {
      Set_FollowingList([]);
    }
  }

  async function GetUserBal() {
    await WalletForwardRef.current.GetUserBal();
  }

  return (
    <div className="inner_header">
      <ConnectWallet
        Set_UserAccountAddr={Set_UserAccountAddr}
        Set_UserAccountBal={Set_UserAccountBal}
        Set_WalletConnected={Set_WalletConnected}
        Set_Accounts={Set_Accounts}
        WalletConnected={WalletConnected}
        AfterWalletConnected={AfterWalletConnected}
        Set_AddressUserDetails={Set_AddressUserDetails}
      />
      <BidPopup item={HitItem} UserAccountAddr={UserAccountAddr} UserAccountBal={UserAccountBal} />
      <PutOnSaleRef
        ref={PutOnSaleForwardRef}
        Set_HitItem={Set_HitItem}
        item={HitItem}
        UserAccountAddr={UserAccountAddr}
        UserAccountBal={UserAccountBal}
        Accounts={Accounts}
        GetUserBal={GetUserBal}
      />
      <PurchaseNowRef
        ref={PurchaseNowForwardRef}
        Set_HitItem={Set_HitItem}
        item={HitItem}
        UserAccountAddr={UserAccountAddr}
        UserAccountBal={UserAccountBal}
        Accounts={Accounts}
        GetUserBal={GetUserBal}
      />
      <WalletRef
        ref={WalletForwardRef}
        Set_UserAccountAddr={Set_UserAccountAddr}
        Set_WalletConnected={Set_WalletConnected}
        Set_UserAccountBal={Set_UserAccountBal}
      />
      <LikeRef ref={LikeForwardRef} setLikedTokenList={setLikedTokenList} MyItemAccountAddr={MyItemAccountAddr} />
      <Header
        fixed
        color="transparent"
        routes={dashboardRoutes}
        brand={
          <Link to="/home">
            <img src={require('../assets/images/logo.png')} alt="logo" className="img-fluid" />
          </Link>
        }
        rightLinks={<HeaderLinks />}
        changeColorOnScroll={{
          height: 50,
          color: 'dark',
        }}
        {...rest}
      />
      <ScrollToTopOnMount />
      <div className={classes.pageHeader + ' inner_pageheader'}>
        <div className="bg_red_1">
          <div className={classes.container}>
            <GridContainer>
              <GridItem xs={12} sm={12} md={12}>
                <div className="d-flex align-items-center justify-content-between">
                  <h2 className="inner_title">Following</h2>
                  <div className="custom_filter">
                    <a
                      href="#discoverFilter"
                      className="menu_btn landingFilterButton"
                      data-toggle="collapse"
                      role="button"
                      aria-expanded="false"
                      aria-controls="discoverFilter"
                    >
                      Filter & Sort <img src={require('../assets/images/filtersorticon.png')} alt="" />
                    </a>
                  </div>
                </div>
                <div className="collapse" id="discoverFilter">
                  <div className="card card-body my-2">
                    <GridContainer className="w-100">
                      <GridItem xs={12} sm={12} md={6} lg={3}>
                        <div className="form-group">
                          <label htmlFor="inputEmail4" className="auth_label">
                            Price
                          </label>
                          <select className="custom-select auth_inp">
                            <option selected>Highest Price</option>
                            <option value="1">Lowest Price</option>
                          </select>
                        </div>
                      </GridItem>
                      <GridItem xs={12} sm={12} md={6} lg={3}>
                        <div className="form-group">
                          <label htmlFor="inputEmail4" className="auth_label">
                            Likes
                          </label>
                          <select className="custom-select auth_inp">
                            <option selected>Most Liked</option>
                            <option value="1">Most Viewed</option>
                            <option value="1">Un Liked</option>
                          </select>
                        </div>
                      </GridItem>
                      <GridItem xs={12} sm={12} md={6} lg={3}>
                        <div className="form-group">
                          <label htmlFor="inputEmail4" className="auth_label">
                            Creator
                          </label>
                          <select className="custom-select auth_inp">
                            <option selected>Verified Only</option>
                            <option value="1">Popular Creators</option>
                            <option value="1">New Creators</option>
                          </select>
                        </div>
                      </GridItem>
                      <GridItem xs={12} sm={12} md={6} lg={3}>
                        <div className="form-group">
                          <label htmlFor="inputEmail4" className="auth_label">
                            Sort By
                          </label>
                          <select className="custom-select auth_inp">
                            <option selected>Newest</option>
                            <option value="1">Popular</option>
                            <option value="1">Price Low</option>
                            <option value="1">Price High</option>
                          </select>
                        </div>
                      </GridItem>
                    </GridContainer>
                  </div>
                </div>
              </GridItem>
            </GridContainer>
          </div>
        </div>
        <div className="container mt-3">
          <div className="row">
            {FollowingList && FollowingList.length > 0
              ? FollowingList.map((item) => {
                  return (
                    <Explore
                      item={item}
                      LikedTokenList={LikedTokenList}
                      hitLike={LikeForwardRef.current.hitLike}
                      UserAccountAddr={UserAccountAddr}
                      UserAccountBal={UserAccountBal}
                      PutOnSale_Click={PutOnSaleForwardRef.current.PutOnSale_Click}
                      PurchaseNow_Click={PurchaseNowForwardRef.current.PurchaseNow_Click}
                      WalletConnected={WalletConnected}
                    />
                  );
                })
              : 'No record Found'}
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
