let EnvName = process.env.REACT_APP_ENVIRONMENT;
console.log('EnvName : api : ', EnvName);

let Front_URL = '';
let Back_URL = '';
let v1Url = '';
let limit = '8';
let shareurl = ' ';
let limitMax = '3000';
let decimalvalues = 1000000000000000000;
// let fee = 2.5;
// let fee = 1000000000000000000;
// let fee = 0.008; // Fee is divisible by 2^x to optimize processing and eliminate calculation loss
let fee = 0.01; // Had to revert back as contract is verifying fee against original value of 1%

let toFixed = 6;
let IPFS_IMG = 'https://ipfs.io/ipfs';

// Popup config ///
let toasterOption = {
  position: 'top-right',
  autoClose: 3000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: false,
  draggable: true,
  progress: undefined,
};

// Token Config ///
const singleType = 721;
const multipleType = 1155;
let networkVersion = '97'; //demo
let currencySymbol = 'BNB';
let tokenSymbol = 'ORE';
// let tokenSymbol = 'DETH';
let PurchaseTransferType = 'currency';

// TODO only give the address once (OREAddress below)
var tokenAddr = {
  ORE: '0x0B43885604c37013B268F23F8dE469087258268a',
};

let OREAddress = '0x0B43885604c37013B268F23F8dE469087258268a'; //testnet bsc
let adminAddress = '0x2Dde310B6DBF75d2db8B92D41d44101601815c1A'; //client admin addrsss
const singleContract = '';

//  //testnet contract deployed
let multipleContract = '0xb9be1c8761c4FF1ab752A0e4aE1396D5503D33dB'; // new parama contract
let exchangeAddress = '0xb9be1c8761c4FF1ab752A0e4aE1396D5503D33dB';

// TODO: change all urls to main domain and https
if (EnvName === 'production') {
  Front_URL = 'https://ore-forge.com';
  Back_URL = 'https://api.ore-forge.com';
  v1Url = 'https://api.ore-forge.com/api';
  IPFS_IMG = 'https://oreshop.mypinata.cloud/ipfs';
  shareurl = 'https://ore-forge.com/info/';
  networkVersion = '56';
  multipleContract = '0x9dBF0292DfE72a1f6BeBd99a9E71641D07B8BA8C';
  exchangeAddress = '0x9dBF0292DfE72a1f6BeBd99a9E71641D07B8BA8C';
  adminAddress = '0x6aF3317a545D1977003e7fDE666d5DAbbCa01CE5';
  OREAddress = '0x8e2D8f40818FbaBA663Db6a24FB9B527Fc7100BE';
  tokenAddr = {
    ORE: OREAddress,
  };
} else if (EnvName === 'dev') {
  Front_URL = 'https://dev.ore-forge.com';
  Back_URL = 'https://dev-api.ore-forge.com';
  v1Url = 'https://dev-api.ore-forge.com/api';
  IPFS_IMG = 'https://oreshop.mypinata.cloud/ipfs';
  shareurl = 'https://ore-forge.com/info/';
} else {
  Front_URL = 'http://localhost:3000';
  Back_URL = 'http://localhost:2053';
  v1Url = 'http://localhost:2053/api';
  IPFS_IMG = 'https://oreshop.mypinata.cloud/ipfs';
  shareurl = 'https://ore-forge.com/info/';
}

let key = {
  Front_URL: Front_URL,
  Back_URL: Back_URL,
  v1Url: v1Url,
  vUrl: v1Url,
  fee: fee,
  decimalvalues: decimalvalues,
  toFixed: toFixed,
  networkVersion: networkVersion,
  currencySymbol: currencySymbol,
  tokenSymbol: tokenSymbol,
  toasterOption: toasterOption,
  limit: limit,
  limitMax: limitMax,
  exchangeAddress: exchangeAddress,
  singleContract: singleContract,
  multipleContract: multipleContract,
  adminAddress: adminAddress,
  tokenAddr: tokenAddr,
  singleType: singleType,
  multipleType: multipleType,
  IPFS_IMG: IPFS_IMG,
  PurchaseTransferType: PurchaseTransferType,
  DETHaddress: OREAddress,
  shareurl: shareurl,
};

export default key;
