import { Button } from '@material-ui/core';
import '@metamask/legacy-web3';
import React, { forwardRef, useImperativeHandle } from 'react';
import { toast } from 'react-toastify';
import Web3 from 'web3';
import ABI from '../../ABI/ABI.json';
import { TokenPricecancel_update_Action } from '../../actions/token';
import config from '../../lib/config';

toast.configure();
let toasterOption = config.toasterOption;

const exchangeAddress = config.multipleContract;

export const CancelOrderRef = forwardRef((props, ref) => {
  const [FormSubmitLoading, Set_FormSubmitLoading] = React.useState('');
  const [TokenPrice, Set_TokenPrice] = React.useState(0);

  const FormSubmit = async () => {
    if (window.ethereum) {
      let web3 = new Web3(window.ethereum);
      if (web3 && web3.eth) {
        let CoursetroContract = new web3.eth.Contract(ABI, exchangeAddress);

        Set_FormSubmitLoading('processing');
        CoursetroContract.methods
          .cancelOrder(props.item.tokenCounts)
          .send({ from: props.Accounts })
          .then(async (result) => {
            Set_FormSubmitLoading('done');
            let postData = {
              tokenOwner: UserAccountAddr,
              tokenCounts: props.item.tokenCounts,
              tokenPrice: TokenPrice,
              blockHash: result.blockHash,
              transactionHash: result.transactionHash,
            };
            let Resp = await TokenPricecancel_update_Action(postData);
            if (Resp.data && Resp.data.RetType && Resp.data.RetType === 'success') {
              toast.success('Collectible cancelled successfully', toasterOption);
              window.$('.modal').modal('hide');
              setTimeout(() => {
                window.location.reload();
              }, 1500);
            }
          })
          .catch((error) => {
            Set_FormSubmitLoading('error');
            toast.error('Order not placed', toasterOption);
          });
      }
    }
  };

  let { item, UserAccountAddr } = props;

  useImperativeHandle(ref, () => ({
    async CancelOrder_Click(item, BuyOwnerDetail = {}) {
      let connectwallet = localStorage.getItem('jakcojsnick');
      if (!connectwallet) {
        window.location.href = '/connect';
        return false;
      }
      props.Set_HitItem(item);
      Set_TokenPrice(0);
      window.$('#cancel_order_modal').modal('show');
    },
  }));
  return (
    <div
      className="modal fade primary_modal"
      id="cancel_order_modal"
      tabIndex="-1"
      role="dialog"
      aria-labelledby="cancel_order_modalCenteredLabel"
      aria-hidden="true"
      data-backdrop="static"
      data-keyboard="false"
    >
      <div className="modal-dialog modal-dialog-centered modal-sm" role="document">
        <div className="modal-content">
          <div className="modal-header text-center">
            <h5 className="modal-title" id="cancel_order_modalLabel">
              Cancel Order
            </h5>
            <p className="text_grey_clickb mb-0"></p>
            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body px-0 pt-0">
            <form className="px-4 bid_form">
              <div className="row pb-2">
                <div className="col-12 col-sm-6">
                  <p className="buy_desc_sm">Token Price</p>
                </div>
                <div className="col-12 col-sm-6 text-sm-right">
                  <p className="buy_desc_sm_bold">
                    {item && item.tokenowners_current && item.tokenowners_current.tokenPrice}
                  </p>
                </div>
              </div>
              <div className="text-center mt-3">
                <Button
                  type="button"
                  className="primary_btn btn-block"
                  onClick={() => FormSubmit()}
                  disabled={FormSubmitLoading === 'processing'}
                >
                  {FormSubmitLoading === 'processing' && (
                    <i className="fa fa-spinner mr-3 spinner_icon" aria-hidden="true" id="circle1"></i>
                  )}
                  Sign sell order
                </Button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
});
