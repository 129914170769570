import { Button } from '@material-ui/core';
// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';
import CKEditor from 'ckeditor4-react';
import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import Select from 'react-select';
import { toast } from 'react-toastify';
import { activateuser, createtopic, getcategory, lastestdiscussion, topdiscussion } from '../actions/community';
import styles from '../assets/jss/material-kit-react/views/landingPage.js';
import Footer from '../components/Footer/Footer';
import GridContainer from '../components/Grid/GridContainer';
import GridItem from '../components/Grid/GridItem';
// core components
import Header from '../components/Header/Header';
import HeaderLinksCommunity from '../components/Header/HeaderLinksCommunity';
import { SecondsToDay } from '../helper/dateFormat';

toast.configure();
const dashboardRoutes = [];
const useStyles = makeStyles(styles);

// Scroll to Top
function ScrollToTopOnMount() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return null;
}
const initialFormValue = {
  topic: '',
  description: '',
  cate: '',
  catename: '',
};
toast.configure();
let toasterOption = {
  position: 'top-right',
  autoClose: 1000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: false,
  draggable: true,
  progress: undefined,
};

export default function Discussion(props) {
  const classes = useStyles();
  const { ...rest } = props;
  const { userId } = useParams();
  const [formValue, setFormValue] = useState(initialFormValue);
  const [description, setdescription] = useState({ description: '' });
  const { topic, cate, catename } = formValue;
  const [validateError, setValidateError] = useState({});
  const [toptopics, settoptopics] = useState([]);
  const [latesttopics, setlatesttopics] = useState([]);
  const [catdrop, setcatdrop] = useState([]);
  const [catList, setcatList] = useState([]);

  useEffect(() => {
    getallcategory();
    getlastesttopic();
    gettoptopic();
    getLatestcat();
    getActivelogin();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  async function getActivelogin() {
    var activate_id = userId;
    if (typeof activate_id !== 'undefined' && activate_id !== '') {
      let passdata = { userid: activate_id };
      var res = await activateuser(passdata);
      if (res && res.result && res.result.data && res.result.data.errormsg) {
        toast.error(res.result.data.errormsg, 'success');
      }
      if (res && res.result && res.result.data && res.result.data.message) {
        toast.success(res.result.data.message, 'success');
      }
    }
  }

  const handletopicSubmit = async (e) => {
    e.preventDefault();
    if (validation()) {
      let reqData = {
        topic,
        description,
        cate,
        catename,
      };
      let result = await createtopic(reqData);
      if (result.error) {
        setValidateError(result.error);
      } else {
        setFormValue(initialFormValue);
        toast.success('Topic Created Successfully.', toasterOption);
        window.location.href = '/discussion';
      }
    }
  };

  const getallcategory = async (e) => {
    let result = await getcategory();
    if (result && result.result) {
      setcatList(result.result);
      var cat = result && result.result.length > 0 && result.result.map((opt) => ({ label: opt.name, value: opt._id }));
      setcatdrop(cat);
    }
  };
  const getLatestcat = async (e) => {};
  const getlastesttopic = async (e) => {
    let { list } = await lastestdiscussion({ id: '' });
    setlatesttopics(list);
  };

  const gettoptopic = async (e) => {
    let { list } = await topdiscussion();
    settoptopics(list);
  };
  const onEditorChange = (evt) => {
    var description_text = evt.editor.getData();
    setdescription(description_text);
  };
  const onChange = (e) => {
    e.preventDefault();
    const { id, value } = e.target;
    let formData = { ...formValue, ...{ [id]: value } };
    setFormValue(formData);
  };
  const handlecat = (e) => {
    let formData = { ...formValue, ...{ cate: e.value, catename: e.label } };
    setFormValue(formData);
  };
  const categorychange = async (e) => {
    let reqData = {
      value: e.target.value,
    };
    let { list } = await topdiscussion(reqData);
    settoptopics(list);
  };

  const latestcategorychange = async (e) => {
    let reqData = {
      id: e.target.value,
    };
    let { list } = await lastestdiscussion(reqData);
    setlatesttopics(list);
  };
  function validation() {
    var isForm = true;
    var errors = {};

    if (topic === '') {
      isForm = false;
      errors.username = 'Title is required';
    } else if (cate === '') {
      isForm = false;
      errors.cate = 'Category is required';
    } else if (description === '') {
      isForm = false;
      errors.description = 'Description is required';
    }
    setValidateError(errors);
    return isForm;
  }

  return (
    <div className="inner_header">
      <Header
        fixed
        color="transparent"
        routes={dashboardRoutes}
        brand={
          <Link to="/home">
            <img src={require('../assets/images/logo.png')} alt="logo" className="img-fluid" />
          </Link>
        }
        rightLinks={<HeaderLinksCommunity />}
        changeColorOnScroll={{
          height: 50,
          color: 'dark',
        }}
        {...rest}
      />
      <ScrollToTopOnMount />
      <div className={classes.pageHeader + ' inner_pageheader'}>
        <div className={classes.container}>
          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              <div className="d-flex align-items-center justify-content-between">
                <h2 className="inner_title">General Community</h2>
                {localStorage.community_token && (
                  <Button className="create_btn" data-toggle="modal" data-target="#new_top_modal">
                    New Topic
                  </Button>
                )}
              </div>
            </GridItem>
          </GridContainer>
        </div>
        <div className="container mt-3">
          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              <nav className="masonry_tab_nav mt-4 items_tab_outer px-0">
                <div className="nav nav-tabs masonry_tab primary_tab dis_tab px-0 mb-3" id="nav-tab" role="tablist">
                  <a
                    className="nav-link"
                    id="latest-tab"
                    data-toggle="tab"
                    href="#latest"
                    role="tab"
                    aria-controls="latest"
                    aria-selected="false"
                  >
                    Latest
                  </a>
                  <a
                    className="nav-link"
                    id="top-tab"
                    data-toggle="tab"
                    href="#top"
                    role="tab"
                    aria-controls="top"
                    aria-selected="false"
                  >
                    Top
                  </a>
                </div>
              </nav>
              <div className="tab-content explore_tab_content mt-0" id="nav-tabContent">
                <div className="tab-pane fade show active" id="latest" role="tabpanel" aria-labelledby="following-tab">
                  <div className="proposal_panel_overall">
                    <div className="table-responsive">
                      <table className="table table_style_1">
                        <thead>
                          <tr>
                            <th>
                              <div className="form-group mb-0">
                                <select
                                  className="form-control  sel_period"
                                  onChange={latestcategorychange}
                                  id="exampleFormControlSelect1"
                                >
                                  <option>All catrgories</option>
                                  {catList &&
                                    catList.map((row, index) => {
                                      return <option value={row._id}>{row.name}</option>;
                                    })}
                                </select>
                              </div>
                            </th>
                            <th className="text-right"></th>
                            <th className="text-right"> Replies </th>
                            <th className="text-right"> Views </th>
                            <th className="text-right"> Activity </th>
                          </tr>
                        </thead>
                        <tbody>
                          {latesttopics &&
                            latesttopics.length > 0 &&
                            latesttopics.map((row, index) => {
                              return (
                                <tr>
                                  <td>
                                    <Link to={'/discussion-detail/' + row._id}>
                                      <p className="cat_title mb-0">{row.topic}</p>
                                    </Link>
                                    <Link to={'/category/' + row.category_id}>
                                      <p className="cat_link cat_link_green mb-0">{row.category_name}</p>
                                    </Link>
                                  </td>
                                  <td className="text-right">
                                    <div className="d-flex creators_details_td justify-content-end">
                                      {row.userList &&
                                        row.userList.length > 0 &&
                                        row.userList.map((sub, subindex) => (
                                          <img
                                            src={require('../assets/images/avatars/avatar8.jpg')}
                                            alt="User"
                                            className="img-fluid"
                                          />
                                        ))}
                                    </div>
                                  </td>
                                  <td className="text-right"> {row.reply_count} </td>
                                  <td className="text-right"> {row.views_count} </td>
                                  <td className="text-right"> {SecondsToDay(row.created_date)} </td>
                                </tr>
                              );
                            })}
                          {latesttopics && latesttopics.length === 0 && <tr>No results found..!</tr>}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
                <div className="tab-pane fade" id="top" role="tabpanel" aria-labelledby="activity-tab">
                  <div className="proposal_panel_overall">
                    <div className="table-responsive">
                      <table className="table table_style_1">
                        <thead>
                          <tr>
                            <th>
                              <div className="form-group mb-0">
                                <select
                                  className="form-control  sel_period"
                                  onChange={categorychange}
                                  id="exampleFormControlSelect1"
                                >
                                  <option>Select Category</option>
                                  {catList &&
                                    catList.map((row, index) => {
                                      return <option value={row._id}>{row.name}</option>;
                                    })}
                                </select>
                              </div>
                            </th>
                            <th className="text-right"></th>
                            <th className="text-right"> Replies </th>
                            <th className="text-right"> Views </th>
                            <th className="text-right"> Activity </th>
                          </tr>
                        </thead>
                        <tbody>
                          {toptopics &&
                            toptopics.map((row, index) => {
                              return (
                                <tr>
                                  <td>
                                    <Link to={'/discussion-detail/' + row._id}>
                                      <p className="cat_title mb-0">{row.topic}</p>
                                    </Link>
                                    <Link to={'/category/' + row.category_id}>
                                      <p className="cat_link cat_link_green mb-0">{row.category_name}</p>
                                    </Link>
                                  </td>
                                  <td className="text-right">
                                    <div className="d-flex creators_details_td justify-content-end">
                                      {row.userList &&
                                        row.userList.length > 0 &&
                                        row.userList.map((sub, subindex) => (
                                          <img
                                            src={require('../assets/images/avatars/avatar8.jpg')}
                                            alt="User"
                                            className="img-fluid"
                                          />
                                        ))}
                                    </div>
                                  </td>
                                  <td className="text-right"> {row.reply_count} </td>
                                  <td className="text-right"> {row.views_count} </td>
                                  <td className="text-right"> {SecondsToDay(row.created_date)} </td>
                                </tr>
                              );
                            })}
                          {toptopics && toptopics.length === 0 && <tr>No results found..!</tr>}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </GridItem>
          </GridContainer>
        </div>
      </div>

      {/* New Topic Modal */}
      <div
        className="modal fade primary_modal"
        id="new_top_modal"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="new_top_modalCenteredLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered modal" role="document">
          <div className="modal-content" id="hide">
            <div className="modal-header text-center">
              <h5 className="modal-title" id="new_top_modalLabel_1">
                Create New Topic
              </h5>
              <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <form className="login_form mt-3" onSubmit={handletopicSubmit}>
                <div className="form-row">
                  <div className="col-12">
                    <label htmlFor="title">Title</label>
                    <div className="mb-3">
                      <input type="text" onChange={onChange} id="topic" className="form-control" placeholder="Title" />
                      {validateError && validateError.username !== '' && (
                        <p style={{ color: 'red' }}>{validateError.username}</p>
                      )}
                    </div>
                  </div>
                  <div className="col-12">
                    <label htmlFor="category">Choose Category</label>
                    <Select id="category" options={catdrop} onChange={handlecat} />
                    {validateError && validateError.cate !== '' && <p style={{ color: 'red' }}>{validateError.cate}</p>}
                  </div>
                </div>
                <label className="mt-2" htmlFor="message">
                  Message
                </label>
                <div className="my-3">
                  <CKEditor id="description" data="<p></p>" onChange={onEditorChange} />
                  {validateError && validateError.description !== '' && (
                    <p style={{ color: 'red' }}>{validateError.description}</p>
                  )}
                </div>
                <div className="mt-3">
                  <Button className="create_btn" type="submit">
                    Create Topic
                  </Button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
}
