/*eslint-disable*/
import { makeStyles } from '@material-ui/core/styles';
// nodejs library that concatenates classes
import classNames from 'classnames';
// nodejs library to set properties for components
import PropTypes from 'prop-types';
import React from 'react';
import styles from '../../assets/jss/material-kit-react/components/footerStyle.js';

const useStyles = makeStyles(styles);

export default function Footer(props) {
  const classes = useStyles();
  const { whiteFont } = props;
  const footerClasses = classNames({
    [classes.footer]: true,
    [classes.footerWhiteFont]: whiteFont,
  });
  const aClasses = classNames({
    [classes.a]: true,
    [classes.footerWhiteFont]: whiteFont,
  });
  return (
    <footer className="footer">
      <div className="container">
        <div className="row">
          <div className="col-12 col-sm-8 col-md-6 col-lg-6 col-xl-6 order-4 order-md-1 order-lg-4 order-xl-1">
            <div className="footer__logo">
              <img src={require('../../assets/images/logo.svg')} alt="" />
            </div>
            <p className="footer__tagline">
              Digital marketplace for crypto collectibles and non-fungible tokens (NFTs).
              <br />
              Buy, sell, and discover exclusive digital assets.
            </p>
            <p className="mt-4">
              <small className="footer__copyright">© ORE FORGE, 2021. All rights reserved</small>
            </p>

            {/*<div className="footer__lang">
						<a className="footer__lang-btn" href="#" role="button" id="dropdownLang" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
							<img src={require("../../assets/images/flags/uk.svg")} alt="" />
							<span>English</span>
						</a>

						<ul className="dropdown-menu footer__lang-dropdown">
							<li><a href="#"><img src={require("../../assets/images/flags/spain.svg")} alt="" /><span>Spanish</span></a></li>
							<li><a href="#"><img src={require("../../assets/images/flags/russia.svg")} alt="" /><span>Russian</span></a></li>
							<li><a href="#"><img src={require("../../assets/images/flags/china.svg")} alt="" /><span>Chinese</span></a></li>
						</ul>
					</div> */}
          </div>

          <div className="col-6 col-md-4 col-lg-6 col-xl-6 order-1 order-md-2 order-lg-1 order-xl-2 offset-md-2 offset-lg-0">
            <h6 className="footer__title">NFT Marketplace</h6>
            <div className="footer__nav">
              <a href="#">Explore</a>
              <a href="/activity">Activity</a>
              {/*<a href="authors.html">Authors</a>*/}
              <a href="/privacy">Privacy Policy</a>
            </div>
            <div className="footer__social mt-3">
              <a href="https://www.facebook.com/oresystem/" target="_blank">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                  <path d="M20.9,2H3.1A1.1,1.1,0,0,0,2,3.1V20.9A1.1,1.1,0,0,0,3.1,22h9.58V14.25h-2.6v-3h2.6V9a3.64,3.64,0,0,1,3.88-4,20.26,20.26,0,0,1,2.33.12v2.7H17.3c-1.26,0-1.5.6-1.5,1.47v1.93h3l-.39,3H15.8V22h5.1A1.1,1.1,0,0,0,22,20.9V3.1A1.1,1.1,0,0,0,20.9,2Z" />
                </svg>
              </a>

              <a href="https://www.twitch.tv/oresystem" target="_blank">
                <img src={require('../../assets/images/twitch.svg')} alt="logo" className="img-fluid" />
              </a>

              <a href="https://www.linkedin.com/company/ore" target="_blank">
                <i className="fab fa-linkedin-in"></i>
              </a>

              <a href="https://twitter.com/ORESystemNFT" target="_blank">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                  <path d="M22,5.8a8.49,8.49,0,0,1-2.36.64,4.13,4.13,0,0,0,1.81-2.27,8.21,8.21,0,0,1-2.61,1,4.1,4.1,0,0,0-7,3.74A11.64,11.64,0,0,1,3.39,4.62a4.16,4.16,0,0,0-.55,2.07A4.09,4.09,0,0,0,4.66,10.1,4.05,4.05,0,0,1,2.8,9.59v.05a4.1,4.1,0,0,0,3.3,4A3.93,3.93,0,0,1,5,13.81a4.9,4.9,0,0,1-.77-.07,4.11,4.11,0,0,0,3.83,2.84A8.22,8.22,0,0,1,3,18.34a7.93,7.93,0,0,1-1-.06,11.57,11.57,0,0,0,6.29,1.85A11.59,11.59,0,0,0,20,8.45c0-.17,0-.35,0-.53A8.43,8.43,0,0,0,22,5.8Z" />
                </svg>
              </a>

              <a href="https://discord.com/invite/62JnENExqj" target="_blank">
                <i className="fab fa-discord"></i>
              </a>

              <a href="https://t.me/TheORESystem" target="_blank">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                  <path d="M11.99432,2a10,10,0,1,0,10,10A9.99917,9.99917,0,0,0,11.99432,2Zm3.17951,15.15247a.70547.70547,0,0,1-1.002.3515l-2.71467-2.10938L9.71484,17.002a.29969.29969,0,0,1-.285.03894l.334-2.98846.01069.00848.00683-.059s4.885-4.44751,5.084-4.637c.20147-.189.135-.23.135-.23.01147-.23053-.36152,0-.36152,0L8.16632,13.299l-2.69549-.918s-.414-.1485-.453-.475c-.041-.324.46649-.5.46649-.5l10.717-4.25751s.881-.39252.881.25751Z" />
                </svg>
              </a>
            </div>
          </div>

          {/*	<div className="col-12 col-md-8 col-lg-6 col-xl-4 order-3 order-lg-2 order-md-3 order-xl-3">
					<div className="row">
						<div className="col-12">
							<h6 className="footer__title">Explore</h6>
						</div>

						<div className="col-6">
							<div className="footer__nav">
								<a href="#">Art</a>
								<a href="#">Photography</a>
								<a href="#">Games</a>
								<a href="#">Music</a>
							</div>
						</div>

						<div className="col-6">
							<div className="footer__nav">
								<a href="#">Domains</a>
								<a href="#">DeFi</a>
								<a href="#">Metaverses</a>
								<a href="#">Memes</a>
							</div>
						</div>
					</div>
				</div>

				<div className="col-6 col-md-4 col-lg-3 col-xl-2 order-2 order-lg-3 order-md-4 order-xl-4">
					<h6 className="footer__title">Community</h6>
					<div className="footer__nav">
						<a href="token.html">Unitok</a>
						<a href="blog.html">Our Blog</a>
						<a href="faq.html">Help Center</a>
						<a href="contacts.html">Contacts</a>
					</div>
				</div> */}
        </div>
      </div>
    </footer>
  );
}

Footer.propTypes = {
  whiteFont: PropTypes.bool,
};
