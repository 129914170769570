import { Button } from '@material-ui/core';
// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';
import '@metamask/legacy-web3';
import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { changepassword } from '../actions/community';
import styles from '../assets/jss/material-kit-react/views/landingPage.js';
// import HeaderLinks from "components/Header/HeaderLinks.js";
import Footer from '../components/Footer/Footer';
import GridContainer from '../components/Grid/GridContainer';
import GridItem from '../components/Grid/GridItem';
import Header from '../components/Header/Header';
// core components
import HeaderLinksCommunity from '../components/Header/HeaderLinksCommunity';
import isEmpty from '../lib/isEmpty';

const dashboardRoutes = [];

const useStyles = makeStyles(styles);
const initialFormValue = {
  new_password: '',
  confirm_password: '',
};
toast.configure();
let toasterOption = {
  position: 'top-right',
  autoClose: 1000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: false,
  draggable: true,
  progress: undefined,
};
// Scroll to Top
function ScrollToTopOnMount() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return null;
}

export default function ResetPassword(props) {
  const classes = useStyles();
  const { id } = useParams();
  const history = useHistory();
  const [formValue, setFormValue] = useState(initialFormValue);
  const [errorValue, seterrorValue] = useState({});
  const { ...rest } = props;

  const onChange = (e) => {
    e.preventDefault();
    const { id, value } = e.target;
    let formData = { ...formValue, ...{ [id]: value } };
    setFormValue(formData);
  };
  const { new_password, confirm_password } = formValue;
  const handleSubmit = async (e) => {
    e.preventDefault();

    let reqData = {
      new_password,
      confirm_password,
      id,
    };
    const { error } = await changepassword(reqData);
    if (error !== undefined) {
      if (isEmpty(error.errors)) {
        //  toast.success("Password has been changed successfully", toasterOption)
      } else {
        seterrorValue(error.errors);
      }
    } else {
      if (isEmpty(error)) {
        toast.success('Password has been changed successfully', toasterOption);
        history.push('/community');
      } else {
        seterrorValue(error.errors);
      }
    }
  };
  return (
    <div className="inner_header">
      <Header
        fixed
        color="transparent"
        routes={dashboardRoutes}
        brand={<img src={require('../assets/images/logo.png')} alt="logo" className="img-fluid" />}
        rightLinks={<HeaderLinksCommunity />}
        changeColorOnScroll={{
          height: 50,
          color: 'dark',
        }}
        {...rest}
      />
      <ScrollToTopOnMount />
      <div className={classes.pageHeader + ' inner_pageheader'}>
        <div className={classes.container}>
          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              <div className="d-flex align-items-center justify-content-center">
                <h2 className="inner_title mb-4">Reset Password</h2>
              </div>
            </GridItem>
          </GridContainer>
        </div>
        <div className="container">
          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              <div className="create_box w-50 reset_box">
                <form className="login_form" onSubmit={handleSubmit}>
                  <div className="form-group">
                    <label htmlFor="new_password">New Password</label>
                    <input
                      type="password"
                      id="new_password"
                      name="new_password"
                      onChange={onChange}
                      className="form-control"
                      placeholder="Enter New Password"
                    />
                    <span style={{ color: 'red' }}>{errorValue.new_password}</span>
                  </div>
                  <div className="form-group">
                    <label htmlFor="confirm_password">Confirm Password</label>
                    <input
                      type="password"
                      id="confirm_password"
                      name="confirm_password"
                      onChange={onChange}
                      className="form-control"
                      placeholder="Enter Confirm Password"
                    />
                    <span style={{ color: 'red' }}>{errorValue.confirm_password}</span>
                  </div>
                  <div className="text-center">
                    <Button className="primary_btn" type="submit">
                      Submit
                    </Button>
                  </div>
                </form>
              </div>
            </GridItem>
          </GridContainer>
        </div>
      </div>
      <Footer />
    </div>
  );
}
