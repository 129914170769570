import axios from 'axios';
import { toast } from 'react-toastify';
import config from '../lib/config';

toast.configure();
let toasterOption = config.toasterOption;

export const halfAddrShow = (addr) => {
  if (addr) {
    return addr.substring(0, 5) + '....' + addr.slice(addr.length - 5);
  } else {
    return '';
  }
};

export const getCurAddr = async () => {
  var currAddr = '';
  if (window.ethereum) {
    if (window.web3 && window.web3.eth && window.web3.eth.defaultAccount) {
      currAddr = window.web3.eth.defaultAccount;
      currAddr = currAddr.toString();
    }
  }
  return currAddr;
};

export const ToastShow = async (data) => {
  if (data.toast && data.toast.type && data.toast.msg) {
    if (data.toast.type === 'success') {
      toast.success(data.toast.msg, toasterOption);
    } else {
      toast.error(data.toast.msg, toasterOption);
    }
  }
};

export const ParamAccountAddr_Detail_Get = async (Payload) => {
  try {
    let Resp = await axios({
      method: 'post',
      url: `${config.vUrl}/user/address/details/get`,
      data: Payload,
    });
    return Resp;
  } catch (err) {
    return {
      // error: err.response.data
    };
  }
};

export const getfaq = async (payload) => {
  try {
    let resp = await axios({
      method: 'get',
      url: `${config.vUrl}/user/getfaq`,
    });
    return {
      result: resp.data.data,
    };
  } catch (err) {}
};
export const FollowChange_Action = async (Payload) => {
  try {
    let Resp = await axios({
      method: 'post',
      url: `${config.vUrl}/user/follow/change`,
      data: Payload,
    });
    return Resp;
  } catch (err) {
    return {
      // error: err.response.data
    };
  }
};
export const newslettersAdd = async (Payload) => {
  try {
    let Resp = await axios({
      method: 'post',
      url: `${config.vUrl}/user/newsletter`,
      data: Payload,
    });
    ToastShow(Resp.data);
    return Resp;
  } catch (err) {
    return {
      // error: err.response.data
    };
  }
};

export const PutOnSale_Action = async (Payload) => {};

export const UserProfile_Update_Action = async (Payload) => {
  try {
    let Resp = await axios({
      method: 'post',
      url: `${config.vUrl}/user/profile/update`,
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      data: Payload,
    });
    ToastShow(Resp.data);
    return Resp;
  } catch (err) {
    return {
      // error: err.response.data
    };
  }
};

export const VerifyProfileRequest = async (Payload) => {
  try {
    let Resp = await axios({
      method: 'post',
      url: `${config.vUrl}/user/profile/requestverification`,
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      data: Payload,
    });
    ToastShow(Resp.data);
    return Resp;
  } catch (err) {
    return {
      // error: err.response.data
    };
  }
};

export const User_FollowList_Get_Action = async (Payload) => {
  try {
    let Resp = await axios({
      method: 'post',
      url: `${config.vUrl}/user/follow/list/`,
      data: Payload,
    });
    return Resp;
  } catch (err) {
    return {
      // error: err.response.data
    };
  }
};
export const User_Follow_Get_Action = async (Payload) => {
  try {
    let Resp = await axios({
      method: 'post',
      url: `${config.vUrl}/user/follow/get-follow/`,
      data: Payload,
    });
    return Resp;
  } catch (err) {
    return {
      // error: err.response.data
    };
  }
};
export const Collectibles_Get_Action = async (Payload) => {
  try {
    let Resp = await axios({
      method: 'post',
      url: `${config.vUrl}/user/collectibles`,
      data: Payload,
    });
    return Resp;
  } catch (err) {
    return {
      // error: err.response.data
    };
  }
};

export const changeReceiptStatus_Action = async (Payload) => {
  try {
    let Resp = await axios({
      method: 'post',
      url: `${config.vUrl}/user/changereceiptstatus`,
      data: Payload,
    });
    return {
      data: Resp,
    };
  } catch (err) {
    return {
      // error: err.response.data
    };
  }
};

export const AddressUserDetails_GetOrSave_Action = async (Payload) => {
  try {
    let Resp = await axios({
      method: 'post',
      url: `${config.vUrl}/user/address/details/getorsave`,
      data: Payload,
    });
    return {
      data: Resp,
    };
  } catch (err) {
    return {
      // error: err.response.data
    };
  }
};

// by muthu

export const User_Following_List_Action = async (Payload) => {
  try {
    let Resp = await axios({
      method: 'post',
      url: `${config.vUrl}/user/follow/following-list/`,
      data: Payload,
    });
    return Resp;
  } catch (err) {
    return {
      // error: err.response.data
    };
  }
};

export const Activity_List_Action = async (Payload) => {
  try {
    let Resp = await axios({
      method: 'post',
      url: `${config.vUrl}/user/activitylist/`,
      data: Payload,
    });
    return Resp;
  } catch (err) {
    return {
      // error: err.response.data
    };
  }
};

export const User_Followers_List_Action = async (Payload) => {
  try {
    let Resp = await axios({
      method: 'post',
      url: `${config.vUrl}/user/follow/followers-list/`,
      data: Payload,
    });
    return Resp;
  } catch (err) {
    return {
      // error: err.response.data
    };
  }
};
