// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';
import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { communityCategory } from '../actions/community';
import styles from '../assets/jss/material-kit-react/views/landingPage.js';
import Footer from '../components/Footer/Footer';
import GridContainer from '../components/Grid/GridContainer';
import GridItem from '../components/Grid/GridItem';
// core components
import Header from '../components/Header/Header';
import HeaderLinksCommunity from '../components/Header/HeaderLinksCommunity';
import { SecondsToDay } from '../helper/dateFormat';

toast.configure();
const dashboardRoutes = [];
const useStyles = makeStyles(styles);

// Scroll to Top
function ScrollToTopOnMount() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return null;
}

toast.configure();

export default function Discussion(props) {
  const classes = useStyles();
  const { ...rest } = props;
  const { id } = useParams();
  const [catCommunity, setcatCommunity] = useState([]);

  useEffect(() => {
    getList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getList = async (e) => {
    let { list } = await communityCategory({ id: id });
    setcatCommunity(list);
  };

  return (
    <div className="inner_header">
      <Header
        fixed
        color="transparent"
        routes={dashboardRoutes}
        brand={
          <Link to="/home">
            <img src={require('../assets/images/logo.png')} alt="logo" className="img-fluid" />
          </Link>
        }
        rightLinks={<HeaderLinksCommunity />}
        changeColorOnScroll={{
          height: 50,
          color: 'dark',
        }}
        {...rest}
      />
      <ScrollToTopOnMount />
      <div className={classes.pageHeader + ' inner_pageheader'}>
        <div className={classes.container}>
          <GridContainer>
            <GridItem xs={12} sm={12} md={12} lg={12} className="m-auto">
              <div className="d-flex align-items-center">
                <Link to="/discussion" className="goback_link">
                  <i className="bi bi-chevron-left"></i>
                  <span>Go back</span>
                </Link>
              </div>
            </GridItem>
          </GridContainer>
        </div>
        <div className="container mt-3">
          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              <div className="tab-content explore_tab_content mt-0" id="nav-tabContent">
                <div className="tab-pane show active" id="latest" role="tabpanel" aria-labelledby="following-tab">
                  <div className="proposal_panel_overall">
                    <div className="table-responsive">
                      <table className="table table_style_1">
                        <thead>
                          <tr>
                            <th>
                              <div className="form-group mb-0"></div>
                              Category
                            </th>
                            <th className="text-right"></th>
                            <th className="text-right"> Replies </th>
                            <th className="text-right"> Views </th>
                            <th className="text-right"> Activity </th>
                          </tr>
                        </thead>
                        <tbody>
                          {catCommunity &&
                            catCommunity.length > 0 &&
                            catCommunity.map((row, index) => {
                              return (
                                <tr>
                                  <td>
                                    <Link to={'/discussion-detail/' + row._id}>
                                      <p className="cat_title mb-0">{row.topic}</p>
                                    </Link>
                                    <Link>
                                      <p className="cat_link cat_link_green mb-0">{row.category_name}</p>
                                    </Link>
                                  </td>
                                  <td className="text-right">
                                    <div className="d-flex creators_details_td justify-content-end">
                                      {row.userList &&
                                        row.userList.length > 0 &&
                                        row.userList.map((sub, subindex) => (
                                          <img
                                            src={require('../assets/images/avatars/avatar8.jpg')}
                                            alt="User"
                                            className="img-fluid"
                                          />
                                        ))}
                                    </div>
                                  </td>
                                  <td className="text-right"> {row.reply_count} </td>
                                  <td className="text-right"> {row.views_count} </td>
                                  <td className="text-right"> {SecondsToDay(row.created_date)} </td>
                                </tr>
                              );
                            })}
                          {catCommunity && catCommunity.length === 0 && <tr>No results found..!</tr>}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </GridItem>
          </GridContainer>
        </div>
      </div>

      <Footer />
    </div>
  );
}
