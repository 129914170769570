import { makeStyles } from '@material-ui/core/styles';
import axios from 'bnb-javascript-sdk-nobroadcast/node_modules/axios';
import React, { useEffect, useRef, useState } from 'react';
import { CollectiblesList_MyItems } from '../actions/token';
import { getCurAddr } from '../actions/user';
import styles from '../assets/jss/material-kit-react/views/landingPage.js';
import GridContainer from '../components/Grid/GridContainer';
import GridItem from '../components/Grid/GridItem';
import Header from '../components/Header/Header';
import HeaderLinks from '../components/Header/HeaderLinks';
import config from '../lib/config';
import SendNFTModal from './SendNftModal';
import ConnectWallet from './separate/ConnectWallet';
import SendableTokenItem from './separate/SendableTokenItem';
const UseStyles = makeStyles(styles);

export default function SendNFTs(props) {
  const classes = UseStyles();
  const [WalletConnected, Set_WalletConnected] = React.useState('false');
  const [UserAccountAddr, Set_UserAccountAddr] = React.useState('');
  const [UserAccountBal, Set_UserAccountBal] = React.useState(0);
  const [AddressUserDetails, Set_AddressUserDetails] = useState({});
  const [Accounts, Set_Accounts] = React.useState('');
  const [currentAddr, setCurrentAddr] = useState('');
  const { ...rest } = props;
  const dashboardRoutes = [];
  const [listOfToken, setListOfToken] = useState([]);
  const [listOfNFTs, setListOfNFTs] = useState([]);
  const sendNftRef = useRef();

  useEffect(() => {
    if (currentAddr !== '') {
      fetchAndSetUserOwnTokens();
    }
  }, [currentAddr]);

  useEffect(() => {
    let currAddr;

    const id = setInterval(async () => {
      currAddr = await getCurAddr();

      if (currAddr) {
        setCurrentAddr(currAddr);

        clearInterval(id);
      }
    }, 1000);
  }, []);

  const fetchAndSetUserOwnTokens = async () => {
    try {
      const { data } = await axios.post(`${config.vUrl}/user/token/list`, { currentAddr });

      if (data) {
        const list = data;
        setListOfNFTs(data);
        const setValueForTokenList = list.map((token) => {
          return {
            value: token._id,
            label: token.tokenName,
          };
        });

        setListOfToken(setValueForTokenList);
      } else {
        setListOfToken([]);
      }
    } catch (error) {
      setListOfToken([]);
    }
  };

  useEffect(() => {
    if (WalletConnected && currentAddr !== '') {
      const payload = {
        Addr: currentAddr,
        MyItemAccountAddr: currentAddr,
        ParamAccountAddr: '',
        UserAccountAddr: UserAccountAddr,
        Target: 'List',
        TabName: 'collectibles',
        init: false,
        from: 'My-Items',
      };
      (async () => {
        const res = await CollectiblesList_MyItems(payload);
        const resData = res?.data;
        if (resData?.list?.length > 0) {
          setListOfNFTs(resData?.list);
        }
      })();
    }
  }, [WalletConnected, currentAddr]);
  return (
    <div>
      {' '}
      <SendNFTModal ref={sendNftRef} />
      <ConnectWallet
        Set_UserAccountAddr={Set_UserAccountAddr}
        Set_UserAccountBal={Set_UserAccountBal}
        Set_WalletConnected={Set_WalletConnected}
        Set_AddressUserDetails={Set_AddressUserDetails}
        Set_Accounts={Set_Accounts}
        WalletConnected={WalletConnected}
        AfterWalletConnected={() => {}}
      />
      <Header
        fixed
        color="transparent"
        routes={dashboardRoutes}
        brand={<img src={require('../assets/images/logo.png')} alt="logo" className="img-fluid" />}
        rightLinks={<HeaderLinks />}
        changeColorOnScroll={{
          height: 50,
          color: 'dark',
        }}
        {...rest}
      />
      <div className={classes.pageHeader + ' inner_pageheader'}>
        <div className="">
          <div className="d-flex justify-content-center">
            <h1 className={classes.sendNFTTitle}>Send NFTs</h1>
          </div>
          <div className="container">
            <GridContainer>
              {listOfNFTs &&
                listOfNFTs.length > 0 &&
                listOfNFTs.map((item) => {
                  return (
                    <GridItem xs={12} sm={6} md={3}>
                      <SendableTokenItem
                        key={item?.id}
                        item={item}
                        UserAccountAddr={currentAddr}
                        WalletConnected={WalletConnected}
                        onSendClick={() => sendNftRef?.current?.sendNFTClick(item)}
                      />
                    </GridItem>
                  );
                })}
            </GridContainer>
          </div>
        </div>
      </div>
    </div>
  );
}
