import { Button } from '@material-ui/core';
import React, { forwardRef, useImperativeHandle } from 'react';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import Web3 from 'web3';
import ABI from '../../ABI/ABI.json';
import { BurnField, TokenCounts_Get_Detail_Action } from '../../actions/token';
import { getCurAddr } from '../../actions/user';
import config from '../../lib/config';

toast.configure();
let toasterOption = config.toasterOption;

export const BurnRef = forwardRef((props, ref) => {
  const history = useHistory();
  const [burnLoading, setBurnLoading] = React.useState('empty');
  const [noofitems, setnoofitem] = React.useState(0);
  const [MyTokenDetail, Set_MyTokenDetail] = React.useState(0);
  var [item1, Set_item1] = React.useState({});

  var {
    UserAccountAddr,
    // Set_item
  } = props;

  const FormSubmit = async (item, Burntoken) => {
    if (parseInt(noofitems) > 0 && parseInt(noofitems) <= parseInt(MyTokenDetail.balance)) {
      if (window.ethereum) {
        var web3 = new Web3(window.ethereum);
        if (web3 && web3.eth) {
          var connectwallet = localStorage.getItem('jakcojsnick');
          if (!connectwallet) {
            window.location.href = '/connect';
            return false;
          }
          var CoursetroContract = new web3.eth.Contract(ABI, config.multipleContract);
          setBurnLoading('processing');

          CoursetroContract.methods
            .burn(UserAccountAddr, item.tokenCounts, noofitems)
            .send({ from: props.Accounts })
            .then(async (result) => {
              setBurnLoading('done');
              var postData = {
                tokenOwner: UserAccountAddr,
                tokenCounts: item.tokenCounts,
                blockHash: result.blockHash,
                transactionHash: result.transactionHash,
                contractAddress: item.contractAddress,
                type: item.type,
                balance: noofitems,
                currAddr: UserAccountAddr,
                quant: item.balance,
              };
              var updateBurnField = await BurnField(postData);
              if (updateBurnField) {
                toast.success('Burned successfully', toasterOption);
                // window.$('#burn_token_modal').modal('hide');
                document.getElementById('closeburn').click();
                history.push('/');
              }
            })
            .catch((error) => {
              setBurnLoading('try');
              toast.error('Order not placed', toasterOption);
            });
        }
      }
    } else {
      toast.error('Invalid Quantity ,, Please Check Quantity', toasterOption);
    }
  };
  const TokenCounts_Get_Detail_Call = async (payload) => {
    var curAddr = await getCurAddr();
    var Resp = await TokenCounts_Get_Detail_Action(payload);

    if (Resp && Resp && Resp.data && Resp.data.Detail && Resp.data.Detail.Resp) {
      var TokenResp = Resp.data.Detail.Resp;

      var IndexVal = -1;

      if (TokenResp.Token[0].tokenowners_all && curAddr) {
        var tokenowners_all = TokenResp.Token[0].tokenowners_all;
        IndexVal = tokenowners_all.findIndex(
          (val) => val.tokenOwner.toString() === curAddr.toString() && val.balance > 0,
        );
      }

      if (IndexVal > -1) {
        Set_MyTokenDetail(tokenowners_all[IndexVal]);
      } else {
        Set_MyTokenDetail({});
      }

      if (TokenResp.Token && TokenResp.Token[0]) {
        //(TokenResp.Token[0]);
        Set_item1(TokenResp.Token[0]);
      }
    }
  };
  async function cleardata() {
    setnoofitem(0);
  }
  const handleChange = (e) => {
    if (MyTokenDetail >= e.target.value) {
      setnoofitem(e.target.value);
      setBurnLoading('init');
    } else if (e.target.value === 0) {
      setBurnLoading('zero');
    } else if (e.target.value === '') {
      setBurnLoading('empty');
    } else if (e.target.value === undefined) {
      setBurnLoading('empty');
    } else {
      setBurnLoading('errors');
    }
  };
  useImperativeHandle(ref, () => ({
    async Burn_Click(item, burndata) {
      var curAddr = await getCurAddr();
      var payload = {
        curAddr: curAddr,
        tokenCounts: item.tokenCounts,
      };
      TokenCounts_Get_Detail_Call(payload);
      window.$('#burn_token_modal').modal('show');
    },
  }));

  return (
    <div
      className="modal fade primary_modal"
      id="burn_token_modal"
      tabIndex="-1"
      role="dialog"
      aria-labelledby="burn_token_modalCenteredLabel"
      aria-hidden="true"
      data-backdrop="static"
      data-keyboard="false"
    >
      <div className="modal-dialog modal-dialog-centered modal-sm" role="document">
        <div className="modal-content">
          <div className="modal-header text-center">
            <h5 className="modal-title" id="burn_token_modalLabel">
              Burn token
            </h5>
            <button
              type="button"
              id="closeburn"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
              onClick={() => window.location.reload(false)}
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body px-0">
            <form className="bid_form">
              <div className="px-4">
                <p className="checkout_text_light_white text-center" style={{ fontWeight: 'bold' }}>
                  {MyTokenDetail.balance} Tokens Available
                </p>
              </div>
              <div className="px-4">
                <p className="checkout_text_light_white">
                  Are you sure to burn this token? This action cannot be undone. Token will be transfered to zero
                  address
                </p>
              </div>
              <div className="px-4 mt-4 pt-2">
                <input
                  id="burn"
                  name="burn"
                  className="form-control"
                  onChange={(e) => {
                    handleChange(e);
                  }}
                  value={noofitems}
                />
                <div className="text-center mt-3">
                  <Button
                    className="primary_btn"
                    onClick={() => FormSubmit(item1, MyTokenDetail)}
                    disabled={
                      burnLoading === 'done' ||
                      burnLoading === 'processing' ||
                      burnLoading === 'zero' ||
                      burnLoading === 'errors' ||
                      burnLoading === 'empty'
                    }
                  >
                    {burnLoading === 'processing' && (
                      <i className="fa fa-spinner mr-3 spinner_icon" aria-hidden="true" id="circle1"></i>
                    )}
                    {burnLoading === 'init' && 'Continue'}
                    {burnLoading === 'processing' && 'In-progress...'}
                    {burnLoading === 'done' && 'Done'}
                    {burnLoading === 'try' && 'Try Again'}
                    {burnLoading === 'errors' && 'Check Balance'}
                    {burnLoading === 'zero' && 'Quantity cannot be 0'}
                    {burnLoading === 'empty' && 'Quantity cannot be empty'}
                  </Button>
                  <Button className="create_btn" data-dismiss="modal" onClick={() => cleardata()} aria-label="Close">
                    Cancel
                  </Button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
});
