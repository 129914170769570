import '@metamask/legacy-web3';
import React, { forwardRef, useImperativeHandle } from 'react';
import { toast } from 'react-toastify';
import { InlineShareButtons } from 'sharethis-reactjs';
import config from '../../lib/config';

toast.configure();
var Front_URL = config.Front_URL;

export const ShareNowRef = forwardRef((props, ref) => {
  const [ShareUrl, setShareUrl] = React.useState('');

  useImperativeHandle(ref, () => ({
    async ShareSocial_Click(item) {
      if (item && item._id) {
        var url = Front_URL + '/info/' + item.tokenCounts;
        setShareUrl(url);
        window.$('#share_modal').modal('show');
      }
    },
  }));

  return (
    <div>
      <div
        className="modal fade primary_modal"
        id="share_modal"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="share_modalCenteredLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered modal" role="document">
          <div className="modal-content">
            <div className="modal-header text-center">
              <h5 className="modal-title" id="share_modalLabel">
                Share link to this page
              </h5>
              <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <InlineShareButtons
                config={{
                  alignment: 'center',
                  color: 'social',
                  enabled: true,
                  font_size: 16,
                  language: 'en',
                  networks: ['telegram', 'facebook', 'twitter'],
                  padding: 12,
                  radius: 4,
                  show_total: false,
                  size: 40,
                  url: ShareUrl,
                  image: 'https://bit.ly/2CMhCMC',
                  description: 'ORE Forg',
                  title: 'ORE Forg',
                  message: 'Hi visit ORE Forg and buy Article what You want',
                  subject: 'Hi visit ORE Forg and buy Article what You want',
                  username: 'ORE Forg',
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
});
