import { Button } from '@material-ui/core';
// nodejs library that concatenates classes
// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';
import '@metamask/legacy-web3';
import axios from 'axios';
import React, { useEffect, useRef, useState } from 'react';
// Slick Carousel
import Slider from 'react-slick';
import { toast } from 'react-toastify';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';
import Web3 from 'web3';
import DETH_ABI from '../ABI/DETH_ABI.json';
import {
  Bidding_Detail_Action,
  CollectiblesList_Home,
  hotcollectionlists,
  licensedlists,
  liveauctionlists,
  Topseller_List_Action,
} from '../actions/token';
import { getCurAddr } from '../actions/user';
import styles from '../assets/jss/material-kit-react/views/landingPage.js';
import Footer from '../components/Footer/Footer';
import GridContainer from '../components/Grid/GridContainer';
import GridItem from '../components/Grid/GridItem';
// @material-ui/icons
// core components
import Header from '../components/Header/Header';
import HeaderLinks from '../components/Header/HeaderLinks';
import config from '../lib/config';
import { BurnRef } from './separate/BurnRef';
import { CancelOrderRef } from './separate/CancelOrderRef';
import CollectionDisplay from './separate/CollectionDisplay';
import ConnectWallet from './separate/ConnectWallet';
import Explore from './separate/Explore';
import { LikeRef } from './separate/LikeRef';
import { PlaceAndAcceptBidRef } from './separate/PlaceAndAcceptBidRef';
import { PurchaseNowRef } from './separate/PurchaseNowRef';
import { PutOnSaleRef } from './separate/PutOnSaleRef';
import { ReportNowRef } from './separate/ReportNowRef';
import { ShareNowRef } from './separate/ShareNowRef';
import TokenItem from './separate/TokenItem';
import { WalletRef } from './separate/WalletRef';

toast.configure();

const dashboardRoutes = [];

const useStyles = makeStyles(styles);
// Scroll to Top
function ScrollToTopOnMount() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return null;
}
const Home = (props) => {
  const classes = useStyles();
  const { ...rest } = props;
  let settingsLiveAuction = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1279,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 959,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 599,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  let settingsHotCollection = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1279,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 959,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 599,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  // Countdown Timer

  let LikeForwardRef = useRef();
  let PutOnSaleForwardRef = useRef();
  let PurchaseNowForwardRef = useRef();
  let BurnForwardRef = useRef();
  let CancelOrderForwardRef = useRef();
  let WalletForwardRef = useRef();
  let ReportForwardRef = useRef();
  let ShareForwardRef = useRef();
  let PlaceABidForwardRef = useRef();

  const [liveauctionlist, setliveauctionlist] = useState([]);
  const [licensedGameList, setLicensedGameList] = useState([]);

  const [LikedTokenList, setLikedTokenList] = React.useState([]);
  const [UserAccountAddr, Set_UserAccountAddr] = React.useState('');
  const [UserAccountBal, Set_UserAccountBal] = React.useState(0);
  const [AddressUserDetails, Set_AddressUserDetails] = useState({});
  const [Accounts, Set_Accounts] = React.useState('');
  const [pricesort, setpricesort] = React.useState('');
  const [datesort, setdatesort] = React.useState('');
  const [likesort, setlikesort] = React.useState('');
  const [ownersort, setownersort] = React.useState('');
  const [MyItemAccountAddr, Set_MyItemAccountAddr] = React.useState('');
  const [HitItem, Set_HitItem] = useState({});
  const [Categorylist, setCategorylist] = React.useState([]);
  const [TokenList, setTokenList] = React.useState([]);
  const [sellerList, setsellerList] = React.useState([]);
  const [CatName, setCatName] = React.useState('All');
  const [CatBasedTokenList, setCatBasedTokenList] = React.useState({
    loader: false,
    All: { page: 1, list: [], onmore: true },
  });
  const [WalletConnected, Set_WalletConnected] = React.useState('false');
  const [hotcollectionlist, sethotcollectionlist] = React.useState([]);
  const [tokenCounts, Set_tokenCounts] = useState(0);
  const [item, Set_item] = useState({});
  const [tokenCounts_Detail, Set_tokenCounts_Detail] = useState({});
  const [MyTokenBalance, Set_MyTokenBalance] = useState(0);
  const [Bids, Set_Bids] = useState([]);
  const [AccepBidSelect, Set_AccepBidSelect] = useState(0);
  const [tokenBidAmt, Set_tokenBidAmt] = useState(0);
  const [NoOfToken, Set_NoOfToken] = useState(1);
  const [ValidateError, Set_ValidateError] = useState({});
  const [TokenBalance, Set_TokenBalance] = useState(0);
  const [YouWillPay, Set_YouWillPay] = useState(0);
  const [YouWillPayFee, Set_YouWillPayFee] = useState(0);
  const [YouWillGet, Set_YouWillGet] = useState(0);
  const [BidApply_ApproveCallStatus, Set_BidApply_ApproveCallStatus] = React.useState('init');
  const [BidApply_SignCallStatus, Set_BidApply_SignCallStatus] = React.useState('init');
  const [AllowedQuantity, Set_AllowedQuantity] = useState(0);
  const [fullLoader, Set_fullLoader] = useState(false);
  const [totaluserbidAmt, setTotaluserbidAmt] = React.useState(0);

  async function BiddingAmout() {
    try {
      let currAddr = window.web3.eth.defaultAccount;
      let payload = { currAddr };
      let Resp = await Bidding_Detail_Action(payload);
      if (Resp && Resp.data) {
        setTotaluserbidAmt(Resp.data.res1);
      }
    } catch (err) {}
  }

  const CategoryListCall = async () => {
    const response = await axios.get(`${config.vUrl}/token/category/list`);
    if (response && response.data && response.data.list) {
      setCategorylist(response.data.list);
    }
  };

  const onLoadMore = () => {
    CatBasedTokenList[CatName].page = CatBasedTokenList[CatName].page + 1;
    setCatBasedTokenList(CatBasedTokenList);

    TokenListCall({
      page: CatBasedTokenList[CatName].page + 1,
      pricesort: pricesort,
      likesort: likesort,
      datesort: datesort,
      ownersort: ownersort,
    });
  };

  const catChange = async (name) => {
    if (name !== CatName) {
      setCatName(name);
      if (typeof CatBasedTokenList[name] === 'undefined') {
        CatBasedTokenList[name] = { page: 1, list: [], onmore: true };
        setCatBasedTokenList(CatBasedTokenList);
        TokenListCall({
          CatName: name,
          page: 1,
          pricesort: pricesort,
          likesort: likesort,
          datesort: datesort,
          ownersort: ownersort,
        });
      }
    }
  };

  const liveauctioncall = async () => {
    let Resp = await liveauctionlists();
    if (Resp && Resp.data && Resp.data.list) {
      setliveauctionlist(Resp.data.list);
    }
  };

  const licensedGames = async () => {
    let Resp = await licensedlists();
    if (Resp && Resp.data && Resp.data.list) {
      setLicensedGameList(Resp.data.list);
    }
  };

  const hotcollectioncall = async () => {
    let Resp = await hotcollectionlists();
    if (Resp && Resp.data && Resp.data.list) {
      sethotcollectionlist(Resp.data.list);
    }
  };

  const getTopSeller = async () => {
    let sellerList = await Topseller_List_Action();
    let list1 = sellerList && sellerList.data && sellerList.data.list ? sellerList.data.list : [];
    list1.sort((a, b) => parseFloat(b.sellCount) - parseFloat(a.sellCount));
    setsellerList(list1);
  };

  const TokenListCall = async (data = {}) => {
    let currAddr = await getCurAddr();
    let name = CatName;
    if (data.CatName) {
      name = data.CatName;
    }
    let payload = {
      limit: config.limit,
      page: CatBasedTokenList[name] && CatBasedTokenList[name].page ? CatBasedTokenList[name].page : 1,
      currAddr: currAddr,
      CatName: name,
      price: data.pricesort,
      like: data.likesort,
      date: data.datesort,
      owner: data.ownersort,
      from: 'Home',
    };
    CatBasedTokenList.loader = true;
    setCatBasedTokenList(CatBasedTokenList);

    let resp = await CollectiblesList_Home(payload);
    CatBasedTokenList.loader = false;
    setCatBasedTokenList(CatBasedTokenList);
    if (
      resp &&
      resp.data &&
      resp.data.from === 'token-collectibles-list-home' &&
      resp.data.list &&
      resp.data.list.length > 0
    ) {
      setTokenList(TokenList.concat(resp.data.list));
      //setTokenList(resp.data.list);
      if (typeof CatBasedTokenList[name] === 'undefined') {
        CatBasedTokenList[name] = { page: 1, list: [] };
      }
      CatBasedTokenList[name].list = CatBasedTokenList[name].list.concat(resp.data.list);
      // CatBasedTokenList[name].list = resp.data.list;
      setCatBasedTokenList([]);
      setCatBasedTokenList(CatBasedTokenList);
    } else {
      CatBasedTokenList[name].onmore = false;
      setCatBasedTokenList([]);
      setCatBasedTokenList(CatBasedTokenList);
    }
  };

  useEffect(() => {
    getInit();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getInit = async () => {
    CategoryListCall();
    TokenListCall();
    BiddingAmout();
    liveauctioncall();
    hotcollectioncall();
    getTopSeller();
    licensedGames();
    //tokenbal();
  };
  const AfterWalletConnected = async () => {
    let curAddr = await getCurAddr();
    if (window.ethereum) {
      let web3 = new Web3(window.ethereum);
      let CoursetroContract = new web3.eth.Contract(DETH_ABI, config.tokenAddr[config.tokenSymbol]);
      let tokenBal = await CoursetroContract.methods.balanceOf(curAddr).call();
      let tokenBalance = tokenBal / config.decimalvalues;
      Set_TokenBalance(tokenBalance.toFixed(5));
    }

    try {
      LikeForwardRef.current.getLikesData();
    } catch (err) {}
  };

  const GetUserBal = async () => {
    await WalletForwardRef.current.GetUserBal();
  };

  const pricechange = async (e) => {
    setlikesort('');
    setdatesort('');
    setownersort('');
    if (e.target.value === '1') {
      setpricesort(1);
    } else {
      setpricesort(-1);
    }
    TokenListCall({
      CatName: CatName,
      page: 1,
      pricesort: e.target.value === '1' ? 1 : -1,
      likesort: likesort,
      datesort: datesort,
      ownersort: ownersort,
    });
  };

  const likechange = async (e) => {
    setdatesort('');
    setpricesort('');
    setownersort('');
    if (e.target.value === '1') {
      setlikesort(1);
    } else {
      setlikesort(-1);
    }
    TokenListCall({
      CatName: CatName,
      page: 1,
      pricesort: pricesort,
      likesort: e.target.value === '1' ? 1 : -1,
      datesort: datesort,
      ownersort: ownersort,
    });
  };

  const authorchange = async (e) => {
    if (e.target.value === '-1') {
      setlikesort('');
      setdatesort('');
      setpricesort('');
      setownersort(-1);
    } else {
      setownersort(e.target.value);
    }
    TokenListCall({
      CatName: CatName,
      page: 1,
      pricesort: '',
      likesort: '',
      datesort: '',
      ownersort: e.target.value === '-1' ? -1 : e.target.value,
    });
  };

  const sortchange = async (e) => {
    if (e.target.value === '1') {
      setdatesort(1);
      setlikesort('');
      setpricesort('');
      setownersort('');
      TokenListCall({
        CatName: CatName,
        page: 1,
        pricesort: pricesort,
        likesort: likesort,
        datesort: e.target.value === '1' ? 1 : -1,
        ownersort: ownersort,
      });
    } else if (e.target.value === '-1') {
      setdatesort(-1);
      setlikesort('');
      setpricesort('');
      setownersort('');
      TokenListCall({
        CatName: CatName,
        page: 1,
        pricesort: pricesort,
        likesort: likesort,
        datesort: e.target.value === '1' ? 1 : -1,
        ownersort: ownersort,
      });
    } else if (e.target.value === 'lowprice') {
      setpricesort(1);
      setlikesort('');
      setdatesort('');
      setownersort('');
      TokenListCall({
        CatName: CatName,
        page: 1,
        pricesort: 1,
        likesort: likesort,
        datesort: datesort,
        ownersort: ownersort,
      });
    } else {
      setpricesort(-1);
      setlikesort('');
      setdatesort('');
      setownersort('');
      TokenListCall({
        CatName: CatName,
        page: 1,
        pricesort: -1,
        likesort: likesort,
        datesort: datesort,
        ownersort: ownersort,
      });
    }
  };

  return (
    <div className="home_header">
      <ConnectWallet
        Set_UserAccountAddr={Set_UserAccountAddr}
        Set_UserAccountBal={Set_UserAccountBal}
        Set_WalletConnected={Set_WalletConnected}
        Set_AddressUserDetails={Set_AddressUserDetails}
        Set_Accounts={Set_Accounts}
        WalletConnected={WalletConnected}
        AfterWalletConnected={AfterWalletConnected}
        Set_fullLoader={Set_fullLoader}
        fullLoader={fullLoader}
      />
      {/*<BidPopup
        item={HitItem}
        UserAccountAddr={UserAccountAddr}
        UserAccountBal={UserAccountBal}
      />*/}
      <PutOnSaleRef
        ref={PutOnSaleForwardRef}
        Set_HitItem={Set_HitItem}
        item={HitItem}
        UserAccountAddr={UserAccountAddr}
        UserAccountBal={UserAccountBal}
        Accounts={Accounts}
        GetUserBal={GetUserBal}
      />
      <PlaceAndAcceptBidRef
        ref={PlaceABidForwardRef}
        Set_HitItem={Set_HitItem}
        Set_WalletConnected={Set_WalletConnected}
        Set_UserAccountAddr={Set_UserAccountAddr}
        Set_UserAccountBal={Set_UserAccountBal}
        Set_AddressUserDetails={Set_AddressUserDetails}
        Set_Accounts={Set_Accounts}
        Set_MyItemAccountAddr={Set_MyItemAccountAddr}
        Set_tokenCounts={Set_tokenCounts}
        Set_item={Set_item}
        Set_tokenCounts_Detail={Set_tokenCounts_Detail}
        Set_MyTokenBalance={Set_MyTokenBalance}
        Set_Bids={Set_Bids}
        Set_AccepBidSelect={Set_AccepBidSelect}
        Set_tokenBidAmt={Set_tokenBidAmt}
        Set_NoOfToken={Set_NoOfToken}
        Set_ValidateError={Set_ValidateError}
        Set_TokenBalance={Set_TokenBalance}
        Set_YouWillPay={Set_YouWillPay}
        Set_YouWillPayFee={Set_YouWillPayFee}
        Set_YouWillGet={Set_YouWillGet}
        Set_BidApply_ApproveCallStatus={Set_BidApply_ApproveCallStatus}
        Set_BidApply_SignCallStatus={Set_BidApply_SignCallStatus}
        WalletConnected={WalletConnected}
        UserAccountAddr={UserAccountAddr}
        UserAccountBal={UserAccountBal}
        AddressUserDetails={AddressUserDetails}
        Accounts={Accounts}
        MyItemAccountAddr={MyItemAccountAddr}
        tokenCounts={tokenCounts}
        item={item}
        tokenCounts_Detail={tokenCounts_Detail}
        MyTokenBalance={MyTokenBalance}
        Bids={Bids}
        AccepBidSelect={AccepBidSelect}
        tokenBidAmt={tokenBidAmt}
        NoOfToken={NoOfToken}
        ValidateError={ValidateError}
        TokenBalance={TokenBalance}
        YouWillPay={YouWillPay}
        YouWillPayFee={YouWillPayFee}
        YouWillGet={YouWillGet}
        BidApply_ApproveCallStatus={BidApply_ApproveCallStatus}
        BidApply_SignCallStatus={BidApply_SignCallStatus}
        AllowedQuantity={AllowedQuantity}
        totaluserbidAmt={totaluserbidAmt}
        setTotaluserbidAmt={setTotaluserbidAmt}
      />
      <PurchaseNowRef
        ref={PurchaseNowForwardRef}
        Set_HitItem={Set_HitItem}
        item={HitItem}
        UserAccountAddr={UserAccountAddr}
        UserAccountBal={UserAccountBal}
        TokenBalance={TokenBalance}
        Accounts={Accounts}
        GetUserBal={GetUserBal}
      />
      <WalletRef
        ref={WalletForwardRef}
        Set_UserAccountAddr={Set_UserAccountAddr}
        Set_WalletConnected={Set_WalletConnected}
        Set_UserAccountBal={Set_UserAccountBal}
      />
      <LikeRef ref={LikeForwardRef} setLikedTokenList={setLikedTokenList} MyItemAccountAddr={MyItemAccountAddr} />
      <BurnRef
        ref={BurnForwardRef}
        GetUserBal={GetUserBal}
        UserAccountAddr={UserAccountAddr}
        UserAccountBal={UserAccountBal}
        TokenBalance={TokenBalance}
        Accounts={Accounts}
      />

      <CancelOrderRef
        ref={CancelOrderForwardRef}
        Set_HitItem={Set_HitItem}
        item={HitItem}
        UserAccountAddr={UserAccountAddr}
        UserAccountBal={UserAccountBal}
        TokenBalance={TokenBalance}
        Accounts={Accounts}
        GetUserBal={GetUserBal}
      />
      <ReportNowRef UserAccountAddr={UserAccountAddr} ref={ReportForwardRef} />
      <ShareNowRef ref={ShareForwardRef} />
      <Header
        color="transparent"
        routes={dashboardRoutes}
        brand={<img src={require('../assets/images/logo.png')} alt="logo" className="img-fluid" />}
        rightLinks={<HeaderLinks />}
        fixed
        changeColorOnScroll={{
          height: 20,
          color: 'white',
        }}
        {...rest}
      />
      <ScrollToTopOnMount />
      <section className="homeBanner">
        <div className="container">
          <GridContainer>
            <GridItem xs={12} sm={7} md={7} lg={8}>
              <div className="homeDetails">
                <h2>
                  ORE FORGE <small> - Next Gen NFT</small>
                </h2>
                <p>
                  Digital marketplace for Next-Gen crypto collectibles and non-fungible tokens. Buy, sell, and discover
                  exclusive in-game digital assets.
                </p>
                <div className="homeBannerButton">
                  <a href="" className="btn btnRedButton">
                    EXPLORE
                  </a>

                  <a
                    href={
                      UserAccountAddr && UserAccountAddr !== '' && UserAccountAddr !== undefined
                        ? '/create'
                        : '/connect'
                    }
                    className="btn btnyellowButton"
                  >
                    CREATE
                  </a>
                </div>
              </div>
            </GridItem>
            <GridItem xs={12} sm={5} md={5} lg={4}>
              <div className="homeBannerImage text-right">
                <img src={require('../assets/images/bannerImg-logo.png')} alt="" className="img-fluid" />
              </div>
            </GridItem>
          </GridContainer>
        </div>
      </section>

      <div className="homeMain">
        {licensedGameList && licensedGameList.length > 0 && (
          <section className="liveAuction">
            <div className="container">
              <div className="main__title">
                <h2>ORE Stealth Games</h2>
              </div>

              <div className="main__carousel-wrap">
                <div className="main__carousel main__carousel--live owl-carousel" id="live">
                  <Slider {...settingsLiveAuction}>
                    {licensedGameList &&
                      licensedGameList.map((item) => {
                        return (
                          <TokenItem
                            key={item.tokenName}
                            item={item}
                            Set_item={Set_item}
                            LikedTokenList={LikedTokenList}
                            hitLike={LikeForwardRef.current.hitLike}
                            UserAccountAddr={UserAccountAddr}
                            UserAccountBal={UserAccountBal}
                            PutOnSale_Click={PutOnSaleForwardRef.current.PutOnSale_Click}
                            PurchaseNow_Click={PurchaseNowForwardRef.current.PurchaseNow_Click}
                            PlaceABid_Click={PlaceABidForwardRef.current.PlaceABid_Click}
                            Set_Bids={Set_Bids}
                            Bids={Bids}
                            Set_tokenCounts_Detail={Set_tokenCounts_Detail}
                            Set_MyTokenBalance={Set_MyTokenBalance}
                            Set_AllowedQuantity={Set_AllowedQuantity}
                            Set_YouWillPay={Set_YouWillPay}
                            Set_YouWillPayFee={Set_YouWillPayFee}
                            Set_YouWillGet={Set_YouWillGet}
                            Bid_Click={PlaceABidForwardRef.current.Bid_Click}
                            Burn_Click={BurnForwardRef.current.Burn_Click}
                            CancelOrder_Click={CancelOrderForwardRef.current.CancelOrder_Click}
                            WalletConnected={WalletConnected}
                            SubmitReport_Click={ReportForwardRef.current.SubmitReport_Click}
                            ShareSocial_Click={ShareForwardRef.current.ShareSocial_Click}
                          />
                        );
                      })}
                  </Slider>
                </div>
              </div>
            </div>
          </section>
        )}

        {liveauctionlist && liveauctionlist.length > 0 && (
          <section className="liveAuction">
            <div className="container">
              <div className="main__title">
                <h2>Live auctions</h2>
              </div>

              <div className="main__carousel-wrap">
                <div className="main__carousel main__carousel--live owl-carousel" id="live">
                  <Slider {...settingsLiveAuction}>
                    {liveauctionlist &&
                      liveauctionlist.map((item) => {
                        return (
                          <TokenItem
                            key={item.tokenName}
                            item={item}
                            Set_item={Set_item}
                            LikedTokenList={LikedTokenList}
                            hitLike={LikeForwardRef.current.hitLike}
                            UserAccountAddr={UserAccountAddr}
                            UserAccountBal={UserAccountBal}
                            PutOnSale_Click={PutOnSaleForwardRef.current.PutOnSale_Click}
                            PurchaseNow_Click={PurchaseNowForwardRef.current.PurchaseNow_Click}
                            PlaceABid_Click={PlaceABidForwardRef.current.PlaceABid_Click}
                            Set_Bids={Set_Bids}
                            Bids={Bids}
                            Set_tokenCounts_Detail={Set_tokenCounts_Detail}
                            Set_MyTokenBalance={Set_MyTokenBalance}
                            Set_AllowedQuantity={Set_AllowedQuantity}
                            Set_YouWillPay={Set_YouWillPay}
                            Set_YouWillPayFee={Set_YouWillPayFee}
                            Set_YouWillGet={Set_YouWillGet}
                            Bid_Click={PlaceABidForwardRef.current.Bid_Click}
                            Burn_Click={BurnForwardRef.current.Burn_Click}
                            CancelOrder_Click={CancelOrderForwardRef.current.CancelOrder_Click}
                            WalletConnected={WalletConnected}
                            SubmitReport_Click={ReportForwardRef.current.SubmitReport_Click}
                            ShareSocial_Click={ShareForwardRef.current.ShareSocial_Click}
                          />
                        );
                      })}
                  </Slider>
                </div>
              </div>
            </div>
          </section>
        )}

        <section className="homeTopSellers">
          <div className="container">
            <div className="main__title">
              <h2>Top sellers</h2>
            </div>

            <div className="topSellersBox">
              <GridContainer>
                <GridItem xs={12} sm={12} md={12} lg={12}>
                  <ul className="sellers-list otherSellerList">
                    {sellerList &&
                      sellerList.length > 0 &&
                      sellerList.map((item, i) => (
                        <li key={item._id}>
                          <span className="sellers-list__number">{parseInt(i) + parseInt(1)}</span>
                          <a
                            href={`${config.Front_URL}/userprofile/${sellerList[i].curraddress}`}
                            style={{ cursor: 'pointer' }}
                          >
                            <div
                              className={
                                item && item.Accountverified === 'Verified'
                                  ? 'sellers-list__author sellers-list__author--verified'
                                  : 'sellers-list__author'
                              }
                            >
                              {item && item.curraddress && item.image ? (
                                <img src={item.image} alt={item.name} />
                              ) : (
                                <img src={require('../assets/images/avatars/avatar.jpg')} alt={item.name} />
                              )}
                              <div className="sellers-list__author_name">
                                {item && item.name && item.name !== '' ? item.name : item.curraddress}
                              </div>
                              <span>{item && item.sellCount} NFTS </span>
                            </div>
                          </a>
                        </li>
                      ))}
                  </ul>
                </GridItem>
              </GridContainer>
            </div>
          </div>
        </section>

        <section className="explore_section section pb-0">
          <div className="container">
            <div className="d-flex align-items-center justify-content-between mb-0 exploreHeaderGroup">
              <div className="main__title mt-0">
                <h2 className="m-0">Explore</h2>
              </div>
              <nav className="masonry_tab_nav">
                <div className="nav nav-tabs masonry_tab" id="nav-tab" role="tablist">
                  <button
                    type="button"
                    className="nav-link active"
                    onClick={() => catChange('All')}
                    data-toggle="tab"
                    role="tab"
                    aria-controls="all"
                    aria-selected="true"
                  >
                    All
                  </button>
                  {Categorylist.map((item) => {
                    return (
                      <button
                        type="button"
                        className="nav-link"
                        onClick={() => catChange(item.name)}
                        data-tabname={item.name}
                        key={item._id}
                        data-toggle="tab"
                        role="tab"
                        aria-controls="all"
                        aria-selected="true"
                      >
                        {item.name}
                      </button>
                    );
                  })}
                </div>
              </nav>
              <div className="custom_filter">
                <a
                  href="#discoverFilter"
                  className="menu_btn landingFilterButton"
                  data-toggle="collapse"
                  role="button"
                  aria-expanded="false"
                  aria-controls="discoverFilter"
                >
                  Filter & Sort <img src={require('../assets/images/filtersorticon.png')} alt="" />
                </a>
              </div>
            </div>
            <div className="collapse" id="discoverFilter">
              <div className="card card-body my-2">
                <GridContainer className="w-100">
                  <GridItem xs={12} sm={6} md={6} lg={3}>
                    <div className="form-group">
                      <label htmlFor="inputEmail4" className="auth_label">
                        Price
                      </label>
                      <select defaultValue="-1" className="custom-select auth_inp" onChange={pricechange}>
                        <option value="-1">Highest Price</option>
                        <option value="1">Lowest Price</option>
                      </select>
                    </div>
                  </GridItem>
                  <GridItem xs={12} sm={6} md={6} lg={3}>
                    <div className="form-group">
                      <label htmlFor="inputEmail4" className="auth_label">
                        Likes
                      </label>
                      <select defaultValue="-1" className="custom-select auth_inp" onChange={likechange}>
                        <option value="-1">Most Liked</option>
                        <option value="1">Least Liked</option>
                      </select>
                    </div>
                  </GridItem>
                  <GridItem xs={12} sm={6} md={6} lg={3}>
                    <div className="form-group">
                      <label htmlFor="inputEmail4" className="auth_label">
                        Creator
                      </label>
                      <select defaultValue="verified" className="custom-select auth_inp" onChange={authorchange}>
                        <option value="verified">Verified Only</option>
                        <option value="popular">Popular Creators</option>
                        <option value="new">New Creators</option>
                      </select>
                    </div>
                  </GridItem>
                  <GridItem xs={12} sm={6} md={6} lg={3}>
                    <div className="form-group">
                      <label htmlFor="inputEmail4" className="auth_label">
                        Sort By
                      </label>
                      <select defaultValue="-1" className="custom-select auth_inp" onChange={sortchange}>
                        <option value="-1">Newest</option>
                        <option value="1">Oldest</option>
                        <option value="lowprice">Price Low</option>
                        <option value="highprice">Price High</option>
                      </select>
                    </div>
                  </GridItem>
                </GridContainer>
              </div>
            </div>
            <div className="tab-content explore_tab_content" id="nav-tabContent">
              <div className="tab-pane fade show active" id="all" role="tabpanel" aria-labelledby="all-tab">
                <GridContainer className="w-100">
                  {CatBasedTokenList &&
                  CatName &&
                  CatBasedTokenList[CatName] &&
                  CatBasedTokenList[CatName].list &&
                  CatBasedTokenList[CatName].list.length > 0 ? (
                    CatBasedTokenList[CatName].list.map((item) => {
                      return (
                        <Explore
                          key={item._id}
                          item={item}
                          Set_item={Set_item}
                          LikedTokenList={LikedTokenList}
                          hitLike={LikeForwardRef.current.hitLike}
                          UserAccountAddr={UserAccountAddr}
                          UserAccountBal={UserAccountBal}
                          PutOnSale_Click={PutOnSaleForwardRef.current.PutOnSale_Click}
                          PurchaseNow_Click={PurchaseNowForwardRef.current.PurchaseNow_Click}
                          PlaceABid_Click={PlaceABidForwardRef.current.PlaceABid_Click}
                          Set_Bids={Set_Bids}
                          Bids={Bids}
                          Set_tokenCounts_Detail={Set_tokenCounts_Detail}
                          Set_MyTokenBalance={Set_MyTokenBalance}
                          Set_AllowedQuantity={Set_AllowedQuantity}
                          Set_YouWillPay={Set_YouWillPay}
                          Set_YouWillPayFee={Set_YouWillPayFee}
                          Set_YouWillGet={Set_YouWillGet}
                          // Bid_Click={PlaceABidForwardRef.current.Bid_Click}
                          Burn_Click={BurnForwardRef.current.Burn_Click}
                          CancelOrder_Click={CancelOrderForwardRef.current.CancelOrder_Click}
                          WalletConnected={WalletConnected}
                          SubmitReport_Click={ReportForwardRef.current.SubmitReport_Click}
                          ShareSocial_Click={ShareForwardRef.current.ShareSocial_Click}
                        />
                      );
                    })
                  ) : (
                    <p className="noRecords">No records found</p>
                  )}
                </GridContainer>
                {CatBasedTokenList &&
                CatBasedTokenList.loader === false &&
                CatBasedTokenList[CatName] &&
                CatBasedTokenList[CatName].onmore === true ? (
                  <p className="text-center">
                    <Button onClick={onLoadMore} className={classes.navLink + ' create_btn'}>
                      Load More
                    </Button>
                  </p>
                ) : (
                  ''
                )}
              </div>
            </div>
          </div>
        </section>
        <section className="homeHotCollection">
          <div className="container">
            <div className="row row--grid">
              <div className="col-12">
                <div className="main__title">
                  <h2>Hot collections</h2>
                </div>
              </div>
              <div className="col-12">
                <div className="main__carousel-wrap">
                  <div className="main__carousel main__carousel--collections owl-carousel" id="collections">
                    <Slider {...settingsHotCollection}>
                      {hotcollectionlist &&
                        hotcollectionlist.map((collection) => {
                          return <CollectionDisplay key={collection._id} collection={collection} />;
                        })}
                    </Slider>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      <Footer />
    </div>
  );
};

export default Home;
