import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';
import './index.css';
import './main.css';
import store from './store';
import Activity from './views/Activity.jsx';
import CollectionListing from './views/CollectionListing';
import Community from './views/Community.jsx';
import communityCategory from './views/CommunityCategory.jsx';
import connect from './views/ConnectWallet.jsx';
import Create from './views/Create.jsx';
import CreateCollection from './views/CreateCollection';
import CreateSingleAndMultiple from './views/CreateSingleAndMultiple.jsx';
import Discussion from './views/Discussion.jsx';
import DiscussionDetail from './views/DiscussionDetail.jsx';
import EditProfile from './views/EditProfile.jsx';
import Following from './views/Following.jsx';
// pages
import Home from './views/Home.jsx';
import HowItWorks from './views/HowItWorks.jsx';
import Info from './views/Info.jsx';
import Myitems from './views/MyItems.jsx';
import Privacypolicy from './views/PrivacyPolicy.jsx';
import Proposals from './views/Proposals.jsx';
import ResetPassword from './views/ResetPassword.jsx';
import search from './views/Search.jsx';
import SendNFTs from './views/SendNFTs';
import Terms from './views/TermsOfService.jsx';
import UserProfile from './views/UserProfile';

ReactDOM.render(
  <Provider store={store}>
    <BrowserRouter basename="/">
      <Switch>
        <Route path="/connect" component={connect} />
        <Route path="/how-it-works" component={HowItWorks} />
        <Route path="/privacy" component={Privacypolicy} />
        <Route path="/terms" component={Terms} />
        <Route path="/Changepassword/:id" component={ResetPassword} />
        <Route path="/community" component={Community} />
        <Route path="/activate/:userId" component={Discussion} />
        <Route path="/discussion-detail/:id" component={DiscussionDetail} />
        <Route path="/discussion" component={Discussion} />
        <Route path="/activity" component={Activity} />
        <Route path="/info/:tokenidval" component={Info} />
        <Route path="/category/:id" component={communityCategory} />
        <Route path="/following" component={Following} />
        <Route path="/my-items" component={Myitems} />
        <Route path="/userprofile/:paramAddress" component={UserProfile} />
        <Route path="/user/:paramUsername" component={Myitems} />
        <Route path="/proposals" component={Proposals} />
        <Route path="/edit-profile" component={EditProfile} />
        <Route path="/send-nft" component={SendNFTs} />
        <Route path="/search" component={search} />
        <Route path="/create-single" component={CreateSingleAndMultiple} />
        <Route path="/create-multiple" component={CreateSingleAndMultiple} />
        <Route path="/create-collection" component={CreateCollection} />
        <Route path="/collections/:id" component={CollectionListing} />
        <Route path="/create" component={Create} />
        <Route path="/home" component={Home} />
        <Route exact path="/*" component={Home}>
          <Redirect to="/home" />
        </Route>
      </Switch>
    </BrowserRouter>
  </Provider>,
  document.getElementById('root'),
);
