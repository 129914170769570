import React, { useMemo } from 'react';
import config from '../../lib/config';

const coverImage = require('../../assets/images/cover/cover3.jpg');

const Preview = ({ filePreUrl, filePreReader }) => {
  const extension = useMemo(() => (filePreUrl ? filePreUrl.split('.').pop() : ''), [filePreUrl]);

  return (
    <>
      {extension === 'mp4' ? (
        <video
          id="imgPreview"
          src={filePreReader ? filePreReader : coverImage}
          type="video/mp4"
          alt="Collection"
          className="img-fluid"
          autoPlay
          playsInLine
          loop
          muted
        ></video>
      ) : extension === 'mp3' ? (
        <audio src={filePreReader ? filePreReader : coverImage} type="audio/mp3" controls className="audio"></audio>
      ) : (
        <img src={filePreReader ? filePreReader : coverImage} id="imgPreview" alt="Collections" className="img-fluid" />
      )}
      {extension === 'mp3' && (
        <img src={config.Back_URL + '/images/music.png'} id="imgPreview" alt="Collections" className="img-fluid" />
      )}
    </>
  );
};

export default Preview;
