/*eslint-disable*/
import { Button, List, ListItem } from '@material-ui/core';
import Hidden from '@material-ui/core/Hidden';
// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';
import { AccountBalanceWallet, FileCopy, Notifications } from '@material-ui/icons';
import '@metamask/legacy-web3';
import { TimeAgo } from '@n1ru4l/react-time-ago';
import axios from 'axios';
import $ from 'jquery';
import React, { useEffect, useRef } from 'react';
// react components for routing our app without refresh
import { Link, NavLink } from 'react-router-dom';
import Web3 from 'web3';
import DETH_ABI from '../../ABI/DETH_ABI.json';
import { Activity_List_Action } from '../../actions/user';
import styles from '../../assets/jss/material-kit-react/components/headerLinksStyle.js';
import config from '../../lib/config';
// core components
import CustomDropdown from '../CustomDropdown/CustomDropdown';

const useStyles = makeStyles(styles);

export default function HeaderLinks(props) {
  const classes = useStyles();

  const [HeaderSearchInputVal, setHeaderSearchInputVal] = React.useState('');
  const [tokenlist, setTokenlist] = React.useState([]);
  const [ActivityList, Set_ActivityList] = React.useState([]);
  const [Activitycount, Set_Activitycount] = React.useState(0);
  const [accounts, setAccounts] = React.useState('');
  const [currAddr, setcurrAddr] = React.useState('');
  const [dethBln, setDethBln] = React.useState(0);
  const [blns, setBln] = React.useState(0);
  const [searchShow, setsearchShow] = React.useState(false);

  useEffect(() => {
    Get_Activity_list();
    getconnect();
  }, []);

  async function getconnect() {
    if (window.ethereum) {
      var web3 = new Web3(window.ethereum);
      try {
        if (typeof web3 !== 'undefined') {
          window.ethereum.enable().then(async function () {
            const web3 = new Web3(window.web3.currentProvider);
            if (window.web3.currentProvider.isMetaMask === true) {
              await web3.eth.getAccounts(async function (error, result) {
                setAccounts(result[0]);
                var currAddr = window.web3.eth.defaultAccount;
                setcurrAddr(currAddr);
                var web3 = new Web3(window.ethereum);
                var CoursetroContract = new web3.eth.Contract(DETH_ABI, config.tokenAddr[config.tokenSymbol]);
                var tokenDethBln = await CoursetroContract.methods.balanceOf(currAddr).call();
                var value = tokenDethBln / 1000000000000000000;
                setDethBln(value.toFixed(8));
                window.web3.eth.getBalance(result[0], async (error, balance) => {
                  var currbal = balance / 1000000000000000000;
                  setBln(currbal.toFixed(5));
                });
              });
            }
          });
        } else {
          //  toast.success("Please Add Metamask External", toasterOption);
        }
      } catch (err) {}
    } else {
      // toast.success("Please Add Metamask External", toasterOption);
    }
  }
  async function Get_Activity_list() {
    if (window && window.web3 && window.web3.eth && window.web3.eth.defaultAccount !== undefined) {
      var currAddr = window.web3.eth.defaultAccount;
      var resp = await Activity_List_Action({
        addr: currAddr,
      });

      if (resp && resp.data && resp.data.list) {
        var List = resp.data.list;
        if (List.length > 0) {
          Set_Activitycount(List.length);
        }
        Set_ActivityList(List);
      } else {
        Set_ActivityList([]);
      }
    } else {
      Set_ActivityList([]);
    }
  }

  $('.search_inp').bind('click', function (e) {
    $('.topSearchPopup').fadeIn();
    e.stopPropagation();
  });

  $(document).bind('click', function () {
    $('.topSearchPopup').fadeOut();
  });

  const HeaderSearchInputClear = (e) => {
    setHeaderSearchInputVal('');
  };

  // var HeaderSearchLoadTimeout;
  const timerRef = useRef(null);

  const inputChange = (e) => {
    if (e && e.target && typeof e.target.value !== 'undefined' && e.target.name) {
      var value = e.target.value;
      switch (e.target.name) {
        case 'HeaderSearchInput':
          setHeaderSearchInputVal(value);
          var data = {
            searchVal: value,
          };
          // HeaderSearchLoad(data);
          if (timerRef.current) {
            clearTimeout(timerRef.current);
          }
          timerRef.current = setTimeout(() => {
            HeaderSearchLoad(data);
          }, 1000);
          break;
        default:
        // code block
      }
    }
  };

  const HeaderSearchLoad = (data) => {
    if (data.searchVal !== '') {
      axios
        .get(`${config.Back_URL}/user/search/` + data.searchVal)
        .then((response) => {
          if (response && response.data) {
            if (response.data.itemdata.length > 0) {
              setTokenlist(response.data.itemdata);
              $('#usemneu_dd').show();
            } else {
              setTokenlist(response.data.data);
              $('#usemneu_dd').show();
            }
          }
        })
        .catch((e) => console.log(e));
    } else {
      $('#usemneu_dd').hide();
    }
  };

  const connect_Wallet_call = () => {
    window.$('#connect_Wallet_call').click();
  };
  async function handleWallet(data) {
    if (window.ethereum) {
      var web3 = new Web3(window.ethereum);
      if (localStorage.getItem('jakcojsnick')) {
      } else {
        window.location.href = '/connect';
      }
    } else {
    }
  }
  async function disconnectMetamask() {
    // alert('hi')
    if (localStorage.getItem('jakcojsnick')) {
      localStorage.removeItem('jakcojsnick');
      Get_Activity_list();
    } else {
      localStorage.removeItem('trustetllaw');
      setAccounts('');
    }
  }
  async function onnotificationclick() {
    // TODO Add logic
  }
  const clearNotifications = () => {
    //Add Logic to clear the notifications
  };

  return (
    <div>
      {/*********************afterlogin***************************************/}
      {localStorage.getItem('jakcojsnick') !== null &&
      localStorage.getItem('jakcojsnick') !== undefined &&
      localStorage.getItem('jakcojsnick') !== '' ? (
        <List className={classes.list + ' main_navbar'}>
          <ListItem className={classes.listItem}>
            <Button className={classes.navLink + ' create_btn'}>
              <Link to="/create">Create</Link>
            </Button>
          </ListItem>
          <ListItem className={classes.listItem + ' menu_dropdown dropdown_header_ul noti_ul'}>
            <CustomDropdown
              noLiPadding
              buttonText={
                <div>
                  <Notifications onClick={onnotificationclick} className="menu_icons" />
                  <span className="xlHide">Notification</span> <sup>{Activitycount}</sup>
                </div>
              }
              dropdownList={[
                <div>
                  <i
                    className="fa fa-times float-right danger"
                    onClick={() => {
                      clearNotifications();
                    }}
                  ></i>
                  {Activitycount === 0 ? (
                    <div className="noti_parent">
                      <p className="no_noti">No records</p>
                    </div>
                  ) : (
                    ActivityList.slice(0, 5).map((item) => {
                      var imageUrl = config.Back_URL + '/images/noimage.png';
                      var text = '';
                      var name = '';
                      if (item.actiontype === 'following') {
                        var followTxt = item.touseraddress;
                        if (item.touseraddress === currAddr) {
                          followTxt = 'You ';
                        }
                        text = 'started following ' + item.touseraddress;
                        name =
                          item.userdetail && item.userdetail.name !== ''
                            ? item.userdetail.name
                            : item.userdetail.curraddress;
                      } else if (item.actiontype === 'listings') {
                        text = 'listed by ' + item.useraddress + ' for ' + item.price + ' ETH';
                        name = item.name ? item.name : '';
                      } else if (item.actiontype === 'bidrequest') {
                        text = item.useraddress + ' offered ' + item.price + ' wETH for 1 edition editions each';
                        name = item.name ? item.name : '';
                      } else if (item.actiontype === 'purchase') {
                        text =
                          'purchased by ' + item.useraddress + ' for ' + item.price + ' ETH from ' + item.touseraddress;
                        name = item.name ? item.name : '';
                      } else if (item.actiontype === 'like') {
                        text = 'liked by ' + item.useraddress;
                        name = item.name ? item.name : '';
                      } else if (item.actiontype === 'sales') {
                        text = 'sales by ' + item.useraddress;
                        name = item.name ? item.name : '';
                      } else if (item.actiontype === 'burns') {
                        text = 'burns by ' + item.useraddress;
                        name = item.name ? item.name : '';
                      }

                      var linkUrl = '/info/' + item.tokenCounts;
                      if (item.itemtype === 'users') {
                        linkUrl = '/user/' + item.useraddress;
                      }

                      if (name.length > 15) {
                        name = name.substring(0, 18) + '...';
                      }
                      return (
                        <Link to={linkUrl} key={item._id}>
                          <div className="noti_parent">
                            <div className="noti_head">
                              {/*  <img src={imageUrl} height="50px" width="50px" alt="User" className="img-fluid mr-3" />
                               */}

                              <div className="media-body flex_body">
                                <div>
                                  <p className="mt-0 media_text">{name}</p>
                                  <p className="mt-0 media_num mt-0">{text}</p>
                                  <p className="mt-0 media_text2">
                                    {<TimeAgo date={new Date(item.timestamp)}>{({ value }) => value}</TimeAgo>}
                                  </p>
                                </div>
                              </div>
                            </div>
                            {/*  <p className="noti_head">
                        {(item.price && item.price > 0) ? <span>{item.price}</span> :''}
                        <span> {item.actiontype} (<TimeAgo date={new Date(item.timestamp)}>{({ value }) => value}</TimeAgo>)</span>
                      </p>*/}
                          </div>
                        </Link>
                      );
                    })
                  )}
                  <Link to="/activity" className="create_btn">
                    View All
                  </Link>
                </div>,
              ]}
            />
          </ListItem>

          <ListItem className={classes.listItem + ' menu_dropdown dropdown_header_ul noti_ul wallet_drop'}>
            <CustomDropdown
              noLiPadding
              buttonText={
                <div>
                  <AccountBalanceWallet className="menu_icons" /> <span className="xlHide">Wallet</span>
                </div>
              }
              dropdownList={[
                <div>
                  <div className="noti_parent">
                    <div className="d-flex align-items-center">
                      <p className="wallet_address">{currAddr}</p> <FileCopy className="ml-2 wllet_copy_icon" />
                    </div>
                    <div className="wallet_balance">
                      <h4>Balance</h4>
                      <p>{parseFloat(blns).toFixed(4)} BNB</p>
                    </div>
                    <div className="wallet_balance">
                      <h4>ORE Balance</h4>
                      <p>{parseFloat(dethBln).toFixed(4)} ORE</p>
                    </div>
                  </div>
                  <hr />
                  <div className="noti_parent">
                    <NavLink to="/my-items" className={classes.dropdownLink}>
                      My Items
                    </NavLink>
                    <NavLink to="/edit-profile" className={classes.dropdownLink}>
                      Edit Profile
                    </NavLink>
                    <NavLink to="/send-nft" className={classes.dropdownLink}>
                      Send NFTs
                    </NavLink>
                    <Button
                      type="submit"
                      className={classes.dropdownLink + ' p-0'}
                      onClick={() => disconnectMetamask()}
                    >
                      Disconnect
                    </Button>
                  </div>
                </div>,
              ]}
            />
          </ListItem>
          <Hidden lgUp>
            <ListItem className={classes.listItem}>
              <NavLink className={classes.navLink} to="/home">
                Explore
              </NavLink>
            </ListItem>

            <ListItem className={classes.listItem}>
              <NavLink className={classes.navLink} to="/my-items">
                My items
              </NavLink>
            </ListItem>

            <ListItem className={classes.listItem}>
              <NavLink className={classes.navLink} to="/how-it-works">
                How it Works
              </NavLink>
            </ListItem>

            <ListItem className={classes.listItem + ' menu_dropdown'}>
              <CustomDropdown
                className="dropDownMainBox"
                noLiPadding
                buttonText="Community"
                dropdownList={[
                  <NavLink to="/activity" className={classes.dropdownLink}>
                    Activity
                  </NavLink>,
                  <NavLink to="/following" className={classes.dropdownLink}>
                    Following
                  </NavLink>,
                  <NavLink to="/community" className={classes.dropdownLink}>
                    Community
                  </NavLink>,
                  <NavLink to="/discussion" className={classes.dropdownLink}>
                    Discussion
                  </NavLink>,
                ]}
              />
            </ListItem>
          </Hidden>
        </List>
      ) : (
        <List className={classes.list + ' main_navbar'}>
          <ListItem className={classes.listItem + ' ml-3'}>
            <Button className={classes.navLink + ' create_btn'}>
              <Link to="/connect">Connect Wallet</Link>
            </Button>
          </ListItem>
          <Hidden lgUp>
            <ListItem className={classes.listItem}>
              <NavLink className={classes.navLink} to="/home">
                Explore
              </NavLink>
            </ListItem>

            <ListItem className={classes.listItem}>
              <NavLink className={classes.navLink} to="/my-items">
                My items
              </NavLink>
            </ListItem>

            <ListItem className={classes.listItem}>
              <NavLink className={classes.navLink} to="/how-it-works">
                How it Works
              </NavLink>
            </ListItem>

            <ListItem className={classes.listItem + ' menu_dropdown'}>
              <CustomDropdown
                className="dropDownMainBox"
                noLiPadding
                buttonText="Community"
                dropdownList={[
                  <NavLink to="/activity" className={classes.dropdownLink}>
                    Activity
                  </NavLink>,
                  <NavLink to="/following" className={classes.dropdownLink}>
                    Following
                  </NavLink>,
                  <NavLink to="/community" className={classes.dropdownLink}>
                    Community
                  </NavLink>,
                  <NavLink to="/discussion" className={classes.dropdownLink}>
                    Discussion
                  </NavLink>,
                ]}
              />
            </ListItem>
          </Hidden>
        </List>
      )}

      {/*********************************community******************************/}

      {/* <ListItem className={classes.listItem}>
        <Button className={classes.navLink + " create_btn btn_header mr-3"} data-toggle="modal" data-target="#login_modal">
          Login
        </Button>
      </ListItem>

      <ListItem className={classes.listItem}>Not
        <Button className={classes.navLink + " create_btn btn_header"} data-toggle="modal" data-target="#register_modal">
         Signup
        </Button>
      </ListItem> */}
    </div>
  );
}
