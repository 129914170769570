import { Button } from '@material-ui/core';
import React, { forwardRef, useImperativeHandle, useState } from 'react';
import { toast } from 'react-toastify';
import Web3 from 'web3';
import ABI from '../ABI/ABI.json';
import { Send_Complete_Action } from '../actions/token';
import config from '../lib/config';

toast.configure();
let toasterOption = config.toasterOption;

const SendNFTModal = forwardRef((props, ref) => {
  const [fieldInputs, setFieldInputs] = useState({ quantity: null, recipientAddress: null });
  const [validationErrors, setValidationErrors] = useState({});
  const [tokenDetail, setTokenDetail] = useState({});
  const [FormSubmitLoading, Set_FormSubmitLoading] = React.useState('');

  useImperativeHandle(
    ref,
    () => ({
      sendNFTClick: async (item) => {
        setTokenDetail(item);
        setFieldInputs({ quantity: null, recipientAddress: null });
        window.$('#SendNow_modal').modal('show');
      },
    }),
    [fieldInputs, validationErrors],
  );
  async function buymodal() {
    setValidationErrors({});
    setFieldInputs({ quantity: null, recipientAddress: null });
    setTimeout(() => window.$('#SendNow_modal').modal('hide'), 600);
    // setTimeout(() => window.location.reload(false), 900);
  }

  const inputChange = (e) => {
    let { name, value } = e.target;
    switch (name) {
      case 'Quantity':
        value = value ? parseInt(value) : null;
        setFieldInputs({ ...fieldInputs, quantity: value });
        if (value === 0 || value < 0) {
          setValidationErrors({ ...validationErrors, quantity: "'Quantity' must be greater than 0" });
        } else if (value === '' || typeof value === 'string' || isNaN(value)) {
          setValidationErrors({ ...validationErrors, quantity: "'Quantity' must be a number." });
        } else {
          setValidationErrors({ ...validationErrors, quantity: '' });
        }
        break;
      case 'RecipientAddress':
        setFieldInputs({ ...fieldInputs, recipientAddress: value });
        if (value === '') {
          setValidationErrors({ ...validationErrors, recipientAddress: "'Recipient Address' is required" });
        } else if (value.length >= 75) {
          setValidationErrors({
            ...validationErrors,
            recipientAddress: "'Recipient Address' should be less than 75 characters.",
          });
        } else {
          setValidationErrors({
            ...validationErrors,
            recipientAddress: '',
          });
        }
        break;
    }
  };

  const isValidate = () => {
    let validate = false;
    Object.values(fieldInputs).map((value) => {
      if (!value) {
        validate = true;
      }
    });
    return validate;
  };

  // Get current MM account
  async function currentMmAccount(address){
    address = await window.ethereum.request({ method: 'eth_requestAccounts' })[0];
  };
  
  const send = () => {
    const contractAddress = config.multipleContract;
    const { tokenQuantity, tokenOwner, tokenCounts, type: tokenType, tokenPrice } = tokenDetail;
    let web3 = new Web3(window.ethereum);
    
    let tokenQuantity_ = fieldInputs.quantity;
    let tokenOwner_ = "";
    
    let CoursetroContract = new web3.eth.Contract(ABI, contractAddress);
    Set_FormSubmitLoading('processing');
    CoursetroContract.methods
        .safeTransferFrom(tokenOwner, fieldInputs.recipientAddress, tokenCounts, tokenQuantity, [])
        .send({ from: tokenOwner })
        .then(async (result) =>
        {
            Set_FormSubmitLoading('done');
            let postData = {
                tokenOwner, // old owner
                UserAccountAddr: fieldInputs.recipientAddress.toLowerCase(), // new owner
                tokenCounts,
                tokenType,
                NoOfToken: tokenType === 721 ? 1 : tokenQuantity,
                transactionHash: result.transactionHash
            };
            let Resp = await Send_Complete_Action(postData);
            if (Resp.data && Resp.data.toast && Resp.data.toast.type === 'success')
            {
                toast.success('Collectible sent successfully', toasterOption);
                window.$('.SendNow_modal').modal('hide');
                setTimeout(() =>
                    {
                        window.location.reload(false);
                    },
                    2000);
            }
        })
        .catch((error) => {
            console.log(error);
            toast.error('Order not placed');
        });
  };

  return (
    <div>
      <div
        className="modal fade primary_modal SendNow_modal"
        id="SendNow_modal"
        tabIndex="-1"
        role="dialog"
        data-backdrop="static"
        data-keyboard="false"
        aria-labelledby="accept_modalCenteredLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered modal-md" role="document">
          <div className="modal-content">
            <div className="modal-header text-center">
              <h5 className="modal-title" id="buy_modalLabel">
                Send NFT
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
                id="close9"
                onClick={buymodal}
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body px-0">
              <div className="bor_bot_modal mb-3 px-4 "></div>
              <form className="bid_form" action="#">
                <div className="mb-3 px-4">
                  <label htmlFor="quantity">Quantity</label>
                  <div className="mb-3 input_grp_style_1">
                    <input
                      type="number"
                      className="form-control primary_inp text-center"
                      name="Quantity"
                      id="quantity"
                      onChange={inputChange}
                      placeholder="e.g. 2"
                      autoComplete="off"
                      value={fieldInputs?.quantity}
                      // onKeyUp={onKeyUp}
                      required
                    />
                    {validationErrors?.quantity && <span className="text-danger">{validationErrors.quantity}</span>}
                  </div>
                </div>
                <div className="bor_bot_modal mb-3 px-4">
                  <label htmlFor="recipientAddress">Recipient Address</label>
                  <div className="mb-3 input_grp_style_1">
                    <input
                      type="text"
                      className="form-control primary_inp text-center"
                      name="RecipientAddress"
                      id="recipientAddress"
                      onChange={inputChange}
                      placeholder="e.g. 0x852886399aD06f23d0cB2EBd44377815690A91a0"
                      autoComplete="off"
                      value={fieldInputs?.recipientAddress}
                      // onKeyUp={onKeyUp}
                      required
                      maxLength={75}
                    />
                    {validationErrors?.recipientAddress && (
                      <span className="text-danger">{validationErrors.recipientAddress}</span>
                    )}
                  </div>
                </div>
              </form>
              <form className="px-4">
                <div className="text-center">
                  <Button
                    type="button"
                    className="primary_btn btn-block"
                    onClick={() => send()}
                    disabled={FormSubmitLoading === 'processing'}
                  >
                    {FormSubmitLoading === 'processing' && (
                      <i className="fa fa-spinner mr-3 spinner_icon" aria-hidden="true" id="circle1"></i>
                    )}
                    Send
                  </Button>
                  <Button className="create_btn  btn-block" data-dismiss="modal" aria-label="Close">
                    Cancel
                  </Button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
});

export default SendNFTModal;
