import Input from '@material-ui/core/Input';
import { makeStyles } from '@material-ui/core/styles';
import React, { useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { CreateCollectionItem } from '../actions/token';
import styles from '../assets/jss/material-kit-react/views/landingPage.js';
import Preview from '../components/Common/Preview';
import ScrollToTopOnMount from '../components/Common/ScrollToTopOnMount';
import Button from '../components/CustomButtons/Button';
import Footer from '../components/Footer/Footer';
import GridContainer from '../components/Grid/GridContainer';
import GridItem from '../components/Grid/GridItem';
import Header from '../components/Header/Header';
import HeaderLinks from '../components/Header/HeaderLinks';
import config from '../lib/config';

const logImage = require('../assets/images/logo.png');
const arrowIcon = require('../assets/images/arrow_icon.png');
const useStyles = makeStyles(styles);
let toasterOption = config.toasterOption;

const defaultValues = {
  name: '',
  validname: true,
  description: '',
  image: '',
};

const CreateCollection = ({ ...rest }) => {
  const [formValues, setFormValues] = useState(defaultValues);
  const [filePreReader, setFilePreReader] = useState();
  const [filePreUrl, setFilePreUrl] = useState();
  const [attachFile, setAttachFile] = useState();
  const [isPrivate, setIsPrivate] = useState(false);
  const history = useHistory();

  const classes = useStyles();

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormValues({
      ...formValues,
      [name]: value,
      ['valid' + name]: !!value,
    });
  };

  const selectFileChange = (e) => {
    const validExtensions = ['png', 'gif', 'webp', 'mp4', 'mp3']; //array of valid extensions

    if (e.target && e.target.files) {
      const reader = new FileReader();
      const file = e.target.files[0];
      const fileName = file.name;
      const fileNameExt = fileName.substr(fileName.lastIndexOf('.') + 1).toLowerCase();

      if (!validExtensions.includes(fileNameExt)) {
        toast.error('Only these file types are accepted : ' + validExtensions.join(', '), toasterOption);
        return false;
      } else {
        const fileSize = file.size / 1024 / 1024;

        if (fileSize > 30) {
          toast.error('File size exceeds 30 MB', toasterOption);
          return false;
        } else {
          setAttachFile(file);
          reader.readAsDataURL(file);
          reader.onloadend = (e) => {
            if (reader.result) {
              setFilePreReader(reader.result);
            }
          };
          setFilePreUrl(e.target.value);
        }
      }
    }
  };

  const onCreateItem = async () => {
    if (!attachFile || !formValues.validname) {
      toast.error('Please upload file or collection name is not valid!', toasterOption);
      return;
    }

    const currAddress = localStorage.getItem('address');
    if (!currAddress) return;

    const payload = {
      collectionFile: attachFile,
      collectionName: formValues.name,
      collectionDesc: formValues.description,
      currAddress: currAddress.toLowerCase(),
      isPrivate,
    };

    const res = await CreateCollectionItem(payload);
    const { success } = res.data;
    if (!success) {
      toast.error('Failed to create collection!', toasterOption);
    } else {
      history.push('/');
      toast.success('New collection has been created!', toasterOption);
    }
  };

  const CheckedChange = (e) => {
    if (e && e.target && e.target.name) {
      if (e.target.name === 'isPrivate') {
        setIsPrivate(!isPrivate);
      }
    }
  };

  return (
    <div className="inner_header">
      <Header
        fixed
        color="transparent"
        routes={[]}
        brand={<img src={logImage} alt="logo" className="img-fluid" />}
        rightLinks={<HeaderLinks />}
        changeColorOnScroll={{
          height: 50,
          color: 'dark',
        }}
        {...rest}
      />

      <div className="inner_pageheader">
        <div className={classes.container}>
          <Link to="/create" className="d-flex align-items-center">
            <img src={arrowIcon} alt="logo" className="arrow_icon" />
            <h2 className="inner_title">Create Multiple Collectible</h2>
          </Link>
        </div>

        <div className="container">
          <GridContainer>
            <GridItem xs={12} sm={4} md={3}>
              <label className="primary_label">Preview</label>
              <div className="single_collectible">
                <div className="card mb-0">
                  <div className="card__cover">
                    <Preview filePreReader={filePreReader} filePreUrl={filePreUrl} />
                  </div>
                </div>
              </div>
            </GridItem>

            <GridItem xs={12} sm={8} md={9}>
              <form>
                <div className="form-row">
                  <div className="form-group col-md-6">
                    <div className="d-flex justify-content-between align-items-start">
                      <div>
                        <label className="primary_label" htmlFor="inputEmail4">
                          Upload file
                        </label>
                        <p className="form_note">PNG, GIF, WEBP, MP4 or MP3.</p>
                      </div>

                      <label htmlFor="contained-button-file">
                        <Input accept="image/*" id="contained-button-file" type="file" onChange={selectFileChange} />
                        <Button round color="info" component="span">
                          Upload
                        </Button>
                      </label>
                    </div>
                  </div>
                </div>

                <div className="form-row">
                  <div className="form-group col-md-6 d-flex flex-column">
                    <label className="primary_label">Name</label>
                    <Input
                      name="name"
                      defaultValue={formValues.name}
                      error={!formValues.validname}
                      onChange={handleInputChange}
                    />
                  </div>
                </div>

                <div className="form-row">
                  <div className="form-group col-md-6 d-flex flex-column">
                    <label className="primary_label">
                      Description <span className="text-muted">(Optional)</span>
                    </label>
                    <Input name="description" defaultValue={formValues.description} onChange={handleInputChange} />
                  </div>
                </div>

                <div className="form-row mt-4">
                  <div className="form-group col-xs-12 col-md-6">
                    <div className="d-flex justify-content-between align-items-start">
                      <div className="w-75">
                        <label className="primary_label" htmlFor="isPrivate">
                          Private?
                        </label>
                      </div>
                      <label className="switch toggle_custom">
                        <input type="checkbox" id="isPrivate" name="isPrivate" onChange={CheckedChange} />
                        <span className="slider"></span>
                      </label>
                    </div>
                  </div>
                </div>

                <Button round color="danger" onClick={onCreateItem}>
                  Create Collection
                </Button>
              </form>
            </GridItem>
          </GridContainer>
        </div>
      </div>

      <Footer />
      <ScrollToTopOnMount />
    </div>
  );
};

export default CreateCollection;
