import axios from 'axios';
import { toast } from 'react-toastify';
import config from '../lib/config';

toast.configure();
let toasterOption = config.toasterOption;

export const ToastShow = async (data) => {
  if (data.toast && data.toast.type && data.toast.msg) {
    if (data.toast.type === 'success') {
      toast.success(data.toast.msg, toasterOption);
    } else {
      toast.error(data.toast.msg, toasterOption);
    }
  }
};

export const ipfsmetadatafunciton = async (payload) => {
  var formData = new FormData();
  // if(payload.Image) { formData.append('Image', payload.Image); }
  if (payload.name) {
    formData.append('name', payload.name);
  }
  if (payload.image) {
    formData.append('image', payload.image);
  }
  // if(payload.description) { formData.append('description', payload.description); }

  try {
    let respData = await axios({
      method: 'post',
      url: `${config.vUrl}/opensearoute/ipfsmetadata`,
      data: formData,
    });
    return {
      data: respData.data,
    };
  } catch (err) {
    return {
      // error: err.response.data
    };
  }
};

export const Topseller_List_Action = async (Payload) => {
  try {
    let Resp = await axios({
      method: 'post',
      url: `${config.vUrl}/token/top-seller-list/`,
      data: Payload,
    });
    return Resp;
  } catch (err) {
    return {
      // error: err.response.data
    };
  }
};

export const getcmsdetails = async (data) => {
  try {
    let respData = await axios({
      method: 'get',
      url: `${config.vUrl}/token/getcmsdetails`,
    });
    return {
      loading: false,
      result: respData.data,
    };
  } catch (err) {
    return {
      loading: false,
      error: err,
    };
  }
};

export const CancelBid_Action = async (payload) => {
  try {
    let Resp = await axios({
      method: 'post',
      url: `${config.vUrl}/token/bid/cancel`,
      data: payload,
    });
    ToastShow(Resp.data);
    return {
      data: Resp.data,
    };
  } catch (err) {}
};

export const acceptBId_Action = async (payload) => {
  try {
    let Resp = await axios({
      method: 'post',
      url: `${config.vUrl}/token/bid/accept`,
      data: payload,
    });
    ToastShow(Resp.data);
    return {
      data: Resp.data,
    };
  } catch (err) {}
};
export const Bidding_Detail_Action = async (payload) => {
  try {
    let Resp = await axios({
      method: 'post',
      url: `${config.vUrl}/token/bid/bidtotalamount`,
      data: payload,
    });
    ToastShow(Resp.data);
    return {
      data: Resp.data,
    };
  } catch (err) {}
};

export const BidApply_ApproveAction = async (payload) => {
  try {
    let resp = await axios({
      method: 'post',
      url: `${config.vUrl}/token/bid/apply`,
      data: payload,
    });
    ToastShow(resp.data);

    return {
      data: resp.data,
    };
  } catch (err) {}
};

export const TokenCounts_Get_Detail_Action = async (payload) => {
  try {
    let resp = await axios({
      method: 'post',
      url: `${config.vUrl}/token/tokenCounts`,
      data: payload,
    });
    return {
      data: resp.data,
    };
  } catch (err) {}
};

export const PurchaseNow_Complete_Action = async (payload) => {
  try {
    let resp = await axios({
      method: 'post',
      url: `${config.vUrl}/token/purchase/complete`,
      data: payload,
    });
    return {
      data: resp.data,
    };
  } catch (err) {}
};

export const Send_Complete_Action = async (payload) => {
  try {
    let resp = await axios({
      method: 'post',
      url: `${config.vUrl}/token/send/complete`,
      data: payload,
    });
    return {
      data: resp.data,
    };
  } catch (err) {}
};

export const TokenPricecancel_update_Action = async (payload) => {
  try {
    let resp = await axios({
      method: 'post',
      url: `${config.vUrl}/token/price/calcelprice`,
      data: payload,
    });
    return {
      data: resp.data,
    };
  } catch (err) {}
};

export const TokenPriceChange_update_Action = async (payload) => {
  try {
    let resp = await axios({
      method: 'post',
      url: `${config.vUrl}/token/price/change`,
      data: payload,
    });
    return {
      data: resp.data,
    };
  } catch (err) {}
};
export const TokenCount_Get_Action = async (payload) => {
  try {
    let resp = await axios({
      method: 'get',
      url: `${config.vUrl}/token/count/get`,
      data: payload,
    });
    return {
      data: resp.data,
    };
  } catch (err) {}
};

export const CreateCollectionItem = async (payload) => {
  try {
    const { collectionFile, collectionName, collectionDesc, currAddress, isPrivate } = payload;

    let formData = new FormData();
    if (collectionFile) {
      formData.append('collectionFile', collectionFile);
    }
    if (collectionName) {
      formData.append('collectionName', collectionName);
    }
    if (collectionDesc) {
      formData.append('collectionDesc', collectionDesc);
    }
    if (currAddress) {
      formData.append('currAddress', currAddress);
    }
    if (isPrivate) {
      formData.append('isPrivate', isPrivate);
    }

    let resp = await axios({
      method: 'post',
      url: `${config.vUrl}/token/add/collection`,
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      data: formData,
    });
    return {
      data: resp.data,
    };
  } catch (err) {
    return null;
  }
};

export const ListCollection = async (currAddress) => {
  let resp = await axios({
    method: 'get',
    url: `${config.vUrl}/token/collection/list/${currAddress}`,
  });
  return { data: resp.data };
};

export const TokenAddItemAction = async (payload) => {
  try {
    var formData = new FormData();
    if (payload.Image) {
      formData.append('Image', payload.Image);
    }
    if (payload.ipfsimage) {
      formData.append('ipfshash', payload.ipfsimage);
    }
    if (payload.Name) {
      formData.append('Name', payload.Name);
    }
    if (payload.secondaryFileHash) {
      formData.append('secondaryFileHash', payload.secondaryFileHash);
    }
    if (payload.secondaryFile) {
      formData.append('secondaryFile', payload.secondaryFile);
    }
    if (payload.Count) {
      formData.append('Count', payload.Count);
    }
    if (payload.longDescription) {
      formData.append('longDescription', payload.longDescription);
    }
    if (payload.shortDescription) {
      formData.append('shortDescription', payload.shortDescription);
    }
    if (payload.Price) {
      formData.append('Price', payload.Price);
    }
    if (payload.Royalities) {
      formData.append('Royalities', payload.Royalities);
    }
    if (payload.Category_label) {
      formData.append('Category_label', payload.Category_label);
    }
    if (payload.Bid) {
      formData.append('Bid', payload.Bid);
    }
    if (payload.Properties) {
      formData.append('Properties', payload.Properties);
    }
    if (payload.Owner) {
      formData.append('Owner', payload.Owner);
    }
    if (payload.Creator) {
      formData.append('Creator', payload.Creator);
    }
    if (payload.CategoryId) {
      formData.append('CategoryId', payload.CategoryId);
    }
    if (payload.CollectionId) {
      formData.append('CollectionId', payload.CollectionId);
    }
    if (payload.CollectionLabel) {
      formData.append('CollectionLabel', payload.CollectionLabel);
    }
    if (payload.Quantity) {
      formData.append('Quantity', payload.Quantity);
    }
    if (payload.Balance) {
      formData.append('Balance', payload.Balance);
    }
    if (payload.ContractAddress) {
      formData.append('ContractAddress', payload.ContractAddress);
    }
    if (payload.Status) {
      formData.append('Status', payload.Status);
    }
    if (payload.HashValue) {
      formData.append('HashValue', payload.HashValue);
    }
    if (payload.Type) {
      formData.append('Type', payload.Type);
    }
    if (payload.MinimumBid) {
      formData.append('MinimumBid', payload.MinimumBid);
    }
    if (payload.EndClocktime) {
      formData.append('EndClocktime', payload.EndClocktime);
    }
    if (payload.Clocktime) {
      formData.append('Clocktime', payload.Clocktime);
    }
    if (payload.UnLockcontent) {
      formData.append('UnLockcontent', payload.UnLockcontent);
    }
    if (payload.biddingtoken) {
      formData.append('biddingtoken', payload.biddingtoken);
    }

    if (payload.PutOnSale) {
      formData.append('PutOnSale', payload.PutOnSale);
    }
    if (payload.PutOnSaleType) {
      formData.append('PutOnSaleType', payload.PutOnSaleType);
    }
    if (payload.isPrivate) {
      formData.append('isPrivate', payload.isPrivate);
    }
    if (payload.isMinting) {
      formData.append('isMinting', payload?.isMinting);
    }

    if (payload.legalAgreement) {
      formData.append('legalAgreementOption', payload?.legalAgreement.option);
      formData.append('legalAgreementText', payload?.legalAgreement.text);
    }

    let respData = await axios({
      method: 'post',
      url: `${config.vUrl}/token/add/item`,
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      data: formData,
    });
    return { data: respData.data };
  } catch (err) {
    return { error: err };
  }
};

export const TokenAddOwnerAction = async (payload) => {
  try {
    var SendData = {};
    if (payload.Count) {
      SendData.Count = payload.Count;
    }
    if (payload.Price) {
      SendData.Price = payload.Price;
    }
    if (payload.Owner) {
      SendData.Owner = payload.Owner;
    }
    if (payload.Balance) {
      SendData.Balance = payload.Balance;
    }
    if (payload.Quantity) {
      SendData.Quantity = payload.Quantity;
    }
    if (payload.ContractAddress) {
      SendData.ContractAddress = payload.ContractAddress;
    }
    if (payload.Type) {
      SendData.Type = payload.Type;
    }
    if (payload.HashValue) {
      SendData.HashValue = payload.HashValue;
    }
    if (payload.Status) {
      SendData.Status = payload.Status;
    }
    SendData.PutOnSaleType = payload.PutOnSaleType;
    let resp1Data = await axios({
      method: 'post',
      url: `${config.vUrl}/token/add/owner`,
      data: SendData,
    });
    return { data: resp1Data.data };
  } catch (err) {
    return {
      //errors: err.response.data
    };
  }
};

export const CreateTokenValidationAction = async (payload) => {
  try {
    let respData = await axios({
      method: 'post',
      url: `${config.vUrl}/token/add/item/validation`,
      data: payload,
    });
    return {
      data: respData.data,
    };
  } catch (err) {}
};

export const hotcollectionlists = async () => {
  try {
    let respData = await axios({
      method: 'get',
      url: `${config.vUrl}/token/token/hotcollectionlist`,
    });
    return {
      data: respData.data,
    };
  } catch (err) {}
};

export const licensedlists = async () => {
  try {
    let respData = await axios({
      method: 'get',
      url: `${config.vUrl}/token/token/licensedlist`,
    });
    return {
      data: respData.data,
    };
  } catch (err) {}
};

export const liveauctionlists = async () => {
  try {
    let respData = await axios({
      method: 'get',
      url: `${config.vUrl}/token/token/liveauctionlist`,
    });
    return {
      data: respData.data,
    };
  } catch (err) {}
};

export const gettop3lsit = async () => {
  try {
    let respData = await axios({
      method: 'get',
      url: `${config.vUrl}/token/token/top3list`,
    });
    return {
      data: respData.data,
    };
  } catch (err) {}
};

export const GetCategoryAction = async (payload) => {
  try {
    let respData = await axios({
      method: 'get',
      url: `${config.vUrl}/token/category/list`,
      data: payload,
    });
    return {
      data: respData.data,
    };
  } catch (err) {}
};

export const GetLikeDataAction = async (payload) => {
  try {
    let respData = await axios({
      method: 'post',
      url: `${config.vUrl}/token/like/list`,
      data: payload,
    });
    return {
      data: respData.data,
    };
  } catch (err) {}
};

export const AddLikeAction = async (payload) => {
  try {
    let resp = await axios({
      method: 'post',
      url: `${config.vUrl}/token/like`,
      data: payload,
    });
    return {
      data: resp.data,
    };
  } catch (err) {}
};

export const CollectiblesList_MyItems = async (payload) => {
  try {
    let resp = await axios({
      method: 'post',
      url: `${config.vUrl}/token/collectibles/list/myitems`,
      data: payload,
    });
    return {
      data: resp.data,
    };
  } catch (err) {}
};

export const CollectiblesList_Home = async (payload) => {
  try {
    let resp = await axios({
      method: 'post',
      url: `${config.vUrl}/token/collectibles/list/home`,
      data: payload,
    });
    return {
      data: resp.data,
    };
  } catch (err) {}
};

export const otherauthorlist = async (payload) => {
  try {
    let resp = await axios({
      method: 'post',
      url: `${config.vUrl}/token/collectibles/list/info`,
      data: payload,
    });
    return {
      data: resp.data,
    };
  } catch (err) {}
};

export const CollectiblesList_Follow = async (payload) => {
  try {
    let resp = await axios({
      method: 'post',
      url: `${config.vUrl}/token/collectibles/list/follow`,
      data: payload,
    });
    return {
      data: resp.data,
    };
  } catch (err) {}
};

export async function activityUpdate(data) {
  try {
    let checkAddr = await axios({
      method: 'post',
      url: `${config.vUrl}/token/test/activityUpdate`,
      data: data,
    });
    return {
      data: checkAddr.data,
    };
  } catch (err) {
    return {
      // error: err.response.data
    };
  }
}

///by muthu

// convert
export const convertion = async (data) => {
  try {
    let respData = await axios({
      method: 'get',
      url: 'https://min-api.cryptocompare.com/data/price?fsym=BNB&tsyms=USD',
    });
    return {
      data: respData.data,
    };
  } catch (err) {
    return {
      // error: err.response.data
    };
  }
};
export const BurnField = async (data) => {
  try {
    await axios({
      method: 'post',
      url: `${config.vUrl}/token/BurnField`,

      data: data,
    });
    return {
      loading: false,
    };
  } catch (err) {
    return {
      loading: false,
      error: err.response.data.errors,
    };
  }
};

export const ipfsmetadatafuncitonNew = async (payload) => {
  var formData = new FormData();
  // if(payload.Image) { formData.append('Image', payload.Image); }
  if (payload.tokenId) {
    formData.append('tokenId', payload.tokenId);
  }
  if (payload.image) {
    formData.append('image', payload.image);
  }
  if (payload.TokenName) {
    formData.append('TokenName', payload.TokenName);
  }

  // if(payload.description) { formData.append('description', payload.description); }

  try {
    let respData = await axios({
      method: 'post',
      url: `${config.vUrl}/token/ipfsmetadatahash`,
      data: formData,
    });
    return {
      data: respData.data,
    };
  } catch (err) {
    return {
      // error: err.response.data
    };
  }
};

export const ipfsImageHashGet = async (payload) => {
  var formData = new FormData();
  if (payload.Image) {
    formData.append('Image', payload.Image);
    formData.append('tokenid', payload.tokenid);
    formData.append('TokenName', payload.TokenName);
    if (payload.secondaryContent) {
      formData.append('secondaryContent', payload.secondaryContent);
      formData.append('categoryId', payload.category.value);
    }
  }

  try {
    let respData = await axios({
      method: 'post',
      url: `${config.vUrl}/token/ipfsImageHashGet`,
      data: formData,
    });
    return {
      data: respData.data,
    };
  } catch (err) {
    return {
      // error: err.response.data
    };
  }
};

export const HistoryList = async (payload) => {
  try {
    let resp = await axios({
      method: 'post',
      url: `${config.vUrl}/token/token-history`,
      data: payload,
    });
    return {
      data: resp.data,
    };
  } catch (err) {}
};

export const transferToken = async (payload) => {
  try {
    let res = await axios({
      method: 'post',
      url: `${config.vUrl}/token/transferNFTs`,
      data: payload,
    });
    return {
      data: res?.data,
      status: res?.status,
    };
  } catch (error) {}
};
